/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setSalesHistory } from "../../features/salesHistory/salesHistoryReducer";
import { setLoadingGetShoppingHistory } from "../../features/loading/historySale/loadingsReducer";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpGetShoppingHistory = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetShoppingHistoryAsync =
    (
      RecordsForPage: number,
      Page: number,
      TotalRecords: number,
      Search: string,
      statusFilter: number,
      dateStart: string,
      dateEnd: string
    ): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingGetShoppingHistory(true));
      try {
        const response = await fetch(
          `${env.operaciones}${
            Routes.getShoppingHistory
          }?RecordsForPage=${RecordsForPage}&Page=${Page}&TotalRecords=${TotalRecords}&FiltroBusqueda=${Search}${
            statusFilter !== 0 ? `&EstadoPago=${statusFilter}` : ""
          }${dateStart !== "" ? `&FechaDesde=${dateStart}` : ""}${
            dateEnd !== "" ? `&FechaHasta=${dateEnd}` : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);

        if (response.status === 200) {
          const data = await response.json();
          // Guardar en el store
          dispatch(setSalesHistory(data));
        } else {
          viewNotification("Error al cargar historial de compras", "info");
        }
        dispatch(setLoadingGetShoppingHistory(false));
      } catch (error) {
        viewNotification("Error al cargar historial de compras", "warning");
        dispatch(setLoadingGetShoppingHistory(false));
      }
    };
  return { httpGetShoppingHistoryAsync };
};

export default useHttpGetShoppingHistory;
