import { useState, useEffect } from "react";
import IItemStyled from "../types/IItemStyled";

const useCategoriesHomeHook = () => {
  const categories: string[] = ["Aceites", "Estereos", "Ruedas", "Repuestos"];

  const [mostViewedCategories, setMostViewedCategories] = useState("Repuestos");

  // Función para cambiar categoría cada 2 segundos
  const changingBillboard = () => {
    let i = 0;
    setInterval(() => {
      if (i === categories.length) {
        i = 0;
      }
      setMostViewedCategories(categories[i]);
      i++;
    }, 1200);
  };

  // Función para asignar color de categoria
  const changingColor = () => {
    if (mostViewedCategories === "Aceites") {
      return "#1BB404";
    }
    if (mostViewedCategories === "Estereos") {
      return "#D90AAB";
    }
    if (mostViewedCategories === "Ruedas") {
      return "#7B61FF";
    }
    if (mostViewedCategories === "Repuestos") {
      return "#FFAC33";
    }
    return "purple";
  };

  useEffect(() => {
    changingBillboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Animación categorías mas vistas
  const [item1, setItem1] = useState<IItemStyled>({
    width: 33,
    height: 110,
  });
  const [item2, setItem2] = useState<IItemStyled>({
    width: 27,
    height: 90,
  });
  const [item3, setItem3] = useState<IItemStyled>({
    width: 40,
    height: 100,
  });
  const [item4, setItem4] = useState<IItemStyled>({
    width: 33,
    height: 90,
    align_self: "flex-end",
  });
  const [item5, setItem5] = useState<IItemStyled>({
    width: 27,
    height: 110,
    align_self: "flex-end",
  });
  const [item6, setItem6] = useState<IItemStyled>({
    width: 40,
    height: 100,
    align_self: "flex-end",
  });

  const item1AnimationHandle = () => {
    setItem1({ width: 57, height: 100 });
    setItem2({ width: 33, height: 100 });
    setItem3({ width: 30, height: 100 });
    setItem4({ width: 30, height: 100, align_self: "flex-end" });
    setItem5({ width: 30, height: 100, align_self: "flex-end" });
  };

  const item2AnimationHandle = () => {
    setItem1({ width: 17, height: 110 });
    setItem2({ width: 43, height: 110 });
    setItem3({ width: 42, height: 100 });
    setItem4({ width: 28, height: 90, align_self: "flex-end" });
    setItem5({ width: 32, height: 90, align_self: "flex-end" });
  };

  const item3AnimationHandle = () => {
    setItem1({ width: 17, height: 110 });
    setItem2({ width: 35, height: 110 });
    setItem3({ width: 55, height: 110 });
    setItem4({ width: 30, height: 90, align_self: "flex-end" });
    setItem5({ width: 30, height: 90, align_self: "flex-end" });
    setItem6({ width: 40, height: 90, align_self: "flex-end" });
  };

  const item4AnimationHandle = () => {
    setItem1({ width: 33, height: 110 });
    setItem2({ width: 34, height: 110 });
    setItem3({ width: 33, height: 110 });
    setItem4({ width: 50, height: 90, align_self: "flex-end" });
    setItem5({ width: 25, height: 90, align_self: "flex-end" });
    setItem6({ width: 25, height: 90, align_self: "flex-end" });
  };

  const item5AnimationHandle = () => {
    setItem1({ width: 31, height: 110 });
    setItem2({ width: 38, height: 60 });
    setItem3({ width: 31, height: 100 });
    setItem4({ width: 31, height: 90, align_self: "flex-end" });
    setItem5({ width: 38, height: 140, align_self: "flex-end" });
    setItem6({ width: 31, height: 100, align_self: "flex-end" });
  };

  const item6AnimationHandle = () => {
    setItem1({ width: 33, height: 100 });
    setItem2({ width: 27, height: 100 });
    setItem3({ width: 40, height: 100 });
    setItem4({ width: 25, height: 100, align_self: "flex-end" });
    setItem5({ width: 25, height: 100, align_self: "flex-end" });
    setItem6({ width: 50, height: 100, align_self: "flex-end" });
  };

  const CleanAnimation = () => {
    setItem1({ width: 33, height: 110 });
    setItem2({ width: 27, height: 90 });
    setItem3({ width: 40, height: 100 });
    setItem4({ width: 33, height: 90, align_self: "flex-end" });
    setItem5({ width: 27, height: 110, align_self: "flex-end" });
    setItem6({ width: 40, height: 100 });
  };
  return {
    categories,
    mostViewedCategories,
    changingColor,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item1AnimationHandle,
    item2AnimationHandle,
    item3AnimationHandle,
    item4AnimationHandle,
    item5AnimationHandle,
    item6AnimationHandle,
    CleanAnimation,
  };
};

export default useCategoriesHomeHook;
