/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import { useState, useEffect } from "react";
// Redux
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setIsRequired } from "../../../features/partnerBusinessForm/partnerBusinessFormReducer";
// Services
import useHttpObtenerTiposInmuebles from "../../../services/BuyForm/useHttpObtenerTiposInmuebles";
import useHttpObtenerCondicionesIva from "../../../services/BuyForm/useHttpObtenerCondicionesIva";
import useHttpObtenerProvinciasPorPais from "../../../services/BuyForm/useHttpObtenerProvinciasPorPais";
import useHttpObtenerDatosSocioDeNegocio from "../../../services/BuyForm/useHttpObtenerDatosSocioDeNegocio";
import useHttpObtenerSocioDeNegocioPorEmail from "../../../services/BuyForm/useHttpObtenerSocioDeNegocioPorEmail";
import useHttpObtenerLocalidadesPorProvincia from "../../../services/BuyForm/useHttpObtenerLocalidadesPorProvincia";
// Models
import { ILocalidades } from "../../../models/BuyFormModels/Localidades";
import { UserData } from "../../../models/UserData";
// Hooks
import useDebounce from "../../../hooks/useDebounce";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

interface IFormHook {
  formData: PartnerBusinessFormData;
  setFormData: (formData: PartnerBusinessFormData) => void;
}

const useFormPartnetBusinessHook = ({ setFormData, formData }: IFormHook) => {
  const dispatch = useAppDispatch();

  const { httpObtenerProvinciasPorPaisAsync } =
    useHttpObtenerProvinciasPorPais();
  const { httpObtenerDatosSocioDeNegocioAsync } =
    useHttpObtenerDatosSocioDeNegocio();
  const { httpObtenerLocalidadesPorProvinciaAsync } =
    useHttpObtenerLocalidadesPorProvincia();
  const { httpObtenerTiposInmueblesAsync } = useHttpObtenerTiposInmuebles();
  const { httpObtenerCondicionesIvaAsync } = useHttpObtenerCondicionesIva();
  const { httpObtenerSocioDeNegocioPorEmailAsync } =
    useHttpObtenerSocioDeNegocioPorEmail();
  const { obtenerUserData } = useLocalStorageHook();

  const userParsed: UserData = obtenerUserData() || ({} as UserData);
  // Form global states
  const tiposInmuebles = useAppSelector(
    (state) => state.partnerBusinessForm.tiposInmuebles
  );
  const provincias = useAppSelector(
    (state) => state.partnerBusinessForm.provincias
  );
  const localidades = useAppSelector(
    (state) => state.partnerBusinessForm.localidades
  );
  const isLoadingLocalidades = useAppSelector(
    (state) => state.isLoadingBuyForm.loadingLocalidades
  );
  const condicionesIva = useAppSelector(
    (state) => state.partnerBusinessForm.condicionesIva
  );
  const DatosSocioDeNegocio = useAppSelector(
    (state) => state.partnerBusinessForm.datosSocioNegocio
  );
  const [renderCounter, setRenderCounter] = useState(0);

  const [page, setPage] = useState(1);

  const [prevFiltroCPLocalidad, setPrevFiltroCPLocalidad] = useState("");
  const [filtroCPLocalidad, setFiltroCPLocalidad] = useState("");

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "provinciaId") {
      setFiltroCPLocalidad("");
      setPrevFiltroCPLocalidad("");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /** Guardar en el formData el código postal y la LocalidadId */
  const handleChangeLocalidad = (data: ILocalidades) => {
    setFiltroCPLocalidad(`${data.nombre} - CP:${data.codigoPostal}`);
    setPrevFiltroCPLocalidad(`${data.nombre} - CP:${data.codigoPostal}`);
    setFormData({
      ...formData,
      localidadId: data.id.toString(),
      codigoPostal: data.codigoPostal,
    });
  };

  /** Carga las localidades del combo desplegable */
  const actualizarLocalidades = (resetPage?: number) => {
    if (!formData.provinciaId) return;
    const pagina = resetPage || page;
    dispatch(
      httpObtenerLocalidadesPorProvinciaAsync(
        formData.provinciaId,
        filtroCPLocalidad,
        pagina
      )
    );
  };

  const debounceFilter = useDebounce(filtroCPLocalidad, 500);
  // UseEffect para consultar el socio de negocio por email cuando el formData.email no sea vacio
  useEffect(() => {
    if (formData.direccion !== "") {
      dispatch(httpObtenerSocioDeNegocioPorEmailAsync(formData.direccion));
    }
  }, [formData.direccion]);

  useEffect(() => {
    setFormData({
      ...formData,
      localidadId: "",
      codigoPostal: "",
    });
    actualizarLocalidades();
  }, [formData.provinciaId, page]);

  useEffect(() => {
    if (formData?.localidadId && formData.localidadId?.toString() !== "") {
      dispatch(setIsRequired({ name: "localidadId", value: false }));
    }
  }, [formData.localidadId]);

  useEffect(() => {
    if (renderCounter < 3) {
      setRenderCounter((prevState) => prevState + 1);
      return;
    }
    if (
      prevFiltroCPLocalidad !== "" &&
      prevFiltroCPLocalidad !== filtroCPLocalidad
    ) {
      setFormData({
        ...formData,
        localidadId: "",
        codigoPostal: "",
      });
    }
    setPrevFiltroCPLocalidad(filtroCPLocalidad);
    actualizarLocalidades(1);
  }, [debounceFilter]);

  useEffect(() => {
    if (formData.tipoPersonaId?.toString() === "2") {
      setFormData({
        ...formData,
        nombre: "",
        apellido: "",
        documento: "",
        tipoDocumentoId: "",
      });
      dispatch(setIsRequired({ name: "nombre", value: false }));
      dispatch(setIsRequired({ name: "apellido", value: false }));
      dispatch(setIsRequired({ name: "documento", value: false }));
      dispatch(setIsRequired({ name: "razonSocial", value: true }));
      dispatch(setIsRequired({ name: "tipoDocumentoId", value: false }));
    } else {
      setFormData({
        ...formData,
        razonSocial: "",
      });
      dispatch(setIsRequired({ name: "razonSocial", value: false }));
    }
  }, [formData.tipoPersonaId]);

  useEffect(() => {
    if (formData.tipoInmuebleId?.toString() !== "2") {
      setFormData({
        ...formData,
        piso: "",
        departamento: "",
      });
      dispatch(setIsRequired({ name: "piso", value: false }));
      dispatch(setIsRequired({ name: "departamento", value: false }));
    } else {
      dispatch(setIsRequired({ name: "piso", value: true }));
      dispatch(setIsRequired({ name: "departamento", value: true }));
    }
  }, [formData.tipoInmuebleId]);

  // Agrupar todas las peticiones en una sola función
  const fetchData = async () => {
    await dispatch(httpObtenerTiposInmueblesAsync());
    await dispatch(httpObtenerCondicionesIvaAsync());
    await dispatch(httpObtenerProvinciasPorPaisAsync());
  };

  // Cuando carga el componente rellenar los datos que existen del usuario
  useEffect(() => {
    dispatch(httpObtenerDatosSocioDeNegocioAsync());
    if (userParsed) {
      setFormData({
        ...formData,
        nombre: userParsed.nombre,
        apellido: userParsed.apellido,
        direccion: userParsed.email,
      });
    }
    fetchData();
  }, []);

  // Setear datos precargados de socio de negocio
  useEffect(() => {
    if (DatosSocioDeNegocio.email !== "") {
      const numeroContacto =
        DatosSocioDeNegocio.telefonoFijo === ""
          ? DatosSocioDeNegocio.telefonoMovil
          : DatosSocioDeNegocio.telefonoFijo;
      // numeroContacto separado por guiones
      const numeroContactoSeparado = numeroContacto
        ? numeroContacto.split("-")
        : [];
      const numeroSolo = numeroContactoSeparado[2] ?? "";
      const codigoArea = numeroContactoSeparado[1] ?? "";

      const domicilio = DatosSocioDeNegocio.domicilios
        ? DatosSocioDeNegocio?.domicilios[0]
        : null;
      setFormData({
        ...formData,
        numeroTel: numeroSolo,
        prefijoLocalidad: codigoArea,
        nombre: DatosSocioDeNegocio.nombre,
        direccion: DatosSocioDeNegocio.email,
        apellido: DatosSocioDeNegocio.apellido,
        razonSocial: DatosSocioDeNegocio.razonSocial || "",
        documento: DatosSocioDeNegocio.numeroDocumento,
        tipoPersonaId: DatosSocioDeNegocio.tipoPersonaId?.toString() || "",
        condicionIvaId: DatosSocioDeNegocio.condicionIvaId?.toString() || "",
        identificacionTributaria:
          DatosSocioDeNegocio.numeroIdenticacionTributaria,
        tipoIdentificacionTributariaId:
          DatosSocioDeNegocio.tipoIdentificacionTributariaId?.toString() || "2",
        piso: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.piso
          : "",
        tipoDocumentoId: DatosSocioDeNegocio.tipoDocumentoID?.toString() || "6",
        calle: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.calle
          : "",
        numero: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.numero
          : "",
        localidadId: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.localidadId
          : "",
        domicilioId: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.id?.toString() ?? null
          : null,
        provinciaId: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.provinciaId
          : "",
        codigoPostal: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.codigoPostal
          : "",
        departamento: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.departamento
          : "",
        tipoInmuebleId: DatosSocioDeNegocio.domicilios
          ? DatosSocioDeNegocio?.domicilios[0]?.tipoInmuebleId ?? ""
          : "",
        tipoTelefonoId:
          DatosSocioDeNegocio.telefonoFijo?.toString() === "" ? "1" : "0",
        socioNegocioId: DatosSocioDeNegocio.socioNegocioId,
      });

      setFiltroCPLocalidad(
        `${domicilio?.localidadNombre ?? ""} - CP:${
          domicilio?.codigoPostal ?? ""
        }`
      );
    }
  }, [DatosSocioDeNegocio]);

  return {
    filtroCPLocalidad,
    setPage,
    page,
    setFiltroCPLocalidad,
    provincias,
    localidades,
    tiposInmuebles,
    condicionesIva,
    handleInputChange,
    isLoadingLocalidades,
    handleChangeLocalidad,
    actualizarLocalidades,
  };
};

export default useFormPartnetBusinessHook;
