import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingSubcategories } from "../../../../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import { addSubcategories } from "../../../../features/categorization/definitions/subcategoriesReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpSubcategories() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpSubcategoriesAsync = (): AppThunk => async (dispatch) => {
    dispatch(setLoadingSubcategories(true));
    try {
      const response = await fetch(
        `${env.catalogo}${Routes.getSubcategories}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            empresaId: empresaId.toString(),
          },
        }
      );
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (data) {
        dispatch(addSubcategories(data));
      }
      dispatch(setLoadingSubcategories(false));
    } catch (error) {
      setLoadingSubcategories(false);
    }
    setLoadingSubcategories(false);
  };
  return {
    httpSubcategoriesAsync,
  };
}

export default useHttpSubcategories;
