import React, { useState } from "react";
import { ICategorization } from "../models/Articles.model";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import useHttpArticles from "../../../services/Categorization/Articles/useHttpArticles";
import { InputPageStyled, ContainerPaginationStyled, NextButtonStyled } from "../styles/PaginationStyled";

interface IPagination {
  filterCode: string;
  valuePerPage: number;
  filterDescription: string;
  resetPaginationToggle: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setResetPaginationToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const Pagination = ({
  filterCode,
  setCurrentPage,
  valuePerPage,
  filterDescription,
  resetPaginationToggle,
  setResetPaginationToggle,
}: IPagination) => {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector(
    (state) => state.categorization as ICategorization
  );
  const { httpArticlesAsync } = useHttpArticles();

  const totalPages = Math.ceil(pagination.totalAllRecords / 10);

  const [page, setPage] = useState<string>(pagination.page.toString());

  const handleNextPage = () => {
    if (pagination.page === totalPages) return;
    setCurrentPage(pagination.page + 1);
    dispatch(
      httpArticlesAsync(
        filterDescription,
        filterCode,
        pagination.page + 1,
        valuePerPage
      )
    );
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handlePrevPage = () => {
    if (pagination.page === 1) return;
    setCurrentPage(pagination.page - 1);
    dispatch(
      httpArticlesAsync(
        filterDescription,
        filterCode,
        pagination.page - 1,
        valuePerPage
      )
    );
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const inputHandler = (e: any) => {
    if (e.target.value === "" || Number(e.target.value) > totalPages) return;
    setPage(e.target.value);
  };

  // Cuando se presiona Enter
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      dispatch(
        httpArticlesAsync(
          filterDescription,
          filterCode,
          Number(page),
          valuePerPage
        )
      );
    }
  };

  return (
    <ContainerPaginationStyled>
      <div>Resultados totales: {pagination.totalAllRecords}</div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {pagination.page !== 1 && (
          <NextButtonStyled onClick={handlePrevPage}>{"<"}</NextButtonStyled>
        )}
        <div>
          <InputPageStyled
            type="number"
            value={page}
            onClick={() => { setPage(""); }}
            onChange={(e) => { inputHandler(e); }}
            onKeyDown={handleKeyDown}
          />
        </div>
        {pagination.page !== totalPages && (
          <>
            de {totalPages}
            <NextButtonStyled onClick={handleNextPage}>{">"}</NextButtonStyled>
          </>
        )}
      </div>
    </ContainerPaginationStyled>
  );
};

export default Pagination;
