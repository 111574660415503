import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IVirtualAssistant {
    viewTab: boolean;
    viewAssistant: boolean;
    }

const initialState: IVirtualAssistant = {
  viewTab: false,
  viewAssistant: false,
};
export const virtualAssistantSlice = createSlice({
  name: "virtualAssistant",
  initialState,
  reducers: {
    setViewTab: (state, action: PayloadAction<boolean>) => {
      state.viewTab = action.payload;
    },
    setViewAssistant: (state, action: PayloadAction<boolean>) => {
      state.viewAssistant = action.payload;
    },
  },
});

export const { setViewTab, setViewAssistant } = virtualAssistantSlice.actions;

export default virtualAssistantSlice.reducer;
