/* eslint-disable indent */
import styled from "styled-components";

export const ContainerHistoryStyled = styled.div`
  padding: 10px;
`;

export const CardSaleStyled = styled.div`
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  padding: 10px 5px 10px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e5e5e5;
  }

  .icon {
    grid-area: icon;
  }
`;

export const HeaderCardStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
  justify-items: start;
  align-items: center;
`;

export const HeaderHistoryCardStyled = styled.div`
  display: grid;
  grid:
    "venta estado precio icon" 1fr
    "fecha medioDePago cliente icon" 1fr
    / 2fr 2fr 2fr 1fr;
  gap: 8px;
  justify-items: start;
  align-items: center;
`;

export const FooterCardStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 10px;
  margin-top: 15px;
  justify-items: start;
  align-items: center;
`;

export const ShowDetailsStyled = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  height: 25px;
  width: 25px;
  margin-left: 40px;
  border-radius: 50%;
  color: white;
  grid-area: icon;
`;

export const CompraStyled = styled.b`
  font-size: 19px;
  grid-area: venta;
`;

export const FechaStyled = styled.p`
  color: gray;
  font-size: 14px;
  margin: 0;
  padding: 0;
  grid-area: fecha;
`;

interface IStatus {
  status: "PAGADO" | "PENDIENTE" | "CANCELADO" | "ARREPENTIDO";
}

export const EstatusStyled = styled.b<IStatus>`
  color: ${(props) =>
    props.status === "PAGADO"
      ? "#009800"
      : props.status === "PENDIENTE"
      ? "#e8ae00"
      : props.status === "ARREPENTIDO"
      ? "#0026ff"
      : "#ff0000"};
  background-color: ${(props) =>
    props.status === "PAGADO"
      ? "#d6f5d6"
      : props.status === "PENDIENTE"
      ? "#fff5d8"
      : props.status === "ARREPENTIDO"
      ? "#e6e6ff"
      : "#ffe6e6"};
  border-radius: 15px;
  font-size: 12px;
  margin: 0;
  padding: 3px 10px;
  grid-area: estado;
`;

export const MedioPagoStyled = styled.p`
  margin: 0;
  padding: 0;
  color: gray;
  font-size: 14px;
  grid-area: medioDePago;
`;

export const PrecioStyled = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  grid-area: precio;
`;

export const CantidadDeArticulosStyled = styled.p`
  margin: 0;
  padding: 0;
  color: gray;
  font-size: 14px;
  grid-area: cliente;
`;

export const DetailStyled = styled.p`
  margin: 0;
  padding: 0;
  color: gray;
  font-size: 14px;
  text-transform: capitalize;
`;

export const InfoClienteStyled = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

export const SearchContainerStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(230px, 2fr) repeat(4, 1fr);
  align-items: center;
  justify-items: stretch;
  gap: 10px;
  padding-right: 10px;
  margin-bottom: 10px;

  @media (max-width: 1445px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const TitleStyled = styled.h2`
  margin: 0 0 0 10px;
  padding: 0;
`;

export const ContainerSearchStyled = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1445px) {
    grid-column: 2 / 6;
    grid-row: 1 / 2;
  }
`;

export const SearchInputStyled = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  min-width: 100px;
  &:focus {
    outline: none;
  }
`;

export const SelectStatusStyled = styled.select`
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  min-width: 170px;
  &:focus {
    outline: none;
  }

  @media (max-width: 1445px) {
    grid-column: 3 / 6;
    grid-row: 2 / 3;
  }
`;

export const DateSelectorStyled = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  min-width: 150px;
  &:focus {
    outline: none;
  }
`;

export const ContainerDateSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
`;

// Modal
export const ModalContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;
