import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Articulo } from "../../models/Articulo";
// import { ICard } from '../../types/Card';
// data de test:
// import {data} from '../../mookups/dataTest'

interface ISearch {
  search: string;
  suggestSearch: string[];
  results: Articulo[];
  pagination: any;
  page: number;
}

type CardsParam = {
  cards: Articulo[];
};

const initialState: ISearch = {
  search: "",
  suggestSearch: [],
  results: [],
  pagination: [],
  page: 1,
};

export const searchSlice = createSlice({
  name: "search",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    addSuggestSearch: (state, action: PayloadAction<string[]>) => {
      state.suggestSearch = action.payload;
    },
    setResult: (state, action: PayloadAction<CardsParam>) => {
      state.results = action.payload.cards;
    },
    resetSearchAndResults: (state) => {
      state.results = [];
      state.search = "";
    },
    setPagination: (state, action: PayloadAction<any>) => {
      state.pagination = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  setPage,
  addSearch,
  setResult,
  setPagination,
  addSuggestSearch,
  resetSearchAndResults,
} = searchSlice.actions;

export default searchSlice.reducer;
