import styled from "styled-components";

interface IModalProps {
  show: boolean;
  width?: string;
  height?: string;
}

export const ContainerStyled = styled.div<IModalProps>`
  position: fixed;
  inset: 0;
  margin: auto;
  width: ${(props) => (props.width ? props.width : "50%")};
  height: ${(props) => (props.height ? props.height : "30%")}; 
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  padding: 25px 10px 10px 10px;
  overflow: auto;
  z-index: 1001;
`;

export const ContainerClose = styled.div`
  align-self: flex-end;
  position: relative;
  top: -15px;
  right: -0px;
  cursor: pointer;
`;
