/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setLoadingDownloadTransferAttachments } from "../../../features/loading/transferAttachments/loadingReducer";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

const useHttpDownloadTransferAttachments = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpDownloadTransferAttachmentsAsync = (adjuntoFileKey: string): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingDownloadTransferAttachments(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.downloadTransfer}?adjuntoFileKey=${adjuntoFileKey}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);

        const esImagen = !!response.headers
          .get("Content-Type")
          ?.includes("image");
        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          // Determina la extensión basada en el tipo MIME del blob
          const extension = esImagen ? ".png" : ".pdf";
          // Asegura que el nombre del archivo tenga la extensión correcta
          const fileName = adjuntoFileKey.includes(".")
            ? adjuntoFileKey
            : `${adjuntoFileKey}${extension}`;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          viewNotification("Error al descargar datos", "info");
        }
        dispatch(setLoadingDownloadTransferAttachments(false));
      } catch (error) {
        viewNotification("Error al descargar datos", "warning");
        dispatch(setLoadingDownloadTransferAttachments(false));
      }
    };
  return { httpDownloadTransferAttachmentsAsync };
};

export default useHttpDownloadTransferAttachments;
