import { ChangeEvent, useEffect, useState } from "react";
// Styled
import {
  Title,
  ContainerStyled,
  ImputTokenStyled,
  ContainerButtonsStyled,
} from "../styles/ManagerPageStyled";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../components/button/Button";
import useHttpGetTokenDms from "../../../services/AppConfiguration/useHttpGetTokenDms";
import useHttpUpdateTokenDms from "../../../services/AppConfiguration/useHttpUpdateTokenDms";
import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import { AsideMenu } from "../components";
import { routesApp } from "../../../RoutesApp";

const index = () => {
  const dispatch = useAppDispatch();
  const tokenDmsRedux = useAppSelector(
    (state) => state.appConfigurationState.tokenDms
  );
  const { httpUpdateTokenDmsAsync } = useHttpUpdateTokenDms();
  const { httpGetTokenDmsAsync } = useHttpGetTokenDms();
  const [tokenDms, setTokenDms] = useState("");

  useEffect(() => {
    dispatch(httpGetTokenDmsAsync());
  }, []);

  useEffect(() => {
    setTokenDms(tokenDmsRedux);
  }, [tokenDmsRedux]);

  const handleAccept = () => {
    dispatch(httpUpdateTokenDmsAsync(tokenDms));
  };

  const handleCancel = () => {
    setTokenDms(tokenDmsRedux);
  };

  const handleChangeTokenDms = (e: ChangeEvent<HTMLInputElement>) => {
    setTokenDms(e.target.value);
  };

  return (
    <div>
      <SessionBar />
      <ContainerConfigurations>
        <AsideMenu active={routesApp.manager} />
        <div>
          <Title>Seguridad:</Title>
          <ContainerStyled>
            <div style={{ display: "flex", gap: "10px" }}>
              <b style={{ whiteSpace: "nowrap" }}>Token Dms:</b>
              <ImputTokenStyled
                type="text"
                value={tokenDms}
                onChange={handleChangeTokenDms}
              />
            </div>
            <ContainerButtonsStyled>
              <Button
                type="button"
                text="Aceptar"
                backgroundColor="secondary"
                height="32px"
                onClick={handleAccept}
              />
              <Button
                type="button"
                text="Cancelar"
                backgroundColor="secondary"
                height="32px"
                onClick={handleCancel}
              />
            </ContainerButtonsStyled>
          </ContainerStyled>
        </div>
      </ContainerConfigurations>
    </div>
  );
};

export default index;
