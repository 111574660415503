import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISubcategoriesByCategory {
  id: number;
  descripcion: string;
  esDatoSistema: boolean;
}

const initial: ISubcategoriesByCategory[] = [];

export const SubcategoriesByCategorySlice: any = createSlice({
  name: "subcategoriesByCategory",
  initialState: initial,
  reducers: {
    addSubcategoriesByCategory: (state, action: PayloadAction<ISubcategoriesByCategory[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { addSubcategoriesByCategory } = SubcategoriesByCategorySlice.actions;

export default SubcategoriesByCategorySlice.reducer;
