import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilePreview } from "../../pages/Home/components/ModalBannerImages/models/FileObjet.models";
import {
  IBanners,
  IBannerCarousel,
} from "../../pages/Home/components/ModalBannerImages/models/Banner.models";
import { IUrlLink } from "../../pages/Home/components/ModalBannerImages/models/IUrlLink.models";

interface ICarouselBanner {
  files: IFilePreview[];
  previews: Array<string>;
  banners: Array<IBanners>;
  reduxValues: IBannerCarousel[];
  IdBannersForDelete: Array<number>;
  originalBanners: Array<IBanners>;
  urlForId: IUrlLink[];
  currentId: number | null;
}

const initial: ICarouselBanner = {
  files: [],
  banners: [],
  previews: [],
  urlForId: [],
  reduxValues: [],
  originalBanners: [],
  IdBannersForDelete: [],
  currentId: null,
};

export const CarouselSlice = createSlice({
  name: "carousel",
  initialState: initial,
  reducers: {
    // original banners
    setOriginalBanners: (state, action: PayloadAction<IBanners[]>) => {
      state.originalBanners = action.payload;
    },
    // Banner actions
    setBannersCarousel: (state, action: PayloadAction<IBannerCarousel[]>) => {
      state.banners = action.payload.map((banner) => ({
        id: banner.id,
        url: banner.url ?? "",
        fileKey: banner.fileKey,
        posicion: banner.posicion,
        hiperVinculo: banner.hiperVinculo,
      }));
    },
    addBannerCarousel: (state, action: PayloadAction<IBanners>) => {
      state.banners.push(action.payload);
    },
    deleteBannerCarousel: (state, action: PayloadAction<number>) => {
      state.banners = state.banners.filter(
        (banner) => banner.id !== action.payload
      );
    },
    // preview actions
    addBannerPreview: (state, action: PayloadAction<string>) => {
      state.previews.push(action.payload);
    },
    deleteBannerPreview: (state, action: PayloadAction<number>) => {
      state.previews.splice(action.payload, 1);
    },
    resetBannerPreview: (state) => {
      state.previews = [];
    },
    // Lista de id para eliminar
    addIdsDeleteBannerCarousel: (state, action: PayloadAction<number>) => {
      state.IdBannersForDelete.push(action.payload);
    },
    resetIdsDeleteBannerCarousel: (state) => {
      state.IdBannersForDelete = [];
    },
    // Files sin procesar
    addFile: (state, action: PayloadAction<IFilePreview>) => {
      state.files.push(action.payload);
    },
    deleteFile: (state, action: PayloadAction<number>) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },
    resetFiles: (state) => {
      state.files = [];
    },
    // Seguidor de values DragAndDrop
    setReduxValue: (state, action: PayloadAction<IBannerCarousel[]>) => {
      state.reduxValues = action.payload;
    },
    addReduxValue: (state, action: PayloadAction<IBannerCarousel>) => {
      state.reduxValues.push(action.payload);
    },
    deleteReduxValue: (state, action: PayloadAction<number>) => {
      state.reduxValues = state.reduxValues.filter(
        (value) => value.id !== action.payload
      );
    },
    resetReduxValue: (state) => {
      state.reduxValues = [];
    },
    // Url for ID
    setUrlsForIds: (state, action: PayloadAction<any[]>) => {
      action.payload.forEach((banner) => {
        const { id, hiperVinculo } = banner;
        // Si el id no existe, agregar el nuevo objeto con id y hiperVinculo
        state.urlForId.push({ id, url: hiperVinculo });
      });
    },
    handleChangeUrl: (
      state,
      action: PayloadAction<React.ChangeEvent<HTMLInputElement>>
    ) => {
      const index = state.urlForId.findIndex(
        (item) => item.id === state.currentId
      );
      if (index !== -1) {
        state.urlForId[index].url = action.payload.target.value;
      }
    },
    setIdCurrent: (state, action: PayloadAction<number>) => {
      state.currentId = action.payload;
    },
    addUrlForIdIfNotExists: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.currentId = id;
      const exists = state.urlForId.some((urlLink) => urlLink.id === id);
      if (!exists) {
        state.urlForId.push({ id, url: "" });
      }
    },
  },
});

export const {
  addFile,
  resetFiles,
  deleteFile,
  setIdCurrent,
  setUrlsForIds,
  setReduxValue,
  addReduxValue,
  handleChangeUrl,
  resetReduxValue,
  deleteReduxValue,
  addBannerPreview,
  addBannerCarousel,
  resetBannerPreview,
  setOriginalBanners,
  setBannersCarousel,
  deleteBannerPreview,
  deleteBannerCarousel,
  addUrlForIdIfNotExists,
  addIdsDeleteBannerCarousel,
  resetIdsDeleteBannerCarousel,
} = CarouselSlice.actions;

export default CarouselSlice.reducer;
