import { useAppSelector } from "../app/hooks";
import { UserData } from "../models/UserData";
import { UserRol } from "../types/enum/RolesUsuario";
import { OrdenDeCompra } from "../models/OrdenDeCompra";
import { PayMethod } from "../types/enum/PayMethod";
import { Item } from "../models/Item";
import { DeliveryMethod } from "../types/enum/DeliveryMethod";

const useLocalStorageHook = () => {
  const empresaId = useAppSelector(
    (state) => state.appConfigurationState.empresaId
  );

  const agregarItemsCarrito = (data: Item) => {
    const nameLS = `${empresaId}-ItemsCarrito`;
    localStorage.setItem(nameLS, JSON.stringify(data));
  };

  const removerItemsCarrito = () => {
    const nameLS = `${empresaId}-ItemsCarrito`;
    localStorage.removeItem(nameLS);
  };

  const obtenerItemsCarrito = () => {
    const nameLS = `${empresaId}-ItemsCarrito`;
    const data = localStorage.getItem(nameLS);
    if (data) {
      return JSON.parse(data);
    }
    return [];
  };

  const agregarMetodoDeEnvio = (data: DeliveryMethod | null | undefined) => {
    const nameLS = `${empresaId}-MetodoDeEnvio`;
    localStorage.setItem(nameLS, JSON.stringify({ data }));
  };

  const removerMetodoDeEnvio = () => {
    const nameLS = `${empresaId}-MetodoDeEnvio`;
    localStorage.removeItem(nameLS);
  };

  const obtenerMetodoDeEnvio = () => {
    const nameLS = `${empresaId}-MetodoDeEnvio`;
    const data = localStorage.getItem(nameLS);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const agregarMetodoDePago = (data: PayMethod | null | undefined) => {
    const nameLS = `${empresaId}-MetodoDePago`;
    localStorage.setItem(nameLS, JSON.stringify(data));
  };

  const removerMetodoDePago = () => {
    const nameLS = `${empresaId}-MetodoDePago`;
    localStorage.removeItem(nameLS);
  };

  const obtenerMetodoDePago = () => {
    const nameLS = `${empresaId}-MetodoDePago`;
    const data = localStorage.getItem(nameLS);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const agregarConfirmacionDeCompra = (data: OrdenDeCompra) => {
    const nameLS = `${empresaId}-ConfirmacionDeCompra`;
    localStorage.setItem(nameLS, JSON.stringify(data));
  };

  const obtenerConfirmacionDeCompra = () => {
    const nameLS = `${empresaId}-ConfirmacionDeCompra`;
    const data = localStorage.getItem(nameLS);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const agregarVirtualAssistant = (
    showAssistant: boolean,
    deleteAssistant: boolean,
    viewTab: boolean
  ) => {
    const dataVA = {
      showAssistant,
      deleteAssistant,
      viewTab,
    };
    const nameLS = `${empresaId}-VirtualAssistantState`;
    localStorage.setItem(nameLS, JSON.stringify(dataVA));
  };

  const obtenerVirtualAssistant = () => {
    const nameLS = `${empresaId}-VirtualAssistantState`;
    const data = localStorage.getItem(nameLS);
    if (data) {
      return JSON.parse(data);
    }
    return {
      showAssistant: false,
      deleteAssistant: false,
      viewTab: false,
    };
  };

  /**
   * Almacena los datos de un usuario con key empresaId en el almacenamiento local.
   * @param data Los datos del usuario a almacenar.
   */
  const agregarDatosDeUsuario = (data: UserData) => {
    localStorage.setItem(empresaId.toString(), JSON.stringify(data));
  };

  /**
   * Obtiene el token de acceso de el usuario almacenado en el almacenamiento local.
   * @returns El token de acceso del usuario si existe, de lo contrario devuelve una cadena vacía.
   */
  const obtenerToken = () => {
    const empresaData = localStorage.getItem(empresaId.toString());
    if (empresaData) {
      const parsedData: UserData = JSON.parse(empresaData);
      return parsedData.accessToken;
    }
    return "";
  };

  /**
   * Obtiene el email de el usuario almacenado en el almacenamiento local.
   * @returns El email del usuario si existe, de lo contrario devuelve una cadena vacía.
   */
  const obtenerEmail = () => {
    const empresaData = localStorage.getItem(empresaId.toString());
    if (empresaData) {
      const parsedData: UserData = JSON.parse(empresaData);
      return parsedData.email;
    }
    return "";
  };

  /**
   * Email con el que se registró el usuario y falta confirmar
   * @param email
   */
  const setEmailToConfirmation = (email:string) => {
    const key = `${empresaId}-EmailToConfirmation`;
    localStorage.setItem(key, email);
  };

  /**
   * Email con el que se registró el usuario y falta confirmar
   */
  const getEmailToConfirmation = () => {
    const key = `${empresaId}-EmailToConfirmation`;
    return localStorage.getItem(key) ?? "";
  };

  /**
   * Email con el que se registró el usuario y falta confirmar
   */
  const removeEmailToConfirmation = () => {
    const key = `${empresaId}-EmailToConfirmation`;
    localStorage.removeItem(key);
  };

  /**
   * Obtiene los datos del usuario almacenado en el almacenamiento local.
   * @returns Los datos del usuario si existen, de lo contrario devuelve null.
   */
  const obtenerUserData = () => {
    const empresaData = localStorage.getItem(empresaId.toString());
    if (empresaData) {
      const parsedData: UserData = JSON.parse(empresaData);
      return parsedData;
    }
    return null;
  };

  /**
   * Obtiene el rol del usuario almacenado en el almacenamiento local.
   * @returns El rol del usuario si existe, de lo contrario devuelve null.
   */
  const obetenerRol = () => {
    const empresaData = localStorage.getItem(empresaId.toString());
    if (empresaData) {
      const parsedData: UserData = JSON.parse(empresaData);
      return parsedData.roles[0] as UserRol;
    }
    return null;
  };

  return {
    obetenerRol,
    obtenerEmail,
    obtenerToken,
    obtenerUserData,
    agregarMetodoDePago,
    removerMetodoDePago,
    obtenerMetodoDePago,
    agregarDatosDeUsuario,
    agregarVirtualAssistant,
    obtenerVirtualAssistant,
    agregarConfirmacionDeCompra,
    obtenerConfirmacionDeCompra,
    obtenerMetodoDeEnvio,
    agregarMetodoDeEnvio,
    removerMetodoDeEnvio,
    agregarItemsCarrito,
    removerItemsCarrito,
    obtenerItemsCarrito,
    getEmailToConfirmation,
    setEmailToConfirmation,
    removeEmailToConfirmation
  };
};

export default useLocalStorageHook;
