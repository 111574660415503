/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import { viewNotification } from "../../utils/ViewNotification";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setConfigNotifications } from "../../features/configNotifications/configNotificationsReducer";
import { setLoadingGetConfigNotification } from "../../features/loading/configNotifications/loadingsReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpGetAllConfigNotifications = () => {
  const empresaId = useAppSelector((state: RootState) => state.appConfigurationState.empresaId);
  const { obtenerToken } = useLocalStorageHook();

  const httpGetAllConfigNotificationsAsync = (): AppThunk => async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingGetConfigNotification(true));
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.getConfigNotifications}?empresaId=${empresaId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data.length > 0) {
          const { email, telefono, id, whatsApp } = data[0];
          // Guardar en el store
          dispatch(setConfigNotifications({ email, telefono, id, whatsApp }));
        } else {
          viewNotification(
            "Aún no tienes configuraciones de notificaciones",
            "info"
          );
        }
        dispatch(setLoadingGetConfigNotification(false));
      } catch (error) {
        viewNotification("Error al buscar configuración", "warning");
        dispatch(setLoadingGetConfigNotification(false));
      }
    };
  return { httpGetAllConfigNotificationsAsync };
};

export default useHttpGetAllConfigNotifications;
