import React, { useState } from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import { ContainerHistoryStyled } from "../../../styles/CollectionReportStyled";
// Components
import Pagination from "./components/Pagination";
import SpinnerSale from "./components/SpinnerSale";
import HeaderFilters from "./components/HeaderFilters";
import NoResultsSales from "./components/NoResultsSales";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ModalConfirm from "./components/ModalConfirm/ModalConfirm";
import CardCollectionReport from "./components/CardCollectionReport";
// Services
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import useHttpUpdateStatusOrder from "../../../../../services/Sales/useHttpUpdateStatusOrder";
// Models
import { Data } from "../../../../../models/HistorySales/Data.model";
// Hooks
import useCollectionReportHook from "./hooks/useCollectionReportHook";
import ModalBase from "../../../../../components/modalBase/ModalBase";
import ModalAttachments from "./components/ModalAttachments/ModalAttachments";

const CollectionReport = () => {
  const {
    page,
    sales,
    idDetails,
    showDetails,
    handleEnter,
    handleSearch,
    handleSetPage,
    handlePrevPage,
    handleNextPage,
    recordsForPage,
    articlesCounter,
    cantidadDePaginas,
    handleShowDetails,
    handleChangeSearch,
    handleChangeDateFilter,
    handleChangeStatusFilter,
    handleChangeResultsForPage,
  } = useCollectionReportHook();

  const dispatch = useAppDispatch();
  const { httpUpdateStatusOrderAsync } = useHttpUpdateStatusOrder();

  const [parent] = useAutoAnimate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAttachments, setShowModalAttachments] =
    useState<boolean>(false);
  const [idVenta, setIdVenta] = useState<number>(0);
  const [status, setStatus] = useState<number>(0);

  const isLoadingHistorySales = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingGetHistorySale
  );

  const isLoadingUpdateStatusOrder = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingUpdateStatusOrder
  );

  const handleCloseModalAttachments = () => {
    setShowModalAttachments(false);
  };

  const handleAcept = () => {
    dispatch(
      httpUpdateStatusOrderAsync(idVenta, status, showModal, setShowModal)
    );
  };

  return (
    <>
      <ModalConfirm
        showModal={showModal}
        setShowModal={setShowModal}
        handleAcept={handleAcept}
        isDisabled={isLoadingUpdateStatusOrder}
        title="¿Quieres cambiar el estado de esta compra a arrepentido?"
      />
      <ModalBase
        children={<ModalAttachments />}
        show={showModalAttachments}
        onClose={handleCloseModalAttachments}
        height="30%" width="50%"
      />
      <ContainerStyled ref={parent}>
        {/* HEADER */}
        <HeaderFilters
          title="Informe de cobros"
          handleEnter={handleEnter}
          handleSearch={handleSearch}
          handleSetPage={handleSetPage}
          handleChangeSearch={handleChangeSearch}
          handleChangeDateFilter={handleChangeDateFilter}
          handleChangeStatusFilter={handleChangeStatusFilter}
        />
        {isLoadingHistorySales ? (
          <SpinnerSale />
        ) : (
          <ContainerHistoryStyled ref={parent}>
            {sales.data.length === 0 ? (
              <NoResultsSales />
            ) : (
              sales.data.map((sale: Data, index: number) => (
                /* CARD */
                <CardCollectionReport
                  key={sale.cliente?.id + index}
                  sale={sale}
                  idDetails={idDetails}
                  idVenta={idVenta}
                  setIdVenta={setIdVenta}
                  setShowModal={setShowModal}
                  showDetails={showDetails}
                  articlesCounter={articlesCounter}
                  handleShowDetails={handleShowDetails}
                  setStatus={setStatus}
                  setShowModalAttachments={setShowModalAttachments}
                  showModalAttachment={showModalAttachments}
                />
              ))
            )}
          </ContainerHistoryStyled>
        )}
        {/* <PaginationSales /> */}
        <Pagination
          page={page}
          sales={sales}
          handleEnter={handleEnter}
          handleSetPage={handleSetPage}
          recordsForPage={recordsForPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          cantidadDePaginas={cantidadDePaginas}
          handleChangeResultsForPage={handleChangeResultsForPage}
        />
      </ContainerStyled>
    </>
  );
};

export default CollectionReport;
