import styled from "styled-components";

export const HeaderStyled = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
`;

// Progress articulos a publicar
export const ProgressStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ContainerItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface IColor {
  active: boolean;
}

export const ProgressItemStyled = styled.div<IColor>`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? props.theme.colors.main.primary : "#8e8e8e"};
`;

export const LabelItemStyled = styled.span`
  font-weight: 500;
  text-align: center;
`;

export const PointerLineStyled = styled.div`
  border-top: 5px dotted #8e8e8e;
  width: 40%;
  margin-bottom: 40px;
`;

// Vista general
export const ContainerButtonsStyled = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 1rem;
`;

export const ButtonStyled = styled.button`
  border: none;
  width: 120px;
  height: 40px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 12px;
  font-family: "Inter";
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.text.first};
  background-color: ${(props) => props.theme.colors.background.fifth};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.main.secondary};
    color: ${(props) => props.theme.colors.text.first};
  }
`;

// Edicion de campos
export const ContainerEditStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

export const InputEditStyled = styled.input`
  border-radius: 8px;
  padding: 3px;
  width: 30%;
  border: 1px solid ${(props) => props.theme.colors.background.fifth};
`;

interface IButtonEditStyled {
  color: string;
}

export const ButtonEditStyled = styled.button<IButtonEditStyled>`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 2px solid ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const DifferentiationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .edit {
    display: none;
  }

  &:hover {
    .diff {
      display: none;
    }
    .edit {
      display: block;
    }
  }
`;

export const InputStyled = styled.input`
  // bordes superior izquierdo y inferior izquierdo redondeados
  border-radius: 6px;
  padding: 3px;
  border: none;
  margin: 0px 6px 0px 6px;
  width: 60px;
  height: 35px;
  // al estar en foco se le agrega un borde
  &:focus {
    outline: none;
  }
`;
export const ContainerDifferentiationMultipleStyled = styled.div`
  background-color: #d3e190;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
`;
