/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingValidateDeleteSubcategory } from "../../../../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import { addRelatedArticles } from "../../../../features/categorization/definitions/RelatedArticlesBySubcategory";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpValidateDeleteSubcategory() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpValidateDeleteSubcategoryAsync =
    (subcategoryId: number): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingValidateDeleteSubcategory(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.getValidateSubcategory}?subCategoriaArticuloId=${subcategoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        dispatch(addRelatedArticles(data));
        dispatch(setLoadingValidateDeleteSubcategory(false));
      } catch (error) {
        dispatch(setLoadingValidateDeleteSubcategory(false));
      }
    };
  return {
    httpValidateDeleteSubcategoryAsync,
  };
}

export default useHttpValidateDeleteSubcategory;
