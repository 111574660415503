/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { setTokenDms } from "../../features/appConfiguration/appConfigurationReducer";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

type Response = {
  token: string;
};
function useHttpGetTokenDms() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetTokenDmsAsync = (): AppThunk => async (dispatch) => {
    try {
      const response = await fetch(
        `${env.seguridad}${
          Routes.getTokenDms
        }?empresaId=${empresaId.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
          },
        }
      );
      validateUnauthorized(response, empresaId);
      const data: Response = await response.json();
      if (data && data.token) {
        dispatch(setTokenDms(data.token));
      }
    } catch (error) {}
  };
  return {
    httpGetTokenDmsAsync,
  };
}

export default useHttpGetTokenDms;
