import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
// Styles
import {
  ButtonColorStyled,
  TableStyledProducts,
  ExpandedColumnStyled,
} from "../../../styles/CategorizationStyled";
import {
  InputStyled,
  InputEditStyled,
  ButtonEditStyled,
  ContainerEditStyled,
  DifferentiationStyled,
  ContainerDifferentiationMultipleStyled,
} from "../../../styles/MyProducts";
// Components
import DataTable, { TableColumn } from "react-data-table-component";
// Redux
import PaginationPriceDefinition from "./components/PaginationPriceDefinition";
import SearchArticle from "../../SearchArticle/SearchArticle";
// Models
import {
  Articulo,
  DataPublishedArticles,
} from "./models/DataPublishedArticles.models";
// Custom hooks
import useMyProductsHook from "./hooks/useMyProductsHook";
import useHttpUpdateSelectedProducts from "../../../../../services/Account/MyProducts/useHttpUpdateSelectedProducts";
// Icons
import EditIcon from "../../../assets/icons/editar.png";
import ModalPriceDefinition from "./modals/ModalPriceDefinition";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Button from "../../../../../components/button/Button";

interface IProps {
  clientGroupId: number;
}

// eslint-disable-next-line max-lines-per-function
const DefinitionOfPriceTable = ({ clientGroupId }: IProps) => {
  const dispatch = useAppDispatch();
  const { httpUpdateSelectedProductsAsync } = useHttpUpdateSelectedProducts();
  // Estados globales
  const publishedArticles = useAppSelector(
    (state) => state.publishedArticles as DataPublishedArticles
  );
  const isLoadingPublishedArticles = useAppSelector(
    (state) => state.isLoadingMyProducts.loadingPublishedArticles
  );

  const [parent] = useAutoAnimate();

  const [currentPage, setCurrentPage] = useState(1);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterDescription, setFilterDescription] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [recordsForPage, setRecordsForPage] = useState(10);

  // Editar states
  const [showInputEditDifferentiation, setShowInputEditDifferentiation] =
    useState(false);
  const [idArticleEdit, setIdArticleEdit] = useState(0);
  const [newDifferentiation, setNewDifferentiation] = useState("");
  const [differentiationsList, setDifferentiationsList] = useState<number[]>(
    []
  );
  const [refreshTable, setRefreshTable] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const { columnSelected, expandedColumns, handlerColumnExpanded, positionY } =
    useMyProductsHook(currentPage, refreshTable, clientGroupId);

  const columns: TableColumn<Articulo>[] = [
    {
      name: "Linea",
      selector: (row) => row.linea.descripcion,
      sortable: true,
    },
    {
      name: "Código",
      cell: (row) => (
        <ExpandedColumnStyled onClick={() => handlerColumnExpanded("codigo")}>
          {row.codigo}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "codigo" ? expandedColumns : "",
      selector: (row) => row.codigo,
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Descripción",
      cell: (row) => (
        <ExpandedColumnStyled
          onClick={() => handlerColumnExpanded("descripcion")}
        >
          {row.descripcion}
        </ExpandedColumnStyled>
      ),
      style: { fontWeight: "bold" },
      width: columnSelected === "descripcion" ? expandedColumns : "",
      selector: (row) => row.descripcion,
      sortable: true,
    },
    {
      name: "Categoria",
      cell: (row) => (
        <ExpandedColumnStyled
          onClick={() => handlerColumnExpanded("categoria")}
        >
          {row.categoria.descripcion}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "categoria" ? expandedColumns : "",
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Cantidad",
      selector: (row) => row.cantidad,
      sortable: true,
      center: true,
    },
    {
      name: "Precio",
      selector: (row) => row.precio,
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Diferenciación",
      cell: (row) => {
        if (showInputEditDifferentiation && idArticleEdit === row.id) {
          return (
            <ContainerEditStyled>
              <InputEditStyled
                type="number"
                onChange={(e) => handleInputEditDifferentiation(e)}
                value={newDifferentiation}
                onKeyDown={(event) => handleKeyDown(event)}
              />
              <ButtonEditStyled
                color={"green"}
                onClick={() => handleConfirmEditDifferentiation()}
              >
                ✔
              </ButtonEditStyled>
              <ButtonEditStyled color={"red"} onClick={handleButtonCancelEdit}>
                ❌
              </ButtonEditStyled>
            </ContainerEditStyled>
          );
        }
        return (
          <DifferentiationStyled
            onClick={() => {
              setShowInputEditDifferentiation(true);
              setIdArticleEdit(row.id);
              setNewDifferentiation(
                row.definicionesArticulosGrupoCliente.descuentoRecargo.toString()
              );
            }}
          >
            <span className="diff">
              {row.definicionesArticulosGrupoCliente
                ? row.definicionesArticulosGrupoCliente.descuentoRecargo
                : "0"}
              %
            </span>
            <ButtonColorStyled color="lime" className="edit">
              <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
            </ButtonColorStyled>
          </DifferentiationStyled>
        );
      },
      sortable: true,
      width: showInputEditDifferentiation ? "20%" : "",
    },
    {
      name: "Últimas unidades",
      selector: (row) =>
        row.definicionesArticulosGrupoCliente
          ? row.definicionesArticulosGrupoCliente.ultimasUnidades
          : "1",
      sortable: true,
      width: "15%",
      center: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Publicado",
      selector: (row) =>
        row.definicionesArticulosGrupoCliente &&
        row.definicionesArticulosGrupoCliente.publicado
          ? "Si"
          : "No",
      sortable: true,
      center: true,
    },
  ];

  const handleChange = ({ selectedRows }: any) => {
    const selectedRowsId = selectedRows.map((row: Articulo) => row.id);
    setDifferentiationsList(selectedRowsId);
  };

  // Cuando se presione cancelar edición
  const handleButtonCancelEdit = () => {
    setShowInputEditDifferentiation(false);
    setIdArticleEdit(0);
    setDifferentiationsList([]);
  };

  // Controlador de input de edición de diferenciación
  const handleInputEditDifferentiation = (e: any) => {
    setNewDifferentiation(e.target.value);
  };

  // Cuando se confirme la edición de la diferenciación individual
  const handleConfirmEditDifferentiation = () => {
    setDifferentiationsList([]);
    // Validar entrada de datos
    if (
      newDifferentiation === "" ||
      clientGroupId === undefined ||
      clientGroupId === null
    ) {
      return;
    }
    // Enviar al endpoint
    dispatch(
      httpUpdateSelectedProductsAsync(
        clientGroupId,
        Number(newDifferentiation),
        [idArticleEdit],
        refreshTable,
        setRefreshTable
      )
    );
    // Resetear valores
    setShowInputEditDifferentiation(false);
    setIdArticleEdit(0);
  };

  // Cuando se confirme la edición de la diferenciación lista
  const handleConfirmEditDifferentiationList = () => {
    if (
      clientGroupId === 0 ||
      differentiationsList.length === 0 ||
      newDifferentiation === ""
    ) {
      return;
    }
    // Enviar al endpoint
    dispatch(
      httpUpdateSelectedProductsAsync(
        clientGroupId,
        Number(newDifferentiation),
        differentiationsList,
        refreshTable,
        setRefreshTable,
        setDifferentiationsList
      )
    );
    // Limpiar estado de lista
    setDifferentiationsList([]);
    // Limpiar selecciones
    setToggleClearRows(!toggledClearRows);
  };

  // Cuando se presiona Enter
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleConfirmEditDifferentiation();
    }
  };

  return (
    <TableStyledProducts ref={parent}>
      <SearchArticle
        title="Definición de precios"
        filterDescription={filterDescription}
        setFilterDescription={setFilterDescription}
        filterCode={filterCode}
        setFilterCode={setFilterCode}
        categoryId={categoryId}
        setShowModal={setShowModal}
        setCategoryId={setCategoryId}
        resetPaginationToggle={resetPaginationToggle}
        setResetPaginationToggle={setResetPaginationToggle}
        clientGroupId={clientGroupId}
      />
      {/* Diferenciacion masiva */}
      {differentiationsList.length > 1 ? (
        <ContainerDifferentiationMultipleStyled>
          <div>Artículos seleccionados: {differentiationsList.length}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            Diferenciación:
            <InputStyled
              type="number"
              onKeyPress={(event) => handleKeyDown(event)}
              onChange={(e) => handleInputEditDifferentiation(e)}
            />
            <Button
              onClick={() => handleConfirmEditDifferentiationList()}
              backgroundColor="secondary"
              text="Modificar seleccionados"
            />
          </div>
        </ContainerDifferentiationMultipleStyled>
      ) : null}
      <DataTable
        selectableRows
        onSelectedRowsChange={handleChange}
        responsive
        data={publishedArticles.data}
        columns={columns}
        progressComponent={"Cargando..."}
        progressPending={isLoadingPublishedArticles}
        noDataComponent="No se encuentran resultados"
        clearSelectedRows={toggledClearRows}
      />
      {!isLoadingPublishedArticles && publishedArticles.data.length !== 0 && (
        <PaginationPriceDefinition
          setCurrentPage={setCurrentPage}
          filterDescription={filterDescription}
          filterCode={filterCode}
          categoryId={categoryId}
          clientGroupId={clientGroupId}
          resetPaginationToggle={resetPaginationToggle}
          setResetPaginationToggle={setResetPaginationToggle}
          recordsForPage={recordsForPage}
          setRecordsForPage={setRecordsForPage}
        />
      )}
      {/* Modal de modificar todos */}
      {showModal && (
        <ModalPriceDefinition
          positionY={positionY}
          setShowModal={setShowModal}
          clientGroupId={clientGroupId}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
        />
      )}
    </TableStyledProducts>
  );
};

export default DefinitionOfPriceTable;
