/* eslint-disable indent */
import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/hooks";
// Models
import { Parametrizacion } from "../../../../../../../models/Parametrization/Parametrizacion.model";
// Service
import useHttpEditProcessParametrization from "../../../../../../../services/Parameterization/useHttpEditProcessParameterization";
import { Store } from "react-notifications-component";

const useBankTransferTimeNotificationsHook = (
  dataTimeNotifications: Parametrizacion[],
  setDataTimeNotifications: Dispatch<SetStateAction<never[]>>
) => {
  const dispatch = useAppDispatch();
  const { httpEditProcessParametrization } =
    useHttpEditProcessParametrization();

  const gruposDeClientes = useAppSelector(
    (state) => state.clientGroup.clientGroup
  );

  const [idGrupoActual, setIdGrupoActual] = useState<number | null>(null);

  // Encontrar el objeto de configuración que coincida con el grupo de cliente id
  const findDataById = (id: number) =>
    dataTimeNotifications.find(
      (config: Parametrizacion) => config.grupoClienteId === id
    );

  // Devuelve el nombre del grupo de cliente según el id
  const findNameClientGroup = (id: number) => {
    const clientGroup = gruposDeClientes.find((group) => group.id === id);
    return clientGroup ? clientGroup.descripcion : "";
  };

  const handleSetCurrentGroup = (id: number) => {
    setIdGrupoActual(id);
  };

  // Cambiar Tiempo de Notificacion
  const handleChangeTimeNotification = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const data = findDataById(id);
    if (data) {
      setDataTimeNotifications((prevData: any) =>
        prevData.map((config: Parametrizacion) =>
          config.id === data?.id
            ? { ...config, minutosParaNotificacion: parseInt(e.target.value) }
            : config
        )
      );
    }
  };

  // Cambiar Tiempo de desbloqueo de stock
  const handleChangeTimeUnlock = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const data = findDataById(id);
    // Si existe, guardar el valor nuevo config
    if (data) {
      setDataTimeNotifications((prevData: any) =>
        prevData.map((config: Parametrizacion) =>
          config.id === data?.id
            ? {
                ...config,
                minutosParaDesbloqueoStock: parseInt(e.target.value),
              }
            : config
        )
      );
    }
  };

  const viewMessageError = (data: Parametrizacion) => {
    if (data?.minutosParaNotificacion < 0) {
      Store.addNotification({
        message: "Minutos para notificación debe ser mayor a 0",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
    if (
      (data.minutosParaDesbloqueoStock &&
        data.minutosParaDesbloqueoStock < data?.minutosParaNotificacion) ||
      data?.minutosParaDesbloqueoStock !== 0
    ) {
      Store.addNotification({
        message:
          "Minutos para desbloqueo de stock debe ser mayor a Minutos para notificación o 0",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  };

  const handleSubmit = (id: number) => {
    handleSetCurrentGroup(id);
    const data = findDataById(id);
    if (
      data?.minutosParaNotificacion &&
      data?.minutosParaNotificacion > 0 &&
      ((data?.minutosParaDesbloqueoStock &&
        data?.minutosParaDesbloqueoStock > data?.minutosParaNotificacion) ||
        data?.minutosParaDesbloqueoStock === 0)
    ) {
      const {
        id,
        grupoClienteId,
        minutosParaDesbloqueoStock,
        minutosParaNotificacion,
      } = data;
      dispatch(
        httpEditProcessParametrization(
          id,
          grupoClienteId,
          minutosParaDesbloqueoStock,
          minutosParaNotificacion
        )
      );
    } else {
      if (data) {
        viewMessageError(data);
      }
    }
  };

  return {
    handleSubmit,
    idGrupoActual,
    findNameClientGroup,
    handleChangeTimeUnlock,
    handleChangeTimeNotification,
  };
};

export default useBankTransferTimeNotificationsHook;
