// Models
import { ICategories } from "../models/Definitions.model";
// Redux
import { useAppSelector } from "../../../app/hooks";
// Styles
import { SelectStyled } from "../styles/CategorizationStyled";

interface ICategorySelectorProps {
  setCategorySelectedId: (id: string) => void;
}

const CategorySelector = ({
  setCategorySelectedId,
}: ICategorySelectorProps) => {
  // Estados globales
  const categories = useAppSelector(
    // Lista de categorías disponibles
    (state) => state.categories as ICategories[]
  );

  const handleChangeCategory = (e: any) => {
    setCategorySelectedId(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <label htmlFor="categories">Categoría:</label>
      <SelectStyled name="categories" id="" onChange={handleChangeCategory}>
        <option value="">Seleccionar categoría</option>
        {categories.map((category: ICategories) => (
          <option key={category.id} value={category.id}>
            {category.descripcion}
          </option>
        ))}
      </SelectStyled>
    </div>
  );
};

export default CategorySelector;
