import React from "react";

const NoResultsSales = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      fontFamily: "Inter, sans-serif",
    }}
  >
    No se encontraron resultados
  </div>
);

export default NoResultsSales;
