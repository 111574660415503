// Articulos mas vendidos
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BestSellingArticleDto } from "../../DTOs/BestSellingArticleDto";

type State = {
  Articles: BestSellingArticleDto[]
};

const initialState: State = {
  Articles: []
};

export const BestSellingArticles = createSlice({
  name: "BestSellingArticles",
  initialState,
  reducers: {
    setItemsBestSellingArticles: (state, action: PayloadAction<Array<BestSellingArticleDto>>) => {
      state.Articles = action.payload;
    },
    resetBestSellingArticles: (state) => {
      state.Articles = [];
    },
  },
});

export const {
  setItemsBestSellingArticles,
  resetBestSellingArticles
} = BestSellingArticles.actions;

export default BestSellingArticles.reducer;
