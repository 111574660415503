import styled from "styled-components";

export const FormStyled = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const ContainerButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
