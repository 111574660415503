import React, { Fragment } from "react";
import { useAppDispatch } from "../../../../../../../app/hooks";
// Styles
import {
  DetailStyled,
  FooterCardStyled,
  ButtonCommentProductStyled,
} from "../../../../../styles/CollectionReportStyled";
// Models
import { Data } from "../../../../../../../models/HistorySales/Data.model";
import { Estado } from "../../../../../../../models/HistorySales/Estado.model";
// Utills
import { cambiarPuntoPorComas } from "../../../../../../../utils/priceFormater";
import { setIdArticleCommentBox } from "../../../../../../../features/commentBox/commentBoxReducer";
import { UserRol } from "../../../../../../../types/enum/RolesUsuario";
import { limitText } from "../../../../../../../utils/limitText";
import useLocalStorageHook from "../../../../../../../utils/useLocalStorageHook";
// Services
import useHttpGetCommentRating from "../../../../../../../services/CommentsAndRatings/useHttpGetCommentRating";

interface ICardDetails {
  sale: Data;
  setShowModalBoxComment?: (value: boolean) => void;
}

const CardDetails = ({ sale, setShowModalBoxComment }: ICardDetails) => {
  const dispatch = useAppDispatch();
  const { obetenerRol } = useLocalStorageHook();
  const { httpGetCommentRatingAsync } = useHttpGetCommentRating();

  /**
   * Maneja la visualización del modal y guarda el id del artículo en redux
   * para vincular el comentario a ese artículo
   *
   * @param {number} idArticle - Id del artículo a vincular con el comentario
   */
  const handleShowModal = (idArticle: number) => {
    setShowModalBoxComment && setShowModalBoxComment(true);
    dispatch(setIdArticleCommentBox(idArticle));
    dispatch(httpGetCommentRatingAsync(idArticle));
  };
  return (
    <FooterCardStyled type={obetenerRol()}>
      {sale.articuloDetalle.map((article) => (
        <Fragment key={article.id}>
          <DetailStyled>{limitText(article.descripcion)}</DetailStyled>
          <DetailStyled>Cantidad: {article.cantidad}</DetailStyled>
          <DetailStyled>${cambiarPuntoPorComas(article.precio)}</DetailStyled>
          {sale.estado === Estado.PAGADO && obetenerRol() === UserRol.Buyer ? (
            <ButtonCommentProductStyled
              onClick={() => handleShowModal(article.id)}
            >
              Valorar producto
            </ButtonCommentProductStyled>
          ) : null}
        </Fragment>
      ))}
    </FooterCardStyled>
  );
};

export default CardDetails;
