import styled from "styled-components";

export const ContainerConfigurations = styled.div`
  display: flex;
  gap: 20px; 
  margin: 60px 4%;
`;

export const ContainerStyled = styled.div`
  width: 85%;
`;
