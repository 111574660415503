import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ContainerSpinner } from "./styles/AppStyled";
import "./index.css";
// Pages
import { NotFound } from "./pages";
// Components
import Spinner from "./components/spinner/Spinner";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "react-dropzone-uploader/dist/styles.css";
import LoadRoates from "./LoadRoutes";

declare global {
  interface Window {
    env: {
      seguridad: string;
      catalogo: string;
      operaciones: string;
      google: string;
      facebook: string;
    };
  }
}

const App = () => (
  // TESTING DE ENTORNO
  // console.log(`API url:${process.env.REACT_APP_API_URL}`);
  <div className="App">
    <ReactNotifications />
    <Suspense
      fallback={
        <ContainerSpinner>
          <Spinner />
        </ContainerSpinner>
      }
    >
      <Routes>
        <Route path="/:aliasEmpresa/*" element={<LoadRoates />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </div>
);
export default App;
