import React from "react";
import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import Footer from "../../../components/footer/Footer";
import { AsideMenu, Articles } from "../components";
import { routesApp } from "../../../RoutesApp";

const ArticlesPage = () => (
  <div>
    <SessionBar />
    <ContainerConfigurations>
      <AsideMenu active={routesApp.articles} />
      <Articles />
    </ContainerConfigurations>
    <Footer />
  </div>
);

export default ArticlesPage;
