/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import { viewNotification } from "../../../../utils/ViewNotification";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpAddOrUpdateAttributes() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAddOrUpdateAttributesAsync =
    (
      attributeDescription: string,
      refreshAttributes: boolean,
      setRefreshAttributes?:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      setShowSaveButton?:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      attributeId?: number
    ): AppThunk =>
    async () => {
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.addOrUpdateAttribute}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              id: attributeId,
              descripcion: attributeDescription,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const successMessage = attributeId
            ? "Atributo editado correctamente"
            : "Atributo creado correctamente";
          const refresh = setRefreshAttributes && !refreshAttributes;
          const hideButton = setShowSaveButton && false;
          viewNotification(successMessage, "success");
          refresh && setRefreshAttributes(refresh);
          hideButton && setShowSaveButton(hideButton);
        } else {
          const errorMessage = attributeId
            ? "Error al editar el atributo"
            : "Error al crear el atributo";
          viewNotification(errorMessage, "danger");
        }
      } catch (error) {
        // mostrar notificacion de error
        viewNotification("Error al editar atributo", "danger");
      }
    };
  return {
    httpAddOrUpdateAttributesAsync,
  };
}

export default useHttpAddOrUpdateAttributes;
