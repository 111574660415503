import React from "react";
import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import Footer from "../../../components/footer/Footer";
import { AsideMenu, PaymentsGateways } from "../components";
import { routesApp } from "../../../RoutesApp";

const PaymentGateways = () => (
  <div>
    <SessionBar />
    <ContainerConfigurations>
      <AsideMenu active={routesApp.paymentGateways} />
      <PaymentsGateways />
    </ContainerConfigurations>
    <Footer />
  </div>
);

export default PaymentGateways;
