import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Models
import { DataTransfer } from "../../models/DataTransfer/DataTransfer.model";
import { ArticuloDetalle } from "../../models/HistorySales/ArticuloDetalle.model";
import { StateDataTransfer } from "../../models/DataTransfer/StateDataTransfer.model";
import { ErrorsBuyConfirmation } from "../../types/ErrorsBuyConfirmation";

const initial: StateDataTransfer = {
  data: [],
  order: [],
  id: null,
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
type test = {
data: ErrorsBuyConfirmation
}

export const DataTransferSlice: any = createSlice({
  name: "DataTransfer",
  initialState: initial,
  reducers: {
    setDataTransfer: (state: any, action: PayloadAction<DataTransfer>) => {
      state.data = action.payload;
    },
    setOrder: (state: any, action: PayloadAction<ArticuloDetalle>) => {
      state.order = action.payload;
    },
    setId: (state: any, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
  },
});

export const { setDataTransfer, setOrder, setId } = DataTransferSlice.actions;

export default DataTransferSlice.reducer;
