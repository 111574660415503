/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingSubcategoriesByCategory } from "../../../../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import { addSubcategoriesByCategory } from "../../../../features/categorization/definitions/subcategoriesByCategoryReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpSubcategoriesByCategory() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpSubcategoriesByCategoryAsync =
    (categoryId: number): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingSubcategoriesByCategory(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.getSubcategoriesByCategory}?categoriaId=${categoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data) {
          dispatch(addSubcategoriesByCategory(data));
        }
        dispatch(setLoadingSubcategoriesByCategory(false));
      } catch (error) {
        setLoadingSubcategoriesByCategory(false);
      }
      setLoadingSubcategoriesByCategory(false);
    };
  return {
    httpSubcategoriesByCategoryAsync,
  };
}

export default useHttpSubcategoriesByCategory;
