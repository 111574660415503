import { useNavigate, useParams } from "react-router-dom";
// import { Login, LoginSeller } from "../../pages";
import { UserRol } from "../../types/enum/RolesUsuario";
import useLocalStorageHook from "../useLocalStorageHook";
import { routesApp } from "../../RoutesApp";
import { useJwt } from "react-jwt";
import { Store } from "react-notifications-component";
import useSecurityHook from "../../hooks/useSecurityHook";

type RequireAuthParams = {
  children: any;
  authorizedRoles?: UserRol[];
};

/**
 * Validate authorization by role
 * @param {RequireAuthParams} children - Children's component
 * @param {RequireAuthParams} rolesAuthorized - Authorized roles (by default all roles are authorized)
 */
const RequireAuth = ({ children, authorizedRoles }: RequireAuthParams) => {
  const { obtenerToken, obetenerRol } = useLocalStorageHook();
  const token = obtenerToken();
  const isAuth = token !== "";
  const { isExpired } = useJwt(token || "");
  const navigate = useNavigate();
  const { aliasEmpresa } = useParams();
  const { logout } = useSecurityHook();

  const lastRolSaved = obetenerRol() ?? UserRol.Buyer.toString();

  if (isAuth && !isExpired && authorizedRoles === undefined) {
    return children;
  }
  if (
    isAuth &&
    !isExpired &&
    authorizedRoles !== undefined &&
    authorizedRoles?.find((x) => x === obetenerRol())
  ) {
    return children;
  } else if (
    (!isAuth || isExpired) &&
    lastRolSaved === UserRol.Seller.toString()
  ) {
    logout();
    return navigate(`/${aliasEmpresa}${routesApp.loginSeller}`);
  } else if (
    (!isAuth || isExpired) &&
    lastRolSaved === UserRol.Buyer.toString()
  ) {
    logout();
    return navigate(`/${aliasEmpresa}${routesApp.login}`);
  } else if (
    (!isAuth || isExpired) &&
    lastRolSaved === UserRol.Administrator.toString()
  ) {
    logout();
    return navigate(`/${aliasEmpresa}${routesApp.loginManager}`);
  }
  return Store.addNotification({
    title: "Acceso denegado",
    type: "danger",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export default RequireAuth;
