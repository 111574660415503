import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.colors.main.primary};
`;

export const RegretButtonStyled = styled(Link)`
  text-decoration: underline;
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
`;
