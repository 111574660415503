// styles
import {
  ImgStyled,
  ItemStyled,
  TitleStyled,
  CategoryStyled,
  ContainerItems,
  ContainerStyled,
  DescriptionStyled,
  RowContainerStyled,
} from "./styles/CategoriesHomeSectionStyled";
// images
import Optica from "../../assets/img/optica.png";
import Ruedas from "../../assets/img/ruedas.png";
import Bateria from "../../assets/img/bateria.png";
import Aceites from "../../assets/img/aceites.png";
import Estereo from "../../assets/img/estereo.png";
import Accesorios from "../../assets/img/accesorios.png";
import Accesorios_palanca from "../../assets/img/palanca.png";
// Hooks
import useCategoriesHomeHook from "../../hooks/useCategoriesHomeHook";

interface ICategoriesHome {
  title?: string;
}
const CategoriesHome = ({ title }: ICategoriesHome) => {
  // Hooks
  const {
    mostViewedCategories,
    changingColor,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item1AnimationHandle,
    item2AnimationHandle,
    item3AnimationHandle,
    item4AnimationHandle,
    item5AnimationHandle,
    item6AnimationHandle,
    CleanAnimation,
  } = useCategoriesHomeHook();

  return (
    <ContainerStyled>
      {/* Colors titles */}
      {title && (
        <TitleStyled>
          {title}{" "}
          <CategoryStyled color={changingColor()}>
            {mostViewedCategories}
          </CategoryStyled>
        </TitleStyled>
      )}

      {/* Animated categories */}
      <ContainerItems>
        <RowContainerStyled>
          <ItemStyled
            borderColor="dark"
            width={item1.width}
            height={item1.height}
            onMouseEnter={item1AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <ImgStyled
              src={Bateria}
              alt="Baterías"
              className="image imageItem1"
              width="auto"
              height="auto"
            />
            <DescriptionStyled className="titleItem1">
              BATERÍAS
            </DescriptionStyled>
          </ItemStyled>

          <ItemStyled
            borderColor="dark"
            width={item2.width}
            height={item2.height}
            flex_direction="column"
            onMouseEnter={item2AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <DescriptionStyled className="titleItem2">
              NEUMÁTICOS
            </DescriptionStyled>
            <ImgStyled
              src={Ruedas}
              alt="neumáticos"
              className="imageItem2"
              width="auto"
              height={90}
            />
          </ItemStyled>

          <ItemStyled
            borderColor="light"
            width={item3.width}
            height={item3.height}
            flex_direction="column"
            onMouseEnter={item3AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <ImgStyled
              src={Estereo}
              alt="Estereos"
              className="imageItem3"
              width={90}
              height="auto"
            />
            <DescriptionStyled className="titleItem3">
              ESTEREOS
            </DescriptionStyled>
          </ItemStyled>
        </RowContainerStyled>

        <RowContainerStyled>
          <ItemStyled
            borderColor="dark"
            width={item4.width}
            height={item4.height}
            align_self={item4.align_self}
            onMouseEnter={item4AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <DescriptionStyled className="titleItem4">
              ACEITES <br />Y FILTROS
            </DescriptionStyled>
            <ImgStyled
              src={Aceites}
              alt="Aceites"
              className="imageItem4"
              width="auto"
              height="auto"
            />
          </ItemStyled>

          <ItemStyled
            borderColor="light"
            width={item5.width}
            height={item5.height}
            align_self={item5.align_self}
            flex_direction="column"
            onMouseEnter={item5AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <ImgStyled
              src={Accesorios}
              alt="Accesorios"
              className="imageItem5"
              width={90}
              height="auto"
            />
            <ImgStyled
              src={Accesorios_palanca}
              alt="Accesorios"
              className="imageItem5-2"
              width={90}
              height="auto"
            />
            <DescriptionStyled className="titleItem5">
              REPUESTOS
            </DescriptionStyled>
          </ItemStyled>

          <ItemStyled
            borderColor="dark"
            width={item6.width}
            height={item6.height}
            align_self={item5.align_self}
            onMouseEnter={item6AnimationHandle}
            onMouseLeave={CleanAnimation}
          >
            <ImgStyled
              src={Optica}
              alt="Opticas"
              className="imageItem6"
              width={90}
              height="auto"
            />
            <DescriptionStyled className="titleItem6">LUCES</DescriptionStyled>
          </ItemStyled>
        </RowContainerStyled>
      </ContainerItems>
    </ContainerStyled>
  );
};

export default CategoriesHome;
