/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import { deleteAttribute } from "../../../features/categorization/articles/attributesInTheArticleReducer";
import { viewNotification } from "../../../utils/ViewNotification";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setLoadingDeleteAttributeInArticle } from "../../../features/loading/categorizations/articles/loadingsReducer";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpDeleteAttributeInArticle() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpDeleteAttributeInArticleAsync =
    (
      articleId: number,
      attributeId: number,
      setShowConfirmationModalAttribute?: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingDeleteAttributeInArticle(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.deleteAttributeInArticle}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              articuloId: articleId,
              atributoId: attributeId,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          dispatch(deleteAttribute(attributeId));
          viewNotification("Atributo eliminado correctamente", "success");
          if (setShowConfirmationModalAttribute) {
            setShowConfirmationModalAttribute(false);
          }
        } else {
          viewNotification("Error al eliminar atributo", "danger");
        }
        // cerrar modal
        dispatch(setLoadingDeleteAttributeInArticle(false));
      } catch (error) {
        viewNotification("Error al eliminar atributo", "danger");
        dispatch(setLoadingDeleteAttributeInArticle(false));
      }
      dispatch(setLoadingDeleteAttributeInArticle(false));
    };
  return {
    httpDeleteAttributeInArticleAsync,
  };
}

export default useHttpDeleteAttributeInArticle;
