import { useEffect, useState } from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import {
  ButtonStyled,
  HeaderContainerStyled,
  InputsContainerStyled,
} from "../../../styles/CategorizationStyled";
import { InputStyled } from "../../../../Home/components/Banner/styles/BannerStyled";
// Components
import { useAutoAnimate } from "@formkit/auto-animate/react";
import AttributesInArticleTable from "../../DataTables/Articles/AttributesInArticleTable";
// Api
import useHttpArticles from "../../../../../services/Categorization/Articles/useHttpArticles";
import useHttpAttributes from "../../../../../services/Categorization/Articles/useHttpAttributes";
import useHttpAttributesInArticle from "../../../../../services/Categorization/Articles/useHttpAttributesInArticle";
// Redux
import { useAppDispatch } from "../../../../../app/hooks";
// Models
import { IArticles } from "../../../models/Articles.model";
import ArticlesTable from "../../DataTables/Articles/ArticlesTable";

const Articles = () => {
  const dispatch = useAppDispatch();
  const [parent] = useAutoAnimate();
  const { httpArticlesAsync } = useHttpArticles();
  const { httpAttributesAsync } = useHttpAttributes();
  const { httpAttributesInArticleAsync } = useHttpAttributesInArticle();

  const [idArticle, setIdArticle] = useState(0);

  const [filterCode, setFilterCode] = useState("");
  const [filterDescription, setFilterDescription] = useState("");

  const [articleSelected, setArticleSelected] = useState<IArticles>();

  const [viewAttributes, setViewAttributes] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    if (e.target.name === "code") {
      setFilterCode(value);
    } else {
      setFilterDescription(value);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = (records?: number) => {
    const perPage = records || 10;
    dispatch(httpArticlesAsync(filterDescription, filterCode, 1, perPage));
    setResetPaginationToggle(!resetPaginationToggle);
    if (idArticle && idArticle !== 0) {
      dispatch(httpAttributesInArticleAsync(idArticle));
    }
  };

  useEffect(() => {
    // Actualizar listado de atributos dados de alta
    dispatch(httpAttributesAsync());
  }, []);

  return (
    <ContainerStyled ref={parent}>
      <HeaderContainerStyled>
        <h2>Artículos</h2>
        <InputsContainerStyled>
          <InputStyled
            type="text"
            placeholder="Código"
            name="code"
            onChange={handleChangeInput}
            value={filterCode}
            onKeyDown={handleKeyDown}
            onClick={() => {
              setFilterDescription("");
              setViewAttributes(false);
            }}
          />
          <InputStyled
            type="text"
            placeholder="Nombre del artículo"
            name="description"
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            value={filterDescription}
            onClick={() => {
              setFilterCode("");
            }}
          />
          <ButtonStyled
            type="button"
            onClick={() => {
              handleSearch();
              setViewAttributes(false);
            }}
          >
            Buscar
          </ButtonStyled>
        </InputsContainerStyled>
      </HeaderContainerStyled>

      {/* Tabla de Artículos */}
      <ArticlesTable
        idArticle={idArticle}
        filterCode={filterCode}
        setIdArticle={setIdArticle}
        articleSelected={articleSelected}
        setViewAttributes={setViewAttributes}
        filterDescription={filterDescription}
        setArticleSelected={setArticleSelected}
        resetPaginationToggle={resetPaginationToggle}
        setResetPaginationToggle={setResetPaginationToggle}
      />

      {/* Tabla de atributos */}
      {viewAttributes && articleSelected?.nombre && (
        <AttributesInArticleTable
          articleSelected={articleSelected}
          idArticle={idArticle}
        />
      )}
    </ContainerStyled>
  );
};

export default Articles;
