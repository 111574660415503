/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingUnpublishAllArticles } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpUnpublishAllArticles() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUnpublishAllArticlesAsync =
    (
      clientGroupId: number,
      setRefreshTable: (value: boolean) => void,
      refreshTable: boolean,
      setShowModal: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingUnpublishAllArticles(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.unpublishAllArticles}?grupoClienteId=${clientGroupId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()
                ?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const message = response.ok
          ? "Los articulos se han ocultado correctamente."
          : "Error al ocultar artículos";
        const type = response.ok ? "success" : "danger";
        viewNotification(message, type);
        dispatch(setLoadingUnpublishAllArticles(false));
        if (response.ok) {
          setRefreshTable && setRefreshTable(!refreshTable);
          setShowModal(false);
        }
      } catch (error) {
        dispatch(setLoadingUnpublishAllArticles(false));
      }
    };
  return {
    httpUnpublishAllArticlesAsync,
  };
}

export default useHttpUnpublishAllArticles;
