/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpUpdateSelectedLastUnits() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUpdateSelectedLastUnitsAsync =
    (
      clientGroupId: number,
      lastUnitValue: number,
      articleIds: number[],
      refreshTable?: boolean,
      setRefreshTable?: (value: boolean) => void
    ): AppThunk =>
    async () => {
      try {
        const response = await fetch(`${env.catalogo}${Routes.editLastUnits}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            empresaId: empresaId.toString(),
          },
          body: JSON.stringify({
            grupoClienteId: clientGroupId,
            ultimasUnidades: lastUnitValue,
            articulosIds: articleIds,
          }),
        });
        validateUnauthorized(response, empresaId);
        viewNotification(
          response.ok ? "Artículo editado correctamente" : "Error al editar",
          response.ok ? "success" : "danger"
        );
        setRefreshTable && setRefreshTable(!refreshTable);
      } catch (error) {
        viewNotification("Error al editar", "danger");
      }
    };
  return {
    httpUpdateSelectedLastUnitsAsync,
  };
}

export default useHttpUpdateSelectedLastUnits;
