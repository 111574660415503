import { useEffect, useState, ChangeEvent } from "react";
// Services
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import useHttpGetCollectionReport from "../../../../../../services/Sales/useHttpGetCollectionReport";
// Models
import { HistorySales } from "../../../../../../models/HistorySales/HistorySales.model";
import { Data } from "../../../../../../models/HistorySales/Data.model";

const useCollectionReportHook = () => {
  const dispatch = useAppDispatch();
  const { httpGetCollectionReportAsync } = useHttpGetCollectionReport();

  const sales = useAppSelector((state) => state.salesHistory) as HistorySales;

  const [idDetails, setIdDetails] = useState(0);
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [recordsForPage, setRecordsForPage] = useState<number>(10);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState<string>("1");

  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");

  const [showDetails, setShowDetails] = useState(false);

  // Mostrar detalles
  const handleShowDetails = (id: number) => {
    setShowDetails(!showDetails);
    setIdDetails(id);
  };

  // Contador de artículos
  const articlesCounter = (ventas: Data) => {
    let counter = 0;
    ventas.articuloDetalle.map((venta) => {
      counter += venta.cantidad;
      return venta;
    });
    return counter;
  };

  // Setear página
  const handleSetPage = (page: string) => {
    // si page es menor o igual a la cantidad de paginas
    if (Number(page) <= cantidadDePaginas()) {
      setPage(page);
    }
  };

  // Guardar en el estado el valor del input de búsqueda
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // Filtrar por estado
  const handleChangeStatusFilter = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(Number(e.target.value));
  };

  // Filtro por date
  const handleChangeDateFilter = (e: ChangeEvent<HTMLInputElement>) => {
    // si es id dateStart guardar en su estado
    if (e.target.id === "dateStart") {
      setDateStart(e.target.value);
    }
    // si es id dateEnd guardar en su estado
    if (e.target.id === "dateEnd") {
      setDateEnd(e.target.value);
    }
  };

  // Presionar enter
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Buscar
  const handleSearch = () => {
    dispatch(
      httpGetCollectionReportAsync(
        recordsForPage,
        Number(page),
        search,
        statusFilter,
        dateStart,
        dateEnd
      )
    );
  };

  // PAGINATION

  // Cambiar cantidad de resultados por página
  const handleChangeResultsForPage = (e: ChangeEvent<HTMLSelectElement>) => {
    setRecordsForPage(Number(e.target.value));
  };

  // Contar Cantidad de páginas
  const cantidadDePaginas = () =>
    Math.ceil(sales.pagination.totalAllRecords / recordsForPage);

  // Avanzar de página
  const handleNextPage = () => {
    if (sales.pagination.page === sales.pagination.totalRecords) return;
    // si page es igual a la cantidad de paginas no avanzar
    if (Number(page) === cantidadDePaginas()) return;
    setPage((sales.pagination.page + 1).toString());
    dispatch(
      httpGetCollectionReportAsync(
        recordsForPage,
        sales.pagination.page + 1,
        search,
        statusFilter,
        dateStart,
        dateEnd
      )
    );
    // Reset
  };

  // Retroceder de página
  const handlePrevPage = () => {
    if (sales.pagination.page === 1) return;
    setPage((sales.pagination.page - 1).toString());
    dispatch(
      httpGetCollectionReportAsync(
        recordsForPage,
        sales.pagination.page - 1,
        search,
        statusFilter,
        dateStart,
        dateEnd
      )
    );
    // Reset
  };

  useEffect(() => {
    if (search === "") {
      dispatch(
        httpGetCollectionReportAsync(
          recordsForPage,
          Number(page),
          search,
          statusFilter,
          dateStart,
          dateEnd
        )
      );
    }
  }, [search]);

  // Traer resultados con los cambios de filtros
  useEffect(() => {
    setPage("1");
    dispatch(
      httpGetCollectionReportAsync(
        recordsForPage,
        1,
        search,
        statusFilter,
        dateStart,
        dateEnd
      )
    );
  }, [statusFilter, recordsForPage, dateStart, dateEnd]);

  return {
    page,
    sales,
    search,
    idDetails,
    handleEnter,
    showDetails,
    statusFilter,
    handleSearch,
    handleSetPage,
    recordsForPage,
    handlePrevPage,
    handleNextPage,
    articlesCounter,
    handleShowDetails,
    cantidadDePaginas,
    handleChangeSearch,
    handleChangeDateFilter,
    handleChangeStatusFilter,
    handleChangeResultsForPage,
  };
};

export default useCollectionReportHook;
