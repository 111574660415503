import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  oderId: 0,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderId: (state, action: PayloadAction<number>) => {
      state.oderId = action.payload;
    },
  },
});

export const {
  setOrderId,
} = orderSlice.actions;

export default orderSlice.reducer;
