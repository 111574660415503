import React from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
// Styles
import {
  ButtonNewPartnerStyled,
  ButtonPartnerSelectStyled,
  ContainerModalPartnerSelectorStyled,
} from "../styles/ModalPartnerSelector";
// Actions
import {
  setDatosSocioNegocio,
  cleanDatosSocioNegocio,
  setEmailFormularioDeSocioNegocio,
} from "../../../features/partnerBusinessForm/partnerBusinessFormReducer";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
// Models

interface IModalPartnerSelector {
  closeModal: () => void;
  setFormData: (formData: any) => void;
  setIsSocioSeteado: (isSocioSeteado: boolean) => void;
}

const ModalPartnerBusinessSelector = ({
  closeModal,
  setFormData,
  setIsSocioSeteado,
}: IModalPartnerSelector) => {
  const dispatch = useAppDispatch();

  const partners = useAppSelector(
    (state) => state.partnerBusinessForm.sociosVinculadosAEmail
  );

  const emailCurrent = useAppSelector((state) => state.user.email);

  /**
   * Censura un documento reemplazando todos los caracteres, excepto los últimos cuatro, con "x".
   *
   * @param {string} document - El documento a censurar.
   * @returns {string} El documento censurado.
   */
  const censorDocument = (document: string) =>
    document.replace(/.(?=.{4})/g, "x");

  /**
   *
   * Limpia los datos del formulario actual y cierra el modal.
   */
  const createNewPartner = () => {
    setIsSocioSeteado(true);
    dispatch(cleanDatosSocioNegocio());
    cleanForm();
    closeModal();
  };

  // Cargar socio de negocio
  const loadPartner = (id: number) => {
    // Limpiar el formulario
    cleanForm();
    // Buscar el indice en el objeto partners
    const socio = partners[id];
    // Setear el estado de socio seteado
    setIsSocioSeteado(true);
    // cargar el objeto en datosSocioNegocio
    dispatch(setDatosSocioNegocio(socio));
    dispatch(setEmailFormularioDeSocioNegocio(emailCurrent));
    closeModal();
  };

  // Limpiar el formulario
  const cleanForm = () => {
    setFormData((prevState: PartnerBusinessFormData) => ({
      ...prevState,
      // Domicilio
      calle: "" /***/,
      numero: "" /***/,
      codigoPostal: "" /***/,
      provinciaId: "" /***/,
      localidadId: "" /***/,
      tipoInmuebleId: "1" /***/,
      piso: "", // habilitar solo si se selecciona departamento /***/
      departamento: "", // habilitar solo si se selecciona departamento /***/
      barrioNombre: "" /***/,
      domicilioId: null,
      // Persona
      nombre: "" /***/,
      apellido: "" /***/,
      tipoPersonaId: "" /***/,
      tipoIdentificacionTributariaId: "2" /***/,
      identificacionTributaria: "" /***/,
      tipoDocumentoId: "6" /***/,
      documento: "" /***/,
      condicionIvaId: "5",
      razonSocial: "", // habilitar solo si se selecciona persona juridica
      // Telefono
      prefijoPais: "54",
      prefijoLocalidad: "",
      tipoTelefonoId: "0",
      numeroTel: "", // cambiar a numero
      socioNegocioId: null
    }));
  };

  return (
    <ContainerModalPartnerSelectorStyled>
      <b>
        El correo está siendo utilizado por otro(s) socio(s), seleccione si
        usted es alguno o cree uno nuevo.
      </b>
      <ButtonNewPartnerStyled onClick={createNewPartner}>
        Crear un nuevo socio
      </ButtonNewPartnerStyled>
      {partners.map((socio, index) => (
        <ButtonPartnerSelectStyled
          key={index}
          onClick={() => loadPartner(index)}
        >
          {socio.nombre} {socio.apellido}{" "}
          {censorDocument(socio.numeroDocumento)} {socio.email}
        </ButtonPartnerSelectStyled>
      ))}
    </ContainerModalPartnerSelectorStyled>
  );
};

export default ModalPartnerBusinessSelector;
