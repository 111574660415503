import styled from "styled-components";

interface IModal {
  positionY?: number;
}

export const ModalContainerStyled = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  min-width: 300px;
  min-height: 120px;
  width: 30%;
  height: 10%;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  position: absolute;
  inset: 0;
  bottom: 30%;
  margin: auto;
  z-index: 999;
`;

export const ModalAttributesContainerStyled = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 30%;
  min-width: 270px;
  height: 120px;
  padding: 20px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  position: absolute;
  inset: 0;
  margin: auto;
  top: ${(props) => (props.positionY ? `${props.positionY / 13}%` : "50%")};
`;

export const ModalCategoryContainerStyled = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 35%;
  height: 140px;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: ${(props) =>
    props.positionY ? `${props.positionY / 10 + 50}%` : "50%"};
  left: 40%;
  overflow: hidden;
`;
