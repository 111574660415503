import React from "react";
import { ModalCategoryContainerStyled } from "../../styles/ModalConfirmation";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import httpDeleteAttribute from "../../../../services/Categorization/Definitions/Attributes/useHttpDeleteAttribute";
import Spinner from "../../../../components/spinner/Spinner";
import Button from "../../../../components/button/Button";

interface IModal {
  idAttribute: number;
  setShowModalDeleteAttribute: (value: boolean) => void;
  refreshAttributes: boolean;
  setRefreshAttributes: (value: boolean) => void;
  positionY?: number;
}

const ModalConfirmationDefinitionsAttribute = ({
  idAttribute,
  refreshAttributes,
  setRefreshAttributes,
  setShowModalDeleteAttribute,
  positionY,
}: IModal) => {
  const dispatch = useAppDispatch();
  const { httpDeleteAttributeAsync } = httpDeleteAttribute();
  const isLoadingDeleteAttribute = useAppSelector(
    (state) => state.isLoadingAttributes.loadingDeleteAttribute
  );

  const handleDeleteAttribute = () => {
    dispatch(
      httpDeleteAttributeAsync(
        idAttribute,
        refreshAttributes,
        setRefreshAttributes,
        setShowModalDeleteAttribute
      )
    );
  };

  const positionYModal =
    positionY && positionY > 1000 ? Number(positionY) + 300 : positionY;

  return (
    <ModalCategoryContainerStyled positionY={positionYModal}>
      <b>¿Desea eliminar el atributo seleccionado?</b>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button
          text="Cancelar"
          onClick={() => setShowModalDeleteAttribute(false)}
        />
        {isLoadingDeleteAttribute ? (
          <Spinner />
        ) : (
          <Button
            text="Eliminar"
            backgroundColor="secondary"
            onClick={handleDeleteAttribute}
          />
        )}
      </div>
    </ModalCategoryContainerStyled>
  );
};

export default ModalConfirmationDefinitionsAttribute;
