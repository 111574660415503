import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingCategories: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingCategories: (state, action: PayloadAction<boolean>) => {
      state.loadingCategories = action.payload;
    },
  },
});

export const { setLoadingCategories } = loadingsSlice.actions;

export default loadingsSlice.reducer;
