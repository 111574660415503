/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { setLoadingAddCart } from "../../features/loading/cart/loadingsReducer";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { IArticlesForCart } from "../../models/ArticulosParaCarrito";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpAddProductToCart() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();
  const httpAddProductToCartAsync =
    (articles: IArticlesForCart[]): AppThunk<Promise<boolean>> =>
    async (dispatch): Promise<boolean> => {
      const token = obtenerToken();
      // quitar comillas del token
      const tokenAccess = token?.replace(/['"]+/g, "");

      dispatch(setLoadingAddCart(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.addProductToCart}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${tokenAccess}`,
              Accept: "application/json",
              "Content-Type": "application/json",
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify(articles),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const data = await response.json();
          viewNotification(data.mensaje, "success");
          dispatch(setLoadingAddCart(false));
          return true;
        }
        dispatch(setLoadingAddCart(false));
        return false;
      } catch (error) {
        setLoadingAddCart(false);
        return false;
      }
    };
  return {
    httpAddProductToCartAsync,
  };
}

export default useHttpAddProductToCart;
