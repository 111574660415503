import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../../app/hooks";
import useHttpPublishedArticles from "../../../../../../services/Account/MyProducts/useHttpPublishedArticles";

const useMyProductsHook = (
  currentPage: number,
  refreshTable: boolean,
  clientGroupId: number
) => {
  const dispatch = useAppDispatch();
  const { httpPublishedArticlesAsync } = useHttpPublishedArticles();
  const [columnSelected, setColumnSelected] = useState<string>("");
  const [expandedColumns, setExpandedColumns] = useState<string>("");
  const [positionY, setPositionY] = useState(0);

  const handlerColumnExpanded = (column: string) => {
    setColumnSelected(column);
    if (expandedColumns === "") {
      setExpandedColumns("30%");
    } else {
      setExpandedColumns("");
    }
  };

  useEffect(() => {
    dispatch(
      httpPublishedArticlesAsync(currentPage, "", "", "", clientGroupId, 10)
    );
  }, [currentPage, refreshTable]);

  // Rastrear posición del scroll
  useEffect(() => {
    const handleScroll = () => {
      setPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    columnSelected,
    expandedColumns,
    positionY,
    handlerColumnExpanded,
  };
};

export default useMyProductsHook;
