import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingAttributes } from "../../../features/loading/categorizations/articles/loadingsReducer";
import { addAttributes } from "../../../features/categorization/articles/attributesReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpAttributes() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAttributesAsync = (): AppThunk => async (dispatch) => {
    dispatch(setLoadingAttributes(true));
    try {
      const response = await fetch(`${env.catalogo}${Routes.getAttributes}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
          empresaId: empresaId.toString(),
        },
      });
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (data) {
        dispatch(addAttributes(data));
      }
      dispatch(setLoadingAttributes(false));
    } catch (error) {
      setLoadingAttributes(false);
    }
    setLoadingAttributes(false);
  };
  return {
    httpAttributesAsync,
  };
}

export default useHttpAttributes;
