import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IConfigNotifications {
  email: string | null;
  telefono: string | null;
  id: string | null;
  whatsapp: string | null;

}

const initial: IConfigNotifications = {
  email: null,
  telefono: null,
  id: null,
  whatsapp: null,
};

export const ConfigNotificationsSlice = createSlice({
  name: "ConfigNotifications",
  initialState: initial,
  reducers: {
    setConfigNotifications: (state, action: PayloadAction<any>) => {
      // añadir el items al state
      state.email = action.payload.email;
      state.telefono = action.payload.telefono;
      state.id = action.payload.id;
      state.whatsapp = action.payload.whatsApp;
    }
  },
});

export const { setConfigNotifications } = ConfigNotificationsSlice.actions;

export default ConfigNotificationsSlice.reducer;
