import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingRating: false,
  loadingComments: false,
  loadingCurrentComment: false,
  loadingDeleteComments: false,
  loadingAddOrUpdateComments: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingComments: (state, action: PayloadAction<boolean>) => {
      state.loadingComments = action.payload;
    },
    setLoadingCurrentComment: (state, action: PayloadAction<boolean>) => {
      state.loadingCurrentComment = action.payload;
    },
    setLoadingAddOrUpdateComments: (state, action: PayloadAction<boolean>) => {
      state.loadingAddOrUpdateComments = action.payload;
    },
    setLoadingDeleteComments: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteComments = action.payload;
    },
    setLoadingRating: (state, action: PayloadAction<boolean>) => {
      state.loadingRating = action.payload;
    },
  }
});

export const {
  setLoadingRating,
  setLoadingComments,
  setLoadingDeleteComments,
  setLoadingCurrentComment,
  setLoadingAddOrUpdateComments,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
