/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routesApp } from "../../RoutesApp";
// Styles
import {
  ImgStyled,
  TextStyled,
  LinkStyled,
  WrapperStyled,
  LeftDivStyled,
  RightDivStyled,
  FillButtonStyled,
  ImgContainerStyled,
  CartContainerStyled,
  UserBarContainerStyled,
  QuantityContainerStyled,
  UserContainerStyled,
} from "./Styled";
// Icons
import Location from "./../../assets/icons/location.svg";
// import BellIcon from "../iconsComponents/BellIcon";
import BasketIcon from "../iconsComponents/BasketIcon";
import avatarUndefined from "../../assets/icons/avatar.png";
import { getToken } from "../../utils/helper";
// Redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { resetBasket } from "../../features/buy_Order/buy_OrderReducer";
// utils
import DmsValidate from "../../utils/DmsValidate";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
// Services
import useHttpQuitCart from "../../services/Cart/useHttpQuitCart";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Models
import { UserData } from "../../models/UserData";

const SessionBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { obtenerToken, removerItemsCarrito } = useLocalStorageHook();
  const token = getToken(obtenerToken);

  const { httpQuitCartAsync } = useHttpQuitCart();
  const { obtenerUserData } = useLocalStorageHook();

  const cartItems = useAppSelector(
    (state) => state.currentBuyOrder.ordenDeCompra.carritoDeCompras.items
  );
  const userRedux = useAppSelector((state) => state.user);
  const { aliasEmpresa } = useParams();

  const [cartQuantityState, setCartQuantityState] = useState(0);

  const [parent] = useAutoAnimate();

  // Convertir userData a objeto
  const userDataObj: UserData = obtenerUserData() || ({} as UserData);

  // Existe token valido?
  const isLoggedIn = token !== "";
  const isUserValid = userDataObj?.nombre !== "";

  const returnAvatar = () => {
    let avatar = "";
    if (userRedux?.avatar === "") {
      avatar = avatarUndefined;
    } else if (userRedux?.avatar === null) {
      avatar = avatarUndefined;
    } else if (userRedux?.avatar === undefined) {
      avatar = avatarUndefined;
    } else {
      avatar = userRedux?.avatar;
    }
    return avatar;
  };

  useEffect(() => {
    if (!window.location.href.includes(routesApp.purchaseThanks)) {
      setCartQuantityState(cartItems.length);
    } else {
      setCartQuantityState(0);
      dispatch(httpQuitCartAsync());
      dispatch(resetBasket());
      // Limpiar local storage
      removerItemsCarrito();
    }
  }, [cartItems.length]);

  return (
    <WrapperStyled>
      {userRedux.direcciones && userRedux.direcciones[0]?.ciudad && (
        <LeftDivStyled>
          <ImgStyled src={Location} alt="location"></ImgStyled>
          <TextStyled>{userRedux.direcciones[0]?.ciudad}</TextStyled>
        </LeftDivStyled>
      )}

      <RightDivStyled>
        {isLoggedIn && isUserValid ? (
          <UserBarContainerStyled>
            <UserContainerStyled
              ref={parent}
              onClick={() => navigate(`/${aliasEmpresa}${routesApp.profile}`)}
            >
              <ImgContainerStyled>
                <img
                  src={returnAvatar()}
                  onError={(e) => {
                    e.currentTarget.src = avatarUndefined;
                  }}
                  loading="lazy"
                  alt="Avatar icon"
                  style={{ padding: "5px", borderRadius: "50%" }}
                />
              </ImgContainerStyled>
              <TextStyled>
                {userRedux?.nombre} {userRedux?.apellido}
              </TextStyled>
            </UserContainerStyled>
            {/* oculto */}
            {/* <TextStyled>Compras</TextStyled>
            <BellIcon /> */}
          </UserBarContainerStyled>
        ) : (
          <>
            <FillButtonStyled
              onClick={() => navigate(`/${aliasEmpresa}${routesApp.register}`)}
            >
              Creá tu cuenta
            </FillButtonStyled>
            <FillButtonStyled>
              <LinkStyled to={`/${aliasEmpresa}${routesApp.login}`}>
                Inicia Sesión
              </LinkStyled>
            </FillButtonStyled>
          </>
        )}
        {!DmsValidate(token) ? (
          <CartContainerStyled
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.cart}`)}
          >
            <BasketIcon />
            {cartQuantityState > 0 && (
              <QuantityContainerStyled>
                {cartQuantityState}
              </QuantityContainerStyled>
            )}
          </CartContainerStyled>
        ) : null}
      </RightDivStyled>
    </WrapperStyled>
  );
};

export default SessionBar;
