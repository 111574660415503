/* eslint-disable max-lines-per-function */
import { useState } from "react";
// Styles
import {
  TableStyledProducts,
  ExpandedColumnStyled,
} from "../../../styles/CategorizationStyled";

import DataTable, { TableColumn } from "react-data-table-component";
import SearchArticle from "../../SearchArticle/SearchArticle";
// Redux
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import PaginationPriceDefinition from "./components/PaginationPriceDefinition";
// Models
import {
  Articulo,
  DataPublishedArticles,
} from "./models/DataPublishedArticles.models";
// Custom hooks
import useMyProductsHook from "./hooks/useMyProductsHook";
import ModalPublicationOfArticles from "./modals/ModalPublicationOfArticles";
// Services
import useHttpSinglePost from "../../../../../services/Account/MyProducts/useHttpSinglePost";
import useHttpSingleUnpost from "../../../../../services/Account/MyProducts/useHttpSingleUnpost";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Button from "../../../../../components/button/Button";

interface IProps {
  clientGroupId: number;
}

const PublicationOfArticlesTable = ({ clientGroupId }: IProps) => {
  const dispatch = useAppDispatch();
  // Services
  const { httpSinglePostAsync } = useHttpSinglePost();
  const { httpSingleUnpostAsync } = useHttpSingleUnpost();
  // Estados globales
  const publishedArticles = useAppSelector(
    (state) => state.publishedArticles as DataPublishedArticles
  );
  const isLoadingPublishedArticles = useAppSelector(
    (state) => state.isLoadingMyProducts.loadingPublishedArticles
  );

  const [parent] = useAutoAnimate();

  const [currentPage, setCurrentPage] = useState(1);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterDescription, setFilterDescription] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [publicationsList, setPublicationsList] = useState<number[]>([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [recordsForPage, setRecordsForPage] = useState(10);

  const { columnSelected, expandedColumns, handlerColumnExpanded, positionY } =
    useMyProductsHook(currentPage, refreshTable, clientGroupId);

  const columns: TableColumn<Articulo>[] = [
    {
      name: "Linea",
      cell: (row) => (
        <ExpandedColumnStyled onClick={() => handlerColumnExpanded("linea")}>
          {row.linea.descripcion}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "linea" ? expandedColumns : "",
      sortable: true,
    },
    {
      name: "Código",
      cell: (row) => (
        <ExpandedColumnStyled onClick={() => handlerColumnExpanded("codigo")}>
          {row.codigo}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "codigo" ? expandedColumns : "",
      selector: (row) => row.codigo,
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Descripción",
      cell: (row) => (
        <ExpandedColumnStyled
          onClick={() => handlerColumnExpanded("descripcion")}
        >
          {row.descripcion}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "descripcion" ? expandedColumns : "",
      selector: (row) => row.descripcion,
      sortable: true,
      style: { fontWeight: "bold" },
    },
    {
      name: "Categoria",
      cell: (row) => (
        <ExpandedColumnStyled
          onClick={() => handlerColumnExpanded("categoria")}
        >
          {row.categoria.descripcion}
        </ExpandedColumnStyled>
      ),
      width: columnSelected === "categoria" ? expandedColumns : "",
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Cantidad",
      selector: (row) => row.cantidad,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Precio",
      selector: (row) => row.precio,
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Diferenciación",
      selector: (row) =>
        row.definicionesArticulosGrupoCliente
          ? `${row.definicionesArticulosGrupoCliente.descuentoRecargo}%`
          : "0%",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Últ. unidades",
      selector: (row) =>
        row.definicionesArticulosGrupoCliente
          ? row.definicionesArticulosGrupoCliente.ultimasUnidades
          : "1",
      sortable: true,
      center: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
      width: "80px",
    },
    {
      name: "Publicado",
      selector: (row) =>
        row.definicionesArticulosGrupoCliente &&
        row.definicionesArticulosGrupoCliente.publicado
          ? "Si"
          : "No",
      sortable: true,
      width: "100px",
    },
    {
      name: "Publicaciones",
      center: true,
      sortable: true,
      style: {
        backgroundColor: "#f7f7f7",
      },
      width: "120px",
      cell: (row) => {
        if (
          row.definicionesArticulosGrupoCliente &&
          row.definicionesArticulosGrupoCliente.publicado
        ) {
          return (
            <Button
              text="Ocultar"
              onClick={() => handleSingleUnpublication(row.id)}
            />
          );
        }
        return (
          <Button
            text="Publicar"
            backgroundColor="secondary"
            onClick={() => handleSinglePublication(row.id)}
          />
        );
      },
    },
  ];

  const handleChange = ({ selectedRows }: any) => {
    const selectedRowsId = selectedRows.map((row: Articulo) => row.id);
    setPublicationsList(selectedRowsId);
  };

  // publicar individual
  const handleSinglePublication = (idArticle: number) => {
    setPublicationsList([]);
    // Validar entrada de datos
    if (clientGroupId === undefined || clientGroupId === null) return;
    // Enviar al endpoint
    dispatch(
      httpSinglePostAsync(
        clientGroupId,
        [idArticle],
        setRefreshTable,
        refreshTable
      )
    );
  };

  // publicar Masivo
  const handleMassivePost = () => {
    // Validar entrada de datos
    if (clientGroupId === undefined || clientGroupId === null) return;
    // Enviar al endpoint
    dispatch(
      httpSinglePostAsync(
        clientGroupId,
        publicationsList,
        setRefreshTable,
        refreshTable
      )
    );
    setPublicationsList([]);
    setToggleClearRows(!toggledClearRows);
  };

  // despublicar individual
  const handleSingleUnpublication = (idArticle: number) => {
    setPublicationsList([]);
    // Validar entrada de datos
    if (clientGroupId === undefined || clientGroupId === null) return;
    // Enviar al endpoint
    dispatch(
      httpSingleUnpostAsync(
        clientGroupId,
        [idArticle],
        setRefreshTable,
        refreshTable
      )
    );
    setToggleClearRows(!toggledClearRows);
  };

  // despublicar masivo
  const handleMassiveUnpublication = () => {
    // Validar entrada de datos
    if (clientGroupId === undefined || clientGroupId === null) return;
    // Enviar al endpoint
    dispatch(
      httpSingleUnpostAsync(
        clientGroupId,
        publicationsList,
        setRefreshTable,
        refreshTable
      )
    );
    setPublicationsList([]);
    setToggleClearRows(!toggledClearRows);
  };

  return (
    <TableStyledProducts ref={parent}>
      <SearchArticle
        title="Publicación de artículos"
        filterDescription={filterDescription}
        setFilterDescription={setFilterDescription}
        filterCode={filterCode}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        setFilterCode={setFilterCode}
        setShowModal={setShowModal}
        resetPaginationToggle={resetPaginationToggle}
        setResetPaginationToggle={setResetPaginationToggle}
        clientGroupId={clientGroupId}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 20px",
        }}
      >
        {publicationsList.length > 1 && (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleMassivePost}
              text="Publicar seleccionados"
              backgroundColor="secondary"
            />

            <Button
              onClick={handleMassiveUnpublication}
              text="Despublicar seleccionados"
            />
          </div>
        )}
      </div>
      <DataTable
        onSelectedRowsChange={handleChange}
        responsive
        data={publishedArticles.data}
        columns={columns}
        progressComponent={"Cargando..."}
        progressPending={isLoadingPublishedArticles}
        noDataComponent="No se encuentran resultados"
        selectableRows
        clearSelectedRows={toggledClearRows}
      />
      {!isLoadingPublishedArticles && publishedArticles.data.length !== 0 && (
        <PaginationPriceDefinition
          setCurrentPage={setCurrentPage}
          filterDescription={filterDescription}
          filterCode={filterCode}
          categoryId={categoryId}
          clientGroupId={clientGroupId}
          resetPaginationToggle={resetPaginationToggle}
          setResetPaginationToggle={setResetPaginationToggle}
          recordsForPage={recordsForPage}
          setRecordsForPage={setRecordsForPage}
        />
      )}
      {/* Modal de modificar todos */}
      {showModal && (
        <ModalPublicationOfArticles
          positionY={positionY}
          setShowModal={setShowModal}
          clientGroupId={clientGroupId}
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
        />
      )}
    </TableStyledProducts>
  );
};

export default PublicationOfArticlesTable;
