import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingCrearSocio: false,
  loadingDatosSocio: false,
  loadingLocalidades: false,
  loadingObtenerSocioDeNegocioPorEmail: false,
  loadingObtenerEmailSocioDeNegocioAsociadoConDocumentoCuit: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingLocalidades: (state, action: PayloadAction<boolean>) => {
      state.loadingLocalidades = action.payload;
    },
    setLoadingCrearSocio: (state, action: PayloadAction<boolean>) => {
      state.loadingCrearSocio = action.payload;
    },
    setLoadingDatosSocio: (state, action: PayloadAction<boolean>) => {
      state.loadingDatosSocio = action.payload;
    },
    setLoadingObtenerSocioDeNegocioPorEmail: (state, action: PayloadAction<boolean>) => {
      state.loadingObtenerSocioDeNegocioPorEmail = action.payload;
    },
    setLoadingObtenerEmailSocioDeNegocioAsociadoConDocumentoCuit: (state, action: PayloadAction<boolean>) => {
      state.loadingObtenerEmailSocioDeNegocioAsociadoConDocumentoCuit = action.payload;
    }
  },
});

export const {
  setLoadingCrearSocio,
  setLoadingDatosSocio,
  setLoadingLocalidades,
  setLoadingObtenerSocioDeNegocioPorEmail,
  setLoadingObtenerEmailSocioDeNegocioAsociadoConDocumentoCuit,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
