/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
// Services
import useHttpCrearSocioNegocio from "../../../services/BuyForm/useHttpCrearSocioNegocio";
import useHttpConsultarPendientes from "../../../services/BuyForm/useHttpConsultarPendientes";
// Models
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
// Hooks
import useValidationPartnerBusinessHook from "./useValidationPartnerBusinessHook";
import { createdAdaptedFormData } from "../adapters/form-adapter";
import { setIsRequired } from "../../../features/partnerBusinessForm/partnerBusinessFormReducer";

const useIndexBusinessParnerHook = () => {
  const dispatch = useAppDispatch();
  const { httpCrearSocioNegocioAsync } = useHttpCrearSocioNegocio();
  const { httpConsultarPendientesAsync } = useHttpConsultarPendientes();

  const isLoadingObtenerDatosSocio = useAppSelector(
    (state) => state.isLoadingBuyForm.loadingDatosSocio
  );
  const currentPartnerBusiness = useAppSelector(
    (state) => state.partnerBusinessForm.datosSocioNegocio
  );
  // EStados globales
  const isRequiredFields = useAppSelector(
    (state) => state.partnerBusinessForm.isRequired
  );

  const isEmptyFields = Object.values(isRequiredFields).some(
    (value) => value.value === true
  );

  // El documento ya está siendo utilizado?
  const isDocumentoUtilizado = useAppSelector(
    (state) => state.partnerBusinessForm.documentoUtilizado
  );

  // Estado para abrir o cerrar el modal
  const [showModal, setShowModal] = useState(false);
  // Hay socio seteado?
  const [isSocioSeteado, setIsSocioSeteado] = useState(false);

  // estado para guardar los datos del formulario
  const [formData, setFormData] = useState<PartnerBusinessFormData>(
    new PartnerBusinessFormData()
  );

  const validationPartnerBusinessHook =
    useValidationPartnerBusinessHook(formData);

  const { isValidForm, validateAllFormPartnerBusiness } =
    validationPartnerBusinessHook;

  const handleContinue = async (redirectToShippingPage?: boolean) => {
    if (isEmptyFields) {
      return alert("Complete todos los campos requeridos");
    } else if (isDocumentoUtilizado) {
      return alert("El documento ya está siendo utilizado");
    }

    if (await validateAllFormPartnerBusiness()) {
      const adapter = createdAdaptedFormData(formData);
      dispatch(
        httpCrearSocioNegocioAsync(adapter, redirectToShippingPage ?? true)
      );
    } else {
      return alert("El formulario contiene errores");
    }
  };

  const isLoadingCrearSocio = useAppSelector(
    (state) => state.isLoadingBuyForm.loadingCrearSocio
  );

  const partners = useAppSelector(
    (state) => state.partnerBusinessForm.sociosVinculadosAEmail
  );

  /**
   *
   * Cambia el estado de `showModal` a `false`.
   */
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (partners.length > 0 && !isSocioSeteado) {
      setShowModal(true);
    }
  }, [partners]);

  useEffect(() => {
    if (isLoadingObtenerDatosSocio) {
      Store.addNotification({
        title: "Cargando datos...",
        type: "info",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 2000,
        },
      });
    }
  }, [isLoadingObtenerDatosSocio]);

  useEffect(() => {
    dispatch(
      setIsRequired({
        name: "piso",
        value:
          formData.piso === "" && formData.tipoInmuebleId?.toString() === "2",
      })
    );
    dispatch(setIsRequired({ name: "calle", value: formData.calle === "" }));
    dispatch(setIsRequired({ name: "numero", value: formData.numero === "" }));
    dispatch(
      setIsRequired({
        name: "nombre",
        value:
          formData.nombre === "" && formData.tipoPersonaId?.toString() !== "2",
      })
    );
    dispatch(
      setIsRequired({
        name: "apellido",
        value:
          formData.apellido === "" &&
          formData.tipoPersonaId?.toString() !== "2",
      })
    );
    dispatch(
      setIsRequired({
        name: "documento",
        value:
          formData.documento === "" &&
          formData.tipoPersonaId?.toString() !== "2",
      })
    );
    dispatch(
      setIsRequired({ name: "numeroTel", value: formData.numeroTel === "" })
    );
    dispatch(
      setIsRequired({
        name: "razonSocial",
        value:
          formData.razonSocial === "" &&
          formData.tipoPersonaId?.toString() === "2",
      })
    );
    if (formData.provinciaId?.toString() !== "24") {
      dispatch(
        setIsRequired({
          name: "localidadId",
          value: formData.localidadId === "",
        })
      );
    }
    dispatch(
      setIsRequired({
        name: "provinciaId",
        value: formData.provinciaId === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "codigoPostal",
        value: formData.codigoPostal === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "departamento",
        value:
          formData.departamento === "" &&
          formData.tipoInmuebleId?.toString() === "2",
      })
    );
    dispatch(
      setIsRequired({
        name: "tipoPersonaId",
        value: formData.tipoPersonaId === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "condicionIvaId",
        value: formData.condicionIvaId === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "tipoInmuebleId",
        value: formData.tipoInmuebleId === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "tipoTelefonoId",
        value: formData.tipoTelefonoId === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "tipoDocumentoId",
        value:
          formData.tipoDocumentoId === "" &&
          formData.tipoPersonaId?.toString() !== "2",
      })
    );
    dispatch(
      setIsRequired({
        name: "prefijoPais",
        value: formData.prefijoPais === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "prefijoLocalidad",
        value: formData.prefijoLocalidad === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "identificacionTributaria",
        value: formData.identificacionTributaria === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "direccionEmail",
        value: formData.direccion === "",
      })
    );
    dispatch(
      setIsRequired({
        name: "tipoIdentificacionTributariaId",
        value: formData.tipoIdentificacionTributariaId === "",
      })
    );
  }, [formData]);

  useEffect(() => {
    dispatch(httpConsultarPendientesAsync());
  }, []);

  return {
    formData,
    setFormData,
    validationPartnerBusinessHook,
    handleContinue,
    isEmptyFields,
    isValidForm,
    showModal,
    closeModal,
    setIsSocioSeteado,
    currentPartnerBusiness,
    isLoadingCrearSocio,
  };
};

export default useIndexBusinessParnerHook;
