import React from "react";
// Styles
import {
  InputPageStyled,
  NextButtonStyled,
  SelectResultsStyled,
  ContainerPaginationStyled,
} from "../../../../styles/PaginationStyled";

interface IPagination {
  sales: any;
  page: string;
  recordsForPage: number;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handleEnter: (e: any) => void;
  cantidadDePaginas: () => number;
  handleSetPage: (page: string) => void;
  handleChangeResultsForPage: (e: any) => void;
}

const Pagination = ({
  page,
  sales,
  handleEnter,
  handleSetPage,
  recordsForPage,
  handlePrevPage,
  handleNextPage,
  cantidadDePaginas,
  handleChangeResultsForPage,
}: IPagination) => (
  <ContainerPaginationStyled>
    {/* <PaginationSales /> */}
    <div>Resultados totales: {sales.pagination.totalAllRecords}</div>
    <div>
      <span style={{ marginRight: "3px" }}>Resultados por página:</span>
      <SelectResultsStyled
        name="resultsForPage"
        id=""
        value={recordsForPage}
        onChange={(e) => handleChangeResultsForPage(e)}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
      </SelectResultsStyled>
    </div>
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      {sales.pagination.page !== 1 && (
        <NextButtonStyled onClick={handlePrevPage}>{"<"}</NextButtonStyled>
      )}
      <div>
        <InputPageStyled
          type="number"
          value={page}
          onClick={() => {
            handleSetPage("");
          }}
          onChange={(e) => {
            handleSetPage(e.target.value);
          }}
          onKeyDown={(e) => handleEnter(e)}
        />
      </div>
      {sales.pagination.page !== sales.pagination.totalRecords && (
        <>
          de {cantidadDePaginas()}
          <NextButtonStyled onClick={handleNextPage}>{">"}</NextButtonStyled>
        </>
      )}
    </div>
  </ContainerPaginationStyled>
);

export default Pagination;
