const validateUnauthorized = (response: any, empresaId: number) => {
  if (
    response.statusText === "Unauthorized" ||
    response === "Unauthorized" ||
    response.status === 401
  ) {
    localStorage.removeItem(empresaId.toString());
    window.location.reload();
  }
};

export default validateUnauthorized;
