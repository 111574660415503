import { useState, useEffect, KeyboardEvent } from "react";
// Styles
import {
  InputStyled,
  TableStyled,
  ButtonAddStyled,
  ButtonColorStyled,
  ButtonSaveColorStyled,
  ContainerInputStyled,
  InputEditStyled,
} from "../../../styles/CategorizationStyled";
// Components
import DataTable, { TableColumn } from "react-data-table-component";
import ModalConfirmationDefinitionsAttribute from "../../Modals/ModalConfirmationDefinitionsAttribute";
// Models
import { IAttributes } from "../../../models/Definitions.model";
// Redux
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
// Assets
import TrashIcon from "../../../assets/icons/trash.png";
import EditIcon from "../../../assets/icons/editar.png";
// Utils
import { ExistValueInList } from "../../../utils/ExistValueInList";
// Services
import useHttpAttributes from "../../../../../services/Categorization/Definitions/Attributes/useHttpAttributes";
import useHttpAddOrUpdateAttributes from "../../../../../services/Categorization/Definitions/Attributes/useHttpAddOrUpdateAttribute";

const DefinitionsAttributesTable = () => {
  const dispatch = useAppDispatch();

  // Servicios
  const { httpAttributesAsync } = useHttpAttributes();
  const { httpAddOrUpdateAttributesAsync } = useHttpAddOrUpdateAttributes();

  // Estados globales
  const attributesData = useAppSelector(
    (state) => state.attributesDefinitions as IAttributes[]
  );
  const isLoadingAttributes = useAppSelector(
    (state) => state.isLoadingAttributes.loadingAttributesDefinitions as boolean
  );

  // UseStates
  const msg = "Ya existe este atributo";
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [idAttribute, setIdAttribute] = useState(0);
  const [newAttribute, setNewAttribute] = useState("");
  const [refreshAttributes, setRefreshAttributes] = useState(false);
  const [editAttribute, setEditAttribute] = useState("");
  const [positionY, setPositionY] = useState(0);
  const [showModalDeleteAttribute, setShowModalDeleteAttribute] =
    useState(false);

  // Columnas de la tabla
  const columns: TableColumn<IAttributes>[] = [
    {
      name: "Descripción",
      style: { fontWeight: "bold" },
      selector: (row: any) => {
        if (showSaveButton && idAttribute === row.id) {
          return (
            <InputEditStyled
              type="text"
              value={editAttribute}
              maxLength={50}
              onChange={(e) => setEditAttribute(e.target.value)}
              onKeyDown={(e) => {
                handleEnterEdit(e, row.id);
              }}
            />
          );
        }
        return row.descripcion;
      },
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row: IAttributes) => {
        if (showSaveButton && idAttribute === row.id) {
          return (
            <div style={{ display: "flex", gap: "5px" }}>
              <ButtonSaveColorStyled
                color="lime"
                onClick={() => handleEditAttribute(row.id)}
              >
                <p style={{ color: "black", fontWeight: "bold" }}>Guardar</p>
              </ButtonSaveColorStyled>
              <ButtonSaveColorStyled
                color="red"
                onClick={() => handleEditAttribute(0)}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>Cancelar</p>
              </ButtonSaveColorStyled>
            </div>
          );
        }
        return (
          <ButtonColorStyled
            color="lime"
            onClick={() => handleShowInputEdit(row)}
          >
            <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
          </ButtonColorStyled>
        );
      },
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row: IAttributes) => (
        <ButtonColorStyled
          color="orange"
          onClick={() => handleDeleteAttribute(row.id)}
        >
          <img src={TrashIcon} alt="Eliminar" style={{ width: "15px" }} />
        </ButtonColorStyled>
      ),
    },
  ];

  // Agregar atributo
  const handleAddAttribute = () => {
    if (newAttribute === "") return;
    if (!ExistValueInList(attributesData, newAttribute, msg)) {
      // si no existe subcategoria, la agrega
      dispatch(
        httpAddOrUpdateAttributesAsync(
          newAttribute,
          refreshAttributes,
          setRefreshAttributes,
          setShowSaveButton
        )
      );
    }
    setNewAttribute("");
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddAttribute();
    }
  };

  // Mostrar input text para editar atributo
  const handleShowInputEdit = (row: IAttributes) => {
    setIdAttribute(row.id);
    setEditAttribute(row.descripcion);
    // cambiar el boton editar por el boton guardar
    setShowSaveButton(true);
  };

  // Editar atributo
  const handleEditAttribute = (idAttr: number) => {
    // si id es 0, cancelar operacion
    if (idAttr === 0) {
      setShowSaveButton(false);
      return;
    }
    if (editAttribute === "") return;
    // enviar id con text editado (editSubcategory)
    if (!ExistValueInList(attributesData, editAttribute, msg)) {
      dispatch(
        httpAddOrUpdateAttributesAsync(
          editAttribute,
          refreshAttributes,
          setRefreshAttributes,
          setShowSaveButton,
          idAttr
        )
      );
    }
  };

  const handleEnterEdit = (e: KeyboardEvent<HTMLInputElement>, id: number) => {
    if (e.key === "Enter") {
      handleEditAttribute(id);
    }
  };

  // Eliminar Atributo
  const handleDeleteAttribute = (idAttr: number) => {
    setIdAttribute(idAttr);
    setShowModalDeleteAttribute(true);
  };

  // Traer datos en la tabla
  useEffect(() => {
    dispatch(httpAttributesAsync());
  }, [refreshAttributes]);

  // Rastrear posición del scroll
  useEffect(() => {
    const handleScroll = () => {
      setPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TableStyled>
      {/* Agregar atributo */}
      <ContainerInputStyled>
        <InputStyled
          type="text"
          placeholder="Agregar atributo"
          value={newAttribute}
          onChange={(e) => {
            setNewAttribute(e.target.value);
          }}
          onKeyDown={(e) => handleEnter(e)}
        />
        <ButtonAddStyled onClick={() => handleAddAttribute()}>
          Agregar
        </ButtonAddStyled>
      </ContainerInputStyled>
      <DataTable
        responsive
        data={attributesData}
        columns={columns}
        progressComponent={"Cargando..."}
        progressPending={isLoadingAttributes}
        noDataComponent="No se encuentran resultados"
      />
      {/* Modal para eliminar atributo */}
      {showModalDeleteAttribute && (
        <ModalConfirmationDefinitionsAttribute
          idAttribute={idAttribute}
          setShowModalDeleteAttribute={setShowModalDeleteAttribute}
          refreshAttributes={refreshAttributes}
          setRefreshAttributes={setRefreshAttributes}
          positionY={positionY}
        />
      )}
    </TableStyled>
  );
};

export default DefinitionsAttributesTable;
