/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingPublishAllArticles } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import { IErrorPublish } from "../../../DTOs/Errors/ErrorPublishDto";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpPublishAllArticles() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpPublishAllArticlesAsync =
    (
      clientGroupId: number,
      setRefreshTable: (value: boolean) => void,
      refreshTable: boolean,
      setShowModal: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingPublishAllArticles(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.publishAllArticles}?grupoClienteId=${clientGroupId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          viewNotification(
            "Los articulos se han publicado correctamente.",
            "success"
          );
          setRefreshTable && setRefreshTable(!refreshTable);
        } else if (response.status === 400) {
          const data: IErrorPublish = await response.json();
          if (Object.keys(data.EntityErrors).length > 0) {
            data.EntityErrors.Email &&
              viewNotification(data.EntityErrors.Email[0], "danger");
            data.EntityErrors.Telefono &&
              viewNotification(data.EntityErrors.Telefono[0], "danger");
            data.EntityErrors.Id &&
              viewNotification(data.EntityErrors.Id[0], "danger");
          }
        } else {
          viewNotification("Error al intentar publicar", "danger");
        }
        dispatch(setLoadingPublishAllArticles(false));
      } catch (error) {
        dispatch(setLoadingPublishAllArticles(false));
      }
      setShowModal(false);
    };
  return {
    httpPublishAllArticlesAsync,
  };
}

export default useHttpPublishAllArticles;
