import React from "react";
// Styles
import {
  LabelStyled,
  InputStyled,
  InputCheckContainer,
} from "../styles/FormStyles";
// Models
import { ICheckbox } from "../types/Checkbox.model";

const Checkbox = ({ label, name, checked, onChange }: ICheckbox) => (
  <InputCheckContainer>
    <LabelStyled htmlFor={label}>{label}</LabelStyled>
    <InputStyled
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
  </InputCheckContainer>
);

export default Checkbox;
