import React, { useState, Dispatch } from "react";
import {
  InputPageStyled,
  NextButtonStyled,
  ContainerPaginationStyled,
  SelectResultsStyled,
} from "../../../../styles/PaginationStyled";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import useHttpPublishedArticles from "../../../../../../services/Account/MyProducts/useHttpPublishedArticles";
// Models
import { DataPublishedArticles } from "../models/DataPublishedArticles.models";

interface IPagination {
  setCurrentPage: Dispatch<React.SetStateAction<number>>;
  filterDescription: string;
  filterCode: string;
  categoryId: string;
  clientGroupId: number;
  resetPaginationToggle: boolean;
  setResetPaginationToggle: Dispatch<React.SetStateAction<boolean>>;
  recordsForPage: number;
  setRecordsForPage: Dispatch<React.SetStateAction<number>>;
}

const PaginationPriceDefinition = ({
  setCurrentPage,
  filterDescription,
  filterCode,
  categoryId,
  clientGroupId,
  resetPaginationToggle,
  setResetPaginationToggle,
  recordsForPage,
  setRecordsForPage,
}: IPagination) => {
  const dispatch = useAppDispatch();

  const { httpPublishedArticlesAsync } = useHttpPublishedArticles();

  const { pagination } = useAppSelector(
    (state) => state.publishedArticles as DataPublishedArticles
  );

  const [page, setPage] = useState<string>(pagination.page.toString());

  const totalPages = Math.ceil(pagination.totalAllRecords / recordsForPage);

  const handleNextPage = () => {
    if (pagination.page === totalPages) return;
    setCurrentPage(pagination.page + 1);
    dispatch(
      httpPublishedArticlesAsync(
        Number(pagination.page) + 1,
        filterDescription,
        filterCode,
        categoryId,
        clientGroupId,
        recordsForPage
      )
    );
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handlePrevPage = () => {
    if (pagination.page === 1) return;
    setCurrentPage(pagination.page - 1);
    dispatch(
      httpPublishedArticlesAsync(
        pagination.page - 1,
        filterDescription,
        filterCode,
        categoryId,
        clientGroupId,
        recordsForPage
      )
    );
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const inputHandler = (e: any) => {
    if (e.target.value === "" || Number(e.target.value) > totalPages) return;
    setPage(e.target.value);
  };

  // Cuando se presiona Enter
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      dispatch(
        httpPublishedArticlesAsync(
          Number(page),
          filterDescription,
          filterCode,
          categoryId,
          clientGroupId,
          recordsForPage
        )
      );
    }
  };

  // Cambiar cantidad de resultados
  const handleRecordsForPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordsForPage(Number(e.target.value));
    dispatch(
      httpPublishedArticlesAsync(
        Number(page),
        filterDescription,
        filterCode,
        categoryId,
        clientGroupId,
        Number(e.target.value)
      )
    );
  };

  return (
    <ContainerPaginationStyled>
      <div>Resultados totales: {pagination.totalAllRecords}</div>
      <div>
        Resultados por página:
        <SelectResultsStyled
          name="recordsForPage"
          id=""
          value={recordsForPage}
          onChange={handleRecordsForPage}
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </SelectResultsStyled>
      </div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {pagination.page !== 1 && (
          <NextButtonStyled onClick={handlePrevPage}>{"<"}</NextButtonStyled>
        )}
        <div>
          <InputPageStyled
            type="number"
            value={page}
            onClick={() => {
              setPage("");
            }}
            onChange={(e) => {
              inputHandler(e);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        {pagination.page !== totalPages && (
          <>
            de {totalPages}
            <NextButtonStyled onClick={handleNextPage}>{">"}</NextButtonStyled>
          </>
        )}
      </div>
    </ContainerPaginationStyled>
  );
};

export default PaginationPriceDefinition;
