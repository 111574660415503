import { SocioNegocioFormDataDto } from "../../../DTOs/SocioNegocioFormData";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";

export const createdAdaptedFormData = (formData: PartnerBusinessFormData) => {
  const formattedFormData: SocioNegocioFormDataDto = {
    clienteSocioNegocioId: formData.socioNegocioId,
    persona: {
      tipoPersonaId: Number(formData.tipoPersonaId),
      nombre: formData.nombre,
      apellido: formData.apellido,
      tipoIdentificacionTributariaId: Number(formData.tipoIdentificacionTributariaId),
      identificacionTributaria: formData.identificacionTributaria,
      tipoDocumentoId: Number(formData.tipoDocumentoId),
      documento: formData.documento,
      condicionIvaId: formData.condicionIvaId,
      razonSocial: formData.razonSocial,
    },
    ubicaciones: {
      domicilios: [
        {
          calle: formData.calle,
          numero: formData.numero,
          codigoPostal: formData.codigoPostal,
          provinciaId: formData.provinciaId,
          localidadId: formData.localidadId,
          tipoInmuebleId: formData.tipoInmuebleId,
          piso: formData.piso,
          departamento: formData.departamento,
          id: formData.domicilioId,
          barrioNombre: "",
        },
      ],
      telefonos: [
        {
          prefijoPais: formData.prefijoPais,
          prefijoLocalidad: formData.prefijoLocalidad,
          tipoTelefonoId: formData.tipoTelefonoId,
          numero: formData.numeroTel,
        },
      ],
      emails: [
        {
          direccion: formData.direccion,
        },
      ],
    },
  };
  return formattedFormData;
};
