import { useEffect, useState, KeyboardEvent } from "react";
// Styled
import {
  ButtonAddStyled,
  ButtonColorStyled,
  ButtonSaveColorStyled,
  ContainerInputStyled,
  InputEditStyled,
  InputStyled,
  TableStyled,
} from "../../../styles/CategorizationStyled";
// Componentes
import DataTable, { TableColumn } from "react-data-table-component";
import AttributeSelector from "../../AttributeSelector";
// Utils
import { ExistValueInList } from "../../../utils/ExistValueInList";
// Models
import { IValues } from "../../../models/Definitions.model";
// Hooks
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
// Services
import useHttpValues from "../../../../../services/Categorization/Definitions/Attributes/useHttpValues";
import useHttpAddOrUpdateValues from "../../../../../services/Categorization/Definitions/Attributes/useHttpAddOrUpdateValues";
// Assets
import TrashIcon from "../../../assets/icons/trash.png";
import EditIcon from "../../../assets/icons/editar.png";
import ModalConfirmationValue from "../../Modals/ModalConfirmationValue";

const DefinitionsAttributesTable = () => {
  const dispatch = useAppDispatch();

  // Servicios
  const { httpValuesAsync } = useHttpValues();
  const { httpAddOrUpdateValuesAsync } = useHttpAddOrUpdateValues();

  // Estados globales
  const valuesData = useAppSelector(
    (state) => state.valuesDefinitions as IValues[]
  );
  const isLoadingValues = useAppSelector(
    (state) => state.isLoadingAttributes.loadingValuesDefinitions as boolean
  );

  // UseStates
  const msg = "Ya existe este valor";
  const [idValue, setIdValue] = useState(0);
  const [newValue, setNewValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [refreshValues, setRefreshValues] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [positionY, setPositionY] = useState(0);
  const [attributeSelectedId, setAttributeSelectedId] = useState("");
  const [showModalDeleteValue, setShowModalDeleteValue] = useState(false);

  // Columnas de la tabla
  const columns: TableColumn<IValues>[] = [
    {
      name: "Valores",
      style: { fontWeight: "bold" },
      selector: (row: any) => {
        if (showSaveButton && idValue === row.id) {
          return (
            <InputEditStyled
              type="text"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              onKeyDown={(e) => {
                handleEnterEdit(e, row.id);
              }}
            />
          );
        }
        return row.descripcion;
      },
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row: IValues) => {
        if (showSaveButton && idValue === row.id) {
          return (
            <div style={{ display: "flex", gap: "5px" }}>
              <ButtonSaveColorStyled
                color="lime"
                onClick={() => handleEditValue(row.id)}
              >
                <p style={{ color: "black", fontWeight: "bold" }}>Guardar</p>
              </ButtonSaveColorStyled>
              <ButtonSaveColorStyled
                color="red"
                onClick={() => handleEditValue(0)}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>Cancelar</p>
              </ButtonSaveColorStyled>
            </div>
          );
        }
        return (
          <ButtonColorStyled
            color="lime"
            onClick={() => handleShowInputEdit(row)}
          >
            <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
          </ButtonColorStyled>
        );
      },
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row: IValues) => (
        <ButtonColorStyled
          color="orange"
          onClick={() => handleDeleteValue(row.id)}
        >
          <img src={TrashIcon} alt="Eliminar" style={{ width: "15px" }} />
        </ButtonColorStyled>
      ),
    },
  ];

  // Mostrar input text para editar valor
  const handleShowInputEdit = (row: IValues) => {
    setIdValue(row.id);
    setEditValue(row.descripcion);
    // cambiar el boton editar por el boton guardar
    setShowSaveButton(true);
  };

  // Editar valor
  const handleEditValue = (valueId: number) => {
    // si id es 0, cancelar operacion
    if (valueId === 0) {
      setShowSaveButton(false);
      return;
    }
    if (editValue === "") return;
    // enviar id con text editado (editSubcategory)
    if (!ExistValueInList(valuesData, editValue, msg)) {
      dispatch(
        httpAddOrUpdateValuesAsync(
          editValue,
          refreshValues,
          setRefreshValues,
          setShowSaveButton,
          Number(attributeSelectedId),
          valueId
        )
      );
    }
  };

  const handleEnterEdit = (e: KeyboardEvent<HTMLInputElement>, id: number) => {
    if (e.key === "Enter") {
      handleEditValue(id);
    }
  };

  // Agregar valor
  const handleAddValue = () => {
    if (newValue === "") return;
    if (!ExistValueInList(valuesData, newValue, msg)) {
      // si no existe valor, la agrega
      dispatch(
        httpAddOrUpdateValuesAsync(
          newValue,
          refreshValues,
          setRefreshValues,
          setShowSaveButton,
          Number(attributeSelectedId)
        )
      );
    }
    setNewValue("");
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddValue();
    }
  };

  // Eliminar valor
  const handleDeleteValue = (valueId: number) => {
    setIdValue(valueId);
    setShowModalDeleteValue(true);
  };

  // Traer datos en la tabla
  useEffect(() => {
    if (attributeSelectedId !== "") {
      dispatch(httpValuesAsync(attributeSelectedId));
    }
  }, [attributeSelectedId, refreshValues]);

  // Rastrear posición del scroll
  useEffect(() => {
    const handleScroll = () => {
      setPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TableStyled>
      {/* Selector de atributos */}
      <AttributeSelector setAttributeSelectedId={setAttributeSelectedId} />
      {attributeSelectedId !== "" && attributeSelectedId !== null && (
        <>
          {isLoadingValues ? (
            "Cargando..."
          ) : (
            /* Agregar subcategoría */
            <ContainerInputStyled>
              <InputStyled
                type="text"
                placeholder="Agregar valores"
                maxLength={30}
                value={newValue}
                onChange={(e) => {
                  setNewValue(e.target.value);
                }}
                onKeyDown={(e) => handleEnter(e)}
              />
              <ButtonAddStyled onClick={handleAddValue}>
                Agregar
              </ButtonAddStyled>
            </ContainerInputStyled>
          )}
          <DataTable
            responsive
            data={valuesData}
            columns={columns}
            progressComponent={"Cargando..."}
            progressPending={isLoadingValues}
            noDataComponent="No se encuentran resultados"
          />
        </>
      )}
      {/* Modal para eliminar valor */}
      {showModalDeleteValue && idValue !== 0 && (
        <ModalConfirmationValue
          idValue={idValue}
          refreshValues={refreshValues}
          setRefreshValues={setRefreshValues}
          setShowModalDeleteValue={setShowModalDeleteValue}
          positionY={positionY}
        />
      )}
    </TableStyled>
  );
};

export default DefinitionsAttributesTable;
