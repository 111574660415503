import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActiveFilters } from "../../types/ActiveFilters";

// Define the initial state using that type
const initialState: ActiveFilters = {
  marca: [],
  modelo: "",
  ano: null,
  vin: "",
  mostrar: "TODO",
  ubicacion: [],
  precioMaximo: null,
  precioMinimo: null,
  categoria: "",
  subcategoria: "",
  caracteristicas: [],
};

export const activeFilters = createSlice({
  name: "activeFilters",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addBrand: (state, action: PayloadAction<string>) => {
      // buscar si ya existe
      const index = state.marca?.findIndex((m) => m === action.payload);
      if (index === -1) {
        state.marca?.push(action.payload);
      }
    },
    removeBrand: (state, action: PayloadAction<string>) => {
      state.marca = state.marca?.filter((m) => m !== action.payload);
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.modelo = action.payload;
    },
    removeModel: (state) => {
      state.modelo = "";
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.ano = action.payload;
    },
    removeYear: (state) => {
      state.ano = null;
    },
    setVIN: (state, action: PayloadAction<string>) => {
      state.vin = action.payload;
    },
    /* eslint-disable */
    removeVIN: (state, action: PayloadAction<string>) => {
      state.vin = '';
    },
    /* eslint-enable */
    setToShow: (state, action: PayloadAction<"TODO" | "OFERTA">) => {
      state.mostrar = action.payload;
    },
    addFeature: (state, action: PayloadAction<string>) => {
      const index = state.caracteristicas?.findIndex(
        (m) => m === action.payload
      );

      if (index === -1) {
        state.caracteristicas?.push(action.payload);
      }
    },
    removeFeature: (state, action: PayloadAction<string>) => {
      state.caracteristicas = state.caracteristicas?.filter(
        (m) => m !== action.payload
      );
    },
    addLocation: (state, action: PayloadAction<string>) => {
      const index = state.ubicacion?.findIndex((m) => m === action.payload);
      if (index === -1) {
        state.ubicacion?.push(action.payload);
      }
    },
    removeLocation: (state, action: PayloadAction<string>) => {
      state.ubicacion = state.ubicacion?.filter((m) => m !== action.payload);
    },
    setMaxCost: (state, action: PayloadAction<number>) => {
      state.precioMaximo = action.payload;
    },
    removeMaxCost: (state) => {
      state.precioMaximo = null;
    },
    setMinCost: (state, action: PayloadAction<number>) => {
      state.precioMinimo = action.payload;
    },
    removeMinCost: (state) => {
      state.precioMinimo = null;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.categoria = action.payload;
    },
    removeCategory: (state) => {
      state.categoria = "";
    },
    setSubcategory: (state, action: PayloadAction<string>) => {
      state.subcategoria = action.payload;
    },
    removeSubcategory: (state) => {
      state.subcategoria = "";
      state.caracteristicas = [];
    },

    resetFilters: (state) => {
      state.marca = [];
      state.modelo = "";
      state.ano = null;
      state.vin = "";
      state.mostrar = "TODO";
      state.ubicacion = [];
      state.precioMaximo = null;
      state.precioMinimo = null;
      state.categoria = "";
      state.subcategoria = "";
      state.caracteristicas = [];
    },
  },
});

export const {
  addBrand,
  addFeature,
  removeFeature,
  removeMaxCost,
  removeMinCost,
  removeVIN,
  removeYear,
  setModel,
  setVIN,
  setYear,
  addLocation,
  removeBrand,
  removeModel,
  removeLocation,
  setToShow,
  setMinCost,
  setMaxCost,
  resetFilters,
  removeCategory,
  setCategory,
  removeSubcategory,
  setSubcategory,
} = activeFilters.actions;

export default activeFilters.reducer;
