/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk } from "../../app/store";
import env from "../../environment";
import { ColorsDto } from "../../DTOs/ColorsDto";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpSaveThemeColors() {
  const { obtenerToken } = useLocalStorageHook();

  const httpSaveThemeColorsAsync =
    (colors: ColorsDto): AppThunk =>
    async () => {
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.saveThemeColors}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            },
            body: JSON.stringify(colors),
          }
        );

        if (response.status === 200) {
          viewNotification("Guardado!", "success");
        } else {
          viewNotification("Error!", "danger");
        }
      } catch (error) {
        viewNotification("Error!", "danger");
      }
    };
  return {
    httpSaveThemeColorsAsync,
  };
}

export default useHttpSaveThemeColors;
