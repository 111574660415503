import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import searchReducer from "../features/search/searchReducer";
import modalsReducer from "../features/modals/modalsReducer";
import errorsReducer from "../features/errors/errorsReducer";
import filtersReducer from "../features/filters/filtersReducer";
import isLoadingReducer from "../features/loading/loadingReducer";
// Loadings
import isLoadingCartReducer from "../features/loading/cart/loadingsReducer";
import isLoadingBuyFormReducer from "../features/loading/buyForm/loadingsReducer";
import isLoadingPaymentReducer from "../features/loading/payment/loadingsReducer";
import isLoadingSecurityReducer from "../features/loading/security/loadingsReducer";
import isLoadingDataTransferReducer from "../features/loading/transfer/loadingReducer";
import isLoadingHistorySaleReducer from "../features/loading/historySale/loadingsReducer";
import isLoadingPurchaseRegretReducer from "../features/loading/purchaseRegret/loadingReducer";
import isLoadingMyProductsReducer from "../features/loading/account/myProducts/loadingsReducer";
import isLoadingParametrizationReducer from "../features/loading/parametrization/loadingReducer";
import isLoadingArticlesReducer from "../features/loading/categorizations/articles/loadingsReducer";
import isLoadingCommentsAndRatingsReducer from "../features/loading/commentsAndRatings/loadingReducer";
import isLoadingTransferAttachmentsReducer from "../features/loading/transferAttachments/loadingReducer";
import isLoadingConfigNotificationsReducer from "../features/loading/configNotifications/loadingsReducer";
import isLoadingCarouselReducer from "../features/loading/carousel/loadingsReducer";
import isLoadingAttributesReducer from "../features/loading/categorizations/definitions/attributes/loadingsReducer";
import isLoadingCategoriesReducer from "../features/loading/categorizations/definitions/categories/loadingsReducer";
import isLoadingValidateConfigPaymentGatewayReducer from "../features/loading/configPaymentGateway/loadingsReducer";
import isLoadingSubcategoriesReducer from "../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import isLoadingNewAddedProductsReducer from "../features/loading/newAddedProducts/loadingReducer";
// import activeFiltersVinReducer from '../features/filters-vin/activeFiltersVinReducer';
import userDataReducer from "../features/userData/userDataReducer";
import buyOrderReducer from "../features/buy_Order/buy_OrderReducer";
import activeFiltersReducer from "../features/filters/activeFiltersReducer";
import valuesReducer from "../features/categorization/articles/valuesReducer";
import articlesReducer from "../features/categorization/articles/articlesReducer";
import productDetailsReducer from "../features/productDetails/productDetailsSlice";
import attributesReducer from "../features/categorization/articles/attributesReducer";
import attributesInTheArticleReducer from "../features/categorization/articles/attributesInTheArticleReducer";
// Definitions
import valuesDefinitions from "../features/categorization/definitions/valuesReducer";
import categoriesReducer from "../features/categorization/definitions/categoriesReducer";
import attributesDefinitions from "../features/categorization/definitions/attributesReducer";
import subcategoriesReducer from "../features/categorization/definitions/subcategoriesReducer";
import subcategoriesByCategory from "../features/categorization/definitions/subcategoriesByCategoryReducer";
import RelatedArticlesBySubcategory from "../features/categorization/definitions/RelatedArticlesBySubcategory";
// Account - MyProducts
import PublishedArticlesReducer from "../features/account/myProducts/publishedArticlesReducer";
// Cart
import cartReducer from "../features/cart/cartReducer";
import orderReducer from "../features/order/orderReducer";
// buyForm
import partnerBusinessFormReducer from "../features/partnerBusinessForm/partnerBusinessFormReducer";
// App configuration state
import clientGroupReducer from "../features/appConfiguration/clientGroupReducer";
import appConfigurationReducer from "../features/appConfiguration/appConfigurationReducer";
// Notifications config
import notificationsConfigReducer from "../features/configNotifications/configNotificationsReducer";
// History
import salesHistoryReducer from "../features/salesHistory/salesHistoryReducer";
// Validate payment gateway
import paymentGatewayReducer from "../features/paymentGateway/paymentGatewayReducer";
// Data Transfer
import dataTransferReducer from "../features/dataTransfer/dataTransferReducer";
import transferEnableReducer from "../features/transfer/transferReducer";
import transferAttachmentsReducer from "../features/transferAttachments/transferAttachmentsReducer";
// Parametrization
import parametrizationReducer from "../features/parametrization/parametrizationReducer";
// Articulos mas vendidos
import mostSellingProductsReducer from "../features/bestSellingArticles/bestSellingArticlesReducer";
import mostViewedArticlesReducer from "../features/mostViewedArticles/mostViewedArticlesReducer";
import newAddedProductsReducer from "../features/newAddedProducts/newAddedProductsReducer";
// Comentarios
import commentsAndRatingsReducer from "../features/commentsAndRatings/commentsAndRatingsReducer";
import commentBoxReducer from "../features/commentBox/commentBoxReducer";
// Virtual Assistant
import virtualAssistantReducer from "../features/virtualAssistant/virtualAssistantReducer";
// Carousel
import carouselReducer from "../features/carousel/carouselReducer";

export const store = configureStore({
  reducer: {
    // Loadings
    isLoading: isLoadingReducer,
    isLoadingCart: isLoadingCartReducer,
    isLoadingPayment: isLoadingPaymentReducer,
    isLoadingBuyForm: isLoadingBuyFormReducer,
    isLoadingArticles: isLoadingArticlesReducer,
    isLoadingSecurity: isLoadingSecurityReducer,
    isLoadingCarousel: isLoadingCarouselReducer,
    isLoadingAttributes: isLoadingAttributesReducer,
    isLoadingCategories: isLoadingCategoriesReducer,
    isLoadingMyProducts: isLoadingMyProductsReducer,
    isLoadingHistorySale: isLoadingHistorySaleReducer,
    isLoadingDataTransfer: isLoadingDataTransferReducer,
    isLoadingSubcategories: isLoadingSubcategoriesReducer,
    isLoadingPurchaseRegret: isLoadingPurchaseRegretReducer,
    isLoadingParametrization: isLoadingParametrizationReducer,
    isLoadingNewAddedProducts: isLoadingNewAddedProductsReducer,
    isLoadingCommentsAndRatings: isLoadingCommentsAndRatingsReducer,
    isLoadingConfigNotifications: isLoadingConfigNotificationsReducer,
    isLoadingTransferAttachments: isLoadingTransferAttachmentsReducer,
    isLoadingValidateConfigPaymentGateway: isLoadingValidateConfigPaymentGatewayReducer,

    search: searchReducer,
    filters: filtersReducer,
    activeFilters: activeFiltersReducer,
    // filtersVin: filtersReducer,
    // activeFiltersVin: activeFiltersReducer,
    user: userDataReducer,
    modals: modalsReducer,
    errors: errorsReducer,
    currentBuyOrder: buyOrderReducer,
    currentProductDetails: productDetailsReducer,
    // Categorization - Articles
    valuesArticles: valuesReducer,
    attributes: attributesReducer,
    categorization: articlesReducer,
    attributesInArticle: attributesInTheArticleReducer,
    // Categorization - Definitions
    valuesDefinitions,
    attributesDefinitions,
    subcategoriesByCategory,
    categories: categoriesReducer,
    subcategories: subcategoriesReducer,
    relatedArticles: RelatedArticlesBySubcategory,
    // Account - MyProducts
    publishedArticles: PublishedArticlesReducer,
    // Cart
    cart: cartReducer,
    // buyForm
    partnerBusinessForm: partnerBusinessFormReducer,
    // App configuration
    appConfigurationState: appConfigurationReducer,
    clientGroup: clientGroupReducer,
    // Notifications config
    notificationsConfig: notificationsConfigReducer,
    // History
    salesHistory: salesHistoryReducer,
    paymentGatewayValidation: paymentGatewayReducer,
    DataTransfer: dataTransferReducer,
    transferEnabled: transferEnableReducer,
    transferAttachments: transferAttachmentsReducer,
    orderId: orderReducer,
    parametrization: parametrizationReducer,
    mostSellingProducts: mostSellingProductsReducer,
    mostViewedArticles: mostViewedArticlesReducer,
    comments: commentsAndRatingsReducer,
    commentBox: commentBoxReducer,
    virtualAssistant: virtualAssistantReducer,
    newAddedProducts: newAddedProductsReducer,
    carousel: carouselReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
