/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingAttributesInArticle } from "../../../features/loading/categorizations/articles/loadingsReducer";
import { addArticleAttributes } from "../../../features/categorization/articles/attributesInTheArticleReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpAttributesInArticle() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAttributesInArticleAsync =
    (idArticle: number): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingAttributesInArticle(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.getAttributesInArticle}/${idArticle}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data) {
          dispatch(addArticleAttributes(data));
        }
        dispatch(setLoadingAttributesInArticle(false));
      } catch (error) {
        dispatch(setLoadingAttributesInArticle(false));
      }
      dispatch(setLoadingAttributesInArticle(false));
    };
  return {
    httpAttributesInArticleAsync,
  };
}

export default useHttpAttributesInArticle;
