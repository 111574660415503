import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Comprobante } from "../../models/Attachments/Comprobante.model";

interface TransferState {
  transferencia: boolean;
  comprobantes: Comprobante[];
  transferStatus: boolean;
}

const initial: TransferState = {
  transferencia: false,
  comprobantes: [],
  transferStatus: false,
};

export const TransferSlice = createSlice({
  name: "transfer",
  initialState: initial,
  reducers: {
    setTransferEnabled: (state, action: PayloadAction<boolean>) => {
      state.transferencia = action.payload;
    },
    setComprobantes: (state, action: PayloadAction<Comprobante[]>) => {
      state.comprobantes = action.payload;
    },
    setTransferStatus: (state, action: PayloadAction<boolean>) => {
      state.transferStatus = action.payload;
    },
  },
});

export const { setTransferEnabled, setComprobantes, setTransferStatus } =
  TransferSlice.actions;

export default TransferSlice.reducer;
