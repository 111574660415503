/* eslint-disable no-unused-vars */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { setDatosSocioNegocio } from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import { setLoadingDatosSocio } from "../../features/loading/buyForm/loadingsReducer";
import { DatosSocioDeNegocio } from "../../pages/PurchaseForm/models/DatosSocioDeNegocio.model";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpObtenerDatosSocioDeNegocio() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpObtenerDatosSocioDeNegocioAsync =
    (): AppThunk => async (dispatch: any) => {
      dispatch(setLoadingDatosSocio(true));
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.datosSocioDeNegocio}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data: DatosSocioDeNegocio = await response.json();
        if (response.status === 200) {
          dispatch(setDatosSocioNegocio(data));
          dispatch(setLoadingDatosSocio(false));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        dispatch(setLoadingDatosSocio(false));
      }
    };
  return {
    httpObtenerDatosSocioDeNegocioAsync,
  };
}

export default useHttpObtenerDatosSocioDeNegocio;
