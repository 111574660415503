import React from "react";
import {
  SearchInputStyled,
  ContainerSearchStyled,
} from "../../../../styles/CollectionReportStyled";
// import { ButtonStyled } from "../../../SearchArticle/SearchArticleStyled";
import Button from "../../../../../../components/button/Button";

interface ISearch {
  handleSearch: () => void;
  handleEnter: (e: any) => void;
  handleChangeSearch: (e: any) => void;
  handleSetPage: (page: string) => void;
}

const Search = ({
  handleEnter,
  handleChangeSearch,
  handleSetPage,
  handleSearch,
}: ISearch) => (
  <ContainerSearchStyled className="search">
    <SearchInputStyled
      type="text"
      placeholder="Buscar"
      onKeyDown={handleEnter}
      onChange={handleChangeSearch}
    />
    <Button
      onClick={() => {
        handleSetPage("1");
        handleSearch();
      }}
      backgroundColor="secondary"
      text="Buscar"
      height="36px"
      width="100px"
    />
  </ContainerSearchStyled>
);

export default Search;
