import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppConfigurationDto } from "../../DTOs/AppConfigurationDto";
import { defaultTheme } from "../../styles/global";
import { AppConfiguration } from "../../types/AppConfiguration";

const initialState: AppConfiguration = {
  empresaId: 0,
  nombre: "",
  logo: "",
  tokenDms: "",
  currentTheme: defaultTheme,
};

export const appConfigurationSlice = createSlice({
  name: "appConfigurationState",
  initialState,
  reducers: {
    setAppConfiguration: (state, action: PayloadAction<AppConfigurationDto>) => {
      state.empresaId = action.payload.empresaId;
      state.nombre = action.payload.nombre;
      state.logo = action.payload.logo;
      if (action.payload.colorTemaAplicacion !== null) {
        state.currentTheme.colors.main.primary = action.payload.colorTemaAplicacion.primary;
        state.currentTheme.colors.main.secondary = action.payload.colorTemaAplicacion.secondary;
        state.currentTheme.colors.main.tertiary = action.payload.colorTemaAplicacion.tertiary;
        state.currentTheme.colors.main.primaryLight = action.payload.colorTemaAplicacion.primaryLight;
      }
    },
    setTokenDms: (state, action: PayloadAction<string>) => {
      state.tokenDms = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.currentTheme.colors.main.primary = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<string>) => {
      state.currentTheme.colors.main.secondary = action.payload;
    },
    setTertiaryColor: (state, action: PayloadAction<string>) => {
      state.currentTheme.colors.main.tertiary = action.payload;
    },
    setPrimaryLightColor: (state, action: PayloadAction<string>) => {
      state.currentTheme.colors.main.primaryLight = action.payload;
    }
  },
});

export const {
  setAppConfiguration,
  setTokenDms,
  setPrimaryColor,
  setSecondaryColor,
  setTertiaryColor,
  setPrimaryLightColor
} = appConfigurationSlice.actions;

export default appConfigurationSlice.reducer;
