import styled from "styled-components";

export const ContainerPaginationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.main.secondary};
  color: white;
  padding: 10px;
  border-radius: 8px 8px 8px 8px;
`;

export const NextButtonStyled = styled.button`
  background-color: ${(props) => props.theme.colors.main.primary};
  border-radius: 50%;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 4px 10px;
  &:hover {
    filter: brightness(0.8);
  }
`;

export const ActualPageStyled = styled.span`
  background-color: white;
  color: black;
  border-radius: 50%;
  padding: 4px 10px;
  margin-right: 3px;
`;

export const InputPageStyled = styled.input`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  outline: none;
  text-align: center;
`;

export const SelectResultsStyled = styled.select`
  color: white;
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  color: black;
`;
