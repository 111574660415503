import { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
// Models
import { ILocalidades } from "../../../models/BuyFormModels/Localidades";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
import { clearLocalidades } from "../../../features/partnerBusinessForm/partnerBusinessFormReducer";

interface ISelectSearchHook {
  setPage: (page: number) => void;
  page: number;
  disabled?: boolean;
  options: ILocalidades[];
  setFiltro: (filtro: string) => void;
  setFormData: (formData: any) => void;
  onChange: (option: ILocalidades) => void;
  formData: PartnerBusinessFormData;
}

const useSelectSearchHook = ({
  setFiltro,
  setPage,
  page,
  options,
  onChange,
  setFormData,
  formData,
}: ISelectSearchHook) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [showBox, setShowBox] = useState(false);

  const [data, setData] = useState<ILocalidades[]>([]);

  const localidadesTotales = useAppSelector(
    (state) => state.partnerBusinessForm.resultadosTotales
  );

  const handleChangeSearch = (value: string) => {
    dispatch(clearLocalidades());
    setFiltro && setFiltro(value);
    setShowBox(true);
  };

  // Obtener mas localidades
  const getLocalidades = () => {
    const cantidadDePaginasTotales = Math.ceil(localidadesTotales / 50);

    // si existen mas paginas
    if (page < cantidadDePaginasTotales) {
      setPage(page + 1);
    }
  };

  // Manejo del scroll del box
  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (
      Number(scrollTop) + Number(clientHeight) === scrollHeight - 1 ||
      Number(scrollTop) + Number(clientHeight) === scrollHeight
    ) {
      // Se ha llegado al final del scroll
      // hacer la llamada al servicio
      getLocalidades();
      // concatenar los resultados
    }
  };

  const randomNum = crypto.getRandomValues(new Uint32Array(1))[0] % 10 + 1;

  const viewBox = () => {
    setShowBox(true);
  };

  const handleClickOption = (option: ILocalidades) => {
    onChange(option);
    setShowBox(false);
    setFormData((prev: any) => ({
      ...prev,
      codigoPostal: option.codigoPostal,
    }));
  };

  useEffect(() => {
    setData(options);
  }, [options]);

  useEffect(() => {
    dispatch(clearLocalidades());
    setPage(1);
  }, [formData.provinciaId]);

  // Cerrar el box cuando se haga click fuera de el
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setShowBox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return {
    data,
    boxRef,
    showBox,
    viewBox,
    randomNum,
    handleScroll,
    handleClickOption,
    handleChangeSearch,
  };
};

export default useSelectSearchHook;
