import styled from "styled-components";

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

export const TitleStyled = styled.b`
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 160px;
  cursor: pointer;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const CloseButtonStyled = styled.button`
  font-weight: bold;
  font-size: 1.2rem;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 30px;
`;

interface IOptionStyled {
  active?: boolean;
}

export const OptionStyled = styled.p<IOptionStyled>`
  font-size: 1rem;
  margin-bottom: 5px;
  margin-left: 10px;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  padding: 5px;
  width: 150px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${(props) => props.theme.colors.main.primary};
    border-radius: 3px;
  }
`;
