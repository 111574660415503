import React from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import { ContainerHistoryStyled } from "../../../styles/CollectionReportStyled";
// Components
import Pagination from "./components/Pagination";
import SpinnerSale from "./components/SpinnerSale";
import HeaderFilters from "./components/HeaderFilters";
import NoResultsSales from "./components/NoResultsSales";
import CardHistorySale from "./components/CardHistorySale/CardHistorySale";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Services
import { useAppSelector } from "../../../../../app/hooks";
// Models
import { Data } from "../../../../../models/HistorySales/Data.model";
// Hooks
import useHistorySaleHook from "./hooks/useHistorySaleHook";

const SalesHistory = () => {
  const {
    page,
    sales,
    idDetails,
    showDetails,
    handleEnter,
    handleSearch,
    handleSetPage,
    handlePrevPage,
    handleNextPage,
    recordsForPage,
    articlesCounter,
    cantidadDePaginas,
    handleShowDetails,
    handleChangeSearch,
    handleChangeDateFilter,
    handleChangeStatusFilter,
    handleChangeResultsForPage,
  } = useHistorySaleHook();

  const [parent] = useAutoAnimate();
  const isLoadingHistorySales = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingGetHistorySale
  );

  return (
    <ContainerStyled ref={parent}>
      {/* HEADER */}
      <HeaderFilters
        title="Historial de ventas"
        handleEnter={handleEnter}
        handleSearch={handleSearch}
        handleSetPage={handleSetPage}
        handleChangeSearch={handleChangeSearch}
        handleChangeDateFilter={handleChangeDateFilter}
        handleChangeStatusFilter={handleChangeStatusFilter}
      />
      {isLoadingHistorySales ? (
        <SpinnerSale />
      ) : (
        <ContainerHistoryStyled ref={parent}>
          {sales.data.length === 0 ? (
            <NoResultsSales />
          ) : (
            sales.data.map((sale: Data, index: number) => (
              /* CARD */
              <CardHistorySale
                key={sale.cliente?.id + index}
                sale={sale}
                idDetails={idDetails}
                showDetails={showDetails}
                articlesCounter={articlesCounter}
                handleShowDetails={handleShowDetails}
              />
            ))
          )}
        </ContainerHistoryStyled>
      )}
      {/* <PaginationSales /> */}
      <Pagination
        page={page}
        sales={sales}
        handleEnter={handleEnter}
        handleSetPage={handleSetPage}
        recordsForPage={recordsForPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        cantidadDePaginas={cantidadDePaginas}
        handleChangeResultsForPage={handleChangeResultsForPage}
      />
    </ContainerStyled>
  );
};

export default SalesHistory;
