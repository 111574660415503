/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { viewNotification } from "../../../../utils/ViewNotification";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpAddOrUpdateValues() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAddOrUpdateValuesAsync =
    (
      valueDescription: string,
      refreshValues: boolean,
      setRefreshValues:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      setShowSaveButton:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      atributoId: number,
      valueId?: number
    ): AppThunk =>
    async () => {
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.addOrUpdateAttributeValue}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              id: valueId,
              descripcion: valueDescription,
              atributoId,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const successMessage = valueId
            ? "Valor editado correctamente"
            : "Valor creado correctamente";
          const refresh = setRefreshValues && !refreshValues;
          const hideButton = setShowSaveButton && false;
          viewNotification(successMessage, "success");
          refresh && setRefreshValues(refresh);
          hideButton && setShowSaveButton(hideButton);
        } else {
          const errorMessage = valueId
            ? "Error al editar el valor"
            : "Error al crear el valor";
          viewNotification(errorMessage, "danger");
        }
      } catch (error) {
        // mostrar notificacion de error
        viewNotification("Error al editar el valor", "danger");
      }
    };
  return {
    httpAddOrUpdateValuesAsync,
  };
}

export default useHttpAddOrUpdateValues;
