import {
  ButtonSelectorDataTableStyled,
  SelectDataTableContainerStyled,
} from "../styles/CategorizationStyled";
import { Table } from "../models/TablesSelector";

interface ITableSelector {
  active: string;
  setActive: (order: Table) => void;
}

const TableSelector = ({ active, setActive }: ITableSelector) => {
  const handleTableSelector = (order: Table) => {
    setActive(order);
  };

  return (
    <SelectDataTableContainerStyled>
      <ButtonSelectorDataTableStyled
        order={Table.left}
        active={active === Table.left}
        onClick={() => handleTableSelector(Table.left)}
      >
        Subcategorías
      </ButtonSelectorDataTableStyled>
      <ButtonSelectorDataTableStyled
        order={Table.center}
        active={active === Table.center}
        onClick={() => handleTableSelector(Table.center)}
      >
        Atributos
      </ButtonSelectorDataTableStyled>
      <ButtonSelectorDataTableStyled
        order={Table.right}
        active={active === Table.right}
        onClick={() => handleTableSelector(Table.right)}
      >
        Valores
      </ButtonSelectorDataTableStyled>
    </SelectDataTableContainerStyled>
  );
};

export default TableSelector;
