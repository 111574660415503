import React, { JSX } from "react";
// Styles
import { ButtonStyled } from "./Styled";

export interface IButton {
  text?: string | JSX.Element;
  backgroundColor?: "secondary" | "tertiary";
  onClick?: () => void;
  disabled?: boolean;
  width?: string;
  height?: string;
  type?: "button" | "submit" | "reset";
  cursor?: "not-allowed" | "none" | "pointer";
  margin?: string;
  padding?: string;
}

/**
 * Componente botón utilizado para acciones y menús
 * @param backgroundColor: indica el color de la paleta de colores,
 * no acción del botón. Si es null no tendrá backgound.
 * NOTA:
 * Para acciones primarias se utiliza el color main.secundary de la aplicación.
 * Para acciones secundarias no se pasa ningún color
 */
const Button = ({
  text,
  backgroundColor,
  onClick,
  disabled,
  width,
  type,
  height,
  cursor,
  margin,
  padding,
}: //   padding: 10px 24px;
// eslint-disable-next-line no-undef
IButton): JSX.Element => (
  <ButtonStyled
    type={type ?? "button"}
    onClick={onClick}
    disabled={disabled ?? false}
    backgroundColor={backgroundColor}
    width={width}
    height={height}
    cursor={cursor}
    margin={margin}
    padding={padding}
  >
    {text}
  </ButtonStyled>
);

export default Button;
