/* eslint-disable indent */
import React from "react";
// Styles
import {
  FechaStyled,
  CompraStyled,
  PrecioStyled,
  EstatusStyled,
  CardSaleStyled,
  MedioPagoStyled,
  ShowDetailsStyled,
  HeaderHistoryCardStyled,
  CantidadDeArticulosStyled,
} from "../../../../../styles/HistorySaleStyled";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import CardDetails from "./CardDetails";
import NoteDetails from "./NoteDetails";
// Utills
import { cambiarPuntoPorComas } from "../../../../../../../utils/priceFormater";
// Models
import { Data } from "../../../../../../../models/HistorySales/Data.model";

interface ICardSale {
  sale: Data;
  idDetails: number;
  showDetails: boolean;
  handleShowDetails: (id: number) => void;
  articlesCounter: (sale: Data) => number;
}

const CardHistorySale = ({
  sale,
  handleShowDetails,
  idDetails,
  showDetails,
}: ICardSale) => {
  const [parent] = useAutoAnimate();

  return (
    <CardSaleStyled ref={parent} onClick={() => handleShowDetails(sale.numero)}>
      <HeaderHistoryCardStyled>
        <CompraStyled>Venta #{sale.numero}</CompraStyled>
        <EstatusStyled status={sale.estado}>{sale.estado}</EstatusStyled>
        <PrecioStyled>${cambiarPuntoPorComas(sale.precioTotal)}</PrecioStyled>
        {showDetails && idDetails === sale.numero ? (
          <ShowDetailsStyled className="icon">-</ShowDetailsStyled>
        ) : (
          <ShowDetailsStyled className="icon">+</ShowDetailsStyled>
        )}
        <FechaStyled>Fecha: {sale.fechaCompra}</FechaStyled>
        <MedioPagoStyled>Medio de pago: {sale.medioPago}</MedioPagoStyled>
        <CantidadDeArticulosStyled>
          Cliente: {sale.cliente.nombre} {sale.cliente.apellido}
        </CantidadDeArticulosStyled>
      </HeaderHistoryCardStyled>
      {/* DETAILS */}
      {showDetails && idDetails === sale.numero && <CardDetails sale={sale} />}
      {/* Nota */}
      {showDetails &&
        idDetails === sale.numero &&
        sale.comentarioPedido !== "" && (
          <NoteDetails nota={sale.comentarioPedido} />
        )}
    </CardSaleStyled>
  );
};

export default CardHistorySale;
