import styled from "styled-components";

// datalist
export const DatalistStyled = styled.ul`
  list-style-type: none;
  border-radius: 0 0 10px 10px;
  padding: 0;
`;

// item datalist
export const ItemDatalistStyled = styled.li`
  display: flex;
  align-items: center;
  text-transform: lowercase;
  gap: 10px;
  padding: 10px 0;
  border-radius: 10px;
  border-bottom: 1px solid #f1f1f1;

  &:hover {
    background-color: ${(props) => props.theme.colors.main.tertiary};
    font-weight: bold;
  }
`;
