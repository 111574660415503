import { ContainerStyled, ContainerClose } from "./ModalBaseStyled";
import close from "../../assets/icons/close.svg";

interface IModalProps {
  children: any;
  show: boolean;
  width?: string;
  height?: string;
  hideCloseButton?: boolean;
  onClose: () => void;
}

const ModalBase = ({
  children,
  show,
  width,
  height,
  onClose,
  hideCloseButton = false,
}: IModalProps) => (
  <ContainerStyled show={show} width={width} height={height}>
    {!hideCloseButton && (
      <ContainerClose>
        <img src={close} alt="close" onClick={onClose} />
      </ContainerClose>
    )}
    {children}
  </ContainerStyled>
);
export default ModalBase;
