import React from "react";
import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import Footer from "../../../components/footer/Footer";
import { AsideMenu, SalesHistory } from "../components";
import { routesApp } from "../../../RoutesApp";

const SalesHistoryPage = () => (
  <div>
    <SessionBar />
    <ContainerConfigurations>
      <AsideMenu active={routesApp.salesHistory} />
      <SalesHistory />
    </ContainerConfigurations>
    <Footer />
  </div>
);

export default SalesHistoryPage;
