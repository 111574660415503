/* eslint-disable no-unused-vars */
export enum routesApp {
  login = "/login",
  cart = "/carrito",
  register = "/registro",
  results = "/resultados",
  details = "/details/:id",
  detailsOnly = "/details",
  redirection = "/redirection",
  resultsVin = "/resultadosvin",
  spareParts = "/resultadosvin/repuestosvin",
  accessories = "/resultadosvin/accesoriosvin",
  paymentMethod = "/metodopago",
  purchaseError = "/error-compra",
  loginSeller = "/login-vendedor",
  transfer = "/carga-comprobante",
  pendingPayment = "/pago-pendiente",
  shippingProcess = "/proceso-envio",
  purchaseForm = "/formulario-compra",
  resetPasword = "/reiniciar-contraseña",
  purchaseThanks = "/gracias-por-comprar",
  buyConfirmation = "/confirmacionCompra",
  forgotPassword = "/recuperar-contrasena",
  emailConfirmation = "/confirmacionEmail",
  purchaseRegret = "/arrepentimiento-compra",
  // Rutas de configuracion
  profile = "/perfil",
  articles = "/articulos",
  products = "/productos",
  management = "/gestion",
  billing = "/facturacion",
  activePurchases = "/compras",
  definitions = "/definiciones",
  paymentGateways = "/pasarelas",
  collectionReport = "/informes",
  purchaseReturns = "/devoluciones",
  notifications = "/medios-de-contacto",
  timeNotifications = "/tiempos-notificaciones",
  salesHistory = "/historial-ventas",
  shoppingHistory = "/historial-compras",
  manager = "/manager",
  loginManager = "/login-manager",
  colorsSystem = "/colores-del-sistema"
}
