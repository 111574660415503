import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import { AsideMenu } from "../components";
import { routesApp } from "../../../RoutesApp";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setPrimaryColor,
  setPrimaryLightColor,
  setSecondaryColor,
  setTertiaryColor,
} from "../../../features/appConfiguration/appConfigurationReducer";
import Button from "../../../components/button/Button";
import {
  AdvanceConfigurationContainerStyled,
  BasicConfigurationContainerStyled,
  CheckboxEnabledAdvanceConfigurationStyled,
  ColumnLeftContainerStyled,
  ColumnRightContainerStyled,
  HomeCategoryContainerStyled,
  TitleEnabledAdvanceConfigurationStyled,
} from "../styles/ColorsSystemStyled";
import CategoriesHome from "../../Home/components/CategoriesHome/CategoriesHome";
import useHttpSaveThemeColors from "../../../services/AppConfiguration/useHttpSaveThemeColors";
import { ColorsDto } from "../../../DTOs/ColorsDto";

const Index = () => {
  const _mainColorsState = useAppSelector(
    (state) => state.appConfigurationState.currentTheme.colors.main
  );
  const dispatch = useAppDispatch();
  const { httpSaveThemeColorsAsync } = useHttpSaveThemeColors();

  const [primary, setPrimary] = useState(_mainColorsState.primary);
  const [secondary, setSecondary] = useState(_mainColorsState.secondary);
  const [tertiary, setTertiary] = useState(_mainColorsState.tertiary);
  const [primaryLight, setPrimaryLight] = useState(
    _mainColorsState.primaryLight || ""
  );
  const [_mainColorsStateInit] = useState(_mainColorsState);

  /** Valida si el usuario tiene alguna configuracion opcional */
  const getIsAdvanceConfig = () => !(primaryLight === "");

  const [isAdvanceConfig, setIsAdvanceConfig] = useState<boolean>(
    getIsAdvanceConfig()
  );

  useEffect(() => {
    dispatch(setPrimaryColor(primary));
  }, [primary]);

  useEffect(() => {
    dispatch(setSecondaryColor(secondary));
  }, [secondary]);

  useEffect(() => {
    dispatch(setTertiaryColor(tertiary));
  }, [tertiary]);

  useEffect(() => {
    dispatch(setPrimaryLightColor(primaryLight));
    setIsAdvanceConfig(getIsAdvanceConfig());
  }, [primaryLight]);

  const setConfigurationHandle = () => {
    setIsAdvanceConfig(!isAdvanceConfig);
    setPrimaryLight(_mainColorsStateInit.primaryLight || "");
  };

  const saveConfigurationHandle = () => {
    // eslint-disable-next-line prefer-const
    let data: ColorsDto = { ..._mainColorsState };
    if (!isAdvanceConfig) {
      data.primaryLight = "";
      dispatch(setPrimaryLightColor(""));
    }
    dispatch(httpSaveThemeColorsAsync(data));
  };

  const cancelarConfigurationHandle = async () => {
    window.location.reload();
  };

  return (
    <div>
      <SessionBar />
      <ContainerConfigurations>
        <AsideMenu active={routesApp.colorsSystem} />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <h1 style={{ display: "flex", justifySelf: "center" }}>
            Colores del sistema
          </h1>
          <p style={{ margin: 0, padding: 0, color: "green" }}>
            Las modificaciones en los colores del sistema se aplicarán al
            seleccionar 'Guardar y Aplicar'{" "}
          </p>

          <BasicConfigurationContainerStyled>
            <ColumnLeftContainerStyled>
              <h3 style={{ display: "flex", justifySelf: "center" }}>
                Selección de colores:
              </h3>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "150px" }}>Color principal:</td>
                    <td>
                      <input
                        type="color"
                        value={primary}
                        onChange={(e) => setPrimary(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "150px" }}>Color secundario:</td>
                    <td>
                      <input
                        type="color"
                        value={secondary}
                        onChange={(e) => setSecondary(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "150px" }}>Color terciario:</td>
                    <td>
                      <input
                        type="color"
                        value={tertiary}
                        onChange={(e) => setTertiary(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ColumnLeftContainerStyled>

            <ColumnRightContainerStyled>
              <h3
                style={{
                  display: "flex",
                  marginTop: "40px",
                  alignSelf: "center",
                }}
              >
                Vista previa de botones:
              </h3>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <Button
                  height="40px"
                  backgroundColor="secondary"
                  text="Botón de acción primaria"
                />
                <Button height="40px" text="Botón de acción secundaria" />
                <Button
                  height="40px"
                  backgroundColor="tertiary"
                  text="Botón de menú"
                />
              </div>
            </ColumnRightContainerStyled>
          </BasicConfigurationContainerStyled>

          <TitleEnabledAdvanceConfigurationStyled>
            <CheckboxEnabledAdvanceConfigurationStyled
              type="checkbox"
              checked={isAdvanceConfig}
              onChange={() => setConfigurationHandle()}
            />
            Configuración avanzada
          </TitleEnabledAdvanceConfigurationStyled>

          <AdvanceConfigurationContainerStyled show={isAdvanceConfig}>
            <ColumnLeftContainerStyled>
              <h3 style={{ display: "flex", justifySelf: "center" }}>
                Selección de color:
              </h3>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "210px" }}>
                      Color Principal Claro (light):
                    </td>
                    <td>
                      <input
                        type="color"
                        value={primaryLight}
                        onChange={(e) => setPrimaryLight(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ColumnLeftContainerStyled>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <h3
                style={{
                  display: "flex",
                  marginTop: "40px",
                  alignSelf: "center",
                }}
              >
                Vista previa:
              </h3>
              <HomeCategoryContainerStyled>
                <CategoriesHome />
              </HomeCategoryContainerStyled>
            </div>
          </AdvanceConfigurationContainerStyled>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              margin: "20px 0px 40px 0px",
            }}
          >
            <Button
              height="40px"
              backgroundColor="secondary"
              text="Guardar y Aplicar"
              onClick={() => saveConfigurationHandle()}
            />
            <Button
              height="40px"
              text="Cancelar"
              onClick={() => cancelarConfigurationHandle()}
            />
          </div>
        </div>
      </ContainerConfigurations>
    </div>
  );
};

export default Index;
