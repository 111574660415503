import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISubcategories {
  id: number;
  descripcion: string;
  esDatoSistema: boolean;
}

const initial: ISubcategories[] = [];

export const SubcategoriesSlice: any = createSlice({
  name: "subcategories",
  initialState: initial,
  reducers: {
    addSubcategories: (state, action: PayloadAction<ISubcategories[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { addSubcategories } = SubcategoriesSlice.actions;

export default SubcategoriesSlice.reducer;
