import styled from "styled-components";

export const ContainerModalPartnerSelectorStyled = styled.div`
  padding: 10px 20px;
`;

export const ButtonPartnerSelectStyled = styled.button`
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 20px;
  width: 100%;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ButtonNewPartnerStyled = styled.button`
  background-color: #93ed8d;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
  padding: 10px 20px;
  width: 100%;

  &:hover {
    background-color: #51cd40;
    color: #ffffff;
  }
`;
