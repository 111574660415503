import env from "../../environment";
import { Routes } from "../routes";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();
  const httpIsValidUserSocioDeNegocioAsociadoConDocumentoOCuitAsync = async (
    documentoCuit: string
  ): Promise<boolean> => {
    const response = await fetch(
      `${env.seguridad}${Routes.GetEmailSocioNegocioAsociadoConDocumentoCuit}?numeroDocumentoCuit=${documentoCuit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
          empresaId: empresaId.toString(),
        },
      }
    );
    validateUnauthorized(response, empresaId);
    if (response.status === 200) {
      const data = await response.text();
      viewNotification(
        `Estos datos están vinculados a un socio ya existente con el correo ${data}`,
        "warning"
      );
      return false;
    }
    return true;
  };
  return {
    httpValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync:
      httpIsValidUserSocioDeNegocioAsociadoConDocumentoOCuitAsync,
  };
}

export default useValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync;
