import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingLogin: false,
  loadingDmsLogin: false,
  loadingRegister: false,
  loadingGoogleLogin: false,
  loadingCloseSession: false,
  loadingResetPassword: false,
  loadingFacebookLogin: false,
  loadingForgotPassword: false,
  loadingEmailConfirmation: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingRegister: (state, action: PayloadAction<boolean>) => {
      state.loadingRegister = action.payload;
    },
    setLoadingLogin: (state, action: PayloadAction<boolean>) => {
      state.loadingLogin = action.payload;
    },
    setLoadingCloseSession: (state, action: PayloadAction<boolean>) => {
      state.loadingCloseSession = action.payload;
    },
    setLoadingEmailConfirmation: (state, action: PayloadAction<boolean>) => {
      state.loadingEmailConfirmation = action.payload;
    },
    setLoadingResetPassword: (state, action: PayloadAction<boolean>) => {
      state.loadingResetPassword = action.payload;
    },
    setLoadingForgotPassword: (state, action: PayloadAction<boolean>) => {
      state.loadingForgotPassword = action.payload;
    },
    setLoadingGoogleLogin: (state, action: PayloadAction<boolean>) => {
      state.loadingGoogleLogin = action.payload;
    },
    setLoadingFacebookLogin: (state, action: PayloadAction<boolean>) => {
      state.loadingFacebookLogin = action.payload;
    },
    setLoadingDmsLogin: (state, action: PayloadAction<boolean>) => {
      state.loadingDmsLogin = action.payload;
    }
  },
});

export const {
  setLoadingLogin,
  setLoadingRegister,
  setLoadingDmsLogin,
  setLoadingGoogleLogin,
  setLoadingCloseSession,
  setLoadingFacebookLogin,
  setLoadingResetPassword,
  setLoadingForgotPassword,
  setLoadingEmailConfirmation,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
