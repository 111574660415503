import React from "react";
import { ModalAttributesContainerStyled } from "../../styles/ModalConfirmation";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import httpDeleteAttributeInArticle from "../../../../services/Categorization/Articles/useHttpDeleteAttributeInArticle";
import Spinner from "../../../../components/spinner/Spinner";
import Button from "../../../../components/button/Button";

interface IModal {
  setShowConfirmationModalAttribute: (value: boolean) => void;
  idArticle: number;
  idAttribute: number;
  positionY?: number;
}

const ModalConfirmationAttribute = ({
  idArticle,
  idAttribute,
  setShowConfirmationModalAttribute,
  positionY,
}: IModal) => {
  const dispatch = useAppDispatch();
  const isLoadingDeleteAttribute = useAppSelector(
    (state) => state.isLoadingArticles.loadingDeleteAttributeInArticle
  );
  const { httpDeleteAttributeInArticleAsync } = httpDeleteAttributeInArticle();

  const handleDeleteAttribute = () => {
    dispatch(
      httpDeleteAttributeInArticleAsync(
        idArticle,
        idAttribute,
        setShowConfirmationModalAttribute
      )
    );
  };

  const positionYModal =
    positionY && positionY > 1000 ? Number(positionY) + 300 : positionY;

  return (
    <ModalAttributesContainerStyled positionY={positionYModal}>
      <b>¿Deseas eliminar el atributo de este artículo?</b>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button
          text="Cancelar"
          onClick={() => setShowConfirmationModalAttribute(false)}
        />
        {isLoadingDeleteAttribute ? (
          <Spinner />
        ) : (
          <Button
            text="Eliminar"
            backgroundColor="secondary"
            onClick={handleDeleteAttribute}
          />
        )}
      </div>
    </ModalAttributesContainerStyled>
  );
};

export default ModalConfirmationAttribute;
