import { useJwt } from "react-jwt";

const DmsValidate = (token:string) => {
  const { decodedToken }: any = useJwt(token);
  // transforma el objeto en string
  const decodedTokenString = JSON.stringify(decodedToken);
  return decodedTokenString.includes("Vendedor");
};

export default DmsValidate;
