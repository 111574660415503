import { lazy } from "react";

const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login/LoginComprador"));
const LoginSeller = lazy(() => import("./Login/LoginVendedor"));
const Profile = lazy(() => import("./settings/pages/ProfilePage"));
const Details = lazy(() => import("./Details"));
const Register = lazy(() => import("./Register"));
const NotFound = lazy(() => import("./NotFound"));
const Redirection = lazy(() => import("./Redirection"));
const PurchaseForm = lazy(() => import("./PurchaseForm"));
const SearchResults = lazy(() => import("./SearchResults"));
const PurchaseError = lazy(() => import("./purchaseError"));
const PaymentMethod = lazy(() => import("./payment_Method"));
const PendingPayment = lazy(() => import("./pendingPayment"));
const PurchaseThanks = lazy(() => import("./PurchaseThanks"));
const PurchaseRegret = lazy(() => import("./PurchaseRegret"));
const ShippingProcess = lazy(() => import("./ShippingProcess"));
// const PaymentProcess = lazy(() => import('./ShippingProcess'));
const BuyConfirmation = lazy(() => import("./BuyConfirmation"));
const SparePartsWithVin = lazy(() => import("./SparePartsWithVin"));
const EmailConfirmation = lazy(() => import("./EmailConfirmation"));
const AccessoriesWithVin = lazy(() => import("./AccessoriesWithVin"));
const SearchResultsWithVIN = lazy(() => import("./SearchResultsWithVIN"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const Cart = lazy(() => import("./Cart"));
const Transfer = lazy(() => import("./Transfer"));

export {
  Home,
  Cart,
  Login,
  Details,
  Profile,
  NotFound,
  Register,
  Transfer,
  LoginSeller,
  Redirection,
  PurchaseForm,
  ResetPassword,
  PurchaseError,
  PaymentMethod,
  SearchResults,
  PendingPayment,
  PurchaseRegret,
  PurchaseThanks,
  ForgotPassword,
  BuyConfirmation,
  ShippingProcess,
  SparePartsWithVin,
  EmailConfirmation,
  AccessoriesWithVin,
  SearchResultsWithVIN
};
