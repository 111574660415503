import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingTransferAttachments: false,
  loadingDownloadTransferAttachments: false
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingTransferAttachments: (state, action: PayloadAction<boolean>) => {
      state.loadingTransferAttachments = action.payload;
    },
    setLoadingDownloadTransferAttachments: (state, action: PayloadAction<boolean>) => {
      state.loadingDownloadTransferAttachments = action.payload;
    },
  }
});

export const { setLoadingTransferAttachments, setLoadingDownloadTransferAttachments } = loadingsSlice.actions;

export default loadingsSlice.reducer;
