/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { setLoadingGetParametrization } from "../../features/loading/parametrization/loadingReducer";
import { setParametrization } from "../../features/parametrization/parametrizationReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpGetProcessParametrization = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetProcessParametrization = (): AppThunk => async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingGetParametrization(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.getProcessParametrization}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data) {
          dispatch(setParametrization(data));
        } else {
          viewNotification(
            "Aún no tienes configuraciones de notificaciones",
            "info"
          );
        }
        dispatch(setLoadingGetParametrization(false));
      } catch (error) {
        dispatch(setLoadingGetParametrization(false));
        viewNotification("Error al buscar configuración", "warning");
      }
    };
  return { httpGetProcessParametrization };
};

export default useHttpGetProcessParametrization;
