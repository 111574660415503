import { decodeToken } from "react-jwt";
import { UserRol } from "../types/enum/RolesUsuario";
import { TokenPayload } from "../types/TokenPayload";
import useLocalStorageHook from "./useLocalStorageHook";

/**
 * Get token from localStorage
 * @param obtenerToken - function to get the token from local storage
 * @returns token or empty string
 */
export const getToken = (obtenerToken: () => string): string => {
  try {
    return obtenerToken() || "";
  } catch (error) {
    return "";
  }
};

/**
 * Get current user
 * @returns if not exist logged user return by default Buyer Role
 */
export const getUserRol = (): UserRol => {
  const { obtenerToken } = useLocalStorageHook();
  const token = getToken(obtenerToken);
  const decodedToken = decodeToken(token) as TokenPayload;

  if (decodedToken && decodedToken.role !== "") {
    switch (decodedToken.role) {
      case UserRol.Administrator.toString():
        return UserRol.Administrator;
      case UserRol.Seller.toString():
        return UserRol.Seller;
    }
  }
  return UserRol.Buyer;
};
