import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initial = {
  transferencia: false,
};

export const TransferSlice = createSlice({
  name: "transfer",
  initialState: initial,
  reducers: {
    setTransferEnabled: (state, action: PayloadAction<boolean>) => {
      state.transferencia = action.payload;
    },
  },
});

export const { setTransferEnabled } = TransferSlice.actions;

export default TransferSlice.reducer;
