/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingAddOrUpdateSubcategories } from "../../../../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import { viewNotification } from "../../../../utils/ViewNotification";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpAddOrUpdateSubcategories() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAddOrUpdateSubcategoriesAsync =
    (
      subCategoryName: string,
      categoriyId: number,
      refreshSubCategories: boolean,
      setRefreshSubCategories:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      setShowSaveButton?:
        | ((value: boolean | ((prevVar: boolean) => boolean)) => void)
        | undefined,
      subCategoryId?: number
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingAddOrUpdateSubcategories(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.addOrUpdateSubcategories}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              id: subCategoryId,
              nombre: subCategoryName,
              categoriaArticuloId: categoriyId,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const successMessage = subCategoryId
            ? "Subcategoría editada correctamente"
            : "Subcategoría creada correctamente";
          const refresh = setRefreshSubCategories && !refreshSubCategories;
          const hideButton = setShowSaveButton && false;
          viewNotification(successMessage, "success");
          refresh && setRefreshSubCategories(refresh);
          hideButton && setShowSaveButton(hideButton);
        } else {
          const errorMessage = subCategoryId
            ? "Error al editar subcategoría"
            : "Error al crear subcategoría";
          viewNotification(errorMessage, "danger");
        }
        dispatch(setLoadingAddOrUpdateSubcategories(false));
      } catch (error) {
        dispatch(setLoadingAddOrUpdateSubcategories(false));
        // mostrar notificacion de error
      }
      dispatch(setLoadingAddOrUpdateSubcategories(false));
    };
  return {
    httpAddOrUpdateSubcategoriesAsync,
  };
}

export default useHttpAddOrUpdateSubcategories;
