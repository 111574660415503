import { Store } from "react-notifications-component";

// Denota true si el valor se encuentra dentro de la lista, de lo contrario, devuelve false
export const ExistValueInList = (
  obj: any,
  value: string,
  msgDangerNotification: string
) => {
  if (obj.find((item: any) => item.descripcion === value)) {
    Store.addNotification({
      title: msgDangerNotification,
      container: "bottom-full",
      type: "danger",
      dismiss: {
        duration: 3000,
      },
    });
    return true;
  }
  return false;
};
