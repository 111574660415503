/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { setLoadingCrearSocio } from "../../features/loading/buyForm/loadingsReducer";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setExistenOperacionesPendientes } from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
import { viewNotification } from "../../utils/ViewNotification";

function useHttpConsultarPendientes() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();
  const httpConsultarPendientesAsync =
    (): AppThunk => async (dispatch: any) => {
      const token = obtenerToken();
      // quitar comillas del token
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingCrearSocio(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.orderStatus}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          dispatch(setLoadingCrearSocio(false));
          const data: boolean = await response.json();
          dispatch(setExistenOperacionesPendientes(data));
        }
      } catch (error) {
        dispatch(setLoadingCrearSocio(false));
        viewNotification("Error al consultar las operaciones pendientes", "danger");
      }
    };
  return {
    httpConsultarPendientesAsync,
  };
}

export default useHttpConsultarPendientes;
