import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingPurchaseRegret: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingPurchaseRegret: (state, action: PayloadAction<boolean>) => {
      state.loadingPurchaseRegret = action.payload;
    },
  }
});

export const { setLoadingPurchaseRegret } = loadingsSlice.actions;

export default loadingsSlice.reducer;
