/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import { setLoadingPostDataTransfer } from "../../../features/loading/transfer/loadingReducer";
import { setId } from "../../../features/dataTransfer/dataTransferReducer";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

interface Transfer {
  esDatoSistema: boolean;
  alias: string;
  banco: string;
  cbu: string;
  cuenta: string;
  cuitCuil: string;
  habilitado: boolean;
  titular: string;
  id?: number;
}

const useHttpPostDataTransfer = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const dataTransferRedux = useAppSelector(
    (state: any) => state.DataTransfer.data
  );
  const idFirstConfig = useAppSelector((state: any) => state.DataTransfer.id);
  const httpPostDataTransferAsync = (transferData: Transfer): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingPostDataTransfer(true));
      if (dataTransferRedux?.id && dataTransferRedux?.id !== 0) {
        transferData.id = dataTransferRedux.id;
      }
      if (idFirstConfig && idFirstConfig !== null) {
        transferData.id = idFirstConfig;
      }
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.postDataTransfer}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify(transferData),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          const data = await response.json();
          // Guardar el id de la data para que se use si se configura ese id nuevo
          dispatch(setId(data.id));
          viewNotification("Cambios guardados", "success");
        } else {
          viewNotification("Error, intente nuevamente", "danger");
        }
        dispatch(setLoadingPostDataTransfer(false));
      } catch (error) {
        viewNotification("Error, intente nuevamente", "danger");
        dispatch(setLoadingPostDataTransfer(false));
      }
    };
  return { httpPostDataTransferAsync };
};

export default useHttpPostDataTransfer;
