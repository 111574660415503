import { Home } from "../pages";
import { getToken } from "./helper";
import useLocalStorageHook from "./useLocalStorageHook";

const NotShow = ({ children }: any) => {
  const { obtenerToken } = useLocalStorageHook();
  const isAuth = getToken(obtenerToken) !== "";

  if (isAuth) {
    return <Home />;
  }

  return children;
};

export default NotShow;
