import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-width: 0;
`;

export const InputSearchContainer = styled.div`
  display: flex;
  padding-left: 0.5rem;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.main.tertiary};
  border-radius: 10px;
  position: relative;
  left: 29px;
  bottom: 2px;
  z-index: 1;
  height: 32px;
  width: 100px;
  font-size: 0.8rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

interface IBox {
  sizeWindow: number;
}

export const BoxResults = styled.div<IBox>`
  background-color: #ffffff;
  border: 2px solid ${({ theme }) => theme.colors.main.tertiary};
  border-radius: 10px;
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  top: 26px;
  left: -50px;

  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.main.tertiary};
    border-radius: 10px;
    width: 2px;
  }
`;

export const ListResults = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OptionsStyled = styled.li`
  cursor: pointer;
  list-style: none;
  overflow: hidden;
  font-size: 0.8rem;
  white-space: nowrap;
  padding: 0.3rem 10px;
  text-overflow: ellipsis;
  border-bottom: 2px solid ${({ theme }) => theme.colors.main.tertiary};
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.tertiary};
    color: #fff;
  }
`;

export const ArrowIcon = styled.img<{ isOpen: boolean }>`
  justify-self: center;
  align-self: center;
  width: 18px;
  height: 12px;
  margin: 0px 0px 0px 5px;
  display: flex;
  filter: contrast(2);
  align-items: center;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
`;

export const WrapOptionSelected = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 90px;
`;

export const TextOption = styled.b`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
