/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import {
  addCurrentComment,
  resetCurrentComment,
} from "../../features/commentsAndRatings/commentsAndRatingsReducer";
import { setLoadingCurrentComment } from "../../features/loading/commentsAndRatings/loadingReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpGetCommentRating() {
  // Obtiene el comentario del usuario en el artículo
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetCommentRatingAsync = (articuloId: number): AppThunk => async (dispatch: any) => {
      const token = obtenerToken();

      // quitar comillas del token
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingCurrentComment(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.getCommentRating}?articuloId=${articuloId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          // Guardar en redux
          dispatch(addCurrentComment(data));
        } else {
          // reset current comment
          dispatch(resetCurrentComment());
        }
        dispatch(setLoadingCurrentComment(false));
      } catch (error) {
        dispatch(setLoadingCurrentComment(false));
      }
    };
  return {
    httpGetCommentRatingAsync,
  };
}

export default useHttpGetCommentRating;
