import React, { useState, useEffect, JSX } from "react";
import { useAppSelector } from "../../../../../app/hooks";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import { ContainerHistoryStyled } from "../../../styles/CollectionReportStyled";
// Components
import Pagination from "../Sales/components/Pagination";
import SpinnerSale from "../Sales/components/SpinnerSale";
import HeaderFilters from "../Sales/components/HeaderFilters";
import NoResultsSales from "../Sales/components/NoResultsSales";
import CardShoppingHistory from "./components/CardShoppingHistory";
import CommentsBox from "../../../../Details/components/CommentsBox";
import ModalBase from "../../../../../components/modalBase/ModalBase";
// Hook
import useHistoryHook from "./hooks/useHistoryHook";
// Services
import CommentPreview from "./components/CommentPreview";
// Animations
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Models
import Spinner from "../../../../../components/spinner/Spinner";
import { Data } from "../../../../../models/HistorySales/Data.model";

const History = () => {
  const {
    page,
    shopping,
    handleEnter,
    handleSearch,
    handleSetPage,
    handlePrevPage,
    handleNextPage,
    recordsForPage,
    cantidadDePaginas,
    handleChangeSearch,
    handleChangeDateFilter,
    handleChangeStatusFilter,
    handleChangeResultsForPage,
  } = useHistoryHook();
  const [parent] = useAutoAnimate();

  const [showModalBoxComment, setShowModalBoxComment] = useState(false);

  const isLoadingGetShoppingHistory = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingGetShoppingHistory
  );

  const isLoadingCurrentComment = useAppSelector(
    (state) => state.isLoadingCommentsAndRatings.loadingCurrentComment
  );

  const currentComment = useAppSelector(
    (state) => state.comments.currentComment
  );

  /**
   * Renderiza el componente de caja de comentario o comentario actual basado en el ID del artículo actual.
   *
   * @returns {JSX.Element} El componente de comentario a renderizar.
   */
  const renderComponentComment = (): JSX.Element =>
    currentComment.articuloId === 0 ? (
      <CommentsBox setShowModalBoxComment={setShowModalBoxComment} />
    ) : (
      <CommentPreview />
    );

  useEffect(() => {
    renderComponentComment();
  }, [currentComment.articuloId]);

  return (
    <ContainerStyled ref={parent}>
      {/* HEADER */}
      <HeaderFilters
        title="Historial de compras"
        handleEnter={handleEnter}
        handleSearch={handleSearch}
        handleSetPage={handleSetPage}
        handleChangeSearch={handleChangeSearch}
        handleChangeDateFilter={handleChangeDateFilter}
        handleChangeStatusFilter={handleChangeStatusFilter}
      />
      {isLoadingGetShoppingHistory ? (
        <SpinnerSale />
      ) : (
        <ContainerHistoryStyled ref={parent}>
          {shopping.data.length === 0 ? (
            <NoResultsSales />
          ) : (
            shopping.data.map((sale: Data, index: number) => (
              /* CARD */
              <CardShoppingHistory
                key={sale.cliente?.id + index}
                sale={sale}
                setShowModalBoxComment={setShowModalBoxComment}
              />
            ))
          )}
        </ContainerHistoryStyled>
      )}
      {/* <PaginationSales /> */}
      <Pagination
        page={page}
        sales={shopping}
        handleEnter={handleEnter}
        handleSetPage={handleSetPage}
        recordsForPage={recordsForPage}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        cantidadDePaginas={cantidadDePaginas}
        handleChangeResultsForPage={handleChangeResultsForPage}
      />
      {/* Modal de caja de comentario */}
      <ModalBase
        show={showModalBoxComment}
        onClose={() => setShowModalBoxComment(false)}
        height="340px"
        width="60%"
      >
        {isLoadingCurrentComment ? <Spinner /> : renderComponentComment()}
      </ModalBase>
    </ContainerStyled>
  );
};

export default History;
