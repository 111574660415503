/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setLoadingEditParametrization } from "../../features/loading/parametrization/loadingReducer";
import { MedioDePago } from "../../types/enum/MedioDePago";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpEditProcessParametrization = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpEditProcessParametrization = (id: number | undefined = 0, grupoClienteId: number | undefined, minutosParaDesbloqueoStock: number | undefined, minutosParaNotificacion: number | undefined): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingEditParametrization(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.editProcessParametrization}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              esDatoSistema: true,
              id: id !== null ? id : undefined,
              grupoClienteId,
              minutosParaDesbloqueoStock,
              medioDePagoId: MedioDePago.TRANSFERENCIA,
              minutosParaNotificacion,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          viewNotification("Configuración creada con éxito", "success");
        } else {
          viewNotification("Error al crear configuración", "warning");
        }
        dispatch(setLoadingEditParametrization(false));
      } catch (error) {
        viewNotification("Error al crear configuración", "warning");
        dispatch(setLoadingEditParametrization(false));
      }
    };
  return { httpEditProcessParametrization };
};

export default useHttpEditProcessParametrization;
