import React from "react";
import starIconEmpty from "../../assets/icons/starIconEmpty.svg";
import starIconFilled from "../../assets/icons/starIconFilled.svg";
import starGrey from "../../assets/icons/starGrey.svg";

type Params ={
  icon:"starIconEmpty" | "starIconFilled" | "starGrey",
  width:number,
  height: number
}

function StarIcon({ icon, width, height }: Params) {
  if (icon === "starIconEmpty") {
    return <img src={starIconEmpty} style={{ width: `${width}px`, height: `${height}px` }}/>;
  }

  if (icon === "starGrey") {
    return <img src={starGrey} style={{ width: `${width}px`, height: `${height}px` }}/>;
  }

  return <img src={starIconFilled} style={{ width: `${width}px`, height: `${height}px` }}/>;
}

export default StarIcon;
