import styled from "styled-components";

export const Title = styled.h1`
  display: flex;
  justify-content: center;
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 620px;
  border-radius: 12px;
  padding: 10px;
  margin: 10px 20px 20px;
  box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.29);
  transition: all 0.3s ease-in-out;
`;

export const ContainerButtonsStyled = styled.div`
  display: flex;
  justify-content: end;
  gap: 5px;
`;

export const ImputTokenStyled = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #7c7c7c;
`;
