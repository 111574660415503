/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  TitleStyled,
  OptionStyled,
  ContainerMenu,
  CloseButtonStyled,
  ContainerTitle,
} from "../styles/AsideMenuStyled";
import { routesApp } from "../../../RoutesApp";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { UserRol } from "../../../types/enum/RolesUsuario";
import useSecurityHook from "../../../hooks/useSecurityHook";
import Button from "../../../components/button/Button";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

interface AsideMenuProps {
  active: string;
}

const AsideMenu = ({ active }: AsideMenuProps) => {
  const { aliasEmpresa } = useParams();
  const navigate = useNavigate();
  const [parent] = useAutoAnimate();
  const { obetenerRol } = useLocalStorageHook();
  const [accountOpen, setAccountOpen] = useState(true);
  const [articlesOpen, setArticlesOpen] = useState(true);
  const [purchasesOpen, setPurchasesOpen] = useState(true);
  const [salesOpen, setSalesOpen] = useState(true);
  const [definitionsOpen, setDefinitionsOpen] = useState(true);
  const [configurationsOpen, setConfigurationsOpen] = useState(true);

  const isSeller = obetenerRol() === UserRol.Seller;
  const isAdministrator = obetenerRol() === UserRol.Administrator;
  const { logout } = useSecurityHook();

  const logoutAndRefreshPage = () => {
    logout();
    window.location.reload();
  };

  return (
    <ContainerMenu ref={parent}>
      {/* Cuenta */}
      {!isAdministrator && (
        <ContainerTitle onClick={() => setAccountOpen(!accountOpen)}>
          <TitleStyled>Cuenta</TitleStyled>
          {accountOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}

      {accountOpen && !isAdministrator && (
        <>
          <OptionStyled
            active={active === routesApp.profile}
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.profile}`)}
          >
            Mi perfil
          </OptionStyled>
          {!isSeller && (
            <OptionStyled
              active={active === routesApp.management}
              onClick={() =>
                navigate(`/${aliasEmpresa}${routesApp.management}`)
              }
            >
              Gestión de cuenta
            </OptionStyled>
          )}
        </>
      )}

      {/* Artículos */}
      {isSeller && (
        <ContainerTitle onClick={() => setArticlesOpen(!articlesOpen)}>
          <TitleStyled>Artículos</TitleStyled>
          {articlesOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}
      {articlesOpen && isSeller && (
        <>
          <OptionStyled
            active={active === routesApp.products}
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.products}`)}
          >
            Mis productos
          </OptionStyled>
        </>
      )}
      {/* Definiciones */}
      {isSeller && (
        <ContainerTitle onClick={() => setDefinitionsOpen(!definitionsOpen)}>
          <TitleStyled>Definiciones</TitleStyled>
          {definitionsOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}
      {definitionsOpen && isSeller && (
        <>
          <OptionStyled
            active={active === routesApp.articles}
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.articles}`)}
          >
            Artículos
          </OptionStyled>
          <OptionStyled
            active={active === routesApp.definitions}
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.definitions}`)}
          >
            Categorizaciones
          </OptionStyled>
        </>
      )}

      {/* Compras */}
      {!isSeller && !isAdministrator && (
        <ContainerTitle onClick={() => setPurchasesOpen(!purchasesOpen)}>
          <TitleStyled>Compras</TitleStyled>
          {purchasesOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}
      {purchasesOpen && !isSeller && !isAdministrator && (
        <>
          {/* oculto */}
          {/* <OptionStyled
            active={active === routesApp.activePurchases}
            onClick={() =>
              navigate(`/${aliasEmpresa}${routesApp.activePurchases}`)
            }
          >
            Compras activas
          </OptionStyled> */}
          <OptionStyled
            active={active === routesApp.shoppingHistory}
            onClick={() =>
              navigate(`/${aliasEmpresa}${routesApp.shoppingHistory}`)
            }
          >
            Historial de compras
          </OptionStyled>
          {/* oculto */}
          {/* <OptionStyled
            active={active === routesApp.billing}
            onClick={() => navigate(`/${aliasEmpresa}${routesApp.billing}`)}
          >
            Facturación
          </OptionStyled> */}
          <OptionStyled
            active={active === routesApp.purchaseReturns}
            onClick={() =>
              navigate(`/${aliasEmpresa}${routesApp.purchaseRegret}`)
            }
          >
            Devoluciones
          </OptionStyled>
        </>
      )}

      {/* Ventas */}
      {isSeller && (
        <ContainerTitle onClick={() => setSalesOpen(!salesOpen)}>
          <TitleStyled>Ventas</TitleStyled>
          {salesOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}
      {salesOpen && isSeller && (
        <>
          <OptionStyled
            active={active === routesApp.collectionReport}
            onClick={() =>
              navigate(`/${aliasEmpresa}${routesApp.collectionReport}`)
            }
          >
            Informe de cobros
          </OptionStyled>
        </>
      )}
      {salesOpen && isSeller && (
        <>
          <OptionStyled
            active={active === routesApp.salesHistory}
            onClick={() =>
              navigate(`/${aliasEmpresa}${routesApp.salesHistory}`)
            }
          >
            Historial de Ventas
          </OptionStyled>
        </>
      )}

      {/* Configuraciones */}
      {(isSeller || isAdministrator) && (
        <ContainerTitle
          onClick={() => setConfigurationsOpen(!configurationsOpen)}
        >
          <TitleStyled>Configuraciones</TitleStyled>
          {configurationsOpen ? (
            <CloseButtonStyled>-</CloseButtonStyled>
          ) : (
            <CloseButtonStyled>+</CloseButtonStyled>
          )}
        </ContainerTitle>
      )}
      {configurationsOpen && (isSeller || isAdministrator) && (
        <>
          {isSeller && (
            <>
              <OptionStyled
                active={active === routesApp.notifications}
                onClick={() =>
                  navigate(`/${aliasEmpresa}${routesApp.notifications}`)
                }
              >
                Medios de Contacto
              </OptionStyled>
              <OptionStyled
                active={active === routesApp.timeNotifications}
                onClick={() =>
                  navigate(`/${aliasEmpresa}${routesApp.timeNotifications}`)
                }
              >
                Tiempo de notificación y desbloqueo automático
              </OptionStyled>
              <OptionStyled
                active={active === routesApp.paymentGateways}
                onClick={() =>
                  navigate(`/${aliasEmpresa}${routesApp.paymentGateways}`)
                }
              >
                Medios de pago
              </OptionStyled>
              <OptionStyled
                active={active === routesApp.colorsSystem}
                onClick={() =>
                  navigate(`/${aliasEmpresa}${routesApp.colorsSystem}`)
                }
              >
                Colores del sistema
              </OptionStyled>
            </>
          )}
          {isAdministrator && (
            <>
              <OptionStyled
                active={active === routesApp.manager}
                onClick={() => navigate(`/${aliasEmpresa}${routesApp.manager}`)}
              >
                Seguridad
              </OptionStyled>
            </>
          )}
        </>
      )}

      <Button
        text="Cerrar sesión"
        backgroundColor="tertiary"
        margin="20px 0px 0px 0px"
        onClick={() => logoutAndRefreshPage()}
      />
    </ContainerMenu>
  );
};

export default AsideMenu;
