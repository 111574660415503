import { useEffect, useState } from "react";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
import useValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync from "../../../services/BuyForm/useValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync";
import { IValidationPartnerBusinessHook } from "../types/IValidationPartnerBusinessHook";
import { useAppSelector } from "../../../app/hooks";
import { TipoPersona } from "../../../types/enum/TipoPersona";
import { TipoDocumento } from "../../../types/enum/TipoDocumento";

/** Validaciones del formulario de Socio de Negocio */
const useValidationPartnerBusinessHook = (formData :PartnerBusinessFormData): IValidationPartnerBusinessHook => {
  const [identificationDocErrMsgValidation, setIdentityDocumentErrMsgValidation] = useState<string>("");
  const [ciutCuilErrMsgValidation, setCiutCuilErrMsgValidation] = useState<string>("");
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const { httpValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync } = useValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync();

  const currentPartnerBusiness = useAppSelector((state) => state.partnerBusinessForm.datosSocioNegocio);

  useEffect(() => {
    if (identificationDocErrMsgValidation === "" &&
    ciutCuilErrMsgValidation === "") {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }, [identificationDocErrMsgValidation, ciutCuilErrMsgValidation]);

  useEffect(() => {
    const errMsg = validateIdentificationDocument();
    setIdentityDocumentErrMsgValidation(errMsg);
  }, [formData?.tipoDocumentoId, formData?.documento]);

  useEffect(() => {
    const errMsg = validateCiutCuil();
    setCiutCuilErrMsgValidation(errMsg);
  }, [formData?.tipoIdentificacionTributariaId, formData?.identificacionTributaria]);

  useEffect(() => {
    const errMsg = validateIdentificationDocument();
    setIdentityDocumentErrMsgValidation(errMsg);
  }, [formData?.tipoPersonaId, formData?.tipoPersonaId]);

  const expRegOnlyNumers = /^[0-9]+$/;

  /** Valida documento de identidad */
  const validateIdentificationDocument = () => {
    if (formData) {
      if (formData?.tipoPersonaId === TipoPersona.JURIDICA.toString()) {
        return "";
      }
      if (formData?.tipoDocumentoId === "" ||
      formData?.documento === undefined ||
      formData?.documento === "") {
        return "Campo requerido";
      } else if (formData?.tipoDocumentoId.toString() !== TipoDocumento.PASAPORTE.toString() && !(formData?.documento.match(expRegOnlyNumers))) {
        return "Debe ingresar caracteres numéricos";
      } else if (formData?.tipoDocumentoId.toString() === TipoDocumento.DNI.toString() && formData?.documento.length < 7) {
        return "El DNI debe contener al menos 7 caracteres";
      } else if (formData?.tipoDocumentoId.toString() !== TipoDocumento.PASAPORTE.toString() && formData?.documento.length > 10) {
        return "No debe superar los 10 caracteres";
      } else if (formData?.tipoDocumentoId.toString() === TipoDocumento.PASAPORTE.toString() && formData?.documento.length > 12) {
        return "No debe superar los 12 caracteres";
      }
    }
    return "";
  };

  // Valida cuit/cuil
  const validateCiutCuil = () => {
    if (formData) {
      if (formData?.tipoIdentificacionTributariaId === "" ||
      formData?.documento === undefined ||
      formData?.identificacionTributaria === "") {
        return "Campo requerido";
      } else if (!(formData?.identificacionTributaria.match(expRegOnlyNumers))) {
        return "Igresar caracteres numéricos";
      } else if (formData?.identificacionTributaria.length !== 11) {
        return "Debe contener 11 caracteres numéricos";
      }
    }
    return "";
  };

  // Validar por cuit/cuil si ya está en uso el socio de negocio
  const validateCiutCuilAsync = async ():Promise<boolean> => {
    if (formData?.identificacionTributaria === undefined ||
        formData?.identificacionTributaria === "" ||
        ciutCuilErrMsgValidation !== "") {
      return false;
    }
    if (currentPartnerBusiness.socioNegocioId === null || currentPartnerBusiness.socioNegocioId === 0) {
      if (await httpValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync(formData?.identificacionTributaria)) {
        setIsValidForm(true);
        return true;
      }
      setCiutCuilErrMsgValidation("Este dato está vinculado a un socio ya existente.");
      setIsValidForm(false);
      return false;
    }
    return true;
  };

  // Validar por documento si ya está en uso el socio de negocio
  const validateIdentityDocumentAsync = async ():Promise<boolean> => {
    if (formData?.tipoPersonaId === TipoPersona.JURIDICA.toString()) {
      return true;
    }
    if (formData?.documento === undefined ||
        formData?.documento === "" ||
        identificationDocErrMsgValidation !== "") {
      return false;
    }
    if (currentPartnerBusiness.socioNegocioId === null || currentPartnerBusiness.socioNegocioId === 0) {
      if (await httpValidarSocioDeNegocioAsociadoConDocumentoOCuitAsync(formData?.documento)) {
        setIsValidForm(true);
        return true;
      }
      setIdentityDocumentErrMsgValidation("Este dato está vinculado a un socio ya existente.");
      setIsValidForm(false);
      return false;
    }
    return true;
  };

  /** Ejecuta todas las validaciones */
  const validateAllFormPartnerBusiness = async ():Promise<boolean> => {
    if (currentPartnerBusiness.tipoPersonaId === TipoPersona.FISICA.toString()) {
      const [isValidCuil, isValidDoc] = await Promise.all([validateCiutCuilAsync(), validateIdentityDocumentAsync()]);
      if (validateIdentificationDocument() === "" &&
      validateCiutCuil() === "" &&
      isValidCuil &&
      isValidDoc) {
        return true;
      }
    } else {
      const isValidCuil = await validateCiutCuilAsync();
      if (validateCiutCuil() === "" && isValidCuil) {
        return true;
      }
    }
    return false;
  };

  return {
    identificationDocErrMsgValidation,
    isValidForm,
    ciutCuilErrMsgValidation,
    validateCiutCuilAsync,
    validateIdentityDocumentAsync,
    validateAllFormPartnerBusiness
  };
};

export default useValidationPartnerBusinessHook;
