/* eslint-disable indent */
import styled from "styled-components";
import { IButton } from "./Button";

export const ButtonStyled = styled.button<IButton>`
  min-width: auto; 
  width: ${(props): string => props.width || "150px"};
  height: ${(props): string => props.height === undefined ? "40px" : props.height};
  margin: ${(props): string => props.margin || "0px"};
  padding: ${(props): string => props.padding || "0px"};
  background-color: ${(props): string => {
  switch (props.backgroundColor) {
      case "secondary":
        return props.theme.colors.main.secondary;
      case "tertiary":
        return props.theme.colors.main.tertiary;
      default:
        return "white";
    }
  }};    
  color: ${(props): string => props.backgroundColor ? "white" : "#222222"};  
  border: ${(props): string => props.backgroundColor ? "none" : "1px solid #D0D5DD"};
  border-radius: 10px;
  cursor: ${(props): string => props.cursor ?? "pointer"};
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter';

  &:hover {   
    border: 1px solid ${(props) => props.theme.colors.main.primary}; 
  }

  &:disabled {
    background-color: gray;
    border: none;
    cursor: not-allowed;
  }
`;
