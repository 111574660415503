/* eslint-disable indent */
import React, { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../../../../../app/hooks";
// Styles
import { HeaderContainerStyled } from "../../../../../styles/CategorizationStyled";
import { CardSquareStyled } from "../../PaymentGateways/styles/PaymentGatewayStyles";
import {
  FormConfigsStyled,
  ContainerSaveButtonStyled,
  CardTransferConfigsStyled,
  ContainerCardsGroupsStyled,
} from "../styles/BankTransfersTimeNotifications";
// Components
import Button from "../../../../../../../components/button/Button";
import Field from "../../../../../../PurchaseForm/components/Field";
import Spinner from "../../../../../../../components/spinner/Spinner";
// Models
import { Parametrizacion } from "../../../../../../../models/Parametrization/Parametrizacion.model";
// Hooks
import useBankTransferTimeNotificationsHook from "../hooks/useBankTransferTimeNotificationsHook";

interface IBankTransfer {
  dataTimeNotifications: Parametrizacion[];
  setDataTimeNotifications: Dispatch<SetStateAction<never[]>>;
}

const BankTransfersTimeNotifications = ({
  dataTimeNotifications,
  setDataTimeNotifications,
}: IBankTransfer) => {
  // Hooks
  const {
    handleSubmit,
    idGrupoActual,
    findNameClientGroup,
    handleChangeTimeUnlock,
    handleChangeTimeNotification,
  } = useBankTransferTimeNotificationsHook(
    dataTimeNotifications,
    setDataTimeNotifications
  );

  const isLoadingPostTimeNotification = useAppSelector(
    (state) => state.isLoadingParametrization.loadingEditParametrization
  );

  return (
    <CardSquareStyled>
      <HeaderContainerStyled>
        <h3>Transferencias bancarias</h3>
      </HeaderContainerStyled>
      <ContainerCardsGroupsStyled>
        {dataTimeNotifications.map((state) => (
          <CardTransferConfigsStyled key={state.grupoClienteId}>
            <h4>
              Grupo de cliente: {findNameClientGroup(state.grupoClienteId)}
            </h4>
            <FormConfigsStyled>
              <Field
                type="number"
                value={state.minutosParaDesbloqueoStock?.toString() ?? "0"}
                name="minutosParaDesbloqueoStock"
                label="Minutos para desbloqueo de stock:"
                onChange={(e) =>
                  handleChangeTimeUnlock(e, state.grupoClienteId)
                }
              />
              <Field
                type="number"
                value={state.minutosParaNotificacion?.toString() ?? "0"}
                onChange={(e) =>
                  handleChangeTimeNotification(e, state.grupoClienteId)
                }
                name="minutosParaNotificacion"
                label="Minutos para notificacion:"
              />
            </FormConfigsStyled>
            <ContainerSaveButtonStyled>
              {isLoadingPostTimeNotification &&
              idGrupoActual === state?.grupoClienteId ? (
                <div style={{ display: "flex", marginLeft: "45px" }}>
                  <Spinner />
                </div>
              ) : (
                <Button
                  text="Guardar"
                  type="button"
                  onClick={() => handleSubmit(state.grupoClienteId)}
                  backgroundColor="secondary"
                  width="100%"
                ></Button>
              )}
            </ContainerSaveButtonStyled>
          </CardTransferConfigsStyled>
        ))}
      </ContainerCardsGroupsStyled>
    </CardSquareStyled>
  );
};

export default BankTransfersTimeNotifications;
