/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { viewNotification } from "../../../../utils/ViewNotification";
import { setLoadingDeleteValue } from "../../../../features/loading/categorizations/definitions/attributes/loadingsReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpDeleteValue() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpDeleteValueAsync =
    (
      idValue: number,
      refreshValue: boolean,
      setRefreshValue: (value: boolean) => void,
      setShowModalDeleteValue: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingDeleteValue(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.deleteAttributeValue}/${idValue}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          // si response ok, notification success
          viewNotification("Valor eliminado correctamente", "success");
          setRefreshValue(!refreshValue);
          setShowModalDeleteValue(false);
        } else {
          // si response not ok, notification error
          viewNotification("Error al eliminar el valor", "danger");
        }
        dispatch(setLoadingDeleteValue(false));
      } catch (error) {
        setLoadingDeleteValue(false);
      }
      setLoadingDeleteValue(false);
    };
  return {
    httpDeleteValueAsync,
  };
}

export default useHttpDeleteValue;
