import React from "react";
// Styles
import {
  TitleStyled,
  SearchContainerStyled,
} from "../../../../styles/CollectionReportStyled";
// Components
import DateSelector from "./DateSelector";
import SelectStatus from "./SelectStatus";
import Search from "./Search";

interface IHeaderFilters {
  title: string;
  handleSearch: () => void;
  handleEnter: (e: any) => void;
  handleChangeSearch: (e: any) => void;
  handleSetPage: (page: string) => void;
  handleChangeDateFilter: (e: any) => void;
  handleChangeStatusFilter: (e: any) => void;
}

const HeaderFilters = ({
  title,
  handleEnter,
  handleSearch,
  handleSetPage,
  handleChangeSearch,
  handleChangeDateFilter,
  handleChangeStatusFilter,
}: IHeaderFilters) => (
  <SearchContainerStyled>
    <TitleStyled className="title">{title}</TitleStyled>
    {/* Selectores de fechas */}
    <DateSelector handleChangeDateFilter={handleChangeDateFilter} />
    {/* Selector de estado de la compra */}
    <SelectStatus handleChangeStatusFilter={handleChangeStatusFilter} />
    {/* Buscador */}
    <Search
      handleEnter={handleEnter}
      handleChangeSearch={handleChangeSearch}
      handleSetPage={handleSetPage}
      handleSearch={handleSearch}
    />
  </SearchContainerStyled>
);

export default HeaderFilters;
