import React, { useEffect, useState } from "react";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { routesApp } from "../../RoutesApp";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// Models
import { Item } from "../../models/Item";
import { UserData } from "../../models/UserData";
// features
import {
  addSearch,
  addSuggestSearch,
} from "../../features/search/searchReducer";
import { setUserData } from "../../features/userData/userDataReducer";
import { addProduct } from "../../features/buy_Order/buy_OrderReducer";
import { setCategory } from "../../features/filters/activeFiltersReducer";
import { useJwt } from "react-jwt";
// Utils
import { getToken } from "../../utils/helper";
import { buildParams } from "../../utils/buildParams";
// Services
import useHttpSuggestSearchPhrase from "../../services/Catalogue/useHttpSuggestSearchPhrase";
import useHttpCart from "../../services/Cart/useHttpCart";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
// Utils
import validateUnauthorized from "../../utils/validateUnauthorized";

const useHeaderHook = () => {
  const { httpCartAsync } = useHttpCart();
  const { httpSuggestSearchPhraseAsync } = useHttpSuggestSearchPhrase();
  const { obtenerUserData, obtenerItemsCarrito } = useLocalStorageHook();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [selectOption, setSelectOption] = useState<string>("");
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { aliasEmpresa } = useParams();
  const empresaId = useAppSelector(
    (state) => state.appConfigurationState.empresaId
  );

  const { obtenerToken } = useLocalStorageHook();
  const token = getToken(obtenerToken);

  const cartLocalStorage = obtenerItemsCarrito();

  const userRedux = useAppSelector((state) => state.user);
  const cartItems = useAppSelector(
    (state) => state.currentBuyOrder.ordenDeCompra.carritoDeCompras.items
  );

  // Convertir userData a objeto
  const userDataObj: UserData = obtenerUserData() || ({} as UserData);
  const { isExpired } = useJwt(token);

  // Existe token valido?
  const isLoggedIn = token !== "";
  const isUserValid = userDataObj?.nombre !== "";

  useEffect(() => {
    const searchParam = searchParams.get("Busqueda");
    if (searchParam && searchParam !== "") {
      setSearch(searchParam);
    } else {
      setSearch("");
    }
  }, [searchParams]);

  /**
   * Maneja el cambio en el campo de búsqueda.
   * Actualiza el estado de búsqueda y, si el valor no está vacío y tiene más de un carácter,
   * despacha una acción para sugerir frases de búsqueda.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - El evento de cambio del input.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value !== "" && e.target.value.length > 1) {
      dispatch(httpSuggestSearchPhraseAsync(String(e.target.value)));
    }
  };

  /**
   * Maneja el cambio de selección en el dropdown de categorías.
   * Actualiza el estado de la opción seleccionada y despacha una acción para establecer la categoría en el estado global.
   *
   * @param {string} value - El valor de la opción seleccionada.
   */
  const handleChangeSelect = (value: string) => {
    setSelectOption(value);
    dispatch(setCategory(selectOption));
  };

  /**
   * Maneja el clic en el botón de búsqueda.
   * Limpia las sugerencias de búsqueda y navega a la página de resultados con los parámetros de búsqueda y categoría seleccionada.
   */
  const handleClickForSearch = (suggest: string = search) => {
    dispatch(addSuggestSearch([]));
    // Si el usuario ya está en esa ruta
    if (
      location &&
      location.pathname.includes(`${routesApp.results}`) &&
      !location.pathname.includes(`${routesApp.resultsVin}`)
    ) {
      dispatch(setCategory(selectOption));
      dispatch(addSearch(suggest));
    } else {
      navigate(
        `/${aliasEmpresa}${routesApp.results}?${buildParams(suggest, {
          categoria: selectOption,
        }).toString()}`,
        {
          state: {
            suggest,
            category: selectOption,
          },
        }
      );
    }
  };

  /**
   * Maneja el evento de presionar la tecla Enter en el campo de búsqueda.
   * Si el campo de búsqueda no está vacío, navega a la página de resultados con los parámetros de búsqueda y categoría seleccionada.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} e - El evento de teclado.
   */
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (search === "") return;
    if (e.key === "Enter") {
      // Si el usuario ya está en esa ruta
      if (location && location.pathname.includes(`${routesApp.results}`)) {
        if (selectOption !== "") {
          dispatch(setCategory(selectOption));
        }
        dispatch(addSearch(search));
      } else {
        navigate(
          `/${aliasEmpresa}${routesApp.results}?${buildParams(search, {
            categoria: selectOption,
          }).toString()}`,
          {
            state: {
              search,
              category: selectOption,
            },
          }
        );
      }
    }
  };

  /**
   * Agrega los productos del carrito almacenados en el localStorage al estado global.
   */
  const addCartLocalStorage = () => {
    cartLocalStorage.map((item: Item) => {
      // Guardar en redux
      dispatch(addProduct(item));
      return item;
    });
  };

  useEffect(() => {
    if (isExpired) {
      validateUnauthorized("Unauthorized", empresaId);
    }
  }, []);

  useEffect(() => {
    if (userRedux.nombre === "" && isLoggedIn && isUserValid) {
      // Guardar datos del usuario en local en redux
      dispatch(setUserData(userDataObj));
    }

    if (isLoggedIn) {
      if (cartItems.length === 0) {
        dispatch(httpCartAsync());
      }
    }
    if (cartItems.length === 0 && cartLocalStorage.length > 0) {
      addCartLocalStorage();
    }
  }, []);

  return {
    search,
    handleClickForSearch,
    handleEnter,
    handleChange,
    handleChangeSelect,
  };
};

export default useHeaderHook;
