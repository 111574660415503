import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../app/hooks";
// Styles
import { ModalContainerStyled } from "../../../../styles/ModalConfirmation";
// Models
import { DataPublishedArticles } from "..//models/DataPublishedArticles.models";
// Services
import useHttpPublishAllArticles from "../../../../../../services/Account/MyProducts/useHttpPublishAllArticles";
import useHttpUnpublishAllArticles from "../../../../../../services/Account/MyProducts/useHttpUnpublishAllArticles";
// Components
import Button from "../../../../../../components/button/Button";
import Spinner from "../../../../../../components/spinner/Spinner";
import { viewNotification } from "../../../../../../utils/ViewNotification";

interface IModal {
  setShowModal: (value: boolean) => void;
  positionY: number;
  clientGroupId: number;
  refreshTable: boolean;
  setRefreshTable: (value: boolean) => void;
}

const ModalPublicationOfArticles = ({
  positionY,
  setShowModal,
  clientGroupId,
  refreshTable,
  setRefreshTable,
}: IModal) => {
  const dispatch = useAppDispatch();
  const { httpPublishAllArticlesAsync } = useHttpPublishAllArticles();
  const { httpUnpublishAllArticlesAsync } = useHttpUnpublishAllArticles();
  const { pagination } = useAppSelector(
    (state) => state.publishedArticles as DataPublishedArticles
  );
  const loadingPublish = useAppSelector(
    (state) => state.isLoadingMyProducts.loadingPublishAllArticles
  );
  const loadingUnpublish = useAppSelector(
    (state) => state.isLoadingMyProducts.loadingUnpublishAllArticles
  );

  // Pblicar todos los artículos
  const handlePublishAll = () => {
    dispatch(
      httpPublishAllArticlesAsync(
        clientGroupId,
        setRefreshTable,
        refreshTable,
        setShowModal
      )
    );
  };

  // Despublicar todos los artículos
  const handleUnpublishAll = () => {
    dispatch(
      httpUnpublishAllArticlesAsync(
        clientGroupId,
        setRefreshTable,
        refreshTable,
        setShowModal
      )
    );
  };

  // Lanzar notificación de acción en proceso
  const handleNotification = () => {
    viewNotification("Procesando modificaciones", "info");
  };

  useEffect(() => {
    if (loadingPublish || loadingUnpublish) {
      handleNotification();
    }
  }, [loadingPublish || loadingUnpublish]);

  return (
    <ModalContainerStyled positionY={positionY}>
      <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <div
          onClick={() => setShowModal(false)}
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "50%",
            padding: "2px 7px",
            cursor: "pointer",
          }}
        >
          X
        </div>
      </div>
      <b> {pagination.totalAllRecords} artículos seleccionados</b>
      <div style={{ display: "flex", gap: "50px", paddingBottom: "25px" }}>
        {loadingUnpublish ? (
          <Spinner />
        ) : (
          <Button text="Despublicar todos" onClick={handleUnpublishAll} />
        )}
        {loadingPublish ? (
          <Spinner />
        ) : (
          <Button
            text="Publicar todos"
            backgroundColor="secondary"
            onClick={handlePublishAll}
          />
        )}
      </div>
    </ModalContainerStyled>
  );
};

export default ModalPublicationOfArticles;
