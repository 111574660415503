/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import {
  addLocalidades,
  addResultadosTotales,
} from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import { ILocalidades } from "../../models/BuyFormModels/Localidades";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { setLoadingLocalidades } from "../../features/loading/buyForm/loadingsReducer";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpObtenerLocalidadesPorProvincia() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpObtenerLocalidadesPorProvinciaAsync =
    (
      provinciaId: string,
      filtro: string,
      page: number,
      postalCode?: number
    ): AppThunk =>
    async (dispatch: any) => {
      dispatch(setLoadingLocalidades(true));
      try {
        const response = await fetch(
          `${env.catalogo}${
            Routes.ObtenerLocalidadesPorProvincia
          }?provinciaId=${provinciaId}&TotalRecords=50&Page=${page}&RecordsForPage=50&localidadNombreCodigoPostal=${filtro}&codigoPostal=${
            postalCode || ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (response.status === 200) {
          dispatch(addLocalidades(data.data as ILocalidades[]));
          dispatch(addResultadosTotales(data.pagination.totalAllRecords));
        }
        dispatch(setLoadingLocalidades(false));
      } catch (error) {
        dispatch(setLoadingLocalidades(false));
      }
    };
  return {
    httpObtenerLocalidadesPorProvinciaAsync,
  };
}

export default useHttpObtenerLocalidadesPorProvincia;
