import * as React from "react";
import { SVGProps } from "react";
import { useTheme } from "styled-components";

const BasketIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width={32}
      height={33}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)" fill={theme.colors.main.primary}>
          <path d="M12.328 32.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM26 32.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM32.078 5.87a.999.999 0 0 0-.77-.37h-20.82l.65 2h18.86l-2.67 12h-15L7.758 5.03a1 1 0 0 0-.66-.65l-4.1-1.26a1.004 1.004 0 0 0-.59 1.92l3.59 1.1 4.59 14.5-1.64 1.34-.13.13a2.66 2.66 0 0 0-.08 3.39 2.751 2.751 0 0 0 2.26 1h16.69a1 1 0 0 0 0-2h-16.85a.67.67 0 0 1-.56-1l2.41-2h15.44a1 1 0 0 0 1-.78l3.17-14a1 1 0 0 0-.22-.85Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h32v32H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" transform="translate(-1 .5)" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BasketIcon;
