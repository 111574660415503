// Articulos mas vendidos
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MostViewedArticlesDto } from "../../DTOs/MostViewedArticlesDto";

type State = {
  Articles: MostViewedArticlesDto[]
};

const initialState: State = {
  Articles: []
};

export const MostViewedArticles = createSlice({
  name: "MostViewedArticles",
  initialState,
  reducers: {
    setMostViewedArticles: (state, action: PayloadAction<Array<MostViewedArticlesDto>>) => {
      state.Articles = action.payload;
    },
    resetMostViewedArticles: (state) => {
      state.Articles = [];
    },
  },
});

export const {
  setMostViewedArticles,
  resetMostViewedArticles
} = MostViewedArticles.actions;

export default MostViewedArticles.reducer;
