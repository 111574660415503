/* eslint-disable indent */
import { Link } from "react-router-dom";
import styled from "styled-components";

// convertir valor hex de color a rgba css
export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)} , ${parseInt(
        result[3],
        16
      )}, 0.4)`
    : "#000000";
};

export const WrapperStyled = styled.div`
  background: ${(props) => props.theme.colors.main.secondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 6.5% 10px 6.5%;
  padding-top: 70px;
`;

export const LeftDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightDivStyled = styled.div`
  display: flex;
  flex: 2;
  justify-content: end;
  flex-wrap: wrap;
  gap: 10px;
`;

export const FillButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 40px;
  border: none;
  color: white;
  background: none;
  font-family: "inter";
  font-weight: 600;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: white;
  font-family: "inter";
  font-weight: 600;
`;

export const ButtonStyled = styled.button`
  color: ${(props) => props.theme.colors.text.second}; //white
  border-color: transparent;
  padding: 10px 14px;
  margin-right: 65px;
  background: transparent;
  font-family: "inter";
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 0px
      ${(props) => hexToRgb(props.theme.colors.main.primary)};
  }
`;

export const ImgStyled = styled.img`
  margin-right: 10px;
`;

export const TextStyled = styled.div`
  color: ${(props) => props.theme.colors.text.second};
  font-family: "inter";
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    border-bottom: 3px solid ${(props) => props.theme.colors.text.second};
  }
`;

export const UserBarContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;

export const UserContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ImgContainerStyled = styled.div`
  border: 1px solid ${(props) => props.theme.colors.main.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
`;

export const CartContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const QuantityContainerStyled = styled.div`
  color: white;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: 20px;
  margin-top: -20px;
`;
