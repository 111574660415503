import React from "react";
// Styles
import {
  DateSelectorStyled,
  ContainerDateSelectorStyled,
} from "../../../../styles/CollectionReportStyled";

interface IDataSelector {
  handleChangeDateFilter: (e: any) => void;
}

const DateSelector = ({ handleChangeDateFilter }: IDataSelector) => (
  <>
    <ContainerDateSelectorStyled>
      <label htmlFor="dateStart">Desde:</label>
      <DateSelectorStyled
        type="date"
        id="dateStart"
        className="dateStart"
        onChange={handleChangeDateFilter}
      />
    </ContainerDateSelectorStyled>
    <ContainerDateSelectorStyled>
      <label htmlFor="dateEnd">Hasta:</label>
      <DateSelectorStyled
        type="date"
        className="dateEnd"
        id="dateEnd"
        onChange={handleChangeDateFilter}
      />
    </ContainerDateSelectorStyled>
  </>
);

export default DateSelector;
