/* eslint-disable indent */
import React from "react";
// Styles
import {
  ErrorMsg,
  LabelStyled,
  InputStyled,
  InputContainer,
} from "../styles/FormStyles";
// Models
import { IField } from "../types/Field.model";
import { isInteger } from "../utils/Utils";

const Field = ({
  label,
  name,
  value,
  onChange,
  onClicked,
  type,
  isDisabled,
  placeholder,
  isValidCP,
  isRequired = true,
}: IField) => (
  <InputContainer>
    <LabelStyled htmlFor={label}>{label}</LabelStyled>
    <InputStyled
      type={type ?? "text"}
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClicked}
      placeholder={placeholder ?? ""}
      required={isRequired}
      disabled={isDisabled}
    />
    {name === "piso" && value !== "" && !isInteger(value.toString()) && (
      <ErrorMsg>Número inválido: {value}</ErrorMsg>
    )}
    {(value === "" || value === undefined || value === null) && isRequired && (
      <ErrorMsg>Campo requerido</ErrorMsg>
    )}
    {value !== "" &&
      value !== undefined &&
      value !== null &&
      name === "codigoPostal" &&
      !isValidCP && <ErrorMsg>Codigo postal inválido</ErrorMsg>}
  </InputContainer>
);

export default Field;
