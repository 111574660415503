import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// Models
import {
  IArticles,
  IAttribute,
  IAttributeValues,
} from "../../../models/Articles.model";
// Styles
import {
  SelectStyled,
  ButtonColorStyled,
  SelectAttributeStyled,
  ContainerPreviewStyled,
} from "../../../styles/CategorizationStyled";
// Hooks
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
// Icons
import EditIcon from "../../../assets/icons/editar.png";
import TrashIcon from "../../../assets/icons/trash.png";
// Components
import { Store } from "react-notifications-component";
import ModalConfirmationAttribute from "../../Modals/ModalConfirmationAttribute";
// Services
import useHttpValues from "../../../../../services/Categorization/Articles/useHttpValues";
import useHttpAttributesInArticle from "../../../../../services/Categorization/Articles/useHttpAttributesInArticle";
import useHttpAddOrUpdateAttribute from "../../../../../services/Categorization/Articles/useHttpAddOrUpdateAttribute";

interface IAttributesInArticleTable {
  articleSelected: IArticles;
  idArticle: number;
}

/* eslint-disable */

const AttributesInArticleTable = ({
  articleSelected,
  idArticle,
}: IAttributesInArticleTable) => {
  const dispatch = useAppDispatch();

  // Servicios
  const { httpValuesAsync } = useHttpValues();
  const { httpAttributesInArticleAsync } = useHttpAttributesInArticle();
  const { httpAddOrUpdateAttributeAsync } = useHttpAddOrUpdateAttribute();

  // Estados globales
  const attributes = useAppSelector(
    // Atributos disponibles para agregar al articulo
    (state) => state.attributes as IAttribute[]
  );
  const attributesInArticle = useAppSelector(
    // Atributos que tiene el articulo seleccionado
    (state) => state.attributesInArticle as IAttributeValues[]
  );
  const attributeValues = useAppSelector(
    // Lista de valores posibles para el atributo seleccionado
    (state) => state.valuesArticles as string[]
  );

  // UseStates
  const [idAttribute, setIdAttribute] = useState(0);
  const [showSelectValueId, setShowSelectValueId] = useState(0);
  const [attributeDescription, setAttributeDescription] = useState("");
  const [positionY, setPositionY] = useState(0);
  const [showConfirmationModalAttribute, setShowConfirmationModalAttribute] =
    useState(false);
  const [selectValue, setSelectValue] = useState("");

  const [newAddAttribute, setNewAddAttribute] = useState({
    id: 0,
    descripcion: "",
    valor: "",
  });
  const [showPrevisualizacion, setShowPrevisualizacion] = useState(false);

  // Mostrar modal de confirmación para eliminar un atributo
  const handleShowModalAttribute = (attributeId: number) => {
    setIdAttribute(attributeId);
    setShowConfirmationModalAttribute(true);
  };

  // Mostrar previsualizacion de agregar atributo
  const handleChangeAddAtribute = (event: any) => {
    setSelectValue(event?.target.value);
    if (event?.target.value === "") {
      setShowPrevisualizacion(false);
      return;
    }
    // si el id ya se encuentra en la lista de atributos, enviar notificacion
    const attributeExist = attributesInArticle?.some(
      (attribute: any) => attribute.id === Number(event?.target.value)
    );
    if (attributeExist) {
      Store.addNotification({
        title: "Este atributo ya se encuentra en el artículo",
        container: "bottom-full",
        type: "danger",
        dismiss: {
          duration: 3000,
        },
      });
      return;
    }
    // sino, setear el nuevo atributo en la previsualizacion
    setNewAddAttribute({
      id: Number(event?.target.value),
      descripcion: event?.target.selectedOptions[0].getAttribute("itemProp"),
      valor: "",
    });

    // Mostrar valores posibles
    dispatch(httpValuesAsync(event?.target.value));

    event?.target.value === ""
      ? setShowPrevisualizacion(false)
      : setShowPrevisualizacion(true);
  };

  // Agregar un atributo nuevo con su valor al articulo si este no se encuentra aplicado
  const handleAddAtribute = (e: any) => {
    if (
      (e.target.value !== "" ||
        newAddAttribute.descripcion !== "" ||
        newAddAttribute.id !== 0) &&
      articleSelected
    ) {
      dispatch(httpValuesAsync(newAddAttribute.id));
      dispatch(
        httpAddOrUpdateAttributeAsync(
          articleSelected.id,
          newAddAttribute.id,
          newAddAttribute.descripcion,
          e.target.value
        )
      );
      setNewAddAttribute({
        id: 0,
        descripcion: "",
        valor: "",
      });
    }
    setShowPrevisualizacion(false);
  };

  // Mostrar valores posibles para el atributo en el selector
  const handleClickSelect = (attribute: IAttributeValues) => {
    if (attribute.id === 0) return;
    setIdAttribute(attribute.id);
    setAttributeDescription(attribute.descripcion);
    dispatch(httpValuesAsync(attribute.id));
  };

  // Editar valor para el atributo
  const handleChangeValue = (value: string) => {
    if (value === "") return;
    if (articleSelected) {
      dispatch(
        httpAddOrUpdateAttributeAsync(
          articleSelected.id,
          idAttribute,
          attributeDescription,
          value
        )
      );
    }
    setShowSelectValueId(0);
  };

  // Mostrar selector de valores para el atributo
  const handleShowSelect = (attribute: IAttribute) => {
    if (showSelectValueId === attribute.id) {
      setShowSelectValueId(0);
      return;
    }
    setShowSelectValueId(attribute.id);
  };

  // Columnas de la tabla
  const columnsAttributes: any = [
    {
      name: "Descripción",
      selector: (row: any) => row.descripcion,
    },
    {
      name: "Valor",
      cell: (attribute: IAttributeValues) => {
        if (attribute.valor && showSelectValueId !== attribute.id) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "5px",
              }}
            >
              <span>{attribute.valor}</span>
            </div>
          );
        }
        return (
          <div style={{ display: "flex" }}>
            <SelectStyled
              key={attribute.id}
              onClick={() => handleClickSelect(attribute)}
              onChange={(e) => {
                handleChangeValue(e.target.value);
              }}
            >
              {attributeValues.length === 0 ? (
                <option value="">Sin elementos disponibles</option>
              ) : (
                <option value="">Seleccionar</option>
              )}
              {attributeValues?.map((value: string, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </SelectStyled>
          </div>
        );
      },
    },
    {
      name: "Editar",
      button: true,
      width: "60px",
      cell: (item: IAttribute) => (
        <ButtonColorStyled color="lime" onClick={() => handleShowSelect(item)}>
          <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
        </ButtonColorStyled>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      width: "60px",
      cell: (item: IAttribute) => (
        <ButtonColorStyled
          color="orange"
          onClick={() => handleShowModalAttribute(item.id)}
        >
          <img src={TrashIcon} alt="Eliminar" style={{ width: "15px" }} />
        </ButtonColorStyled>
      ),
    },
  ];

  useEffect(() => {
    setShowPrevisualizacion(false);
  }, [articleSelected]);

  // Rastrear posición del scroll
  useEffect(() => {
    const handleScroll = () => {
      setPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div style={{ margin: "50px 0" }}>
        <DataTable
          responsive
          pointerOnHover
          title={`Atributos - ${articleSelected?.nombre}`}
          columns={columnsAttributes}
          data={attributesInArticle || []}
          noDataComponent="No se encuentran atributos"
          progressComponent={"Cargando..."}
          highlightOnHover
        />

        {/* Previsualizacion agregar valor */}
        {showPrevisualizacion && (
          <ContainerPreviewStyled>
            <span>Agregar: {newAddAttribute.descripcion}</span>
            <SelectStyled
              onChange={handleAddAtribute}
              onClick={() => setSelectValue("")}
            >
              {attributeValues.length === 0 ? (
                <option value="">No hay valores definidos</option>
              ) : (
                <option value="">Seleccionar</option>
              )}
              {attributeValues?.map((value: string, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </SelectStyled>
          </ContainerPreviewStyled>
        )}

        {/* Selector Agregar atributos */}
        <SelectAttributeStyled
          onChange={handleChangeAddAtribute}
          value={selectValue}
        >
          <option value="">Agregar atributo</option>
          {attributes?.map((attribute: IAttribute) => (
            <option
              key={attribute.id}
              value={attribute.id}
              itemProp={attribute.descripcion}
            >
              {attribute.descripcion}
            </option>
          ))}
        </SelectAttributeStyled>
      </div>
      {showConfirmationModalAttribute && (
        <ModalConfirmationAttribute
          idArticle={idArticle}
          idAttribute={idAttribute}
          setShowConfirmationModalAttribute={setShowConfirmationModalAttribute}
          positionY={positionY}
        />
      )}
    </div>
  );
};

export default AttributesInArticleTable;
