import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HistorySales } from "../../models/HistorySales/HistorySales.model";
import { Data } from "../../models/HistorySales/Data.model";

const initial: HistorySales = {
  data: [],
  pagination: {
    recordsForPage: 0,
    page: 0,
    totalRecords: 0,
    totalAllRecords: 0,
  },
};

export const SalesHistorySlice: any = createSlice({
  name: "SalesHistory",
  initialState: initial,
  reducers: {
    setSalesHistory: (state: any, action: PayloadAction<HistorySales>) => {
      state.data = action.payload.data;
      state.pagination = action.payload.pagination;
    },
    // Modificar la propiedad de data.estado a "ARREPENTIDO"
    setSalesHistoryCancel: (state: any, action: PayloadAction<number>) => {
      state.data.forEach((element: any) => {
        if (element.numero === action.payload) {
          element.estado = "ARREPENTIDO";
        }
      });
    },
    // Modificar la propiedad de data.estado a "CANCELADO"
    setCollectionReportCancel: (state: any, action: PayloadAction<number>) => {
      state.data.forEach((element: any) => {
        if (element.numero === action.payload) {
          element.estado = "CANCELADO";
        }
      });
    },
    // Modificar la propiedad de data.estado a "PAGADO"
    setCollectionReportAccept: (state: any, action: PayloadAction<number>) => {
      state.data.forEach((element: any) => {
        if (element.numero === action.payload) {
          element.estado = "PAGADO";
        }
      });
    },
    updateOrderNoteNumber: (
      state: any,
      action: PayloadAction<{ ordenCompraId: number; nuevoNumeroNota: number }>
    ) => {
      state.data.forEach((element: Data) => {
        if (element.numero === action.payload.ordenCompraId) {
          element.numeroNotaPedidoVenta = action.payload.nuevoNumeroNota;
        }
      });
    },
  },
});

export const {
  setSalesHistory,
  setSalesHistoryCancel,
  updateOrderNoteNumber,
  setCollectionReportCancel,
  setCollectionReportAccept,
} = SalesHistorySlice.actions;

export default SalesHistorySlice.reducer;
