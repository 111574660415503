import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingGetHistorySale: false,
  loadingUpdateStatusOrder: false,
  loadingGetShoppingHistory: false,
  loadingRegisterAcceptPayment: false,
  loadingRegisterCancelPayment: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingGetHistorySale: (state, action: PayloadAction<boolean>) => {
      state.loadingGetHistorySale = action.payload;
    },
    setLoadingUpdateStatusOrder: (state, action: PayloadAction<boolean>) => {
      state.loadingUpdateStatusOrder = action.payload;
    },
    setLoadingGetShoppingHistory: (state, action: PayloadAction<boolean>) => {
      state.loadingGetShoppingHistory = action.payload;
    },
    setLoadingRegisterAcceptPayment: (state, action: PayloadAction<boolean>) => {
      state.loadingRegisterAcceptPayment = action.payload;
    },
    setLoadingRegisterCancelPayment: (state, action: PayloadAction<boolean>) => {
      state.loadingRegisterCancelPayment = action.payload;
    },
  },
});

export const {
  setLoadingGetHistorySale,
  setLoadingUpdateStatusOrder,
  setLoadingGetShoppingHistory,
  setLoadingRegisterAcceptPayment,
  setLoadingRegisterCancelPayment,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
