/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { viewNotification } from "../../utils/ViewNotification";
import { setTokenDms } from "../../features/appConfiguration/appConfigurationReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpUpdateTokenDms() {
  const appConfigurationState = useAppSelector(
    (state: RootState) => state.appConfigurationState
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUpdateTokenDmsAsync =
    (tokenDms: string): AppThunk =>
    async (dispatch) => {
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.updateTokenDms}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: appConfigurationState.empresaId.toString(),
            },
            body: JSON.stringify({
              token: tokenDms,
            }),
          }
        );
        if (response.status === 200) {
          dispatch(setTokenDms(tokenDms));
          viewNotification("Guardado!", "success");
        } else {
          viewNotification("Error!", "danger");
        }
      } catch (error) {
        viewNotification("Error!", "danger");
      }
    };
  return {
    httpUpdateTokenDmsAsync,
  };
}

export default useHttpUpdateTokenDms;
