import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingDeleteValue: false,
  loadingDeleteAttribute: false,
  loadingValuesDefinitions: false,
  loadingAttributesDefinitions: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingAttributesDefinitions: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingAttributesDefinitions = action.payload;
    },
    setLoadingDeleteAttribute: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteAttribute = action.payload;
    },
    setLoadingDeleteValue: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteValue = action.payload;
    },
    setLoadingValuesDefinitions: (state, action: PayloadAction<boolean>) => {
      state.loadingValuesDefinitions = action.payload;
    },
  },
});

export const {
  setLoadingDeleteValue,
  setLoadingDeleteAttribute,
  setLoadingValuesDefinitions,
  setLoadingAttributesDefinitions,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
