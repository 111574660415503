export class PartnerBusinessFormData {
  // domicilio:
  calle: string;
  numero: string;
  codigoPostal: string;
  provinciaId: string;
  localidadId: string;
  domicilioId: string | null;
  tipoInmuebleId: string;
  piso: string;
  departamento: string;
  // persona
  nombre: string;
  apellido: string;
  tipoPersonaId: string;
  tipoIdentificacionTributariaId: string;
  identificacionTributaria: string;
  tipoDocumentoId: string;
  documento: string;
  condicionIvaId: string;
  razonSocial: string;
  // email
  direccion: string;
  // telefono
  prefijoPais: string;
  prefijoLocalidad: string;
  numeroTel: string;
  tipoTelefonoId: string;
  socioNegocioId: number | null;

  constructor() {
    this.socioNegocioId = 0;
    // Domicilio
    this.calle = "";
    this.numero = "";
    this.codigoPostal = "";
    this.provinciaId = "";
    this.localidadId = "";
    this.domicilioId = null;
    this.tipoInmuebleId = "1";
    this.piso = ""; // habilitar solo si se selecciona departamento /***/
    this.departamento = ""; // habilitar solo si se selecciona departamento /***/
    // barrioNombre: "" /***/,

    // Persona
    this.nombre = "";
    this.apellido = "";
    this.tipoPersonaId = "";
    this.tipoIdentificacionTributariaId = "2";
    this.identificacionTributaria = "";
    this.tipoDocumentoId = "6";
    this.documento = "";
    this.condicionIvaId = "5";
    this.razonSocial = ""; // habilitar solo si se selecciona persona juridica
    // Email
    this.direccion = ""; // de email
    // Telefono
    this.prefijoPais = "54";
    this.prefijoLocalidad = "";
    this.tipoTelefonoId = "0";
    this.numeroTel = ""; // cambiar a numero
  }
}
