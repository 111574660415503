import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingPaymentIntention: false,
  loadingPaymentMercadoPago: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingPaymentIntention: (state, action: PayloadAction<boolean>) => {
      state.loadingPaymentIntention = action.payload;
    },
    setLoadingMercadoPago: (state, action: PayloadAction<boolean>) => {
      state.loadingPaymentMercadoPago = action.payload;
    },
  }
});

export const { setLoadingPaymentIntention, setLoadingMercadoPago } = loadingsSlice.actions;

export default loadingsSlice.reducer;
