import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingDeleteCarouselImage: false,
  loadingUploadCarouselImage: false,
  loadingGetCarouselImages: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingDeleteCarouselImage: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteCarouselImage = action.payload;
    },
    setLoadingUploadCarouselImage: (state, action: PayloadAction<boolean>) => {
      state.loadingUploadCarouselImage = action.payload;
    },
    setLoadingGetCarouselImage: (state, action: PayloadAction<boolean>) => {
      state.loadingGetCarouselImages = action.payload;
    },
  },
});

export const {
  setLoadingGetCarouselImage,
  setLoadingDeleteCarouselImage,
  setLoadingUploadCarouselImage,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
