import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAttributes {
  id: number;
  descripcion: string;
  esDatoSistema: boolean;
}

const initial: IAttributes[] = [];

export const AttributesSlice: any = createSlice({
  name: "attributes",
  initialState: initial,
  reducers: {
    addAttributes: (state, action: PayloadAction<IAttributes[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { addAttributes } = AttributesSlice.actions;

export default AttributesSlice.reducer;
