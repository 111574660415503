import { Link, useParams } from "react-router-dom";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Styles
import {
  // AStyled,
  NavStyled,
  // LinkStyled,
  InputStyled,
  SearchStyled,
  ContainerStyled,
  LogoImagenStyled,
  SearchLogoStyled,
  ButtonSearchStyled,
  BurguerMenuButtonStyled,
  BurguerMenuContainerStyled,
  LogoContainerStyled,
} from "./Styled";
// Icons
import burguerMenu from "../../assets/burguer.png";
// import Logo from "../../assets/icons/logo.svg";
import Search from "../../assets/icons/search.svg";
// Hooks
import useHeaderHook from "./useHeaderHook";
import { useAppSelector } from "../../app/hooks";
// Components
import Dropdown from "../dropdownCustom/Dropdown";

import SuggestSearch from "../suggestSearch/SuggestSearch";

const Header = () => {
  const {
    search,
    handleClickForSearch,
    handleEnter,
    handleChange,
    handleChangeSelect,
  } = useHeaderHook();

  const [parent] = useAutoAnimate();

  const { aliasEmpresa } = useParams();

  const categorias = useAppSelector((state) => state.filters.categorias);

  const suggestSearch = useAppSelector((state) => state.search.suggestSearch);

  const logoEmpresa = useAppSelector(
    (state) => state.appConfigurationState.logo
  );

  const options = categorias?.map((m) => ({ value: m, label: m }));

  function handleSelect(option: any) {
    handleChangeSelect(option.value);
  }

  const defaultValue = [
    {
      value: "Sin categorías",
      label: "Sin categorías",
    },
  ];

  return (
    <ContainerStyled>
      {/* Logo */}
      <LogoContainerStyled>
        <Link to={`/${aliasEmpresa}/`}>
          <LogoImagenStyled src={logoEmpresa} alt="Logo" loading="lazy" />
        </Link>
      </LogoContainerStyled>

      <Dropdown
        options={categorias.length === 0 ? defaultValue : options}
        onSelect={handleSelect}
        showDefaultString="Todas"
      />

      {/* New Search */}
      <SearchStyled ref={parent}>
        <div style={{ display: "flex" }}>
          <InputStyled
            type="text"
            name="search"
            value={search}
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              handleEnter(e);
            }}
            autoComplete="off"
          />

          <ButtonSearchStyled onClick={() => handleClickForSearch()}>
            <SearchLogoStyled src={Search} alt="search icon" />
          </ButtonSearchStyled>
        </div>
        {/* Sugerencias */}
        {suggestSearch.length > 0 && search !== "" && (
          <SuggestSearch
            suggestSearchList={suggestSearch}
            handleClickForSearch={handleClickForSearch}
          />
        )}
      </SearchStyled>

      {/* burguer menu */}
      <BurguerMenuContainerStyled>
        <BurguerMenuButtonStyled>
          <img
            src={burguerMenu}
            alt="menú"
            loading="lazy"
            style={{ width: "100%" }}
          />
        </BurguerMenuButtonStyled>
      </BurguerMenuContainerStyled>
      {/* Nav */}
      <NavStyled ref={parent}>
        {/* oculto */}
        {/* <div>
          <AStyled
            href="https://oversoft.net/institucional/quienes-somos2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Quienes somos
          </AStyled>
        </div>
        <div>
          <LinkStyled to="#">Ayuda</LinkStyled>
        </div> */}
      </NavStyled>
    </ContainerStyled>
  );
};

export default Header;
