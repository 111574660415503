// Articulos mas vendidos
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IArticle {
    id: string;
    descripcion: string;
    imagenes: string[];
    ultimasUnidades: boolean;
    precio: number;
  }

interface IStateNewAddedProducts {
  articles: IArticle[];
}

const initialState: IStateNewAddedProducts = {
  articles: []
};

export const NewAddedProducts = createSlice({
  name: "newAddedProducts",
  initialState,
  reducers: {
    setNewAddedProducts: (state, action: PayloadAction<Array<IArticle>>) => {
      state.articles = action.payload;
    },
  },
});

export const {
  setNewAddedProducts,
} = NewAddedProducts.actions;

export default NewAddedProducts.reducer;
