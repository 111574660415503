import React from "react";
import { SelectStatusStyled } from "../../../../styles/CollectionReportStyled";
import { Status } from "../../../../../../models/HistorySales/Status.model";

interface ISelectStatus {
  handleChangeStatusFilter: (e: any) => void;
}

const SelectStatus = ({ handleChangeStatusFilter }: ISelectStatus) => (
  <SelectStatusStyled
    name="estado"
    className="status"
    id="estado"
    onChange={handleChangeStatusFilter}
  >
    <option value={0}>Todos</option>
    <option value={Status.PENDIENTE}>Pendientes</option>
    <option value={Status.PAGADO}>Pagados</option>
    <option value={Status.CANCELADO}>Cancelados</option>
    <option value={Status.ARREPENTIDO}>Arrepentidos</option>
  </SelectStatusStyled>
);

export default SelectStatus;
