import styled from "styled-components";

export const DialogStyled = styled.dialog`
  z-index: 1000;
  position: fixed;
  top: 50%;
  border: 1px solid black;
  box-shadow: 0 0 5px black;
  border-radius: 10px;
`;

export const TitleStyled = styled.h3`
  text-align: center;
  margin: 10px;
  padding: 10px;
`;

export const ContainerButtonsStyled = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;
