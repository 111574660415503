/* eslint-disable indent */
import React, { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
// Styles
import {
  FechaStyled,
  CompraStyled,
  PrecioStyled,
  EstatusStyled,
  CardSaleStyled,
  MedioPagoStyled,
  HeaderCardStyled,
  CantidadDeArticulosStyled,
} from "../../../../styles/CollectionReportStyled";
// Utills
import { cambiarPuntoPorComas } from "../../../../../../utils/priceFormater";
// Models
import { Data } from "../../../../../../models/HistorySales/Data.model";
import { Estado } from "../../../../../../models/HistorySales/Estado.model";
import { MedioDePago } from "../../../../../../models/HistorySales/MedioDePago.model";
// Components
import Button from "../../../../../../components/button/Button";
// Services
import useHttpRegisterAcceptPayment from "../../../../../../services/Sales/useHttpRegisterAcceptPayment";
import useHttpRegisterCancelPayment from "../../../../../../services/Sales/useHttpRegisterCancelPayment";
import useHttpGetTransferAttachments from "../../../../../../services/Transfers/Adjunto/useHttpGetTransferAttachments";
import Spinner from "../../../../../../components/spinner/Spinner";

interface ICardCollectionReport {
  sale: Data;
  idDetails: number;
  idVenta: number;
  setIdVenta: (value: number) => void;
  setShowModal: (value: boolean) => void;
  showDetails: boolean;
  handleShowDetails: (id: number) => void;
  articlesCounter: (sale: Data) => number;
  setStatus: (value: number) => void;
  setShowModalAttachments: (value: boolean) => void;
  showModalAttachment: boolean;
}

const CardCollectionReport = ({
  sale,
  setStatus,
  showModalAttachment,
  setIdVenta,
  setShowModal,
  handleShowDetails,
  setShowModalAttachments,
}: ICardCollectionReport) => {
  const { httpRegisterAcceptPaymentAsync } = useHttpRegisterAcceptPayment();
  const { httpRegisterCancelPaymentAsync } = useHttpRegisterCancelPayment();
  const { httpGetTransferAttachmentsAsync } = useHttpGetTransferAttachments();

  const dispatch = useAppDispatch();
  const [parent] = useAutoAnimate();

  const [numeroVenta, setNumeroVenta] = useState(0);

  const handleShowModal = () => {
    setIdVenta(sale.numero);
    setStatus(sale.estado === Estado.PAGADO ? 4 : 0);
    setShowModal(true);
  };

  const isLoadingAcceptPayment = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingRegisterAcceptPayment
  );

  const isLoadingCancelPayment = useAppSelector(
    (state) => state.isLoadingHistorySale.loadingRegisterCancelPayment
  );

  const handleAcceptPayment = () => {
    setNumeroVenta(sale.numero);
    dispatch(httpRegisterAcceptPaymentAsync(sale.numero));
  };
  const handleCancelPayment = () => {
    setNumeroVenta(sale.numero);
    dispatch(httpRegisterCancelPaymentAsync(sale.numero));
  };

  const handleGetAllTransferAttachments = () => {
    if (!showModalAttachment) {
      dispatch(
        httpGetTransferAttachmentsAsync(sale.numero, setShowModalAttachments)
      );
    }
  };

  return (
    <CardSaleStyled ref={parent} onClick={() => handleShowDetails(sale.numero)}>
      <HeaderCardStyled>
        <CompraStyled>Venta #{sale.numero}</CompraStyled>
        <EstatusStyled status={sale.estado}>{sale.estado}</EstatusStyled>
        <PrecioStyled>${cambiarPuntoPorComas(sale.precioTotal)}</PrecioStyled>
        <FechaStyled>Fecha: {sale.fechaCompra}</FechaStyled>
        <MedioPagoStyled>Medio de pago: {sale.medioPago}</MedioPagoStyled>
        {sale.cliente.nombre !== "" ? (
          <CantidadDeArticulosStyled>
            Cliente: {sale.cliente.nombre} {sale.cliente.apellido}
          </CantidadDeArticulosStyled>
        ) : (
          <CantidadDeArticulosStyled>
            Cliente: {sale.cliente.razonSocial}
          </CantidadDeArticulosStyled>
        )}
        <FechaStyled>
          Nro nota de pedido :{" "}
          {sale.numeroNotaPedidoVenta === 0 ? "-" : sale.numeroNotaPedidoVenta}
        </FechaStyled>
        {sale.estado === Estado.PAGADO ? (
          <Button
            text="Arrepentido"
            height="34px"
            backgroundColor="secondary"
            onClick={handleShowModal}
          />
        ) : (
          <div></div>
        )}
        {sale.estado === Estado.PENDIENTE &&
          sale.medioPago === MedioDePago.TRANSFERENCIA && (
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                text="Rechazar Pago"
                height="34px"
                onClick={handleCancelPayment}
                disabled={isLoadingCancelPayment && sale.numero === numeroVenta}
              />
              <Button
                text={isLoadingAcceptPayment ? <Spinner /> : "Aceptar Pago"}
                height="34px"
                backgroundColor="secondary"
                onClick={handleAcceptPayment}
                disabled={isLoadingAcceptPayment && sale.numero === numeroVenta}
              />
            </div>
          )}
        {sale.medioPago === MedioDePago.TRANSFERENCIA && (
          <Button
            text="Ver comprobante"
            height="34px"
            backgroundColor="secondary"
            onClick={handleGetAllTransferAttachments}
          />
        )}
      </HeaderCardStyled>
    </CardSaleStyled>
  );
};

export default CardCollectionReport;
