import styled from "styled-components";

interface IAdvanceConfigurationContainerStyled {
  show:boolean
}

export const BasicConfigurationContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AdvanceConfigurationContainerStyled = styled.div<IAdvanceConfigurationContainerStyled>`
  display: ${props => props.show ? "flex" : "none"};
  flex-direction: row;  
`;

export const ColumnRightContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center; 
`;

export const ColumnLeftContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const HomeCategoryContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  transform: scale(0.5);
  align-self: center;
  background-color: black;
  padding: 30px;
  margin: -150px -100px -100px -100px
`;

export const TitleEnabledAdvanceConfigurationStyled = styled.h3`
  margin: 50px 0px 10px 0px;
`;

export const CheckboxEnabledAdvanceConfigurationStyled = styled.input`
  
`;
