/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingEditDeffinition } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpUpdateSelectedProducts() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUpdateSelectedProductsAsync =
    (
      clientGroupId: number,
      differentiation: number,
      articleIds: number[],
      refreshTable?: boolean,
      setRefreshTable?: (value: boolean) => void,
      setDifferentiationsList?: (value: number[]) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingEditDeffinition(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.editDeffinitions}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              grupoClienteId: clientGroupId,
              diferenciacion: differentiation,
              articulosIds: articleIds,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        viewNotification(
          response.ok ? "Artículo editado correctamente" : "Error al editar",
          response.ok ? "success" : "danger"
        );
        if (response.ok) {
          setRefreshTable && setRefreshTable(!refreshTable);
          setDifferentiationsList && setDifferentiationsList([]);
        }
        dispatch(setLoadingEditDeffinition(false));
      } catch (error) {
        dispatch(setLoadingEditDeffinition(false));
      }
      dispatch(setLoadingEditDeffinition(false));
    };
  return {
    httpUpdateSelectedProductsAsync,
  };
}

export default useHttpUpdateSelectedProducts;
