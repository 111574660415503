import { useState, useEffect } from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import {
  HeaderStyled,
  ContainerButtonsStyled,
} from "../../../styles/MyProducts";
// Components
import ProgressBarProducts from "../../ProgressBarProducts";
import LastUnitsTable from "../../DataTables/MyProducts/LastUnitsTable";
import CustomerGroupTable from "../../DataTables/MyProducts/CustomerGroupTable";
import DefinitionOfPriceTable from "../../DataTables/MyProducts/DefinitionOfPriceTable";
import PublicationOfArticlesTable from "../../DataTables/MyProducts/PublicationOfArticlesTable";
// Services
import useHttpPublishedArticles from "../../../../../services/Account/MyProducts/useHttpPublishedArticles";
// Redux
import { useAppDispatch } from "../../../../../app/hooks";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Button from "../../../../../components/button/Button";

const Products = () => {
  const dispatch = useAppDispatch();
  const { httpPublishedArticlesAsync } = useHttpPublishedArticles();
  const [parent] = useAutoAnimate();

  const [progress, setProgress] = useState(0);
  const [groupSelected, setGroupSelected] = useState(1);

  useEffect(() => {
    if (groupSelected !== null) {
      dispatch(httpPublishedArticlesAsync(1, "", "", "", groupSelected, 10));
    }
  }, [groupSelected]);

  // Renderiza la tabla seleccionada en el progress bar
  const returnTable = () => {
    switch (progress) {
      case 0:
        return (
          <CustomerGroupTable
            setGroupSelected={setGroupSelected}
            groupSelected={groupSelected}
            setProgress={setProgress}
          />
        );
      case 1:
        return <DefinitionOfPriceTable clientGroupId={groupSelected} />;
      case 2:
        return <LastUnitsTable clientGroupId={groupSelected} />;
      case 3:
        return <PublicationOfArticlesTable clientGroupId={groupSelected} />;
      default:
        return (
          <CustomerGroupTable
            setGroupSelected={setGroupSelected}
            groupSelected={groupSelected}
            setProgress={setProgress}
          />
        );
    }
  };

  return (
    <ContainerStyled ref={parent}>
      <HeaderStyled>Artículos a publicar</HeaderStyled>
      <ProgressBarProducts progress={progress} setProgress={setProgress} />
      {returnTable()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Boton volver */}
        {groupSelected !== null && progress > 0 && (
          <ContainerButtonsStyled>
            <Button
              onClick={() => {
                setProgress(progress - 1);
              }}
              width="150px"
              height="40px"
              backgroundColor="secondary"
              text="Volver"
            />
          </ContainerButtonsStyled>
        )}
        {/* Boton continuar */}
        {groupSelected !== null && progress < 3 && (
          <ContainerButtonsStyled>
            <Button
              text="Continuar"
              width="150px"
              height="40px"
              backgroundColor="secondary"
              onClick={() => {
                setProgress(progress + 1);
              }}
            />
          </ContainerButtonsStyled>
        )}
      </div>
    </ContainerStyled>
  );
};

export default Products;
