import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingCategories } from "../../../../features/loading/categorizations/definitions/categories/loadingsReducer";
import { addCategories } from "../../../../features/categorization/definitions/categoriesReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpCategories() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpCategoriesAsync = (): AppThunk => async (dispatch) => {
    dispatch(setLoadingCategories(true));
    try {
      const response = await fetch(`${env.catalogo}${Routes.getCategories}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
          empresaId: empresaId.toString(),
        },
      });
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (data) {
        dispatch(addCategories(data));
      }
      dispatch(setLoadingCategories(false));
    } catch (error) {
      setLoadingCategories(false);
    }
    setLoadingCategories(false);
  };
  return {
    httpCategoriesAsync,
  };
}

export default useHttpCategories;
