/* eslint-disable @typescript-eslint/no-unused-vars */
// Este slice corresponde a los filtros que se muestran al usuario

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Errors } from "../../types/Errors";

const initialState: Errors = {
  httpErrors: [],
  errorsBuyConfirmation: {
    Price: [],
    Stock: [],
    Item: []
  }
};

export const ErrorsSlice = createSlice({
  name: "HttpErrors",
  initialState,
  reducers: {
    addHttpError: (state, action: PayloadAction<any>) => {
      state.httpErrors.unshift(action.payload);
      state.httpErrors = state.httpErrors.slice(0, 3);
    },
    setPriceErrorsBuyConfirmation: (state, action: PayloadAction<Array<string>>) => {
      state.errorsBuyConfirmation.Price = action.payload;
    },
    setStockErrorsBuyConfirmation: (state, action: PayloadAction<Array<string>>) => {
      state.errorsBuyConfirmation.Stock = action.payload;
    },
    setItemErrorsBuyConfirmation: (state, action: PayloadAction<Array<string>>) => {
      state.errorsBuyConfirmation.Item = action.payload;
    },
    resetErrorsBuyConfirmation: (state) => {
      state.errorsBuyConfirmation = {
        Price: [],
        Stock: [],
        Item: []
      };
    },
    resetErrors: (state) => {
      state = {
        httpErrors: [],
        errorsBuyConfirmation: {
          Price: [],
          Stock: [],
          Item: []
        }
      };
    },
  },
});

export const {
  addHttpError,
  setItemErrorsBuyConfirmation,
  setPriceErrorsBuyConfirmation,
  setStockErrorsBuyConfirmation,
  resetErrorsBuyConfirmation,
  resetErrors
} = ErrorsSlice.actions;

export default ErrorsSlice.reducer;
