/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingSingleUnpost } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpSingleUnpost() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpSingleUnpostAsync =
    (
      clientGroupId: number,
      articlesId: number[],
      setRefreshTable: (value: boolean) => void,
      refreshTable: boolean
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingSingleUnpost(true));
      try {
        const response = await fetch(`${env.catalogo}${Routes.singleUnpost}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            empresaId: empresaId.toString(),
          },
          body: JSON.stringify({
            grupoClienteId: clientGroupId,
            ArticulosIds: articlesId,
          }),
        });
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const message =
            articlesId.length > 1
              ? "Los articulos se han ocultado correctamente."
              : "El articulo se ha ocultado correctamente.";
          const type = "success";
          viewNotification(message, type);
          setRefreshTable && setRefreshTable(!refreshTable);
          dispatch(setLoadingSingleUnpost(false));
        } else {
          const message = "Error al editar";
          const type = "danger";
          viewNotification(message, type);
          dispatch(setLoadingSingleUnpost(false));
        }
      } catch (error) {
        dispatch(setLoadingSingleUnpost(false));
      }
    };
  return {
    httpSingleUnpostAsync,
  };
}

export default useHttpSingleUnpost;
