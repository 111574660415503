/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingSinglePost } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import { IErrorPublish } from "../../../DTOs/Errors/ErrorPublishDto";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpSinglePost() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );

  const { obtenerToken } = useLocalStorageHook();

  const httpSinglePostAsync =
    (
      clientGroupId: number,
      articlesId: number[],
      setRefreshTable: (value: boolean) => void,
      refreshTable: boolean
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingSinglePost(true));
      try {
        const response = await fetch(`${env.catalogo}${Routes.singlePost}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            empresaId: empresaId.toString(),
          },
          body: JSON.stringify({
            grupoClienteId: clientGroupId,
            ArticulosIds: articlesId,
          }),
        });
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          const message =
            articlesId.length > 1
              ? "Los articulos se han publicado correctamente."
              : "El articulo se ha publicado correctamente.";
          const type = "success";
          viewNotification(message, type);
          setRefreshTable && setRefreshTable(!refreshTable);
          dispatch(setLoadingSinglePost(false));
        } else if (response.status === 400) {
          const dataError: IErrorPublish = await response.json();
          if (Object.keys(dataError.EntityErrors).length > 0) {
            dataError.EntityErrors.Email &&
              viewNotification(dataError.EntityErrors.Email[0], "danger");
            dataError.EntityErrors.Telefono &&
              viewNotification(dataError.EntityErrors.Telefono[0], "danger");
            dataError.EntityErrors.Id &&
              viewNotification(dataError.EntityErrors.Id[0], "danger");
          }
        } else {
          viewNotification("Error al intentar publicar", "danger");
        }
        dispatch(setLoadingSinglePost(false));
      } catch (error) {
        dispatch(setLoadingSinglePost(false));
      }
    };
  return {
    httpSinglePostAsync,
  };
}

export default useHttpSinglePost;
