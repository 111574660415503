/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { viewNotification } from "../../../../utils/ViewNotification";
import { setLoadingDeleteSubcategory } from "../../../../features/loading/categorizations/definitions/subcategories/loadingsReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpDeleteSubcategory() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpDeleteSubcategoryAsync =
    (
      idSubcategory: number,
      refreshSubCategories: boolean,
      setRefreshSubCategories: (value: boolean) => void,
      setShowModalDeleteSubcategory: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingDeleteSubcategory(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.deleteSubcategory}/${idSubcategory}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          // si response ok, notification success
          viewNotification("Subcategoria eliminada correctamente", "success");
          setRefreshSubCategories(!refreshSubCategories);
          setShowModalDeleteSubcategory(false);
        } else {
          // si response not ok, notification error
          viewNotification("Error al eliminar subcategoria", "danger");
        }
        dispatch(setLoadingDeleteSubcategory(false));
      } catch (error) {
        setLoadingDeleteSubcategory(false);
      }
      setLoadingDeleteSubcategory(false);
    };
  return {
    httpDeleteSubcategoryAsync,
  };
}

export default useHttpDeleteSubcategory;
