import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;
// header
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const HeaderSectionStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingContainerStyled = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ImgContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;
  & img {
    margin-top: 10px;
  }
`;

export const UserNameStyled = styled.p`
  font-weight: bold;
  margin: 15px 0 0 15px;
`;

export const DateStyled = styled.span`
  margin: 0;
  padding: 0;
`;

export const TextStyled = styled.span`
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

// main

export const TextAreaStyled = styled.textarea`
  height: 152px;
  margin-bottom: 10px;
  width: 95%;
  border-radius: 8px;
  font-family: "Inter";
  padding: 15px;
  resize: none;

  .focus {
    /* no está funcionando por alguna razon */
    border: 1px ${(props) => props.theme.colors.border.seventh} solid;
  }
`;

export const MainStyled = styled.div`
  display: flex;
  height: 60%;
  flex-direction: column;
`;

// Footer
export const FooterStyled = styled.div`
  display: flex;
  justify-content: right;
  height: 10%;
`;

export const ButtonStyled = styled.button`
  width: 210px;
  height: 44px;
  background-color: ${(props) => props.theme.colors.main.tertiary};
  color: ${(props) => props.theme.colors.text.second};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
