/* eslint-disable indent */
import React from "react";
// Styles
import {
  FechaStyled,
  CompraStyled,
  PrecioStyled,
  EstatusStyled,
  CardSaleStyled,
  MedioPagoStyled,
  ShowDetailsStyled,
  ModalContainerStyled,
  ButtonsContainerStyled,
  HeaderHistoryCardStyled,
} from "../../../../styles/HistorySaleStyled";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Components
import Button from "../../../../../../components/button/Button";
import CardDetails from "../../Sales/components/CardHistorySale/CardDetails";
import ModalBase from "../../../../../../components/modalBase/ModalBase";
// Utills
import { cambiarPuntoPorComas } from "../../../../../../utils/priceFormater";
// Models
import { Data } from "../../../../../../models/HistorySales/Data.model";
import { Estado } from "../../../../../../models/HistorySales/Estado.model";
import { MedioDePago } from "../../../../../../models/HistorySales/MedioDePago.model";
// Hooks
import useCardShoppingHistoryHook from "../hooks/useCardShoppingHistoryHook";

interface ICardSale {
  sale: Data;
  setShowModalBoxComment: (value: boolean) => void;
}

const CardShoppingHistory = ({ sale, setShowModalBoxComment }: ICardSale) => {
  const [parent] = useAutoAnimate();
  const {
    idDetails,
    closeModal,
    showDetails,
    createNewCart,
    handleRepeatSale,
    checkCartStatus,
    handleShowDetails,
    showModalCartStatus,
    redirectToContinueShopping,
  } = useCardShoppingHistoryHook(sale);

  return (
    <CardSaleStyled ref={parent} onClick={() => handleShowDetails(sale.numero)}>
      <HeaderHistoryCardStyled>
        <CompraStyled>Compra #{sale.numero}</CompraStyled>
        <EstatusStyled status={sale.estado}>{sale.estado}</EstatusStyled>
        <PrecioStyled>${cambiarPuntoPorComas(sale.precioTotal)}</PrecioStyled>
        {showDetails && idDetails === sale.numero ? (
          <ShowDetailsStyled className="icon">-</ShowDetailsStyled>
        ) : (
          <ShowDetailsStyled className="icon">+</ShowDetailsStyled>
        )}
        <FechaStyled>Fecha: {sale.fechaCompra}</FechaStyled>
        <MedioPagoStyled>Medio de pago: {sale.medioPago}</MedioPagoStyled>
        {/* Repetir compra */}
        {sale.estado === Estado.PAGADO && (
          <Button
            text="Repetir compra"
            backgroundColor="secondary"
            onClick={checkCartStatus}
          />
        )}
        {/* Añadir comprobante */}
        {sale.estado === Estado.PENDIENTE &&
          sale.medioPago === MedioDePago.TRANSFERENCIA && (
            <Button
              text="Añadir comprobante"
              onClick={redirectToContinueShopping}
            />
          )}
      </HeaderHistoryCardStyled>
      {/* DETAILS */}
      {showDetails && idDetails === sale.numero && (
        <CardDetails
          sale={sale}
          setShowModalBoxComment={setShowModalBoxComment}
        />
      )}
      <ModalBase show={showModalCartStatus} onClose={closeModal} height={"25%"}>
        <ModalContainerStyled>
          <b style={{ textAlign: "center" }}>
            Actualmente existen productos en su carrito. ¿Cómo desea continuar?
          </b>
          <ButtonsContainerStyled>
            <Button
              text="Conservar productos actuales"
              backgroundColor="secondary"
              onClick={handleRepeatSale}
            />
            <Button text="Crear nuevo carrito" onClick={createNewCart} />
          </ButtonsContainerStyled>
        </ModalContainerStyled>
      </ModalBase>
    </CardSaleStyled>
  );
};

export default CardShoppingHistory;
