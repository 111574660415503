import React, { useState, useEffect, useRef } from "react";
import {
  ArrowIcon,
  BoxResults,
  TextOption,
  ListResults,
  OptionsStyled,
  WrapOptionSelected,
  InputSearchContainer,
} from "./DropdownStyled";
// Icon
import Arrow from "../../assets/icons/arrow.png";
import { useAutoAnimate } from "@formkit/auto-animate/react";

// importar tema

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  showDefaultString: string;
  options: Option[];
  onSelect: (option: Option) => void;
}

function Dropdown({ options, onSelect, showDefaultString }: DropdownProps) {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [parent] = useAutoAnimate();

  function handleOptionClick(option: Option) {
    setSelectedOption(option.label);
    setIsOpen(false);
    onSelect(option);
  }

  const openBox = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Cerrar el box cuando se haga click fuera de el
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <InputSearchContainer ref={boxRef}>
      <div ref={parent}>
        <WrapOptionSelected onClick={() => openBox()}>
          <TextOption>{selectedOption || showDefaultString} </TextOption>
          <ArrowIcon src={Arrow} isOpen={isOpen} />
        </WrapOptionSelected>
        {isOpen && (
          <BoxResults sizeWindow={windowWidth}>
            <ListResults>
              {options?.length === 0 && (
                <OptionsStyled>No hay resultados</OptionsStyled>
              )}
              {options?.map((categoria) => (
                <OptionsStyled
                  key={categoria.value}
                  onClick={() => handleOptionClick(categoria)}
                >
                  {categoria.label}
                </OptionsStyled>
              ))}
              <OptionsStyled
                onClick={() => handleOptionClick({ label: "Todas", value: "" })}
              >
                Todas
              </OptionsStyled>
            </ListResults>
          </BoxResults>
        )}
      </div>
    </InputSearchContainer>
  );
}

export default Dropdown;
