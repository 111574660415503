/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingArticles } from "../../../features/loading/categorizations/articles/loadingsReducer";
import {
  addArticles,
  addPagination,
} from "../../../features/categorization/articles/articlesReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpArticles() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpArticlesAsync =
    (
      Description: string,
      Code: string,
      Page: number,
      RecordsForPage: number
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingArticles(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.getArticles}?codigo=${Code}&descripcion=${Description}&Page=${Page}&RecordsForPage=${RecordsForPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data.data) {
          dispatch(addArticles(data.data));
        }
        if (data.pagination) {
          dispatch(addPagination(data.pagination));
        }
        dispatch(setLoadingArticles(false));
      } catch (error) {
        dispatch(setLoadingArticles(false));
      }
    };
  return {
    httpArticlesAsync,
  };
}

export default useHttpArticles;
