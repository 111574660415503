import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingPublishedArticles: false,
  loadingEditDeffinition: false,
  loadingEditAllDifferentiation: false,
  loadingEditAllLastUnits: false,
  loadingPublishAllArticles: false,
  loadingUnpublishAllArticles: false,
  loadingSinglePost: false,
  loadingSingleUnpost: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingPublishedArticles: (state, action: PayloadAction<boolean>) => {
      state.loadingPublishedArticles = action.payload;
    },
    setLoadingEditDeffinition: (state, action: PayloadAction<boolean>) => {
      state.loadingEditDeffinition = action.payload;
    },
    setLoadingEditAllDifferentiation: (state, action: PayloadAction<boolean>) => {
      state.loadingEditAllDifferentiation = action.payload;
    },
    setLoadingEditAllLastUnits: (state, action: PayloadAction<boolean>) => {
      state.loadingEditAllLastUnits = action.payload;
    },
    setLoadingPublishAllArticles: (state, action: PayloadAction<boolean>) => {
      state.loadingPublishAllArticles = action.payload;
    },
    setLoadingUnpublishAllArticles: (state, action: PayloadAction<boolean>) => {
      state.loadingUnpublishAllArticles = action.payload;
    },
    setLoadingSinglePost: (state, action: PayloadAction<boolean>) => {
      state.loadingSinglePost = action.payload;
    },
    setLoadingSingleUnpost: (state, action: PayloadAction<boolean>) => {
      state.loadingSingleUnpost = action.payload;
    }
  },
});

export const {
  setLoadingEditDeffinition,
  setLoadingEditAllLastUnits,
  setLoadingPublishedArticles,
  setLoadingEditAllDifferentiation,
  setLoadingPublishAllArticles,
  setLoadingUnpublishAllArticles,
  setLoadingSinglePost,
  setLoadingSingleUnpost
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
