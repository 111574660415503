import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { resetBasket } from "../../features/buy_Order/buy_OrderReducer";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
import { viewNotification } from "../../utils/ViewNotification";

function useHttpQuitCart() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpQuitCartAsync = (): AppThunk => async (dispatch) => {
    const token = obtenerToken();
    // quitar comillas del token
    const tokenAccess = token?.replace(/['"]+/g, "");
    try {
      const response = await fetch(`${env.operaciones}${Routes.quitCart}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          empresaId: empresaId.toString(),
        },
      });
      validateUnauthorized(response, empresaId);
      if (response.status === 200) {
        // Limpiar redux
        dispatch(resetBasket());
      }
    } catch (error) {
      viewNotification("Error al quitar el carrito", "danger");
    }
  };
  return {
    httpQuitCartAsync,
  };
}

export default useHttpQuitCart;
