/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setLoadingRegisterCancelPayment } from "../../features/loading/historySale/loadingsReducer";
import { setCollectionReportCancel } from "../../features/salesHistory/salesHistoryReducer";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpRegisterCancelPayment = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpRegisterCancelPaymentAsync =
    (ordenCompraId: number): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingRegisterCancelPayment(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.registerCancelPayment}?ordenCompraId=${ordenCompraId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          viewNotification("Pago cancelado", "success");
          dispatch(setCollectionReportCancel(ordenCompraId));
        } else {
          viewNotification("Error, intente nuevamente", "danger");
        }
        dispatch(setLoadingRegisterCancelPayment(false));
      } catch (error) {
        viewNotification("Error, intente nuevamente", "danger");
        dispatch(setLoadingRegisterCancelPayment(false));
      }
    };
  return { httpRegisterCancelPaymentAsync };
};

export default useHttpRegisterCancelPayment;
