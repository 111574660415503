import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICart {
  itemsCarrito: any[];
}

const initial : ICart = {
  itemsCarrito: []
};

export const ArticlesSlice: any = createSlice({
  name: "articles",
  initialState: initial,
  reducers: {
    addCart: (state, action: PayloadAction<any>) => {
      // añadir el items al state
      state.itemsCarrito = [action.payload];
    },
  },
});

export const { addCart } = ArticlesSlice.actions;

export default ArticlesSlice.reducer;
