import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setDataTransfer } from "../../../features/dataTransfer/dataTransferReducer";
import { setLoadingDataTransfer } from "../../../features/loading/transfer/loadingReducer";
import { DataTransfer } from "../../../models/DataTransfer/DataTransfer.model";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

const useHttpGetDataTransfer = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetDataTransferAsync = (): AppThunk => async (dispatch: any) => {
    // Token
    const token = obtenerToken();
    const tokenAccess = token?.replace(/['"]+/g, "");
    dispatch(setLoadingDataTransfer(true));
    try {
      const response = await fetch(
        `${env.operaciones}${Routes.getDataTransfer}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenAccess}`,
            empresaId: empresaId.toString(),
          },
        }
      );
      validateUnauthorized(response, empresaId);
      const data: DataTransfer[] = await response.json();

      if (data) {
        // Guardar en el store
        dispatch(setDataTransfer(data[0]));
      } else {
        viewNotification("Error al cargar datos de transferencia", "info");
      }
      dispatch(setLoadingDataTransfer(false));
    } catch (error) {
      viewNotification("Error al cargar datos de transferencia", "warning");
      dispatch(setLoadingDataTransfer(false));
    }
  };
  return { httpGetDataTransferAsync };
};

export default useHttpGetDataTransfer;
