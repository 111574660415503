/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import { viewNotification } from "../../../utils/ViewNotification";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setLoadingEditSubcategory } from "../../../features/loading/categorizations/articles/loadingsReducer";
import {
  editSubcategoryRedux,
  deleteSubcategoryRedux,
} from "../../../features/categorization/articles/articlesReducer";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpEditSubcategory() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpEditSubcategoryAsync =
    (
      articleId: number,
      subCategoryId: number | null,
      setShowConfirmationModal: (value: boolean) => void,
      subcategoryName: string | null
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingEditSubcategory(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.editSubcategory}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              articuloId: articleId,
              subCategoriaId: subCategoryId,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          if (!subCategoryId) {
            viewNotification("Subcategoria eliminada correctamente", "success");
            dispatch(deleteSubcategoryRedux(articleId));
          } else {
            viewNotification("Subcategoria editada correctamente", "success");
            // funcion de editar subcategoria en redux
            dispatch(
              editSubcategoryRedux({
                articleId,
                subCategoryId,
                subcategoryName,
              })
            );
          }
          // cerrar modal
          setShowConfirmationModal(false);
        }
        const data = await response.json();
        if (data.status === 400) {
          viewNotification("Error al editar subcategoria", "danger");
        }
        // cerrar modal
        dispatch(setLoadingEditSubcategory(false));
        setShowConfirmationModal(false);
      } catch (error) {
        dispatch(setLoadingEditSubcategory(false));
      }
      dispatch(setLoadingEditSubcategory(false));
    };
  return {
    httpEditSubcategoryAsync,
  };
}

export default useHttpEditSubcategory;
