/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { addValues } from "../../../features/categorization/articles/valuesReducer";
import { setLoadingGetValues } from "../../../features/loading/categorizations/articles/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpValues() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpValuesAsync =
    (atributeId: number): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingGetValues(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.getValues}?atributoId=${atributeId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();
        if (data) {
          dispatch(addValues(data));
        }
        dispatch(setLoadingGetValues(false));
      } catch (error) {
        dispatch(setLoadingGetValues(false));
      }
      dispatch(setLoadingGetValues(false));
    };
  return {
    httpValuesAsync,
  };
}

export default useHttpValues;
