/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { setLoadingUpdateStatusOrder } from "../../features/loading/historySale/loadingsReducer";
import { setSalesHistoryCancel } from "../../features/salesHistory/salesHistoryReducer";
import { Status } from "../../models/HistorySales/Status.model";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpUpdateStatusOrder = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUpdateStatusOrderAsync =
    (
      ordenId: number,
      estadoPago: Status,
      showModal: boolean,
      setShowModal: (value: boolean) => void
    ): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingUpdateStatusOrder(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.changeStaturOrder}?ordenId=${ordenId}&estadoPago=${estadoPago}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          dispatch(setSalesHistoryCancel(ordenId));
          viewNotification("Estado actualizado correctamente", "success");
          setShowModal(!showModal);
        } else {
          viewNotification("Error, intente nuevamente", "danger");
        }
        dispatch(setLoadingUpdateStatusOrder(false));
      } catch (error) {
        viewNotification("Error, intente nuevamente", "danger");
        dispatch(setLoadingUpdateStatusOrder(false));
      }
    };
  return { httpUpdateStatusOrderAsync };
};

export default useHttpUpdateStatusOrder;
