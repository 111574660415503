import React from "react";
import { ModalCategoryContainerStyled } from "../../styles/ModalConfirmation";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import httpDeleteSubcategory from "../../../../services/Categorization/Definitions/Subcategories/useHttpDeleteSubcategory";
import Spinner from "../../../../components/spinner/Spinner";
import Button from "../../../../components/button/Button";

interface IModal {
  idSubcategory: number;
  showModalDeleteSubcategory: boolean;
  setShowModalDeleteSubcategory: (value: boolean) => void;
  refreshSubCategories: boolean;
  setRefreshSubCategories: (value: boolean) => void;
  positionY?: number;
}

const ModalConfirmationCategory = ({
  idSubcategory,
  refreshSubCategories,
  setRefreshSubCategories,
  setShowModalDeleteSubcategory,
  positionY,
}: IModal) => {
  const dispatch = useAppDispatch();
  const { httpDeleteSubcategoryAsync } = httpDeleteSubcategory();
  const isLoadingDeleteSubcategory = useAppSelector(
    (state) => state.isLoadingSubcategories.loadingDeleteSubcategory
  );
  const validateDelete = useAppSelector(
    (state) => state.relatedArticles as number
  );
  const isLoadingValidateDelete = useAppSelector(
    (state) => state.isLoadingSubcategories.loadingValidateDeleteSubcategory
  );

  const handleDeleteSubcategory = () => {
    dispatch(
      httpDeleteSubcategoryAsync(
        idSubcategory,
        refreshSubCategories,
        setRefreshSubCategories,
        setShowModalDeleteSubcategory
      )
    );
  };

  const positionYModal =
    positionY && positionY > 1000 ? Number(positionY) + 300 : positionY;

  return (
    <ModalCategoryContainerStyled positionY={positionYModal}>
      {isLoadingValidateDelete ? (
        <Spinner />
      ) : (
        <>
          <b>¿Desea eliminar la subcategoría relacionada en esta categoría?</b>
          {validateDelete !== 0 && (
            <p style={{ color: "red", margin: 0 }}>
              Esta categoría tiene {validateDelete} artículos relacionados
            </p>
          )}
          <div style={{ display: "flex", gap: "30px" }}>
            <Button
              text="Cancelar"
              onClick={() => setShowModalDeleteSubcategory(false)}
            />
            {isLoadingDeleteSubcategory ? (
              <Spinner />
            ) : (
              <Button
                text="Eliminar"
                backgroundColor="secondary"
                onClick={handleDeleteSubcategory}
              />
            )}
          </div>
        </>
      )}
    </ModalCategoryContainerStyled>
  );
};

export default ModalConfirmationCategory;
