import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { setValidateConfigPaymentGateway } from "../../features/paymentGateway/paymentGatewayReducer";
import { setLoadingValidatePaymentGateway } from "../../features/loading/configPaymentGateway/loadingsReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpValidateConfigMP = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpValidateConfigMPAsync = (): AppThunk => async (dispatch: any) => {
    // Token
    const token = obtenerToken();
    const tokenAccess = token?.replace(/['"]+/g, "");
    dispatch(setLoadingValidatePaymentGateway(true));
    try {
      const response = await fetch(
        `${env.operaciones}${Routes.ConfigValidationMP}?pasarelaPago=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenAccess}`,
            empresaId: empresaId.toString(),
          },
        }
      );
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (response.status === 200) {
        dispatch(setValidateConfigPaymentGateway(data));
        dispatch(setLoadingValidatePaymentGateway(false));
      } else {
        viewNotification("Error", "info");
        dispatch(setLoadingValidatePaymentGateway(false));
      }
    } catch (error) {
      viewNotification("Error, intente nuevamente", "warning");
      dispatch(setLoadingValidatePaymentGateway(false));
    }
  };
  return { httpValidateConfigMPAsync };
};

export default useHttpValidateConfigMP;
