import { ActiveFilters } from "../types/ActiveFilters";

export const buildParams = (search: string, data: ActiveFilters) => {
  const params = new URLSearchParams();

  if (search && search !== "") {
    params.append("Busqueda", search);
  }
  if (data.marca) {
    data.marca.map(item => params.append("marca", item));
  }
  if (data.modelo && data.modelo !== "") {
    params.append("modelo", data.modelo);
  }
  if (data.ano && data.ano !== 0) {
    params.append("ano", data.ano.toString());
  }
  if (data.vin && data.vin !== "") {
    params.append("vin", data.vin);
  }
  if (data.mostrar && data.mostrar !== "TODO") {
    params.append("mostrar", data.mostrar);
  }
  if (data.ubicacion) {
    data.ubicacion.map(item => params.append("ubicacion", item));
  }
  if (data.precioMaximo && data.precioMaximo !== 0) {
    params.append("precioMaximo", data.precioMaximo.toString());
  }
  if (data.precioMinimo && data.precioMinimo !== 0) {
    params.append("precioMinimo", data.precioMinimo.toString());
  }
  if (data.categoria && data.categoria !== "") {
    params.append("categoria", data.categoria);
  }
  if (data.subcategoria && data.subcategoria !== "") {
    params.append("subcategoria", data.subcategoria);
  }
  if (data.caracteristicas) {
    data.caracteristicas.map(item => params.append("caracteristicas", item));
  }

  return params;
};
