import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonVinculateStyled = styled.button`
  background-color: #009ee3;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const MessageStyled = styled.p`
  color: green;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  text-align: center;
`;

export const CardSquareStyled = styled.div`
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-bottom: 3rem;
`;

export const CenterTextStyled = styled.p`
  text-align: center;
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
