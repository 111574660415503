/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { setClientGroup } from "../../features/appConfiguration/clientGroupReducer";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpClientGroups() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpClientGroupsAsync = (): AppThunk => async (dispatch) => {
    try {
      const response = await fetch(`${env.seguridad}${Routes.clientGroup}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
          empresaId: empresaId.toString(),
        },
      });
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (data && data.length > 0) {
        dispatch(setClientGroup(data));
      }
    } catch (error) {
      viewNotification("Error al obtener los grupos de clientes", "danger");
    }
  };
  return {
    httpClientGroupsAsync,
  };
}

export default useHttpClientGroups;
