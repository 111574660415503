import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initial = 0;

export const RelatedArticlesBySubcategoriesSlice: any = createSlice({
  name: "RelatedArticlesBySubcategory",
  initialState: initial,
  reducers: {
    addRelatedArticles: (state, action: PayloadAction<number>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { addRelatedArticles } =
  RelatedArticlesBySubcategoriesSlice.actions;

export default RelatedArticlesBySubcategoriesSlice.reducer;
