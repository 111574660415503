import styled from "styled-components";
import { MEDIADEFAULT } from "../../../../../styles/global";

export const BannerContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  position: relative;
`;

export const BannerImgStyled = styled.img`
  width: 100%;
  border-radius: 25px;
`;

export const SearchContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 5px 20px 0px;
  background-color: white;
  max-width: 824px;
  height: 120px;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  bottom: 100px;
  left: 0;
  right: 0;
  border: 1px solid #e5e5e5;
  /* -webkit-box-shadow: 0px 6px 25px 6px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 6px 25px 6px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 6px 25px 6px rgba(0, 0, 0, 0.13); */

  @media ${MEDIADEFAULT.desktop} {
    position: static;
    margin: 30px auto;
    width: 80%;
  }
`;

export const LabelStyled = styled.label`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const InputsContainerStyled = styled.div`
  display: grid;
  /* grid-template-columns: 20% 20% 20% 20% 15%; */
  grid-template-columns: 50% 20%;
  justify-content: center;
  /* grid-gap: 10px; */
  grid-gap: 30px;

  @media ${MEDIADEFAULT.desktop} {
    grid-template-row: 50% 50%;
  }
`;

interface InputStyledProps {
  error?: boolean;
}

export const InputStyled = styled.input<InputStyledProps>`
  // border: 1px solid #c4c4c4;
  border: ${(props) =>
    props.error ? "2px solid #fc5555" : "1px solid #c4c4c4"};
  border-radius: 10px;
  height: 42px;
  padding: 0 8px;
  /* width: 87%; */
  width: 100%;
`;

export const ButtonStyled = styled.button`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text.second};
  background-color: ${(props) => props.theme.colors.main.secondary};
  border: none;
  border-radius: 8px;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.main.primary};
  }
`;

export const IconStyled = styled.img`
  width: 24px;
  margin-left: 1%;
`;

export const ErrorStyled = styled.p`
  color: red;
  font-size: 12px;
  margin-top: -10px;
`;

export const EditButtonStyled = styled.button`
  margin-top: 60px;
  background-color: #44ff00;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  position: absolute;
  top: -20px;
  right: 70px;
  z-index: 1;
  transition: all 0.3s;

  &:hover {
    width: 40px;
    height: 40px;
  }
`;

export const EditIconStyled = styled.img`
  width: 15px;
`;
