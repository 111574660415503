import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initial: string[] = [];

export const ValuesSlice: any = createSlice({
  name: "valuesDefinitions",
  initialState: initial,
  reducers: {
    addValues: (state, action: PayloadAction<string[]>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { addValues } = ValuesSlice.actions;

export default ValuesSlice.reducer;
