/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { setAppConfiguration } from "../../features/appConfiguration/appConfigurationReducer";
import { setLoadingInitialConfiguration } from "../../features/loading/loadingReducer";
import { AppConfigurationDto } from "../../DTOs/AppConfigurationDto";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpLoadAppConfiguration() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpLoadAppConfigurationAsync =
    (aliasEmpresa: string): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingInitialConfiguration(true));
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.appConfiguration}/${aliasEmpresa}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data: AppConfigurationDto = await response.json();
        if (data && data.empresaId) {
          dispatch(setAppConfiguration(data));
        }
        dispatch(setLoadingInitialConfiguration(false));
      } catch (error) {
        dispatch(setLoadingInitialConfiguration(false));
      }
      dispatch(setLoadingInitialConfiguration(false));
    };
  return {
    httpLoadAppConfigurationAsync,
  };
}

export default useHttpLoadAppConfiguration;
