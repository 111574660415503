import { useState } from "react";
import { routesApp } from "../../../../../../RoutesApp";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { getToken } from "../../../../../../utils/helper";
// Actions
import { setOrderId } from "../../../../../../features/order/orderReducer";
import { resetBasket } from "../../../../../../features/buy_Order/buy_OrderReducer";
// Services
import useHttpAddProductToCart from "../../../../../../services/Cart/useHttpAddProductToCart";
import useHttpCart from "../../../../../../services/Cart/useHttpCart";
import useHttpQuitCart from "../../../../../../services/Cart/useHttpQuitCart";
import useLocalStorageHook from "../../../../../../utils/useLocalStorageHook";
// Models
import { Data } from "../../../../../../models/HistorySales/Data.model";
import { Item } from "../../../../../../models/Item";

const useCardShoppingHistoryHook = (sale: Data) => {
  const { obtenerToken, removerItemsCarrito } = useLocalStorageHook();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { aliasEmpresa } = useParams();

  const [idDetails, setIdDetails] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [showModalCartStatus, setShowModalCartStatus] = useState(false);

  const { httpAddProductToCartAsync } = useHttpAddProductToCart();
  const { httpQuitCartAsync } = useHttpQuitCart();
  const { httpCartAsync } = useHttpCart();

  const cartRedux = useAppSelector(
    (state) => state.currentBuyOrder.ordenDeCompra.carritoDeCompras.items
  );

  // Mostrar detalles
  const handleShowDetails = (id: number) => {
    setShowDetails(!showDetails);
    setIdDetails(id);
  };

  // Redirigir a continuar compra
  const redirectToContinueShopping = () => {
    dispatch(setOrderId(sale.numero));
    navigate(`/${aliasEmpresa}${routesApp.transfer}`);
  };

  // Repetir compra
  const handleRepeatSale = () => {
    closeModal();
    const articles = sale.articuloDetalle.map((article) => {
      let newCantidad = article.cantidad;
      const itemIndex = cartRedux.findIndex(
        (element: Item) => Number(element.articulo?.id) === article.id
      );

      if (itemIndex !== -1 && cartRedux[itemIndex]) {
        newCantidad = article.cantidad + cartRedux[itemIndex]?.cantidad;
      }
      return {
        articuloId: article.id,
        cantidad: newCantidad,
      };
    });
    dispatch(httpAddProductToCartAsync(articles)).then((success) => {
      if (success) {
        navigate(`/${aliasEmpresa}${routesApp.cart}`);
        dispatch(httpCartAsync());
      }
    });
  };

  const closeModal = () => {
    setShowModalCartStatus(false);
  };

  const checkCartStatus = () => {
    if (cartRedux.length > 0) {
      setShowModalCartStatus(true);
      return;
    }
    handleRepeatSale();
  };

  const createNewCart = () => {
    handleQuitCart();
    handleRepeatSale();
  };

  const handleQuitCart = () => {
    if (getToken(obtenerToken) !== "") {
      dispatch(httpQuitCartAsync());
    } else {
      dispatch(resetBasket());
    }
    removerItemsCarrito();
  };

  return {
    idDetails,
    closeModal,
    showDetails,
    createNewCart,
    checkCartStatus,
    handleRepeatSale,
    handleShowDetails,
    showModalCartStatus,
    redirectToContinueShopping,
  };
};

export default useCardShoppingHistoryHook;
