import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { addCondicionesIva } from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import { ITipocondicionesIva } from "../../models/BuyFormModels/TipoCondicionesIva";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
import { viewNotification } from "../../utils/ViewNotification";

function useHttpObtenerCondicionesIva() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();
  const httpObtenerCondicionesIvaAsync =
    (): AppThunk => async (dispatch: any) => {
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.obtenerCondicionesIva}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data: ITipocondicionesIva[] = await response.json();
        if (response.status === 200) {
          dispatch(addCondicionesIva(data));
        }
      } catch (error) {
        viewNotification("Error al obtener las condiciones de iva", "danger");
      }
    };
  return {
    httpObtenerCondicionesIvaAsync,
  };
}

export default useHttpObtenerCondicionesIva;
