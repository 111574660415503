import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProvincias } from "../../models/BuyFormModels/Provincias";
import { ITiposPersonas } from "../../models/BuyFormModels/TiposPersona";
import { ITiposInmuebles } from "../../models/BuyFormModels/TiposInmuebles";
import { ITipocondicionesIva } from "../../models/BuyFormModels/TipoCondicionesIva";
import { ILocalidades } from "../../models/BuyFormModels/Localidades";
import { DatosSocioDeNegocio } from "../../pages/PurchaseForm/models/DatosSocioDeNegocio.model";

const initialState = {
  provincias: [] as IProvincias[],
  localidades: [] as ILocalidades[],
  resultadosTotales: 0,
  tiposPersonas: [] as ITiposPersonas[],
  tiposInmuebles: [] as ITiposInmuebles[],
  condicionesIva: [] as ITipocondicionesIva[],
  /// /////////////
  isRequired: [
    { name: "piso", value: true },
    { name: "calle", value: true },
    { name: "numero", value: true },
    { name: "nombre", value: true },
    { name: "apellido", value: true },
    { name: "documento", value: true },
    { name: "numeroTel", value: true },
    { name: "localidadId", value: true },
    { name: "provinciaId", value: true },
    { name: "razonSocial", value: true },
    { name: "prefijoPais", value: true },
    { name: "departamento", value: true },
    { name: "tipoPersonaId", value: true },
    { name: "barrioNombre", value: false },
    { name: "tipoInmuebleId", value: true },
    { name: "condicionIvaId", value: true },
    { name: "direccionEmail", value: true },
    { name: "tipoTelefonoId", value: true },
    { name: "tipoDocumentoId", value: true },
    { name: "prefijoLocalidad", value: true },
    { name: "identificacionTributaria", value: true },
    { name: "tipoIdentificacionTributariaId", value: true },
  ],
  datosSocioNegocio: {} as DatosSocioDeNegocio,
  sociosVinculadosAEmail: [] as DatosSocioDeNegocio[],
  documentoUtilizado: false,
  existenOperacionesPendientes: false,
};

export const partnerBusinessFormSlice = createSlice({
  name: "PartnerBusinessForm",
  initialState,
  reducers: {
    // añadir tiposPersonas
    addTiposPersonas: (state, action: PayloadAction<ITiposPersonas[]>) => {
      state.tiposPersonas = action.payload;
    },
    // añadir tiposInmuebles
    addTiposInmuebles: (state, action: PayloadAction<ITiposInmuebles[]>) => {
      state.tiposInmuebles = action.payload;
    },
    // añadir provincias
    addProvincias: (state, action: PayloadAction<IProvincias[]>) => {
      state.provincias = action.payload;
    },
    // añadir localidades
    addLocalidades: (state, action: PayloadAction<ILocalidades[]>) => {
      state.localidades = [...state.localidades, ...action.payload];
    },
    clearLocalidades: (state) => {
      state.localidades = [];
    },
    addResultadosTotales: (state, action: PayloadAction<number>) => {
      state.resultadosTotales = action.payload;
    },
    // añadir condiciones iva
    addCondicionesIva: (
      state,
      action: PayloadAction<ITipocondicionesIva[]>
    ) => {
      state.condicionesIva = action.payload;
    },
    // establecer que un x campo debe completarse, es dinamico según la selección del usuario
    // en el formulario
    setIsRequired: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.isRequired = state.isRequired.map((item) => {
        if (item.name === action.payload.name) {
          return { ...item, value: action.payload.value };
        }
        return item;
      });
    },
    setDatosSocioNegocio: (
      state,
      action: PayloadAction<DatosSocioDeNegocio>
    ) => {
      state.datosSocioNegocio = action.payload;
    },
    setEmailFormularioDeSocioNegocio: (
      state,
      action: PayloadAction<string>
    ) => {
      state.datosSocioNegocio.email = action.payload;
    },
    cleanDatosSocioNegocio: (state) => {
      state.datosSocioNegocio.condicionIvaId = "";
      state.datosSocioNegocio.domicilios = [];
      state.datosSocioNegocio.empresaID = null;
      state.datosSocioNegocio.numeroDocumento = "";
      state.datosSocioNegocio.numeroIdenticacionTributaria = "";
      state.datosSocioNegocio.socioNegocioId = null;
      state.datosSocioNegocio.proveedorAutenticacion = null;
      state.datosSocioNegocio.proveedorAutenticacionID = null;
      state.datosSocioNegocio.rolUsuario = null;
      state.datosSocioNegocio.rolUsuarioID = null;
      state.datosSocioNegocio.telefonoFijo = "";
      state.datosSocioNegocio.telefonoMovil = "";
      state.datosSocioNegocio.tipoCliente = null;
      state.datosSocioNegocio.tipoClienteID = 0;
      state.datosSocioNegocio.tipoDocumentoID = null;
      state.datosSocioNegocio.tipoIdentificacionTributariaId = null;
      state.datosSocioNegocio.tipoPersonaId = null;
      state.datosSocioNegocio.razonSocial = null;
    },
    setDocumentoUtilizado: (state, action: PayloadAction<boolean>) => {
      state.documentoUtilizado = action.payload;
    },
    setSociosVinculadosAEmail: (
      state,
      action: PayloadAction<DatosSocioDeNegocio[]>
    ) => {
      state.sociosVinculadosAEmail = action.payload;
    },
    cleanSociosVinculadosAEmail: (state) => {
      state.sociosVinculadosAEmail = [];
    },
    setExistenOperacionesPendientes: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.existenOperacionesPendientes = action.payload;
    },
  },
});

export const {
  setIsRequired,
  addProvincias,
  addLocalidades,
  clearLocalidades,
  addTiposPersonas,
  addTiposInmuebles,
  addCondicionesIva,
  setDatosSocioNegocio,
  cleanSociosVinculadosAEmail,
  addResultadosTotales,
  setDocumentoUtilizado,
  cleanDatosSocioNegocio,
  setSociosVinculadosAEmail,
  setExistenOperacionesPendientes,
  setEmailFormularioDeSocioNegocio,
} = partnerBusinessFormSlice.actions;

export default partnerBusinessFormSlice.reducer;
