import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Articulo } from "../../../models/Articles/Articulo.model";

interface IArticles {
  articles: Articulo[];
  pagination: {
    page: number;
    recordsForPage: number;
    totalAllRecords: number;
  };
}

const initial: IArticles = {
  articles: [],
  pagination: {
    page: 1,
    recordsForPage: 10,
    totalAllRecords: 0,
  },
};

export const ArticlesSlice: any = createSlice({
  name: "articles",
  initialState: initial,
  reducers: {
    addArticles: (state, action: PayloadAction<Articulo[]>) => {
      state.articles = action.payload;
    },
    addPagination: (state, action: PayloadAction<any>) => {
      state.pagination = action.payload;
    },
    // Accion de edicion
    editSubcategoryRedux: (state, action: PayloadAction<{articleId: number, subCategoryId: number, subcategoryName: string}>) => {
      const index = state.articles.findIndex(article => article.id === action.payload.articleId);
      if (index !== -1) {
        state.articles[index].subCategoriaId = action.payload.subCategoryId;
        state.articles[index].subCategoria = action.payload.subcategoryName;
      }
    },
    deleteSubcategoryRedux: (state, action: PayloadAction<number>) => {
      const index = state.articles.findIndex(article => article.id === action.payload);
      if (index !== -1) {
        state.articles[index].subCategoriaId = null;
        state.articles[index].subCategoria = null;
      }
    },
  },
});

export const { addArticles, addPagination, editSubcategoryRedux, deleteSubcategoryRedux } = ArticlesSlice.actions;

export default ArticlesSlice.reducer;
