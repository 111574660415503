import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
// Styles
import { ModalContainerStyled } from "../../styles/ModalConfirmation";
// Components
import Button from "../../../../components/button/Button";
import Spinner from "../../../../components/spinner/Spinner";
// Services
import httpEditSubcategory from "../../../../services/Categorization/Articles/useHttpEditSubcategory";

interface IModal {
  setShowConfirmationModal: (value: boolean) => void;
  idArticle: number;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
}

const ModalConfirmation = ({ setShowConfirmationModal, idArticle }: IModal) => {
  const dispatch = useAppDispatch();
  const isLoadingEditSubcategory = useAppSelector(
    (state) => state.isLoadingArticles.loadingEditSubcategory
  );
  const { httpEditSubcategoryAsync } = httpEditSubcategory();

  const handleDeleteSubcategory = () => {
    dispatch(
      httpEditSubcategoryAsync(idArticle, null, setShowConfirmationModal, null)
    );
  };

  return (
    <ModalContainerStyled>
      <b>¿Deseas eliminar la subcategoría de este artículo?</b>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button
          text="Cancelar"
          onClick={() => setShowConfirmationModal(false)}
        />
        {isLoadingEditSubcategory ? (
          <Spinner />
        ) : (
          <Button
            text="Eliminar"
            backgroundColor="secondary"
            onClick={handleDeleteSubcategory}
          />
        )}
      </div>
    </ModalContainerStyled>
  );
};

export default ModalConfirmation;
