import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IArticleAttributes {
    id: number;
    descripcion: string;
    valor: string;
}

const initial: IArticleAttributes[] = [];

export const AttributesInTheArticleSlice: any = createSlice({
  name: "articleAttributes",
  initialState: initial,
  reducers: {
    addArticleAttributes: (state, action: PayloadAction<IArticleAttributes[]>) => {
      state = action.payload;
      return state;
    },
    addAttribute: (state, action: PayloadAction<IArticleAttributes>) => {
      const existingAttribute = state.find((attr) => attr.id === action.payload.id);
      if (existingAttribute) {
        existingAttribute.descripcion = action.payload.descripcion;
        existingAttribute.valor = action.payload.valor;
      } else {
        state.push(action.payload);
      }
    },
    deleteAttribute: (state, action: PayloadAction<number>) => {
      state.forEach((attr, index) => {
        if (attr.id === action.payload) {
          state.splice(index, 1);
        }
      });
    },
  },
});

export const { addArticleAttributes, addAttribute, deleteAttribute } = AttributesInTheArticleSlice.actions;

export default AttributesInTheArticleSlice.reducer;
