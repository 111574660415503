import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../app/hooks";
// Styles
import { InputEditStyled } from "../../../../styles/MyProducts";
import { ModalContainerStyled } from "../../../../styles/ModalConfirmation";
// Models
import { DataPublishedArticles } from "..//models/DataPublishedArticles.models";
// Services
import useHttpUpdateAllDifferentiation from "../../../../../../services/Account/MyProducts/useHttpUpdateAllDifferentiation";
// Components
import Button from "../../../../../../components/button/Button";
import Spinner from "../../../../../../components/spinner/Spinner";
import { viewNotification } from "../../../../../../utils/ViewNotification";

interface IModal {
  setShowModal: (value: boolean) => void;
  positionY: number;
  clientGroupId: number;
  refreshTable?: boolean;
  setRefreshTable?: (value: boolean) => void;
}

const ModalPriceDefinition = ({
  positionY,
  setShowModal,
  clientGroupId,
  refreshTable,
  setRefreshTable,
}: IModal) => {
  const dispatch = useAppDispatch();
  const { httpUpdateAllDifferentitationAsync } =
    useHttpUpdateAllDifferentiation();
  const { pagination } = useAppSelector(
    (state) => state.publishedArticles as DataPublishedArticles
  );
  const loading = useAppSelector(
    (state) => state.isLoadingMyProducts.loadingEditAllDifferentiation
  );

  const [differentiation, setDifferentiation] = useState("");

  const handleModifyAll = () => {
    if (differentiation === "") return;
    dispatch(
      httpUpdateAllDifferentitationAsync(
        clientGroupId,
        Number(differentiation),
        refreshTable,
        setRefreshTable,
        setShowModal
      )
    );
  };

  // Lanzar notificación de acción en proceso
  const handleNotification = () => {
    viewNotification("Procesando modificaciones", "info");
  };

  useEffect(() => {
    if (loading) {
      handleNotification();
    }
  }, [loading]);

  return (
    <ModalContainerStyled positionY={positionY}>
      <b>Modificar {pagination.totalAllRecords} artículos</b>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <label htmlFor="diferenciacion">Diferenciación:</label>
        <InputEditStyled
          onChange={(e) => {
            setDifferentiation(e.target.value);
          }}
          type="number"
        />
      </div>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button text="Cancelar" onClick={() => setShowModal(false)} />
        {loading ? (
          <Spinner />
        ) : (
          <Button
            text="Modificar todos"
            backgroundColor="secondary"
            onClick={handleModifyAll}
          />
        )}
      </div>
    </ModalContainerStyled>
  );
};

export default ModalPriceDefinition;
