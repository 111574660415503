import React from "react";
import { FooterContainerStyled, RegretButtonStyled } from "./FooterStyled";
import { useParams } from "react-router-dom";
import { routesApp } from "../../RoutesApp";

const Footer = () => {
  const { aliasEmpresa } = useParams();
  return (
    <FooterContainerStyled>
      <RegretButtonStyled to={`/${aliasEmpresa}${routesApp.purchaseRegret}`}>
        Botón de arrepentimiento
      </RegretButtonStyled>
    </FooterContainerStyled>
  );
};

export default Footer;
