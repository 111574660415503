import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-width: 100px;
`;

export const InputCheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

interface IFormContainer {
  active: boolean;
}

export const FormContainer = styled.div<IFormContainer>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-bottom: 3rem;

  opacity: ${(props) => (props.active === false ? 0.5 : 1)};

  @media (max-width: 740px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

interface ILabelStyled {
  disabled?: boolean;
}

export const ContainerSpinner = styled.div`
  display: flex;
  margin-top: 300px;
  width: 20%;
`;

export const LabelStyled = styled.label<ILabelStyled>`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
  color: ${(props) => (props.disabled ? "#ccc" : "#000")};
`;

export const InputStyled = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.8rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const InputMidStyled = styled.input`
  border: 1px solid #ccc;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const InputSelectStyled = styled.select`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.8rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const InputSelecMidtStyled = styled.select`
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0.5rem;
  font-size: 0.8rem;
  min-width: 80px;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const ErrorMsg = styled.span`
  color: red;
  font-size: 0.8rem;
`;

// Select Search styles

export const InputSearchContainer = styled.div`
  display: flex;
  padding-left: 0.5rem;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 32px;
  font-size: 0.8rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
`;

export const SearchStyled = styled.input`
  border: none;
  width: 85%;
  &:focus {
    outline: none;
  }
`;

export const BoxResults = styled.div`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  top: 682px;
  z-index: 999;

  @media (max-width: 797px) {
    top: 660px;
  }

  @media (max-width: 740px) {
    top: 820px;
  }

  @media (max-width: 420px) {
    top: 1150px;
  }
`;

export const ListResults = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OptionsStyled = styled.li`
  list-style: none;
  padding: 0.3rem 10px;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: #0000ffc9;
    color: #fff;
  }
`;
