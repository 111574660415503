import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
// Styles
import {
  MessageStyled,
  ContainerStyled,
  CardSquareStyled,
  CenterTextStyled,
  ButtonContainerStyled,
  ButtonVinculateStyled,
} from "../styles/PaymentGatewayStyles";
// Services
import useHttpGetUrlToAutorizeOperations from "../../../../../../../services/PaymentGateways/useHttpGetUrlToAutorizeOperations";

const MercadoPagoConfig = () => {
  const dispatch = useAppDispatch();

  const { httpGetUrlToAutorizeOperationsAsync } =
    useHttpGetUrlToAutorizeOperations();
  const configureDataMP = useAppSelector(
    (state) => state.paymentGatewayValidation
  );

  const authorize = () => {
    dispatch(httpGetUrlToAutorizeOperationsAsync());
  };
  return (
    <ContainerStyled>
      <h2>Mercado Pago</h2>
      <CardSquareStyled>
        <CenterTextStyled>
          Habilitar cuenta de Mercado Pago para realizar cobros
        </CenterTextStyled>
        <ButtonContainerStyled>
          <ButtonVinculateStyled onClick={authorize}>
            Vincular cuenta de Mercado Pago{" "}
          </ButtonVinculateStyled>
          {configureDataMP?.configurado && (
            <MessageStyled>{configureDataMP?.mensaje}</MessageStyled>
          )}
        </ButtonContainerStyled>
      </CardSquareStyled>
    </ContainerStyled>
  );
};

export default MercadoPagoConfig;
