import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
// Components
import TransferConfig from "./components/TransferConfig";
import MercadoPagoConfig from "./components/MercadoPagoConfig";
// Services
import useHttpValidateConfigMP from "../../../../../../services/PaymentGateways/useHttpValidateConfigMP";
import useHttpGetDataTransfer from "../../../../../../services/Transfers/DataTransfer/useHttpGetDataTransfer";

const PaymentGateways = () => {
  const dispatch = useAppDispatch();
  const { httpValidateConfigMPAsync } = useHttpValidateConfigMP();
  const { httpGetDataTransferAsync } = useHttpGetDataTransfer();

  const [transferData, setTransferData] = useState({
    esDatoSistema: true,
    alias: "",
    banco: "",
    cbu: "",
    cuenta: "",
    cuitCuil: "",
    habilitado: false,
    titular: "",
    id: 0,
  });

  const dataTransferRedux = useAppSelector(
    (state: any) => state.DataTransfer.data
  );

  const isLoadingConfigMP = useAppSelector(
    (state) =>
      state.isLoadingValidateConfigPaymentGateway.loadingValidatePaymentGateway
  );

  useEffect(() => {
    setTransferData({
      ...transferData,
      alias: dataTransferRedux?.alias ?? "",
      banco: dataTransferRedux?.banco ?? "",
      cbu: dataTransferRedux?.cbu ?? "",
      cuenta: dataTransferRedux?.cuenta ?? "",
      cuitCuil: dataTransferRedux?.cuitCuil ?? "",
      habilitado: dataTransferRedux?.habilitado ?? false,
      titular: dataTransferRedux?.titular ?? "",
      id: dataTransferRedux?.id ?? 0,
    });
  }, [dataTransferRedux]);

  useEffect(() => {
    dispatch(httpValidateConfigMPAsync());
    dispatch(httpGetDataTransferAsync());
  }, []);

  return (
    <div>
      <h1>Medios de pago</h1>
      {isLoadingConfigMP ? <p>Cargando...</p> : <MercadoPagoConfig />}
      <TransferConfig
        transferData={transferData}
        setTransferData={setTransferData}
      />
    </div>
  );
};

export default PaymentGateways;
