/* eslint-disable no-unused-vars */
import { useState, ChangeEvent, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  DateStyled,
  MainStyled,
  TextStyled,
  HeaderStyled,
  FooterStyled,
  ButtonStyled,
  TextAreaStyled,
  ContainerStyled,
  HeaderSectionStyled,
  RatingContainerStyled,
} from "../styles/CommentsBoxStyled";
// Components
import { Rating } from "react-simple-star-rating";
import StarIcon from "../../../components/iconsComponents/StarIcon";
// Services
import useHttpAddOrUpdateComment from "../../../services/CommentsAndRatings/useHttpAddOrUpdateComment";
import Spinner from "../../../components/spinner/Spinner";

interface ICommentBox {
  setShowModalBoxComment: (value: boolean) => void;
}

function CommentsBox({ setShowModalBoxComment }: ICommentBox) {
  const dispatch = useAppDispatch();
  const { httpAddOrUpdateCommentAsync } = useHttpAddOrUpdateComment();

  const isLoadingPostComment = useAppSelector(
    (state) => state.isLoadingCommentsAndRatings.loadingAddOrUpdateComments
  );

  const currentComment = useAppSelector(
    (state) => state.comments.currentComment
  );

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [showError, setShowError] = useState(false);

  /**
   * Esta función maneja la valoración del comentario y actualiza el estado de la valoración.
   *
   * @param {number} rate - La valoración a establecer.
   */
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  /**
   * Guarda el comentario en el estado comment y valida que no existan más de 400 caracteres
   */
  const handleComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length < 400) {
      setShowError(false);
      setComment(value);
    } else {
      setShowError(true);
    }
  };

  /**
   * Recupera la fecha actual en el formato dd/mm/yyyy y envía los datos de valoración, comentario y fecha a la petición de enviar comentario
   */
  const handlePublishComment = () => {
    const fechaActual = new Date().toISOString();
    dispatch(
      httpAddOrUpdateCommentAsync(
        rating,
        comment,
        fechaActual,
        setShowModalBoxComment
      )
    );
  };

  useEffect(() => {
    if (currentComment.comentario !== "") {
      setComment(currentComment.comentario);
      setRating(currentComment.valoracion);
    }
  }, []);

  return (
    <ContainerStyled>
      <HeaderStyled>
        <HeaderSectionStyled>
          <DateStyled>{new Date().toLocaleDateString()}</DateStyled>
        </HeaderSectionStyled>
        <RatingContainerStyled>
          <TextStyled>Valora tu compra:</TextStyled>
          <Rating
            className="rating"
            onClick={handleRating}
            initialValue={rating}
            emptyIcon={<StarIcon icon="starIconEmpty" width={24} height={27} />}
            fillIcon={<StarIcon icon="starIconFilled" width={24} height={27} />}
          />
        </RatingContainerStyled>
      </HeaderStyled>

      <MainStyled>
        <TextAreaStyled
          className="focus"
          onFocus={() => true}
          maxLength={400}
          placeholder="Escribe una valoración del producto..."
          value={comment}
          onChange={(e) => handleComment(e)}
        ></TextAreaStyled>
        {showError && (
          <span style={{ color: "red", fontSize: "14px" }}>
            Máximo 400 caracteres
          </span>
        )}
      </MainStyled>

      <FooterStyled>
        <ButtonStyled
          onClick={handlePublishComment}
          disabled={isLoadingPostComment}
        >
          {isLoadingPostComment ? <Spinner /> : "Publicar"}
        </ButtonStyled>
      </FooterStyled>
    </ContainerStyled>
  );
}

export default CommentsBox;
