import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { setViewTab } from "../../features/virtualAssistant/virtualAssistantReducer";
// Components
import Button from "../button/Button";
// Images
import VirtualAssistantImg from "../../assets/img/assistant.png";
// Icons
import Close from "../../assets/icons/close.svg";
import wsp from "./assets/whatsapp.png";
import email from "./assets/email.png";
import telefono from "./assets/telefono.png";
// Styled
import {
  TextStyled,
  IconStyled,
  ItemContact,
  CloseStyled,
  MsgContainer,
  ContactContainer,
  IconContactStyled,
  AssistantContainer,
} from "./Styled";
// Services
import useHttpGetAllConfigNotifications from "../../services/Notifications/useHttpGetAllConfigNotifications";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const VirtualAssistant = () => {
  const dispatch = useAppDispatch();
  const { obtenerVirtualAssistant, agregarVirtualAssistant } = useLocalStorageHook();

  const { httpGetAllConfigNotificationsAsync } =
    useHttpGetAllConfigNotifications();

  const virtualAssistantLocalStorageState = obtenerVirtualAssistant();

  const [showAssistant, setShowAssistant] = useState(virtualAssistantLocalStorageState.showAssistant);
  const [deleteAssistant, setDeleteAssistant] = useState(virtualAssistantLocalStorageState.deleteAssistant);
  const [marginRight, setMarginRight] = useState("-1%");

  const isLoadingContactData = useAppSelector(
    (state) => state.isLoadingConfigNotifications.loadingGetConfigNotification
  );

  const contactData = useAppSelector((state) => state.notificationsConfig);

  // Virtual assistant redux
  const viewTab = useAppSelector((state) => state.virtualAssistant.viewTab);

  /**
   * Maneja el evento de pasar el mouse sobre el asistente virtual.
   *
   * Si la pestaña de vista no está activa, muestra el asistente.
   */
  const handleMouseOver = () => {
    if (viewTab) return;
    setShowAssistant(true);
  };

  /**
   * Maneja el evento de clic en el asistente virtual.
   *
   * Inicia la animación de cierre del asistente, muestra el tab de borde después de un retraso.
   */
  const handleClick = () => {
    // Animacion de cierre
    setDeleteAssistant(true);
    setMarginRight("-50%");
    // Mostrar tab de borde
    setTimeout(() => {
      dispatch(setViewTab(true));
    }, 180);
  };

  /**
   *
   * Despacha una acción para obtener todas las configuraciones de notificaciones.
   */
  const handleViewContactInfo = () => {
    dispatch(httpGetAllConfigNotificationsAsync());
  };

  useEffect(() => {
    // cuando se cierre el tab, abrir el asistente
    if (!viewTab) {
      setShowAssistant(false);
      setDeleteAssistant(false);
      setMarginRight("-1%");
    }
  }, [viewTab]);

  useEffect(() => {
    agregarVirtualAssistant(showAssistant, deleteAssistant, viewTab);
  }, [showAssistant, deleteAssistant, viewTab]);

  return (
    <AssistantContainer
      deleteAssistant={deleteAssistant}
      marginRight={marginRight}
      saveAssistant={viewTab}
    >
      <MsgContainer showAssistant={showAssistant}>
        <CloseStyled src={Close} alt="Close icon" onClick={handleClick} />
        <TextStyled>
          Si tenés dudas o consultas podes comunicarte con nosotros!
        </TextStyled>
        {/* <Button text="Contactarme con un asesor" backgroundColor="secondary" /> */}
        {contactData?.id && (
          <ContactContainer>
            {contactData?.whatsapp && (
              <ItemContact>
                <IconContactStyled src={wsp} alt="whatsapp" />
                {contactData?.whatsapp}
              </ItemContact>
            )}
            {contactData?.telefono && (
              <ItemContact>
                <IconContactStyled src={telefono} alt="telefono" />
                {contactData?.telefono}
              </ItemContact>
            )}
            {contactData?.email && (
              <ItemContact>
                <IconContactStyled src={email} alt="email" />
                {contactData?.email}
              </ItemContact>
            )}
          </ContactContainer>
        )}
        {!contactData?.id && (
          <Button
            width="100%"
            backgroundColor="secondary"
            text={
              isLoadingContactData ? "Cargando..." : "Ver medios de contacto"
            }
            onClick={handleViewContactInfo}
          />
        )}
      </MsgContainer>
      <IconStyled
        src={VirtualAssistantImg}
        alt="virtual assistant"
        onMouseOver={handleMouseOver}
      />
    </AssistantContainer>
  );
};

export default VirtualAssistant;
