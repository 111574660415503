import styled from "styled-components";

export const CardTransferConfigsStyled = styled.div`
  padding: 10px;
  border: 1px solid grey;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ContainerSaveButtonStyled = styled.div`
  display: flex;
  justify-content: end;
`;

export const ContainerCardsGroupsStyled = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const FormConfigsStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ContainerSpinnerStyled = styled.div`
  display: flex;
  margin-left: 45px;
`;
