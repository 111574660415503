// Este slice corresponde a los filtros que se muestran al usuario

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Categoria {
    id: number;
    descripcion: string;
    esDatoSistema: boolean;
  }

  interface SubCategoria {
    id: number;
    descripcion: string;
    esDatoSistema: boolean;
  }

  interface Linea {
    id: number;
    descripcion: string;
    codigo: null,
    esDatoSistema: boolean;
  }

  interface DefinicionesArticulosGrupoCliente {
    empresaId: number;
    articuloId: number;
    descuentoRecargo: number;
    grupoClienteId: number;
    id: number;
    publicado: boolean;
    ultimasUnidades: number;
    esDatoSistema: boolean;
  }

  interface Pagination {
    totalAllRecords: number;
    page: number;
    recordsForPage: number;
  }

  interface Articulo {
  id: number;
  codigo: string;
  descripcion: string;
  precio: number;
  categoria: Categoria;
  subCategoria: SubCategoria;
  Linea: Linea;
  cantidad: number;
  definicionesArticulosGrupoCliente: DefinicionesArticulosGrupoCliente;
}

interface DataPublishedArticles {
  data: Articulo[];
  pagination: Pagination;
}

const initial: DataPublishedArticles = {
  data: [],
  pagination: {
    totalAllRecords: 0,
    page: 1,
    recordsForPage: 10,
  },
};

export const PublishedArticlesSlice: any = createSlice({
  name: "publishedArticles",
  initialState: initial,
  reducers: {
    addPublishedArticles: (state, action: PayloadAction<Articulo[]>) => {
      state.data = action.payload;
    },
    addPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
  },
});

export const { addPublishedArticles, addPagination } = PublishedArticlesSlice.actions;

export default PublishedArticlesSlice.reducer;
