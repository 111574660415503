import styled from "styled-components";

export const ContainerSpinner = styled.div`
  margin-top: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorFormStyled = styled.div`
  color: #eb4949;
  font-size: 14px;
  margin-top: 2px;
`;
