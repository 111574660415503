import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingGetParametrization: false,
  loadingEditParametrization: false,
  loadingDeleteParametrization: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingGetParametrization: (state, action: PayloadAction<boolean>) => {
      state.loadingGetParametrization = action.payload;
    },
    setLoadingEditParametrization: (state, action: PayloadAction<boolean>) => {
      state.loadingEditParametrization = action.payload;
    },
    setLoadingDeleteParametrization: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteParametrization = action.payload;
    },
  }
});

export const { setLoadingGetParametrization, setLoadingEditParametrization, setLoadingDeleteParametrization } = loadingsSlice.actions;

export default loadingsSlice.reducer;
