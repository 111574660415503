import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingCart: false,
  loadingAddCart: false,
  loadingDeleteProductToCart: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingCart: (state, action: PayloadAction<boolean>) => {
      state.loadingCart = action.payload;
    },
    setLoadingAddCart: (state, action: PayloadAction<boolean>) => {
      state.loadingAddCart = action.payload;
    },
    setLoadingDeleteProductToCart: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteProductToCart = action.payload;
    },
  },
});

export const {
  setLoadingCart,
  setLoadingAddCart,
  setLoadingDeleteProductToCart
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
