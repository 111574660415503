import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientGroups } from "../../models/clientGroup";

const initialState = {
  clientGroup: [] as ClientGroups[],
};

export const clientGroupSlice = createSlice({
  name: "clientGroup",
  initialState,
  reducers: {
    setClientGroup: (state, action: PayloadAction<ClientGroups[]>) => {
      state.clientGroup = action.payload;
    },
  },
});

export const { setClientGroup } = clientGroupSlice.actions;

export default clientGroupSlice.reducer;
