import SpinnerSmall from "../../../components/small-spinner/SpinnerSmall";
// Styles
import {
  ErrorMsg,
  BoxResults,
  LabelStyled,
  ListResults,
  SearchStyled,
  OptionsStyled,
  InputContainer,
  InputSearchContainer,
} from "../styles/FormStyles";
// Models
import { ISelectSearch } from "../types/SelectSearch.model";
// Hooks
import useSelectSearchHook from "../hooks/useSelectSearchHook";

const SelectSearch = ({
  name,
  value,
  label,
  placeholder,
  options,
  setPage,
  page,
  formData,
  onChange,
  disabled,
  setFiltro,
  isLoading,
  setFormData,
}: ISelectSearch) => {
  // Custom hook
  const {
    data,
    boxRef,
    showBox,
    viewBox,
    randomNum,
    handleScroll,
    handleClickOption,
    handleChangeSearch,
  } = useSelectSearchHook({
    setPage,
    page,
    options,
    disabled,
    onChange,
    setFiltro,
    setFormData,
    formData,
  });

  return (
    <InputContainer>
      <LabelStyled disabled={disabled} htmlFor={label}>
        {label}
      </LabelStyled>
      <InputSearchContainer>
        <SearchStyled
          type="text"
          id={label}
          name={name}
          onChange={(e) => handleChangeSearch(e.target.value)}
          onClick={viewBox}
          value={disabled ? "" : value}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          required
          title={value ?? ""}
        />
        {isLoading && <SpinnerSmall />}
      </InputSearchContainer>
      {showBox && (
        <BoxResults ref={boxRef} onScroll={handleScroll}>
          <ListResults>
            {data?.length === 0 && (
              <OptionsStyled>No hay resultados</OptionsStyled>
            )}
            {data?.map((option) => (
              <OptionsStyled
                key={option.id + randomNum}
                onClick={() => handleClickOption(option)}
              >
                {option.nombre} - CP:{option.codigoPostal}
              </OptionsStyled>
            ))}
          </ListResults>
        </BoxResults>
      )}
      {(value === "" || formData.localidadId === "") && !disabled && (
        <ErrorMsg>Campo requerido</ErrorMsg>
      )}
    </InputContainer>
  );
};

export default SelectSearch;
