import React, { useEffect } from "react";
// Styles
import {
  InputStyled,
  SelectStyled,
  HeaderContainerStyled,
  InputsContainerStyled
} from "./SearchArticleStyled";
// Services
import useHttpPublishedArticles from "../../../../services/Account/MyProducts/useHttpPublishedArticles";
import useHttpCategories from "../../../../services/Categorization/Definitions/Categories/useHttpCategories";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/button/Button";

interface IProps {
    title: string;
    filterDescription: string;
    setFilterDescription: React.Dispatch<React.SetStateAction<string>>;
    filterCode: string;
    setFilterCode: React.Dispatch<React.SetStateAction<string>>;
    categoryId: string;
    setCategoryId: React.Dispatch<React.SetStateAction<string>>;
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
    resetPaginationToggle: boolean;
    setResetPaginationToggle: React.Dispatch<React.SetStateAction<boolean>>;
    clientGroupId: number
}

export interface ICategories {
    id: number;
    descripcion: string;
    esDatoSistema: boolean;
  }

const SearchArticle = ({
  title,
  filterCode,
  categoryId,
  setCategoryId,
  setFilterCode,
  setShowModal,
  filterDescription,
  setFilterDescription,
  resetPaginationToggle,
  setResetPaginationToggle,
  clientGroupId
}: IProps) => {
  const dispatch = useAppDispatch();
  const { httpPublishedArticlesAsync } = useHttpPublishedArticles();
  const { httpCategoriesAsync } = useHttpCategories();

  const categories = useAppSelector(
    // Lista de categorías disponibles
    (state) => state.categories as ICategories[]
  );

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    if (e.target.name === "code") {
      setFilterCode(value);
    } else {
      setFilterDescription(value);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeCategory = (e: any) => {
    setCategoryId(e.target.value);
  };

  const handleSearch = () => {
    dispatch(httpPublishedArticlesAsync(1, filterDescription, filterCode, categoryId, clientGroupId, 10));
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleModifyAll = () => {
    setShowModal && setShowModal(true);
  };

  useEffect(() => {
    if (filterCode === "" && filterDescription === "") {
      dispatch(httpPublishedArticlesAsync(1, "", "", categoryId, clientGroupId, 10));
    }
  }, [filterDescription, filterCode, categoryId]);

  // UseEffect para cargar las categorias en el estado de redux
  useEffect(() => {
    dispatch(httpCategoriesAsync());
  }
  , []);

  return (
    <HeaderContainerStyled>
      <h3>{title}</h3>
      <InputsContainerStyled>
        <Button
          text="Modificar todos"
          backgroundColor="secondary"
          onClick={handleModifyAll}
        />
        {/* Selector de categoria */}
        <SelectStyled name="" id="" onChange={handleChangeCategory}>
          <option value="">Todas las categorías</option>
          {categories.map((category: ICategories) => (
            <option key={category.id} value={category.id}>
              {category.descripcion}
            </option>
          ))}
        </SelectStyled>

        <InputStyled
          type="text"
          placeholder="Código"
          name="code"
          onChange={handleChangeInput}
          value={filterCode}
          onKeyDown={handleKeyDown}
          onClick={() => {
            setFilterDescription("");
          }}
        />
        <InputStyled
          type="text"
          placeholder="Nombre del artículo"
          name="description"
          onChange={handleChangeInput}
          onKeyDown={handleKeyDown}
          value={filterDescription}
          onClick={() => {
            setFilterCode("");
          }}
        />
        <Button
          type="button"
          onClick={() => {
            handleSearch();
          }}
          text="Buscar"
          backgroundColor="secondary"
          height="36px"
          width="100px"
        />

      </InputsContainerStyled>
    </HeaderContainerStyled>

  );
}
;

export default SearchArticle;
