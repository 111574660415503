import { Store, NOTIFICATION_TYPE } from "react-notifications-component";

export const viewNotification = (text: string, color: NOTIFICATION_TYPE) => {
  Store.addNotification({
    title: text,
    container: "bottom-full",
    type: color,
    dismiss: {
      duration: 2000,
    },
  });
};
