/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import { viewNotification } from "../../../utils/ViewNotification";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setLoadingAddAttribute } from "../../../features/loading/categorizations/articles/loadingsReducer";
import { addAttribute } from "../../../features/categorization/articles/attributesInTheArticleReducer";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpAddOrUpdateAttribute() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAddOrUpdateAttributeAsync =
    (
      articleId: number,
      attributeId: number,
      descriptionAttribute: string,
      valueAttribute: string
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingAddAttribute(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.addAttributeInArticle}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              articuloId: articleId,
              atributos: [
                {
                  id: attributeId,
                  descripcion: descriptionAttribute,
                  valor: valueAttribute,
                },
              ],
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          viewNotification("Atributo editado correctamente", "success");
          dispatch(
            addAttribute({
              id: attributeId,
              descripcion: descriptionAttribute,
              valor: valueAttribute,
            })
          );
        } else {
          viewNotification("Error al editar", "danger");
        }
        dispatch(setLoadingAddAttribute(false));
      } catch (error) {
        dispatch(setLoadingAddAttribute(false));
      }
      dispatch(setLoadingAddAttribute(false));
    };
  return {
    httpAddOrUpdateAttributeAsync,
  };
}

export default useHttpAddOrUpdateAttribute;
