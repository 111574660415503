import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rating } from "../../models/CommentsAndRatings/Rating.model";
import { CommentsAndRatingsData } from "../../models/CommentsAndRatings/CommentsAndRatingsData.model";
import { CurrentComment } from "../../models/CommentsAndRatings/CurrentComment.model";

const initial: CommentsAndRatingsData = {
  data: [],
  currentComment: {
    comentario: "",
    valoracion: 0,
    fecha: "",
    articuloId: 0,
    usuarioId: 0,
    id: 0,
    isNew: false,
    esDatoSistema: false,
    empresaId: 0,
    grupoEmpresaId: 0,
    unidadNegocioId: 0,
    transactionId: "",
  },
  rating: {
    cantidadValoraciones: 0,
    promedioValoraciones: 0,
    contadorValoraciones: {},
  },
  pagination: {
    recordsForPage: 0,
    page: 0,
    totalRecords: 0,
    totalAllRecords: 0,
  },
};

export const ArticlesSlice = createSlice({
  name: "commentsAndRatings",
  initialState: initial,
  reducers: {
    addComments: (state, action: PayloadAction<CommentsAndRatingsData>) => {
      state.data = [...state.data, ...action.payload.data];
      state.pagination = action.payload.pagination;
    },
    addCurrentComment: (state, action: PayloadAction<CurrentComment>) => {
      state.currentComment = action.payload;
    },
    resetIdCurrentComment: (state) => {
      state.currentComment.articuloId = 0;
    },
    addRating: (state, action: PayloadAction<Rating>) => {
      state.rating = action.payload;
    },
    deleteComment: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter(
        (comment) => comment.id !== action.payload
      );
    },
    resetStateComments: (state) => {
      state.data = [];
      state.pagination = {
        recordsForPage: 0,
        page: 0,
        totalRecords: 0,
        totalAllRecords: 0,
      };
    },
    resetCurrentComment: (state) => {
      state.currentComment = {
        comentario: "",
        valoracion: 0,
        fecha: "",
        articuloId: 0,
        usuarioId: 0,
        id: 0,
        isNew: false,
        esDatoSistema: false,
        empresaId: 0,
        grupoEmpresaId: 0,
        unidadNegocioId: 0,
        transactionId: "",
      };
    },
  },
});

export const {
  addRating,
  addComments,
  deleteComment,
  addCurrentComment,
  resetStateComments,
  resetCurrentComment,
  resetIdCurrentComment,
} = ArticlesSlice.actions;

export default ArticlesSlice.reducer;
