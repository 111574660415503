// Este slice corresponde a los loading que se muestran al usuario
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// iniciar el esatado
const initialState = {
  loadingFilters: false,
  loadingPagination: false,
  loadingSearchResults: false,
  loadingProductDetails: false,
  loadingSearchResultsVin: false,
  loadingFiltersAttributes: false,
  loadingCommentsByProduct: false,
  loadingSucursalEcommerce: false,
  loadingSuggestSearchPhrase: false,
  loadingInitialConfiguration: false,
  loadingFiltersSubcategories: false,
  loadingSubcategoriesByCategory: false,
};

// crear el slice
export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoadingFilters: (state, action: PayloadAction<boolean>) => {
      state.loadingFilters = action.payload;
    },
    setLaodingPagination: (state, action: PayloadAction<boolean>) => {
      state.loadingPagination = action.payload;
    },
    setLoadingSearchResults: (state, action: PayloadAction<boolean>) => {
      state.loadingSearchResults = action.payload;
    },
    setLoadingProductDetails: (state, action: PayloadAction<boolean>) => {
      state.loadingProductDetails = action.payload;
    },
    setLoadingSearchResultsVin: (state, action: PayloadAction<boolean>) => {
      state.loadingSearchResultsVin = action.payload;
    },
    setLoadingCommentsByProduct: (state, action: PayloadAction<boolean>) => {
      state.loadingCommentsByProduct = action.payload;
    },
    setLoadingFiltersAttributes: (state, action: PayloadAction<boolean>) => {
      state.loadingFiltersAttributes = action.payload;
    },
    setLoadingSuggestSearchPhrase: (state, action: PayloadAction<boolean>) => {
      state.loadingSuggestSearchPhrase = action.payload;
    },
    setLoadingFiltersSubcategories: (state, action: PayloadAction<boolean>) => {
      state.loadingFiltersSubcategories = action.payload;
    },
    setLoadingInitialConfiguration: (state, action: PayloadAction<boolean>) => {
      state.loadingInitialConfiguration = action.payload;
    },
    setLoadingSucursalEcommerce: (state, action: PayloadAction<boolean>) => {
      state.loadingSucursalEcommerce = action.payload;
    },
  },
});

// exportar las acciones
export const {
  setLoadingFilters,
  setLaodingPagination,
  setLoadingSearchResults,
  setLoadingProductDetails,
  setLoadingSearchResultsVin,
  setLoadingFiltersAttributes,
  setLoadingCommentsByProduct,
  setLoadingSucursalEcommerce,
  setLoadingSuggestSearchPhrase,
  setLoadingInitialConfiguration,
  setLoadingFiltersSubcategories,
} = loadingSlice.actions;

export default loadingSlice.reducer;
