import { DefaultTheme } from "styled-components";

interface IMediaQuery {
  widescreen: string;
  desktop: string;
  tablet: string;
  mobile: string;
}
// Media querys
export const MEDIADEFAULT: IMediaQuery = {
  widescreen: "(max-width: 1024px)",
  desktop: "(max-width: 768px)",
  tablet: "(min-width: 480px)",
  mobile: "(min-width: 320px)",
};

// const FILTER_CSS_LIGHT = "saturate(100%) brightness(150%)";

const colors = {
  green: "#2BFD3C",
  blue: "##085C98",
  black: "#000000",
  grey: "##F6FBFF",
};

/**
 * main.primary: Color principal de la aplicación, utilizando en header, footer, hovers
 * main.secundary: Color secundario de la aplicación que se utiliza en barra de sesión y botones de acciones primarias
 * main.tertiary: Color terciario de la aplicación, se utiliza en botones de menús y hovers de los mismos si no tienen background
 */
export const defaultTheme: DefaultTheme = {
  colors: {
    main: {
      primary: "#085C98",
      secondary: "#3FA4D8",
      tertiary: "#9D9D9D",
      primaryLight: "##84C5E7",
    },
    background: {
      fourth: "#1C1C1E",
      fifth: "#3FA4D8",
      sixth: "white",
    },
    border: {
      second: "white",
      third: "##84C5E7",
      fourth: "rgba(204, 204, 204, 0.6)",
      fifth: colors.grey,
      sixth: "##84C5E7",
      seventh: "##84C5E7",
    },
    text: {
      first: colors.black,
      second: "white",
      third: "##B52020",
      fourth: "#AAA6A6",
      fifth: colors.grey,
      sixth: "##8271F2",
      eighth: "#C4C4C4",
      nineth: "#c2e88c",
    },
  },
};

// first Theme
// const colorsFirstTheme = {
//   black: "#222222",
//   grey: "#D0D5DD",
//   g: "#D601B1",
//   b: "#D46E11",
//   d: "#F79E14",
// };

// export const firstTheme: DefaultTheme = {
//   colors: {
//     background: {
//       first: colorsFirstTheme.g,
//       second: colorsFirstTheme.b,
//       third: colorsFirstTheme.d,
//       fourth: "#D9D9D9",
//       fifth: "#D9FC33",
//       sixth: "white",
//     },
//     border: {
//       first: colorsFirstTheme.black,
//       second: "white",
//       third: "#C4C4C4",
//       fourth: "rgba(204, 204, 204, 0.6)",
//       fifth: colorsFirstTheme.grey,
//       sixth: "#D66DD1",
//       seventh: "#D623C4",
//       eighth: colorsFirstTheme.g,
//       nineth: colorsFirstTheme.b,
//     },
//     text: {
//       first: colorsFirstTheme.black,
//       second: "white",
//       third: "#FC5555",
//       fourth: "grey",
//       fifth: colorsFirstTheme.grey,
//       sixth: colorsFirstTheme.b,
//       seventh: colorsFirstTheme.g,
//       eighth: "#C4C4C4",
//       nineth: "#D6A4D3",
//     },
//     hover: {
//       first: colorsFirstTheme.b,
//       second: colorsFirstTheme.g,
//       third: colorsFirstTheme.g,
//     },
//     shadow: {
//       first: colorsFirstTheme.g,
//       second: colorsFirstTheme.g,
//       third: colorsFirstTheme.g,
//       fourth: colorsFirstTheme.g,
//     },
//   }
// };

// Theme Ntflix
// const colorsNetflixTheme = {
//   black: "#222222",
//   grey: "#FFFFFF",
//   g: "#000000",
//   b: "#E50914", // ROJO
//   d: "#E50914",
// };

// export const netflixTheme: DefaultTheme = {
//   colors: {
//     background: {
//       first: colorsNetflixTheme.g,
//       second: colorsNetflixTheme.b,
//       third: colorsNetflixTheme.d,
//       fourth: "#D9D9D9",
//       fifth: "#D9FC33",
//       sixth: "white",
//     },
//     border: {
//       first: colorsNetflixTheme.b,
//       second: "white",
//       third: "#C4C4C4",
//       fourth: "rgba(204, 204, 204, 0.6)",
//       fifth: colorsNetflixTheme.grey,
//       sixth: "#D66DD1",
//       seventh: "#D623C4",
//       eighth: colorsNetflixTheme.g,
//       nineth: colorsNetflixTheme.b,
//     },
//     text: {
//       first: colorsNetflixTheme.grey,
//       second: "white",
//       third: "#FC5555",
//       fourth: "grey",
//       fifth: colorsNetflixTheme.grey,
//       sixth: colorsNetflixTheme.b,
//       seventh: colorsNetflixTheme.g,
//       eighth: "#C4C4C4",
//       nineth: "#D6A4D3",
//     },
//     hover: {
//       first: colorsNetflixTheme.b,
//       second: colorsNetflixTheme.g,
//       third: colorsNetflixTheme.g,
//     },
//     shadow: {
//       first: colorsNetflixTheme.g,
//       second: colorsNetflixTheme.g,
//       third: colorsNetflixTheme.g,
//       fourth: colorsNetflixTheme.g,
//     },
//   }
// };

// Theme Ntflix
// const colorsNissanTheme = {
//   white: "#FAFAFA",
//   greyA: "#E6E6E6",
//   greyB: "#D1D1D2",
//   greyC: "#818181",
//   greyD: "#686868",
//   black: "#282828",
//   primary: "#C91631",
//   primaryA: "#B32535",
//   primaryB: "#C12739",
//   primaryC: "#BC2738",
//   primaryD: "#CF7A84",
// };

// export const nissanTheme: DefaultTheme = {
//   colors: {
//     background: {
//       first: colorsNissanTheme.greyC,
//       second: colorsNissanTheme.black,
//       third: colorsNissanTheme.black,
//       fourth: colorsNissanTheme.primaryD,
//       fifth: colorsNissanTheme.primary,
//       sixth: colorsNissanTheme.white,
//     },
//     border: {
//       first: colorsNissanTheme.primary,
//       second: colorsNissanTheme.primaryD,
//       third: "#C4C4C4",
//       fourth: "rgba(204, 204, 204, 0.6)",
//       fifth: colorsNetflixTheme.grey,
//       sixth: "#D66DD1",
//       seventh: colorsNissanTheme.white,
//       eighth: colorsNissanTheme.white,
//       nineth: colorsNetflixTheme.b,
//     },
//     text: {
//       first: colorsNetflixTheme.grey,
//       second: "white",
//       third: "#FC5555",
//       fourth: "grey",
//       fifth: colorsNissanTheme.black,
//       sixth: colorsNetflixTheme.b,
//       seventh: colorsNetflixTheme.g,
//       eighth: "#C4C4C4",
//       nineth: colorsNissanTheme.primaryD,
//     },
//     hover: {
//       first: colorsNetflixTheme.b,
//       second: colorsNetflixTheme.g,
//       third: colorsNetflixTheme.g,
//     },
//     shadow: {
//       first: colorsNetflixTheme.g,
//       second: colorsNetflixTheme.g,
//       third: colorsNetflixTheme.g,
//       fourth: colorsNetflixTheme.g,
//     },
// }
// };

// export const theme = nissanTheme;
