/**
 * Función para limitar el texto a un número de caracteres dado.
 * @param {string} text - El texto que se va a limitar.
 * @param {number} characterLimit - El número máximo de caracteres permitidos.
 * @returns {string} - El texto limitado.
 */
export const limitText = (text: string, characterLimit = 19) =>
  text.length > characterLimit
    ? `${text.substring(0, characterLimit)}...`
    : text;
