/* eslint-disable indent */
import { Routes } from "../../../routes";
import { AppThunk, RootState } from "../../../../app/store";
import { setLoadingDeleteAttribute } from "../../../../features/loading/categorizations/definitions/attributes/loadingsReducer";
import env from "../../../../environment";
import { useAppSelector } from "../../../../app/hooks";
import validateUnauthorized from "../../../../utils/validateUnauthorized";
import { viewNotification } from "../../../../utils/ViewNotification";
import useLocalStorageHook from "../../../../utils/useLocalStorageHook";

function useHttpDeleteAttribute() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpDeleteAttributeAsync =
    (
      idAttribute: number,
      refreshAttributes: boolean,
      setRefreshAttributes: (value: boolean) => void,
      setShowModalDeleteAttribute: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingDeleteAttribute(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.deleteAttribute}/${idAttribute}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          // si response ok, notification success
          viewNotification("Atributo eliminado correctamente", "success");
          setRefreshAttributes(!refreshAttributes);
          setShowModalDeleteAttribute(false);
        } else {
          // si response not ok, notification error
          viewNotification("Error al eliminar atributo", "danger");
        }
        dispatch(setLoadingDeleteAttribute(false));
      } catch (error) {
        setLoadingDeleteAttribute(false);
      }
      setLoadingDeleteAttribute(false);
    };
  return {
    httpDeleteAttributeAsync,
  };
}

export default useHttpDeleteAttribute;
