import { ThemeProvider } from "styled-components";
import { useAppSelector } from "../../app/hooks";
import React from "react";

const ThemeProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const currentTheme = useAppSelector(state => state.appConfigurationState.currentTheme);
  return (
    <ThemeProvider theme={currentTheme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
