import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { setLoadingCart } from "../../features/loading/cart/loadingsReducer";
import { addCart } from "../../features/buy_Order/buy_OrderReducer";
import { Item } from "../../models/Item";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpCart() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpCartAsync = (): AppThunk => async (dispatch: any) => {
    const token = obtenerToken();
    // quitar comillas del token
    const tokenAccess = token?.replace(/['"]+/g, "");
    dispatch(setLoadingCart(true));
    try {
      const response = await fetch(`${env.operaciones}${Routes.getCart}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenAccess}`,
          empresaId: empresaId.toString(),
        },
      });
      const data = await response.json();
      if (data.itemsCarrito.length > 0) {
        let cartList: Item[] = [];
        data.itemsCarrito.map((prod: any) => {
          const item: Item = {
            cantidad: prod.cantidadAgregadaAlCarrito,
            fechaArticuloAgregadoAlCarrito: prod.fechaArticuloAgregadoAlCarrito,
            articulo: {
              cantidadDisponible: prod.cantidadDisponible,
              descripcion: prod.descripcionExtendida,
              descuento: prod.porcentajeOferta,
              detalles: [],
              id: prod.articuloId,
              imagenes: [
                {
                  id: prod.imagenes[0] ? prod.imagenes[0] : "",
                  url: prod.imagenes[0] ? prod.imagenes[0] : "",
                },
              ],
              nombre: prod.descripcion,
              precio: prod.precioUnitario,
              ultimasUnidades: prod.cantidadDisponible,
            },
          };
          // GUARDAR EN ESTADO
          cartList = [...cartList, item];
          return item;
        });
        dispatch(addCart(cartList));
        dispatch(setLoadingCart(false));
      }
      dispatch(setLoadingCart(false));
    } catch (error) {
      dispatch(setLoadingCart(false));
    }
    dispatch(setLoadingCart(false));
  };
  return {
    httpCartAsync,
  };
}

export default useHttpCart;
