import styled from "styled-components";

export const ContainerStyled = styled.div`
  width: 100%;
  margin: 5% 88px;
`;

export const ContainerPayStyled = styled.div`
  width: 100%;
  margin: 5% 40px;
`;

export const ContainerFormStyled = styled.div`
  width: 100%;
  margin-left: 30px;
`;

export const TitleStyled = styled.h1`
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 600;
`;

export const ContainerCardsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 53px;
`;

export const ContainerHeaderCardStyled = styled.div`
  border: 1px solid #e8e8e8;
  padding: 10px 14px 10px 14px;
`;

export const EditButtonStyled = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.background.fifth};
  text-align: end;
  font-weight: 600;
  cursor: pointer;
`;

export const ContainerInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  padding: 10px 14px 10px 14px;
  margin-bottom: 5px;
`;

export const ContainerImgStyled = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgStyled = styled.img`
  width: 40px;
`;

export const ContainerDirectionsStyled = styled.div`
  width: 78%;
  font-size: 16px;
`;

export const ContainerOrderStatusMsg = styled.div`
  background-color: #f6ae28;
  padding: 5px 5px;
`;

export const OrderStatusMsg = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  color: white;
`;
