/* eslint-disable @typescript-eslint/no-unused-vars */
// Este slice corresponde a los filtros que se muestran al usuario

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FiltersOptions } from "../../types/FiltersOptions";
// import { Categoria } from '../../models/Categoria'
// import { category } from '../../mookups/Categoria';
import { Atributo } from "../../models/Atributo";

const initialState: FiltersOptions = {
  marcas: [],
  ubicaciones: [],
  categorias: [],
  subcategorias: [],
  atributos: []
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<string[]>) => {
      state.marcas = action.payload;
    },
    removeBrands: (state) => {
      state.marcas = [];
    },
    setLocations: (state, action: PayloadAction<string[]>) => {
      state.ubicaciones = action.payload;
    },
    removeLocations: (state) => {
      state.ubicaciones = [];
    },
    setCategories: (state, action: PayloadAction<string[]>) => {
      state.categorias = action.payload;
    },
    removeCategories: (state) => {
      state.categorias = [];
    },
    setSubcategories: (state, action: PayloadAction<string[]>) => {
      state.subcategorias = action.payload;
    },
    removeSubcategories: (state) => {
      state.subcategorias = [];
    },
    setAttributes: (state, action: PayloadAction<Atributo[]>) => {
      state.atributos = action.payload;
    },
    removeAttributes: (state) => {
      state.atributos = [];
    },
    resetFilters: (state) => {
      state = {
        marcas: [],
        ubicaciones: [],
        categorias: [],
        subcategorias: [],
        atributos: []
      };
    },
  },
});

export const {
  resetFilters,
  setAttributes,
  removeAttributes,
  removeBrands,
  removeCategories,
  removeLocations,
  removeSubcategories,
  setBrands,
  setCategories,
  setLocations,
  setSubcategories
} = filtersSlice.actions;

export default filtersSlice.reducer;
