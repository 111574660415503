import React from "react";
import SessionBar from "../../../components/sessionBar/SessionBar";
import { ContainerConfigurations } from "../styles/styled";
import Footer from "../../../components/footer/Footer";
import { AsideMenu, PurchaseReturns } from "../components";
import { routesApp } from "../../../RoutesApp";

const PurchaseReturnsPage = () => (
  <div>
    <SessionBar />
    <ContainerConfigurations>
      <AsideMenu active={routesApp.purchaseReturns} />
      <PurchaseReturns />
    </ContainerConfigurations>
    <Footer />
  </div>
);

export default PurchaseReturnsPage;
