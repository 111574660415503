/* eslint-disable indent */
import { Routes } from "../../routes";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import { AppThunk, RootState } from "../../../app/store";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { setComprobantes } from "../../../features/transferAttachments/transferAttachmentsReducer";
import { setLoadingTransferAttachments } from "../../../features/loading/transferAttachments/loadingReducer";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

const useHttpGetTransferAttachments = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetTransferAttachmentsAsync =
    (
      ordenCompraId: number,
      setShowModalAttachments: (value: boolean) => void
    ): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingTransferAttachments(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.getAllTransfer}?ordenCompraId=${ordenCompraId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data = await response.json();

        if (data.length > 0) {
          // Guardar en el store
          dispatch(setComprobantes(data));
          setShowModalAttachments(true);
        } else {
          viewNotification("Sin comprobantes", "info");
        }
        dispatch(setLoadingTransferAttachments(false));
      } catch (error) {
        viewNotification("Error al cargar comprobantes", "warning");
        dispatch(setLoadingTransferAttachments(false));
      }
    };
  return { httpGetTransferAttachmentsAsync };
};

export default useHttpGetTransferAttachments;
