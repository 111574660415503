import styled, { keyframes } from "styled-components";

interface IAssistant {
  deleteAssistant?: boolean;
  showAssistant?: boolean;
  marginRight?: string;
  saveAssistant?: boolean;
}

export const MsgContainer = styled.div<IAssistant>`
  background-color: white;
  border-radius: 1rem;
  padding: 55px 180px 5px 90px;
  min-height: 120px;
  text-align: center;
  display: ${(props) => (props.showAssistant ? "block" : "none")};
  animation: ${(props) => (props.showAssistant ? open : "")} 1s;
`;

const open = keyframes`
  from {
    position: fixed;
    bottom: 0;
    right: -30%;
    margin: 2rem;
  }
  to {
    position: fixed;
    bottom: 0;
    right: -1%;
    margin: 2rem;
  }
`;

const close = keyframes`
  from {
    position: fixed;
    bottom: 0;
    right: -1%;
    margin: 2rem;
    display: flex;
  }
  to {
    position: fixed;
    bottom: 0;
    right: -50%;
    margin: 2rem;
  }
`;

export const AssistantContainer = styled.div<IAssistant>`
  position: fixed;
  bottom: 0;
  right: ${(props) => props.marginRight};
  margin: 2rem;
  z-index: 9999;
  animation: ${(props) => (props.deleteAssistant ? close : "")} 1s;
  display: ${(props) => (props.saveAssistant ? "none" : "flex")};
  // pequeño sombreado con bordes
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  // bordes redondeados
  border-radius: 1rem;
`;

export const CloseStyled = styled.img`
  position: absolute;
  top: 10%;
  left: 4%;
  cursor: pointer;
`;

export const IconStyled = styled.img`
  border-radius: 50%;
  background-color: #4a4aff;
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 3;
  width: 135px;
`;

export const TextStyled = styled.p`
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 0;
`;

interface IAssistantTab {
  showTab: boolean;
}

export const AssistantTabStyled = styled.button<IAssistantTab>`
  animation: ${(props) => (props.showTab ? "" : closeTab)} 3s;
  border: none;
  width: 30px;
  height: 180px;
  position: fixed;
  bottom: 2rem;
  right: 0;
  background-color: #e4e4e4;
  color: #9e9e9e;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: ${(props) => (props.showTab ? "flex" : "none")};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 0.5s;

  &:hover {
    background-color: white;
  }
`;

const closeTab = keyframes`
  from {
    position: fixed;
    bottom: 2rem;
    right: 0;
  }
  to {
    position: fixed;
    bottom: 2rem;
    right: -30px;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ItemContact = styled.span`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

export const IconContactStyled = styled.img`
  width: 20px;
`;
