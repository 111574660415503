import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
// Styles
import { FormContainer } from "../../../../../../PurchaseForm/styles/FormStyles";
// Components
import Button from "../../../../../../../components/button/Button";
import Field from "../../../../../../PurchaseForm/components/Field";
import Spinner from "../../../../../../../components/spinner/Spinner";
import Checkbox from "../../../../../../PurchaseForm/components/Checkbox";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// Services
import useHttpPostDataTransfer from "../../../../../../../services/Transfers/DataTransfer/useHttpPostDataTransfer";

interface Transfer {
  esDatoSistema: boolean;
  alias: string;
  banco: string;
  cbu: string;
  cuenta: string;
  cuitCuil: string;
  habilitado: boolean;
  titular: string;
  id: number;
}

interface ITransferConfig {
  transferData: Transfer;
  setTransferData: (data: Transfer) => void;
}

const TransferConfig = ({ transferData, setTransferData }: ITransferConfig) => {
  const dispatch = useAppDispatch();
  const [parent] = useAutoAnimate();
  const { httpPostDataTransferAsync } = useHttpPostDataTransfer();

  const [count, setCount] = useState(0);

  const isLoadingTransferData = useAppSelector(
    (state) => state.isLoadingDataTransfer.loadingDataTransfer
  );

  const isLoadingPostDataTransfer = useAppSelector(
    (state) => state.isLoadingDataTransfer.loadingPostDataTransfer
  );

  const handleSaveChanges = () => {
    dispatch(httpPostDataTransferAsync(transferData));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTransferData({ ...transferData, [name]: value });
  };

  const handleChangeCheckbox = () => {
    setCount(count + 1);
    setTransferData({
      ...transferData,
      habilitado: !transferData.habilitado,
    });
  };

  const emptyFields = () =>
    transferData.alias === "" ||
    transferData.titular === "" ||
    transferData.cuenta === "" ||
    transferData.cuitCuil === "" ||
    transferData.cbu === "" ||
    transferData.banco === "";

  useEffect(() => {
    if (count !== 0 && !emptyFields()) {
      handleSaveChanges();
    }
  }, [transferData.habilitado]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "30px",
          alignItems: "baseline",
        }}
      >
        <h2>Transferencia</h2>
        <Checkbox
          label="Activo"
          name="activo"
          checked={transferData.habilitado}
          onChange={() => handleChangeCheckbox()}
        />
      </div>
      <FormContainer ref={parent} active={transferData.habilitado}>
        {isLoadingTransferData ? (
          <>
            <div></div>
            <Spinner />
            <div></div>
          </>
        ) : (
          <>
            <Field
              label="Alias"
              name="alias"
              value={transferData.alias}
              onChange={handleChange}
            />
            <Field
              label="Titular"
              name="titular"
              value={transferData.titular}
              onChange={handleChange}
            />
            <Field
              label="Cuenta"
              name="cuenta"
              value={transferData.cuenta}
              onChange={handleChange}
            />
            <Field
              label="CUIT/CUIL"
              name="cuitCuil"
              value={transferData.cuitCuil}
              onChange={handleChange}
            />
            <Field
              label="CBU"
              name="cbu"
              value={transferData.cbu}
              type="number"
              onChange={handleChange}
            />
            <Field
              label="Banco"
              name="banco"
              value={transferData.banco}
              onChange={handleChange}
            />
            <Button
              text={
                isLoadingPostDataTransfer ? "Guardando..." : "Guardar cambios"
              }
              backgroundColor="secondary"
              height="32px"
              disabled={isLoadingPostDataTransfer || emptyFields()}
              onClick={handleSaveChanges}
            />
          </>
        )}
      </FormContainer>
    </>
  );
};

export default TransferConfig;
