import React, { useEffect } from "react";
// Styles
import { DatalistStyled, ItemDatalistStyled } from "./styled";
// Assets
import SearchIcon from "./assets/search-icon-suggest.png";

interface IsuggestSearchList {
  suggestSearchList: string[];
  handleClickForSearch: (suggestion?: string) => void;
}

const SuggestSearch = ({
  handleClickForSearch,
  suggestSearchList,
}: IsuggestSearchList) => {
  /**
   * Maneja el evento de clic fuera del componente.
   * Si se hace clic fuera del elemento con id "datalist", se ejecuta la función onClick.
   *
   * @param {MouseEvent} event - El evento de clic del ratón.
   */
  const handleClickOutside = (event: MouseEvent) => {
    const datalistElement = document.getElementById("datalist");
    if (datalistElement && !event.composedPath().includes(datalistElement)) {
      handleClickForSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DatalistStyled id="datalist">
      {suggestSearchList.map((m) => (
        <ItemDatalistStyled
          key={m}
          onClick={() => handleClickForSearch(m)}
        >
          <img src={SearchIcon} alt="search icon" />
          {m}
        </ItemDatalistStyled>
      ))}
    </DatalistStyled>
  );
};

export default SuggestSearch;
