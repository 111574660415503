/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../models/UserData";

const initialState: UserData = {
  accessToken: "",
  apellido: "",
  email: "",
  empresaId: "",
  id: "",
  idToken: null,
  nombre: "",
  picture: null,
  refreshToken: null,
  roles: [],
  tipoCliente: "",
  tipoClienteId: 0,
  telefono: "",
  direcciones: [],
  avatar: "",
};
export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      // Guardar datos en state
      state.accessToken = action.payload.accessToken;
      state.apellido = action.payload.apellido;
      state.email = action.payload.email;
      state.empresaId = action.payload.empresaId;
      state.id = action.payload.id;
      state.idToken = action.payload.idToken;
      state.nombre = action.payload.nombre;
      state.picture = action.payload.picture;
      state.refreshToken = action.payload.refreshToken;
      state.roles = action.payload.roles;
      state.tipoCliente = action.payload.tipoCliente;
      state.tipoClienteId = action.payload.tipoClienteId;
      state.telefono = action.payload.telefono;
      state.direcciones = action.payload.direcciones;
      state.avatar = action.payload.avatar;
    },
    resetUserData: (state) => {
      state = initialState;
    },
  },
});

export const { setUserData, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
