import styled from "styled-components";

export const ContainerCommentStyled = styled.div`
  display: flex;
  width: 88%;
  /* border: 1px yellow solid; */
  margin: 5px 5% 5px 5%;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImgContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px ${(props) => props.theme.colors.main.secondary} solid;
  & img {
    margin-top: 10px;
  }
`;

export const UsernameStyled = styled.p`
  font-weight: bold;
  color: ${(props) => props.theme.colors.text.fifth};
  margin-left: 15px;
  font-size: 17px;
`;

export const RightHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15px;
`;

export const DateStyled = styled.p`
  color: ${(props) => props.theme.colors.text.fourth};
  margin: 5px;
  font-size: 12px;
  font-weight: bold;
`;

export const MainStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeleteStyled = styled.button`
  color: red;
  margin-right: 15px;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #ff0000;
    border-radius: 8px;
    padding: 3px 5px;
    color: white;
  }
`;

export const CommentsStyled = styled.p``;
