import React from "react";
// Styles
import { TitleStyled, DialogStyled, ContainerButtonsStyled } from "./styles";
// Components
import Button from "../../../../../../../components/button/Button";

interface IModalConfirm {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  handleAcept: () => void;
  isDisabled: boolean;
  title: string;
}

const ModalConfirm = ({
  showModal,
  setShowModal,
  handleAcept,
  isDisabled,
  title,
}: IModalConfirm) => {
  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <DialogStyled open={showModal}>
      <form method="dialog">
        <TitleStyled>
          {title}
        </TitleStyled>
        <ContainerButtonsStyled>
          <Button
            type="button"
            text="Aceptar"
            backgroundColor="secondary"

            height="35px"
            onClick={handleAcept}
            disabled={isDisabled}
          />
          <Button
            type="button"
            text="Cancelar"
            backgroundColor="secondary"

            height="35px"
            onClick={handleCancel}
          />
        </ContainerButtonsStyled>
      </form>
    </DialogStyled>
  );
};

export default ModalConfirm;
