import styled from "styled-components";

export const CardProfileStyled = styled.div`
  display: flex;
  padding: 10px;
  overflow: hidden;
  border-radius: 12px;
  background-color: white;
  justify-content: space-between;
  box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.161);
  -moz-box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 1px 10px 12px 0px rgba(0, 0, 0, 0.29);
`;

export const ContainerDataStyled = styled.div`
  gap: 50px;
  display: flex;
  align-items: center;
`;

export const AvatarStyled = styled.img`
  width: 100px;
  height: 110px;
`;

export const ContainerTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: -60px;
  position: relative;
  z-index: 999;
`;

export const ContainerTitleRatingsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -120px;
  position: relative;
  z-index: 999;
`;
