import styled from "styled-components";

export const InputStyled = styled.input`
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 36px;
  padding: 0 8px;
  `;

export const InputsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const HeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const SelectStyled = styled.select`
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 38px;
  padding: 0 8px;
  font-size: 14px;
`;
