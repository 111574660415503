/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { addSuggestSearch } from "../../features/search/searchReducer";
import { setLoadingSuggestSearchPhrase } from "../../features/loading/loadingReducer";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpSuggestSearchPhrase() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpSuggestSearchPhraseAsync =
    (searchPrefixPhrase: string): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingSuggestSearchPhrase(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.suggestSearchPhraseElastic}?searchPrefixPhrase=${searchPrefixPhrase}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              empresaId: empresaId.toString(),
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
            },
          }
        );
        validateUnauthorized(response, empresaId);

        if (response.status === 200) {
          const data = await response.json();
          dispatch(addSuggestSearch(data));
        } else {
          dispatch(addSuggestSearch([]));
        }
        dispatch(setLoadingSuggestSearchPhrase(false));
      } catch (error) {
        dispatch(addSuggestSearch([]));
        dispatch(setLoadingSuggestSearchPhrase(false));
      }
      dispatch(setLoadingSuggestSearchPhrase(false));
    };
  return {
    httpSuggestSearchPhraseAsync,
  };
}

export default useHttpSuggestSearchPhrase;
