/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { SelectStyled } from "../../../styles/CategorizationStyled";
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import { ClientGroups } from "../../../../../models/clientGroup";
import useHttpClientGroups from "../../../../../services/AppConfiguration/useHttpClientGroups";

interface ICustomerGroupTable {
  setGroupSelected: (groupSelected: number) => void;
  groupSelected: number;
  setProgress: (progress: number) => void;
}

const CustomerGroupTable = ({
  setGroupSelected,
  groupSelected,
}: ICustomerGroupTable) => {
  const dispatch = useAppDispatch();
  const { httpClientGroupsAsync } = useHttpClientGroups();

  const gruposDeClientes = useAppSelector(
    (state) => state.clientGroup.clientGroup
  );

  useEffect(() => {
    dispatch(httpClientGroupsAsync());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "5%",
      }}
    >
      <label htmlFor="categories">Grupo de clientes:</label>
      <SelectStyled
        value={groupSelected}
        onChange={(e) => setGroupSelected(Number(e.target.value))}
      >
        {gruposDeClientes.map((group: ClientGroups) => (
          <>
            <option key={group.id} value={group.id}>
              {group.descripcion}
            </option>
          </>
        ))}
      </SelectStyled>
    </div>
  );
};

export default CustomerGroupTable;
