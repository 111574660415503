import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Articulo } from "../../models/Articulo";
import { Comentario } from "../../models/Comentario";

export type ProductDetailsState = {
  product: Articulo;
};

const initialState: ProductDetailsState = {
  product: {
    id: "",
    nombre: "",
    descripcion: "",
    detalles: [],
    imagenes: [],
    precio: 0,
    cantidadDisponible: 0,
    valoracion: 0,
  },
};

export const productDetailsSlice = createSlice({
  name: "productDetailsSlice",
  initialState,
  reducers: {
    setProductDetails: (state, action: PayloadAction<Articulo>) => {
      state.product = action.payload;
    },
    setComments: (
      state,
      action: PayloadAction<{ comentarios: Comentario[] }>
    ) => {
      state.product.comentarios = action.payload.comentarios;
    },
    setRating: (
      state,
      action: PayloadAction<{ valoracion: 0 | 1 | 2 | 3 | 4 | 5 }>
    ) => {
      state.product.valoracion = action.payload.valoracion;
    },
  },
});

export const { setProductDetails, setComments, setRating } =
  productDetailsSlice.actions;

export default productDetailsSlice.reducer;
