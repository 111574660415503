/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react";
import {
  TitleStyled,
  OrderStatusMsg,
  ContainerFormStyled,
  ContainerOrderStatusMsg,
} from "../../ShippingProcess/styles/SelectShippingMethodStyled";
import { FormContainer } from "../styles/FormStyles";
// Components
import Field from "./Field";
import Select from "./Select";
import FieldDouble from "./FieldDouble";
import SelectSearch from "./SelectSearch";
// Hooks
import useFormPartnetBusinessHook from "../hooks/useFormHook";
import { PartnerBusinessFormData } from "../models/PartnerBusinessFormData.model";
import { useAppSelector } from "../../../app/hooks";
import { IValidationPartnerBusinessHook } from "../types/IValidationPartnerBusinessHook";

interface IFormProps {
  formData: PartnerBusinessFormData;
  setFormData: (formData: any) => void;
  validationPartnerBusinessHook: IValidationPartnerBusinessHook;
}

const PartnerBusinessForm = ({
  formData,
  setFormData,
  validationPartnerBusinessHook,
}: IFormProps) => {
  const {
    filtroCPLocalidad,
    setPage,
    page,
    setFiltroCPLocalidad,
    provincias,
    localidades,
    condicionesIva,
    tiposInmuebles,
    handleInputChange,
    isLoadingLocalidades,
    handleChangeLocalidad,
  } = useFormPartnetBusinessHook({ setFormData, formData });

  const DatosSocioDeNegocio = useAppSelector(
    (state) => state.partnerBusinessForm.datosSocioNegocio
  );

  const email = useAppSelector((state) => state.user.email);

  const pendingOperations = useAppSelector(
    (state) => state.partnerBusinessForm.existenOperacionesPendientes
  );

  const {
    identificationDocErrMsgValidation,
    ciutCuilErrMsgValidation,
    validateCiutCuilAsync,
    validateIdentityDocumentAsync,
  } = validationPartnerBusinessHook;

  useEffect(() => {
    // setear email en setFormData
    setFormData({ ...formData, direccion: email });
  }, [formData.direccion]);

  return (
    <ContainerFormStyled>
      <TitleStyled>Datos del comprador</TitleStyled>
      {pendingOperations && (
        <ContainerOrderStatusMsg>
          <OrderStatusMsg>
            No podrá editar los datos mientras existan operaciones pendientes
          </OrderStatusMsg>
        </ContainerOrderStatusMsg>
      )}
      <h2>Personales</h2>
      <FormContainer active={true}>
        <Select
          label="Tipo"
          name="tipoPersonaId"
          value={formData.tipoPersonaId}
          options={[
            {
              id: "1",
              nombre: "Física",
            },
            {
              id: "2",
              nombre: "Jurídica",
            },
          ]}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Select
          label="Condicion IVA"
          name="condicionIvaId"
          options={condicionesIva}
          value={formData.condicionIvaId}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        {formData.tipoPersonaId?.toString() !== "2" && (
          <Field
            label="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            isDisabled={pendingOperations}
          />
        )}
        {formData.tipoPersonaId?.toString() !== "2" && (
          <Field
            label="Apellido"
            name="apellido"
            value={formData.apellido}
            onChange={handleInputChange}
            isDisabled={pendingOperations}
          />
        )}
        {formData.tipoPersonaId?.toString() !== "2" && (
          <FieldDouble
            type="text"
            valuePrimary={formData.tipoDocumentoId}
            valueSecondary={formData.documento}
            namePrimary="tipoDocumentoId"
            nameSecondary="documento"
            label="Documento"
            onBlurAction={validateIdentityDocumentAsync}
            options={[
              {
                value: "",
                label: "Todos",
              },
              {
                value: "1",
                label: "LC",
              },
              {
                value: "2",
                label: "LE",
              },
              {
                value: "3",
                label: "CI",
              },
              {
                value: "4",
                label: "PASAPORTE",
              },
              {
                value: "5",
                label: "DU",
              },
              {
                value: "6",
                label: "DNI",
              },
            ]}
            onChange={handleInputChange}
            validationError={identificationDocErrMsgValidation}
            isDisabled={
              pendingOperations || DatosSocioDeNegocio.socioNegocioId !== null
            }
          />
        )}
        {formData.tipoPersonaId?.toString() === "2" && (
          <Field
            label="Razón social"
            name="razonSocial"
            value={formData.razonSocial}
            onChange={handleInputChange}
            isDisabled={pendingOperations}
          />
        )}
        <FieldDouble
          type="number"
          valuePrimary={formData.tipoIdentificacionTributariaId}
          valueSecondary={formData.identificacionTributaria}
          namePrimary="tipoIdentificacionTributariaId"
          nameSecondary="identificacionTributaria"
          label="Ident. tributaria"
          options={[
            {
              value: "1",
              label: "CUIT",
            },
            {
              value: "2",
              label: "CUIL",
            },
          ]}
          onChange={handleInputChange}
          validationError={ciutCuilErrMsgValidation}
          onBlurAction={validateCiutCuilAsync}
          isDisabled={
            pendingOperations || DatosSocioDeNegocio.socioNegocioId !== null
          }
        />
      </FormContainer>
      <h2>Domicilio</h2>
      <FormContainer active={true}>
        <Select
          label="Tipo"
          name="tipoInmuebleId"
          value={formData.tipoInmuebleId}
          options={tiposInmuebles}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Select
          label="Provincia"
          name="provinciaId"
          value={formData.provinciaId}
          options={provincias}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <SelectSearch
          label="Código postal"
          name="localidadId"
          value={filtroCPLocalidad}
          placeholder="Buscar por código postal o ubicación"
          setPage={setPage}
          page={page}
          formData={formData}
          options={localidades}
          setFiltro={setFiltroCPLocalidad}
          setFormData={setFormData}
          onChange={handleChangeLocalidad}
          isLoading={isLoadingLocalidades}
          disabled={pendingOperations}
        />
        <Field
          label="Calle"
          name="calle"
          value={formData.calle}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Field
          type="number"
          label="Número"
          name="numero"
          value={formData.numero}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        {formData.tipoInmuebleId?.toString() === "2" && (
          <>
            <Field
              label="Piso"
              name="piso"
              value={formData.piso}
              onChange={handleInputChange}
              isDisabled={pendingOperations}
              placeholder="Ingrese un valor numérico"
            />
            <Field
              label="Departamento"
              name="departamento"
              value={formData.departamento}
              onChange={handleInputChange}
              isDisabled={pendingOperations}
            />
          </>
        )}
      </FormContainer>

      <h2>Contacto</h2>
      <FormContainer active={true}>
        <Select
          label="Tipo de teléfono"
          name="tipoTelefonoId"
          value={formData.tipoTelefonoId}
          options={[
            {
              id: "0",
              nombre: "Fijo",
            },
            {
              id: "1",
              nombre: "Celular",
            },
          ]}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Field
          type="number"
          label="Código de área"
          name="prefijoLocalidad"
          value={formData.prefijoLocalidad}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Field
          type="number"
          label="Teléfono"
          name="numeroTel"
          value={formData.numeroTel}
          onChange={handleInputChange}
          isDisabled={pendingOperations}
        />
        <Field
          label="Email"
          name="direccion"
          value={formData.direccion}
          onChange={handleInputChange}
          isDisabled={DatosSocioDeNegocio.email !== "" || pendingOperations}
        />
      </FormContainer>
    </ContainerFormStyled>
  );
};

export default PartnerBusinessForm;
