import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../app/hooks";
// Components
import Comment from "../../../../../Details/components/Comment";
import { ButtonStyled } from "../../../../../../components/button/Styled";
// Styles
import { ContainerButtonStyled } from "../../../../styles/NotificationsFormStyled";
// Services
import useHttpDeleteComment from "../../../../../../services/CommentsAndRatings/useHttpDeleteComment";
// Actions
import { resetIdCurrentComment } from "../../../../../../features/commentsAndRatings/commentsAndRatingsReducer";

const CommentPreview = () => {
  const dispatch = useAppDispatch();
  const { httpDeleteCommentAsync } = useHttpDeleteComment();

  const currentComment = useAppSelector(
    (state) => state.comments.currentComment
  );

  /**
   * Maneja la edición del comentario.
   * Resetea el ID del comentario actual.
   */
  const handleEditComment = () => {
    dispatch(resetIdCurrentComment());
  };

  /**
   * Maneja la Eliminación del comentario.
   * Envía la petición de eliminación del comentario
   */
  const handleDeleteComment = () => {
    dispatch(httpDeleteCommentAsync(currentComment.id));
  };
  return (
    <>
      <Comment
        id={currentComment.id}
        comentario={currentComment.comentario}
        fecha={currentComment.fecha}
        valoracion={currentComment.valoracion}
      />
      <ContainerButtonStyled>
        <ButtonStyled

          height="30px"
          color="primary"
          onClick={handleEditComment}
        >
          Editar
        </ButtonStyled>
        <ButtonStyled

          height="30px"
          color="secondary"
          onClick={handleDeleteComment}
        >
          Eliminar
        </ButtonStyled>
      </ContainerButtonStyled>
    </>
  );
};

export default CommentPreview;
