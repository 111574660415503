import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingGetConfigNotification: false,
  loadingPostConfigNotification: false,
  loadingDeleteConfigNotification: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingGetConfigNotification: (state, action: PayloadAction<boolean>) => {
      state.loadingGetConfigNotification = action.payload;
    },
    setLoadingPostConfigNotification: (state, action: PayloadAction<boolean>) => {
      state.loadingPostConfigNotification = action.payload;
    },
    setLoadingDeleteConfigNotification: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteConfigNotification = action.payload;
    },
  },
});

export const {
  setLoadingGetConfigNotification,
  setLoadingPostConfigNotification,
  setLoadingDeleteConfigNotification,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
