import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingValidatePaymentGateway: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingValidatePaymentGateway: (state, action: PayloadAction<boolean>) => {
      state.loadingValidatePaymentGateway = action.payload;
    },
  },
});

export const {
  setLoadingValidatePaymentGateway,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
