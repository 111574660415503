import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import { HeaderContainerStyled } from "../../../styles/CategorizationStyled";
import {
  FormStyled,
  ContainerButtonStyled,
} from "../../../styles/NotificationsFormStyled";
// Components
import { Store } from "react-notifications-component";
import Button from "../../../../../components/button/Button";
import Field from "../../../../PurchaseForm/components/Field";
import Spinner from "../../../../../components/spinner/Spinner";
// Services
import useHttpEditConfigNotifications from "../../../../../services/Notifications/useHttpEditConfigNotifications";
import useHttpGetAllConfigNotifications from "../../../../../services/Notifications/useHttpGetAllConfigNotifications";
// import useHttpDeleteConfigNotifications from "../../../../../services/Notifications/useHttpDeleteConfigNotifications";

const Notifications = () => {
  const dispatch = useAppDispatch();
  // Services
  const { httpEditConfigNotifications } = useHttpEditConfigNotifications();
  // const { httpDeleteConfigNotifications } = useHttpDeleteConfigNotifications();
  const { httpGetAllConfigNotificationsAsync } =
    useHttpGetAllConfigNotifications();

  // States
  const [configId, setConfigId] = useState<number | null>(null);
  const [notificationData, setNotificationData] = useState({
    email: "",
    telefono: "",
    whatsapp: "",
  });

  const configNotifications = useAppSelector(
    (state) => state.notificationsConfig as any
  );
  // const isLoadingDeleteConfigNotifications = useAppSelector(
  //   (state) =>
  //     state.isLoadingConfigNotifications.loadingDeleteConfigNotification
  // );
  const isLoadingEditConfigNotifications = useAppSelector(
    (state) => state.isLoadingConfigNotifications.loadingPostConfigNotification
  );

  // Cuando cambien los valores de los inputs
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setNotificationData({
      ...notificationData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Notificacion de email
    if (notificationData.email === "") {
      Store.addNotification({
        title: "Error",
        message: "El email no puede estar vacío",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
      return;
    }
    // Notificacion de telefono
    if (notificationData.telefono === "") {
      Store.addNotification({
        title: "Error",
        message: "El teléfono no puede estar vacío",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
      return;
    }
    // Crear configuracion de notificaciones
    dispatch(
      httpEditConfigNotifications(
        notificationData.email,
        Number(notificationData.telefono),
        configId ?? undefined,
        Number(notificationData.whatsapp) ?? undefined
      )
    );
  };

  const resetState = () => {
    setNotificationData({
      email: "",
      telefono: "",
      whatsapp: "",
    });
    setConfigId(null);
  };

  // const handleDeleteConfig = () => {
  //   if (configId === null) return;
  //   dispatch(httpDeleteConfigNotifications(configId, resetState));
  // };

  useEffect(() => {
    if (configNotifications.email !== null) {
      setNotificationData({
        email: configNotifications.email,
        telefono: configNotifications.telefono,
        whatsapp: configNotifications.whatsapp,
      });
      setConfigId(configNotifications?.id);
    } else {
      resetState();
    }
  }, [configNotifications]);

  useEffect(() => {
    dispatch(httpGetAllConfigNotificationsAsync());
  }, []);

  return (
    <ContainerStyled>
      <HeaderContainerStyled>
        <h2>Medios de Contacto</h2>
      </HeaderContainerStyled>
      <FormStyled>
        <Field
          label="Email"
          name="email"
          placeholder="Email"
          value={notificationData.email}
          type="email"
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
        <Field
          label="Teléfono"
          name="telefono"
          placeholder="Número de teléfono"
          value={notificationData.telefono}
          type="number"
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
        <Field
          label="Whatsapp"
          name="whatsapp"
          placeholder="Número de Whatsapp"
          value={notificationData.whatsapp}
          type="number"
          isRequired={false}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </FormStyled>
      <ContainerButtonStyled>
        {/* {isLoadingDeleteConfigNotifications ? (
          <div style={{ display: "flex", marginRight: "65px" }}>
            <Spinner />
          </div>
        ) : (
          <Button
            text="Eliminar"
            type="button"
            onClick={handleDeleteConfig}
            color="secondary"
            height="40px"
          ></Button>
        )} */}
        {isLoadingEditConfigNotifications ? (
          <div style={{ display: "flex", marginLeft: "45px" }}>
            <Spinner />
          </div>
        ) : (
          <Button
            text="Guardar"
            type="button"
            onClick={handleSubmit}
            backgroundColor="secondary"
            height="40px"
          ></Button>
        )}
      </ContainerButtonStyled>
    </ContainerStyled>
  );
};

export default Notifications;
