/* eslint-disable no-unused-vars */

import React from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import { ContainerButtonStyled } from "../../../../PurchaseRegret/styles/FormStyles";
// Components
import Button from "../../../../../components/button/Button";
import useIndexBusinessParnerHook from "../../../../PurchaseForm/hooks/useIndexBusinessParnerHook";
import PartnerBusinessForm from "../../../../PurchaseForm/components/PartnerBusinessForm";
import ModalBase from "../../../../../components/modalBase/ModalBase";
import ModalPartnerBusinessSelector from "../../../../PurchaseForm/components/ModalPartnerSelector";

const Managment = () => {
  const {
    formData,
    setFormData,
    validationPartnerBusinessHook,
    handleContinue,
    isEmptyFields,
    isValidForm,
    showModal,
    closeModal,
    setIsSocioSeteado,
    currentPartnerBusiness,
    isLoadingCrearSocio
  } = useIndexBusinessParnerHook();

  return (
    <ContainerStyled>
      <PartnerBusinessForm
        formData={formData}
        setFormData={setFormData}
        validationPartnerBusinessHook={validationPartnerBusinessHook}
      />
      <ContainerButtonStyled>
        <Button
          height="40px"
          backgroundColor="secondary"
          onClick={() => handleContinue(false)}
          disabled={isEmptyFields || isLoadingCrearSocio || !isValidForm}
          text={isLoadingCrearSocio ? "Cargando..." : "Guardar cambios"}
        />
      </ContainerButtonStyled>
      {
        // si no exite socio de negocio asociado se muestra el modal
        (currentPartnerBusiness.socioNegocioId === null ||
          currentPartnerBusiness.socioNegocioId === 0) &&
        <ModalBase
          show={showModal}
          onClose={closeModal}
          height="50vh"
        >
          <ModalPartnerBusinessSelector
            closeModal={closeModal}
            setFormData={setFormData}
            setIsSocioSeteado={setIsSocioSeteado}
          />
        </ModalBase>
      }
    </ContainerStyled>
  );
};

export default Managment;
