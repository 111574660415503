import React from "react";
import { ModalCategoryContainerStyled } from "../../styles/ModalConfirmation";
import Button from "../../../../components/button/Button";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import httpDeleteValue from "../../../../services/Categorization/Definitions/Attributes/useHttpDeleteValue";
import Spinner from "../../../../components/spinner/Spinner";

interface IModal {
  idValue: number;
  setShowModalDeleteValue: (value: boolean) => void;
  refreshValues: boolean;
  setRefreshValues: (value: boolean) => void;
  positionY?: number;
}

const ModalConfirmationValue = ({
  idValue,
  refreshValues,
  setRefreshValues,
  setShowModalDeleteValue,
  positionY,
}: IModal) => {
  const dispatch = useAppDispatch();
  const { httpDeleteValueAsync } = httpDeleteValue();
  const isLoadingDeleteValue = useAppSelector(
    (state) => state.isLoadingAttributes.loadingDeleteValue
  );

  const handleDeleteAttribute = () => {
    dispatch(
      httpDeleteValueAsync(
        idValue,
        refreshValues,
        setRefreshValues,
        setShowModalDeleteValue
      )
    );
  };

  const positionYModal =
    positionY && positionY > 1000 ? Number(positionY) + 300 : positionY;

  return (
    <ModalCategoryContainerStyled positionY={positionYModal}>
      <b>¿Desea eliminar el valor seleccionado?</b>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button
          text="Cancelar"
          onClick={() => setShowModalDeleteValue(false)}
        />
        {isLoadingDeleteValue ? (
          <Spinner />
        ) : (
          <Button
            text="Eliminar"
            backgroundColor="secondary"
            onClick={handleDeleteAttribute}
          />
        )}
      </div>
    </ModalCategoryContainerStyled>
  );
};

export default ModalConfirmationValue;
