import React from "react";
import { NoteDetailStyled } from "../../../../../styles/CollectionReportStyled";

interface INoteDetails {
  nota: string;
}

const NoteDetails = ({ nota }: INoteDetails) => (
  <NoteDetailStyled>Nota: {nota}</NoteDetailStyled>
);

export default NoteDetails;
