// Este slice corresponde a los modales que se muestran al usuario
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  modalLogin: false,
  redirectHome: false,
};

export const loadingSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalLogin: (state, action: PayloadAction<boolean>) => {
      state.modalLogin = action.payload;
    },
    setRedirectHome: (state, action: PayloadAction<boolean>) => {
      state.redirectHome = action.payload;
    },
  },
});

export const { setModalLogin, setRedirectHome } = loadingSlice.actions;

export default loadingSlice.reducer;
