// Styles
import {
  ErrorMsg,
  LabelStyled,
  InputContainer,
  InputSelectStyled,
} from "../styles/FormStyles";
// Models
import { ISelect } from "../types/Select.model";

const Select = ({
  label,
  name,
  options,
  value,
  onChange,
  isDisabled,
}: ISelect) => (
  <InputContainer>
    <LabelStyled htmlFor={label}>{label}</LabelStyled>
    <InputSelectStyled
      id={label}
      name={name}
      onChange={onChange}
      value={value}
      required
      disabled={isDisabled}
    >
      <option value="">Seleccionar...</option>
      {options?.map((option) => (
        <option
          style={{
            textTransform: "uppercase",
          }}
          value={option.id}
          key={option.id}
        >
          {(option.nombre || option.descripcion).toUpperCase()}
        </option>
      ))}
    </InputSelectStyled>
    {(value === "" || value === undefined || value === null) && (
      <ErrorMsg>Campo requerido</ErrorMsg>
    )}
  </InputContainer>
);

export default Select;
