/* eslint-disable indent */
import { Routes } from "../../routes";
import { AppThunk, RootState } from "../../../app/store";
import { setLoadingEditAllDifferentiation } from "../../../features/loading/account/myProducts/loadingsReducer";
import env from "../../../environment";
import { useAppSelector } from "../../../app/hooks";
import validateUnauthorized from "../../../utils/validateUnauthorized";
import { viewNotification } from "../../../utils/ViewNotification";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";

function useHttpUpdateAllDifferentiation() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpUpdateAllDifferentitationAsync =
    (
      clientGroupId: number,
      differentiation: number,
      refreshTable?: boolean,
      setRefreshTable?: (value: boolean) => void,
      setShowModal?: (value: boolean) => void
    ): AppThunk =>
    async (dispatch) => {
      dispatch(setLoadingEditAllDifferentiation(true));
      try {
        const response = await fetch(
          `${env.catalogo}${Routes.editAllDifferentiations}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              grupoClienteId: clientGroupId,
              diferenciacion: differentiation,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        const message = response.ok
          ? "Artículos editados correctamente"
          : "Error al editar artículos";
        const type = response.ok ? "success" : "danger";
        viewNotification(message, type);
        setRefreshTable && setRefreshTable(!refreshTable);
        setShowModal && setShowModal(false);
        dispatch(setLoadingEditAllDifferentiation(false));
      } catch (error) {
        dispatch(setLoadingEditAllDifferentiation(false));
      }
      dispatch(setLoadingEditAllDifferentiation(false));
    };
  return {
    httpUpdateAllDifferentitationAsync,
  };
}

export default useHttpUpdateAllDifferentiation;
