import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../app/hooks";
import docIcon from "../../../../../assets/icons/documento.png";
// Styles
import {
  ButtonDownload,
  ContainerAttachments,
  ContainerModalAttachments,
} from "./styles";
// Services
import useHttpDownloadTransferAttachments from "../../../../../../../services/Transfers/Adjunto/useHttpDownloadTransferAttachments";
import Spinner from "../../../../../../../components/spinner/Spinner";

const ModalAttachments = () => {
  const dispatch = useAppDispatch();

  const { httpDownloadTransferAttachmentsAsync } =
    useHttpDownloadTransferAttachments();

  const data = useAppSelector(
    (state) => state.transferAttachments.comprobantes
  );

  const isLoadingAttachments = useAppSelector(
    (state) => state.isLoadingTransferAttachments.loadingTransferAttachments
  );

  const handleDownloadFile = (fileKey: string) => {
    dispatch(httpDownloadTransferAttachmentsAsync(fileKey));
  };

  return (
    <ContainerModalAttachments>
      {isLoadingAttachments ? (
        <Spinner />
      ) : (
        data.map((comprobante, index) => (
          <ContainerAttachments key={comprobante.id}>
            <img src={docIcon} alt="Comprobante" style={{ width: "85px" }} />
            <b>{index + 1}</b>
            <a href={comprobante.url} target="_blank">
              Ver
            </a>
            <ButtonDownload
              onClick={() => handleDownloadFile(comprobante.fileKey)}
            >
              Descargar
            </ButtonDownload>
          </ContainerAttachments>
        ))
      )}
    </ContainerModalAttachments>
  );
};

export default ModalAttachments;
