import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  configurado: false,
  mensaje: "",
};

export const paymentGatewaySlice = createSlice({
  name: "validateConfigPaymentGateway",
  initialState,
  reducers: {
    setValidateConfigPaymentGateway: (state, action: PayloadAction<any>) => {
      state.configurado = action.payload.configurado;
      state.mensaje = action.payload.mensaje;
    }
  }
});

export const { setValidateConfigPaymentGateway } = paymentGatewaySlice.actions;

export default paymentGatewaySlice.reducer;
