import useLocalStorageHook from "../utils/useLocalStorageHook";
import { useAppSelector } from "../app/hooks";

/**
 * Manage security tools
 */
const useSecurityHook = () => {
  const empresaId = useAppSelector(
    (state) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();
  /**
  * Logout current user and clean her data from localStorage
  */
  const logout = () => {
    if (obtenerToken() !== undefined) {
      localStorage.removeItem(empresaId.toString());
    }
  };

  return {
    logout
  };
};

export default useSecurityHook;
