import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContainerStyled = styled.nav`
  display: flex;
  justify-content: space-around;
  height: 64px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.main.primary};
  // padding: 0 9% 0 3%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

// Logo
export const LogoContainerStyled = styled.div`
  position: absolute;
  left: 6%;
`;

export const LogoImagenStyled = styled.img`
  width: 125px;
`;

export const TitleStyled = styled.h1`
  font-weight: 400;
  font-size: 32px;
  color: ${(props) => props.theme.colors.text.first};
`;

export const NavStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 670px) {
    display: none;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text.first};
  font-size: 15px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;

  &:hover {
    padding-bottom: 10px;
    border-bottom: 4px solid ${(props) => props.theme.colors.main.secondary};
    color: ${(props) => props.theme.colors.text.first};
  }
`;

export const AStyled = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text.first};
  font-size: 15px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;

  &:hover {
    padding-bottom: 10px;
    border-bottom: 4px solid ${(props) => props.theme.colors.main.secondary};
    color: ${(props) => props.theme.colors.text.first};
  }
`;

export const SearchStyled = styled.div`
  background-color: white;
  width: 50%;
  padding: 4px;
  border-radius: 10px;
  height: auto;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 8px;
`;

export const InputStyled = styled.input`
  border: none;
  font-size: 18px;
  color: ${(props) => props.theme.colors.text.fourth};
  background-color: white !important;
  outline: none;
  width: 100%;
  margin-left: 120px;
`;

export const BurguerMenuContainerStyled = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: none;

  @media (max-width: 670px) {
    display: block;
  }
`;

export const BurguerMenuButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSearchStyled = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 36px;
  width: 36px;
  margin-right: 10px;
  background-color: ${(props) => props.theme.colors.main.tertiary};
  border-radius: 10px;
`;

export const SearchLogoStyled = styled.img`
  width: 25px;
`;

export const OptionStyled = styled.option`
  color: ${(props) => props.theme.colors.text.sixth};
  font-size: 16px;
  font-weight: 600;
`;

export const SelectStyled = styled.select`
  height: 35px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.main.tertiary};
  padding: 4px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.text.sixth};
  font-weight: bold;
  outline: none;
  background-color: white;
  /* &::selection {
    background-color: red;
    color: green;
  }  */
`;
