/* eslint-disable indent */
import styled, { keyframes } from "styled-components";
import IItemStyled from "../../../types/IItemStyled";
import IImgStyled from "../../../types/IImgStyled";

export const ContainerStyled = styled.div`
  background-color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
  overflow: hidden;
`;

export const TitleStyled = styled.p`
  color: white;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
`;

export const CategoryStyled = styled.span`
  font-weight: bold;
  font-size: 40px;
  // poner en mayusculas
  text-transform: uppercase;
  color: ${(props) => props.color || "green"};
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 500px;
  width: 750px;
  flex-direction: column;
`;

export const RowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const RowContainerStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 50%;
`;

export const DescriptionStyled = styled.span`
  font-weight: 900;
  font-family: "Open Sans";
  font-size: 40px;
  color: ${(props) =>
    props.theme.colors.main.primaryLight
      ? props.theme.colors.main.primaryLight
      : props.theme.colors.main.primary};
  display: none;
  opacity: 0;
`;

export const ImgStyled = styled.img<IImgStyled>`
  width: ${({ width }: IImgStyled) =>
    typeof width === "number" ? `${width}%` : width};
  height: ${({ height }: IImgStyled) =>
    typeof height === "number" ? `${height}%` : height};
  max-height: 100%;
  max-width: 100%;
  margin-right: 10px;
`;

// variables de ajuste para alto y ancho de itemStyled
const widthCorrection = 1;
const heightCorrection = 3;

// Frames animation
const showIt = keyframes`
    to{ opacity: 1}
`;

const translateX = keyframes`
   from {
    margin-left: 100%;
    margin-top: 20%;
    width: 10%;
  }
  to {
    margin-left: 53%;
    margin-top: 0%;
    width: 53%;    
  }
`;

const translateY = keyframes`
   from {
    margin-top: 100%;
    margin-bottom: -100%;   
    height: 10%;   
  }
  to {   
    margin-bottom: -10%;
    height: 40%;  
  }
`;

export const ItemStyled = styled.div<IItemStyled>`
  background: ${(props) =>
    `linear-gradient(298.47deg, #141414 8.36%, ${props.theme.colors.main.primary} 88.17%)`};
  border: 3px solid ${(props) =>
    props.borderColor === "light" && props.theme.colors.main.primaryLight
      ? props.theme.colors.main.primaryLight
      : props.theme.colors.main.primary}};  
  border-radius: 20px;
  width: ${({ width }: IItemStyled) => width - widthCorrection}%;
  height: ${({ height }: IItemStyled) => height - heightCorrection}%;
  display: flex;
  flex-direction: ${({ flex_direction }: IItemStyled) =>
    flex_direction || "row"};
  justify-content: center;
  align-items: center;
  align-self: ${({ align_self }: IItemStyled) => align_self || "flex-start"};
  transition: width 1s, height 1s;

  .imageItem5 {
    margin-bottom: -60%;
    margin-left: 40%;
    transform: scale(0.7);
    transition: transform 0.7s;
  }

  .imageItem5-2 {
    margin-top: -50%;
    margin-right: 40%;
    transform: scale(0.7);
    transition: transform 0.7s;
  }

  .imageItem6 {
    margin-left: 10%;
  }

  &:hover {
    .image {
      transform: scale(0.5);
      transition: transform 1s;
    }

    ${DescriptionStyled} {
      display: flex;
      animation: 1s ${showIt} forwards;
    }

    .titleItem1 {
      display: flex;
      margin-left: -20%;
    }

    .imageItem1 {
      margin-left: -20%;
      transition: transform 0.8s;
      transform: translateX(-20%), scale(0.5);
    }

    .titleItem2 {
      display: flex;
      justify-self: center;
      margin-left: 0px;
      margin-top: 17%;
      margin-bottom: -25%;
    }

    .imageItem2 {
      margin-top: 10%;
      transform: scale(0.6);
      transition: transform 1s;
    }

    .titleItem3 {
      display: flex;
      align-self: flex-end;
      margin-right: 5%;
      margin-bottom: -5%;
      opacity: 1;
      animation: 0.7s ${translateX};
    }

    .imageItem3 {
      margin-bottom: -7%;
      transform: scale(1);
      transition: transform 1s;
    }

    .titleItem4 {
      display: flex;
      white-space: nowrap;
      margin-left: 22%;
      opacity: 1;
    }

    .imageItem4 {
      margin-left: -14%;
      transform: scale(0.6);
      transition: transform 0.7s;
    }

    .titleItem5 {
      display: flex;
      opacity: 1;
      margin-top: -30%;
      margin-bottom: 20%;
      animation: 0.7s ${translateY};
    }

    .imageItem5 {
      margin-left: 25%;
      margin-bottom: -50%;
      transform: scale(0.7);
      transition: transform 0.7s;
    }

    .imageItem5-2 {
      margin-top: -50%;
      margin-right: 25%;
      transform: scale(0.5);
      transition: transform 0.7s;
    }

    .titleItem6 {
      display: flex;
      opacity: 1;
      margin-top: 10%;
      margin-left: -22%;
    }

    .imageItem6 {
      margin-left: -15%;
      transform: scale(0.7);
      transition: transform 0.7s;
    }
  }
`;
