import React from "react";
// Styles
import { ContainerStyled } from "../../../styles/styled";
import {
  AvatarStyled,
  CardProfileStyled,
  ContainerDataStyled,
} from "../../../styles/ProfileStyled";
// Assets
import avatarUndefined from "../../../../../assets/icons/avatar.png";
// Components
import Button from "../../../../../components/button/Button";
// Models
import { UserData } from "../../../../../models/UserData";
// Hooks
import useLocalStorageHook from "../../../../../utils/useLocalStorageHook";

const Profile = () => {
  const { obtenerUserData, obetenerRol } = useLocalStorageHook();

  const userData: UserData = obtenerUserData() || ({} as UserData);

  const returnAvatar = () => {
    let avatar = "";
    if (userData?.avatar === "") {
      avatar = avatarUndefined;
    } else if (userData?.avatar === null) {
      avatar = avatarUndefined;
    } else if (userData?.avatar === undefined) {
      avatar = avatarUndefined;
    } else {
      avatar = userData?.avatar;
    }
    return avatar;
  };

  return (
    <ContainerStyled>
      {/* Card de perfil */}
      <CardProfileStyled>
        <ContainerDataStyled>
          <AvatarStyled src={returnAvatar()} alt="avatar" />
          <div>
            <b>
              {userData?.nombre} {userData?.apellido}
            </b>
            <p>{obetenerRol()}</p>
            <p>Email: {userData?.email}</p>
            {userData?.telefono === "" || userData?.telefono === undefined ? null : (
              <p>Teléfono: {userData?.telefono}</p>
            )}
          </div>
        </ContainerDataStyled>
        <Button text="Editar" backgroundColor="secondary" width="120px" />
      </CardProfileStyled>
      {/* oculto */}
      {/* <ContainerTitleStyled>
        <b style={{ fontSize: "30px" }}>Guardados</b>
        <Button text="Ver todo" width="120px"/>
      </ContainerTitleStyled>
      <CarouselRecommended products={data} separation={30} /> */}
      {/* Valoraciones recientes */}
      {/* <ContainerTitleRatingsStyled>
        <b style={{ fontSize: "30px" }}>Valoraciones recientes</b>
        <Button text="Ver todo" width="120px"/>
      </ContainerTitleRatingsStyled>
      <CarouselRatings products={data} separation={54} /> */}
    </ContainerStyled>
  );
};

export default Profile;
