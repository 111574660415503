import React, { forwardRef } from "react";
import {
  DateStyled,
  MainStyled,
  DeleteStyled,
  HeaderStyled,
  CommentsStyled,
  UsernameStyled,
  LeftHeaderStyled,
  RightHeaderStyled,
  ImgContainerStyled,
  ContainerCommentStyled,
} from "../styles/Comment";
// Components
import { Rating } from "react-simple-star-rating";
// Assets
import Avatar from "../../../assets/icons/avatar.png";
import StarIcon from "../../../components/iconsComponents/StarIcon";
// Utils
import { DateFormater } from "../utils/DateFormater";
import useLocalStorageHook from "../../../utils/useLocalStorageHook";
import { UserRol } from "../../../types/enum/RolesUsuario";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import Spinner from "../../../components/spinner/Spinner";
import useHttpDeleteComment from "../../../services/CommentsAndRatings/useHttpDeleteComment";

interface IComentario {
  id: number;
  apellido?: string;
  comentario: string;
  fecha: string;
  nombre?: string;
  valoracion: number;
}

const Comment = forwardRef<HTMLDivElement, IComentario>(
  ({ nombre, apellido, comentario, fecha, valoracion, id }, ref) => {
    const dispatch = useAppDispatch();
    const { httpDeleteCommentAsync } = useHttpDeleteComment();
    const { obetenerRol } = useLocalStorageHook();
    /**
     * Maneja la eliminación de un comentario.
     */
    const handleDeleteComment = () => {
      dispatch(httpDeleteCommentAsync(id));
    };

    const isLoadingDelete = useAppSelector(
      (state) => state.isLoadingCommentsAndRatings.loadingDeleteComments
    );

    return (
      <ContainerCommentStyled ref={ref}>
        <HeaderStyled>
          <LeftHeaderStyled>
            <ImgContainerStyled>
              <img
                src={Avatar}
                alt="Avatar icon"
                style={{ width: "45px", marginBottom: "15px" }}
              />
            </ImgContainerStyled>
            <UsernameStyled>
              {nombre} {apellido}
            </UsernameStyled>
          </LeftHeaderStyled>

          <RightHeaderStyled>
            <Rating
              className="rating"
              initialValue={valoracion}
              allowHover={false}
              readonly
              emptyIcon={<StarIcon icon="starGrey" width={24} height={27} />}
              fillIcon={
                <StarIcon icon="starIconFilled" width={24} height={27} />
              }
            />
            <DateStyled>{DateFormater(fecha)}</DateStyled>
          </RightHeaderStyled>
        </HeaderStyled>
        <MainStyled>
          <CommentsStyled>{comentario}</CommentsStyled>
          {obetenerRol() === UserRol.Seller && (
            <DeleteStyled
              onClick={handleDeleteComment}
              disabled={isLoadingDelete}
            >
              {isLoadingDelete ? <Spinner /> : "Eliminar"}
            </DeleteStyled>
          )}
        </MainStyled>
      </ContainerCommentStyled>
    );
  }
);
export default Comment;
