import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Parametrizacion } from "../../models/Parametrization/Parametrizacion.model";

const initialState = {
  data: [] as any,
};

export const parametrizationSlice = createSlice({
  name: "parametrization",
  initialState,
  reducers: {
    setParametrization: (state, action: PayloadAction<Parametrizacion>) => {
      state.data = action.payload;
    },
  },
});

export const {
  setParametrization,
} = parametrizationSlice.actions;

export default parametrizationSlice.reducer;
