import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingAddOrUpdateSubcategories: false,
  loadingDeleteSubcategory: false,
  loadingSubcategories: false,
  loadingSubcategoriesByCategory: false,
  loadingValidateDeleteSubcategory: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingAddOrUpdateSubcategories: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingAddOrUpdateSubcategories = action.payload;
    },
    setLoadingDeleteSubcategory: (state, action: PayloadAction<boolean>) => {
      state.loadingDeleteSubcategory = action.payload;
    },
    setLoadingSubcategories: (state, action: PayloadAction<boolean>) => {
      state.loadingSubcategories = action.payload;
    },
    setLoadingSubcategoriesByCategory: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingSubcategoriesByCategory = action.payload;
    },
    setLoadingValidateDeleteSubcategory: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingValidateDeleteSubcategory = action.payload;
    },
  },
});

export const { setLoadingValidateDeleteSubcategory, setLoadingSubcategoriesByCategory, setLoadingAddOrUpdateSubcategories, setLoadingDeleteSubcategory, setLoadingSubcategories } = loadingsSlice.actions;

export default loadingsSlice.reducer;
