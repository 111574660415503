// Styles
import {
  ErrorMsg,
  LabelStyled,
  InputContainer,
  InputMidStyled,
  InputSelecMidtStyled,
} from "../styles/FormStyles";
// Models
import { IFieldDouble } from "../types/FieldDouble.model";

const FieldDouble = ({
  type,
  valuePrimary,
  valueSecondary,
  namePrimary,
  nameSecondary,
  isDisabled,
  label,
  options,
  onChange,
  onBlurAction,
  validationError
}: IFieldDouble) => (
  <InputContainer>
    <LabelStyled htmlFor={label}>{label}</LabelStyled>
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      <InputSelecMidtStyled
        id={label}
        name={namePrimary}
        onChange={onChange}
        value={valuePrimary}
        required
        disabled={isDisabled}
      >
        {options?.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </InputSelecMidtStyled>
      <InputMidStyled
        type={type}
        name={nameSecondary}
        value={valueSecondary}
        onChange={onChange}
        required
        disabled={isDisabled}
        onBlur={onBlurAction}
      />
    </div>
    {(validationError !== "") && (
      <ErrorMsg>{validationError}</ErrorMsg>
    )}
  </InputContainer>
);

export default FieldDouble;
