import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICommentBox {
    idArticle: number;
}

const initial: ICommentBox = {
  idArticle: 0,
};

export const CommentBoxSlice = createSlice({
  name: "commentBox",
  initialState: initial,
  reducers: {
    setIdArticleCommentBox: (state, action: PayloadAction<number>) => {
      state.idArticle = action.payload;
    }
  },
});

export const { setIdArticleCommentBox } = CommentBoxSlice.actions;

export default CommentBoxSlice.reducer;
