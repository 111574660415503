/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { viewNotification } from "../../utils/ViewNotification";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setLoadingAddOrUpdateComments } from "../../features/loading/commentsAndRatings/loadingReducer";
import { resetCurrentComment } from "../../features/commentsAndRatings/commentsAndRatingsReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpAddOrUpdateComment() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const articuloId = useAppSelector(
    (state: RootState) => state.commentBox.idArticle
  );
  const currentId = useAppSelector(
    (state: RootState) => state.comments.currentComment.id
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpAddOrUpdateCommentAsync =
    (
      valoracion: number,
      comentario: string,
      fecha: string,
      setShowModalBoxComment: (value: boolean) => void
    ): AppThunk =>
    async (dispatch: any) => {
      dispatch(setLoadingAddOrUpdateComments(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.addOrUpdateComments}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify({
              articuloId,
              valoracion,
              comentario,
              fecha,
              id: currentId,
            }),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.ok) {
          viewNotification("Comentario enviado correctamente", "success");
          dispatch(resetCurrentComment());
          // Cerrar modal
          setShowModalBoxComment(false);
        }
        if (!response.ok) {
          viewNotification(
            "Error al enviar comentario, intente nuevamente",
            "danger"
          );
        }
        dispatch(setLoadingAddOrUpdateComments(false));
      } catch (error) {
        dispatch(setLoadingAddOrUpdateComments(false));
        viewNotification(
          "Error al enviar comentario, intente nuevamente",
          "danger"
        );
      }
    };
  return {
    httpAddOrUpdateCommentAsync,
  };
}

export default useHttpAddOrUpdateComment;
