// Función para formatear los precios
export const addPoints = (num: number) => {
  const price = num?.toString().split(".")[0];
  // Agregar punto de miles
  const priceWithPoints = price?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return priceWithPoints;
};

// Función para capturar los decimales
export const getDecimals = (num: number) => {
  const decimals = num?.toFixed(2).split(".")[1];
  return decimals || "00";
};

export const cambiarPuntoPorComas = (numero: number) => {
  const parts = numero?.toString().split(".");
  // Agregar punto de miles
  const priceWithPoints = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  // Si el número tiene decimales, cambiar punto por coma en la parte decimal y dejar solo 2 decimales
  const decimalWithComma = parts[1] ? parts[1].replace(/\./g, ",").slice(0, 2) : "";
  return decimalWithComma ? `${priceWithPoints},${decimalWithComma}` : priceWithPoints;
};
