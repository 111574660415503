/* eslint-disable */
// routes
export enum Routes {
  filters = "api/Filtro/GetFitrosDropDown",
  searchResults = "api/SearchElastic/SearchStandardArticuloElastic",
  // searchResults = 'Error500TestHandle',
  filtersAttibutes = "api/Filtro/Atributos",
  searchResultsVin = "api/SearchElastic/SearchWithVinArticuloElastic",
  productDetails = "api/SearchElastic/GetArticulosByIdsElastic",
  filtersSubcategories = "api/Filtro/ObtenerSubcategoriasPorCategoria",
  getRatingAndCommentsByProductId = "getRatingAndCommentsByProductId/{id}",
  suggestSearchPhraseElastic = "api/SearchElastic/SuggestSearchPhraseElastic",
  // Payment:
  generatePaymentIntention = "api/OrdenCompra/GenerarOrdenCompra",
  // Categorization - Articulos / Atributos / Valores
  getValues = "api/Categorizacion/Articulos/GetAtributosValores",
  getAttributes = "api/Categorizacion/Articulos/GetAtributosNombres",
  getArticles = "api/Categorizacion/Articulos/GetArticulosCategorizados",
  editSubcategory = "api/Categorizacion/Articulos/EditarSubcategoriaArticulo",
  getAttributesInArticle = "api/Categorizacion/Articulos/ArticulosAtributosValores",
  deleteAttributeInArticle = "api/Categorizacion/Articulos/EliminarArticuloAtributoValor",
  addAttributeInArticle = "api/Categorizacion/Articulos/AgregarOActualizarArticuloAtributosValores",
  // Categorization - Definiciones
  getCategories = "api/Categorizacion/Definiciones/CategoriaArticulo",
  getAttributesDefinitions = "api/Categorizacion/Definiciones/Atributo",
  getValuesDefinitions = "api/Categorizacion/Definiciones/ValorAtributo",
  getSubcategories = "api/Categorizacion/Definiciones/SubCategoriaArticulo",
  deleteSubcategory = "api/Categorizacion/Definiciones/SubCategoriaArticulo/Delete",
  addOrUpdateSubcategories = "api/Categorizacion/Definiciones/SubCategoriaArticulo/AddOrUpdate",
  getValidateSubcategory = "api/Categorizacion/Definiciones/SubCategoriaArticulo/ValidateDelete",
  getSubcategoriesByCategory = "api/Categorizacion/Definiciones/SubCategoriaArticulo/ListByCategoria",
  addOrUpdateAttribute = "api/Categorizacion/Definiciones/Atributo/AddOrUpdate",
  getAttributeValuesById = "api/Categorizacion/Definiciones/ValorAtributo/GetByAtributoId",
  deleteAttribute = "api/Categorizacion/Definiciones/Atributo/Delete",
  addOrUpdateAttributeValue = "api/Categorizacion/Definiciones/ValorAtributo/AddOrUpdate",
  deleteAttributeValue = "api/Categorizacion/Definiciones/ValorAtributo/Delete",
  // Account - My Products
  getPublishedArticles = "api/Publicacion/GetArticulosParaPublicacion",
  editDeffinitions = "api/Publicacion/DiferenciacionPrecios/ActualizarSeleccionados",
  editLastUnits = "api/Publicacion/UltimasUnidades/ActualizarSeleccionados",
  editAllDifferentiations = "api/Publicacion/DiferenciacionPrecios/ActualizarTodos",
  editAllLastUnits = "api/Publicacion/UltimasUnidades/ActualizarTodos",
  publishAllArticles = "api/Publicacion/PublicarTodosLosArticulos",
  unpublishAllArticles = "api/Publicacion/DespublicarTodosLosArticulos",
  singlePost = "api/Publicacion/PublicarArticulos",
  singleUnpost = "api/Publicacion/DespublicarArticulos",
  // Security cognito
  register = "api/Cognito/Registrar",
  login = "api/Cognito/IniciarSesion",
  closeSession = "api/Cognito/CerrarSesion",
  emailConfirmation = "api/Cognito/ConfirmarCuenta",
  resetPassword = "api/Cognito/RestablecerContrasenia",
  forgotPassword = "api/Cognito/EnviarCodigoParaRecuperarContrasenia",
  resendCodeVerification = "api/Cognito/ReenviarCodigoVerificacionCuenta",
  // security proveedores
  googleSignIn = "api/ProveedorAutenticacion/IniciarSesionGoogle",
  facebookSignIn = "api/ProveedorAutenticacion/IniciarSesionFacebook",
  // Security seller
  dmsLogin = "api/ProveedorAutenticacion/IniciarSesionVendedorDms",
  // Security app configuration
  appConfiguration = "api/ECommerceSeguridad/Empresa/GetInitialAppConfiguration",
  clientGroup = "api/ECommerceSeguridad/GrupoClientes/GetFromEmpresaId",
  getTokenDms = "api/ECommerceSeguridad/Empresa/GetTokenDmsToLogin",
  updateTokenDms = "api/ECommerceSeguridad/Empresa/UpdateTokenDms",
  saveThemeColors = "api/ApiColorTemaAplicacion/AddOrUpdate",
  // Carrito
  quitCart = "api/CarritoDeCompras/AbandonarCarrito",
  getCart = "api/CarritoDeCompras/ObtenerCarritoDelUsuario",
  detailsCart = "/api/CarritoDeCompras/GetDetallesArticulos",
  removeProductFromCart = "api/CarritoDeCompras/QuitarArticulo",
  addProductToCart = "api/CarritoDeCompras/AgregarArticulosODefinirCantidades",
  // BuyForm
  obtenerTiposPersonas = "api/SocioNegocio/ObtenerTiposPersonas",
  obtenerTiposInmuebles = "api/SocioNegocio/ObtenerTiposInmuebles",
  ObtenerLocalidadesPorProvincia = "api/Localidad/GetLocalidadesByProvinciaPaginado",
  ObtenerProvincias = "api/SocioNegocio/ObtenerProvincias",
  obtenerCondicionesIva = "api/SocioNegocio/ObtenerCondicionesIva",
  crearSocioDeNegocio = "api/Usuario/ActualizarDatosUsuarioDesdeFormularioSocioNegocio",
  datosSocioDeNegocio = "api/Usuario/GetUsuarioFormularioSocioNegocio",
  cpValidation = "api/Localidad/ValidarCodigoPostal",
  getSociosDeNegocioByEmail = "api/Usuario/GetSociosNegociosByEmail",
  GetEmailSocioNegocioAsociadoConDocumentoCuit = "api/Usuario/GetEmailSocioNegocioAsociadoConDocumentoCuit",
  // Catalogo
  obtenerSucursalEcommerce = "api/Sucursal/ObtenerSucursalEcomerce",
  getNewAddedProducts = "api/Articulo/ObtenerArticulosNuevosIngresos",
  // Notifications
  getConfigNotifications = "api/Configuracion/Notificacion/GetAll",
  editConfigNotifications = "api/Configuracion/Notificacion/AddOrUpdate",
  deleteConfigNotifications = "api/Configuracion/Notificacion/Delete",
  // Historial de ventas
  getCollectionReport = "api/OrdenCompra/ObtenerInformeCobroEmpresa",
  getHistorySale = "api/OrdenCompra/ObtenerHistorialVentaEmpresa",
  getShoppingHistory = "api/OrdenCompra/ObtenerHistorialComprasComprador",
  registerAcceptPayment = "api/Transferencia/RegistrarPagoTrasferencia",
  registerCancelPayment = "api/Transferencia/RegistrarCancelacionCompra",
  getDataTransfer = "api/Transferencia/GetAll",

  getUrlAuthorizeOperations = "api/PasarelaDePago/MercadoPago/GetUrlParaAutorizarOperacionesEnNombreDeVendedor",
  ConfigValidationMP = "api/CredencialPasarelaPago/IsConfigured",
  changeStaturOrder = "api/OrdenCompra/ActualizarEstadoOrdenCompra",
  // Envío de mail de arrepentimiento de compra
  sendEmailPurchaseRegret = "api/OrdenCompra/EnviarEmailArrepentimientoCompra",
  getPurchaseOrder = "api/OrdenCompra/ObtenerOrdenCompra",
  getTransferEnabled = "api/MedioDePago/ObtenerMediosDePagosHabilitados",
  postDataTransfer = "api/Transferencia/AddOrUpdate",
  // Parametrizacion
  getProcessParametrization = "api/ParametrizacionProceso/Transferencia/List",
  deleteProcessParametrization = "api/ParametrizacionProceso/Transferencia/Delete",
  editProcessParametrization = "api/ParametrizacionProceso/Transferencia/AddOrUpdate",
  //Recomendaciones
  getBestSellingArticles = "api/OrdenCompra/ObtenerArticulosMasVendidos",
  getMostViewedArticles = "api/Articulo/ObtenerArticulosSimilaresMasVistos",
  incrementViewClientArticle = "api/Articulo/IncrementarVistaClienteArticulo",
  // Manejo de transferencia
  uploadTransfer = "api/AdjuntoController/UploadAdjuntoTransferencia",
  downloadTransfer = "api/AdjuntoController/DownloadAdjunto",
  getAllTransfer = "api/AdjuntoController/GetAllAdjuntosTransferencias",
  deleteTransfer = "api/AdjuntoController/DeleteAdjuntoTransferencia",
  // Comentarios y valoraciones
  getAllComments = "api/ApiComentarioValoracionArticulo/GetAll",
  getRating = "api/ApiComentarioValoracionArticulo/ObtenerEstadisticasValoraciones",
  addOrUpdateComments = "api/ApiComentarioValoracionArticulo/AddOrUpdate",
  deleteComment = "api/ApiComentarioValoracionArticulo/Delete",
  getCommentRating = "api/ApiComentarioValoracionArticulo/ObtenerComentarioValoracion",
  // Carousel
  getCarouselImages = "api/AdjuntoController/GetAllAdjuntosCarrousel",
  uploadCarouselImages = "api/AdjuntoController/UploadAdjuntoCarrousel",
  deleteCarouselImages = "api/AdjuntoController/DeleteAdjuntosCarrousel",
  //Url donde mercado pago redirecciona
  NotificationOAuthPath = "api/PasarelaDePago/MercadoPago/NotificacionAuth",
  orderStatus = "api/OrdenCompra/ExistenOrdenesDeComprasEstadoPendienteUsuarioClienteLogueado",
}
