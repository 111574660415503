import {
  ContainerItemStyled,
  LabelItemStyled,
  PointerLineStyled,
  ProgressItemStyled,
  ProgressStyled,
} from "../styles/MyProducts";

interface IProgressBar {
  progress: number;
  setProgress: (progress: number) => void;
}

const ProgressBarProducts = ({
  progress,
  setProgress,
}: IProgressBar) => (
  <ProgressStyled>
    <ContainerItemStyled onClick={() => setProgress(0)}>
      <ProgressItemStyled active={progress === 0}></ProgressItemStyled>
      <LabelItemStyled>Grupo de clientes</LabelItemStyled>
    </ContainerItemStyled>
    <PointerLineStyled></PointerLineStyled>
    <ContainerItemStyled
      onClick={() => {
        setProgress(1);
      }}
    >
      <ProgressItemStyled active={progress === 1}></ProgressItemStyled>
      <LabelItemStyled>Definición de precios</LabelItemStyled>
    </ContainerItemStyled>
    <PointerLineStyled></PointerLineStyled>

    <ContainerItemStyled
      onClick={() => {
        setProgress(2);
      }}
    >
      <ProgressItemStyled active={progress === 2}></ProgressItemStyled>
      <LabelItemStyled>Ultimas unidades</LabelItemStyled>
    </ContainerItemStyled>
    <PointerLineStyled></PointerLineStyled>

    <ContainerItemStyled
      onClick={() => {
        setProgress(3);
      }}
    >
      <ProgressItemStyled active={progress === 3}></ProgressItemStyled>
      <LabelItemStyled>Publicación de artículos</LabelItemStyled>
    </ContainerItemStyled>
  </ProgressStyled>
);

export default ProgressBarProducts;
