import { useEffect, useState } from "react";
// Styles
import { HeaderContainerStyled } from "../../../styles/CategorizationStyled";
import { ContainerStyled } from "../../../styles/styled";
// Components
import TableSelector from "../../TableSelector";
import DefinitionsValuesTable from "../../DataTables/Definitions/DefinitionsValuesTable";
import RelatedSubcategoriesTable from "../../DataTables/Definitions/RelatedSubcategoriesTable";
// Services
import useHttpAttributes from "../../../../../services/Categorization/Definitions/Attributes/useHttpAttributes";
import useHttpCategories from "../../../../../services/Categorization/Definitions/Categories/useHttpCategories";
import useHttpSubcategories from "../../../../../services/Categorization/Definitions/Subcategories/useHttpSubcategories";
// Redux
import { useAppDispatch } from "../../../../../app/hooks";
// Models
import { Table } from "../../../models/TablesSelector";
import DefinitionsAttributesTable from "../../DataTables/Definitions/DefinitionsAttributesTable";

const Definitions = () => {
  const dispatch = useAppDispatch();
  // Services
  const { httpAttributesAsync } = useHttpAttributes();
  const { httpCategoriesAsync } = useHttpCategories();
  const { httpSubcategoriesAsync } = useHttpSubcategories();

  // UseStates
  const [active, setActive] = useState(Table.left);
  const [refreshSubCategories, setRefreshSubCategories] = useState(false);

  // Función para retornar la tabla seleccionada
  const returnTable = () => {
    if (active === Table.left) {
      return (
        <RelatedSubcategoriesTable
          refreshSubCategories={refreshSubCategories}
          setRefreshSubCategories={setRefreshSubCategories}
        />
      );
    }
    if (active === Table.center) {
      return <DefinitionsAttributesTable />;
    }
    return <DefinitionsValuesTable />;
  };

  // Peticiones de categorias y subcategorias
  useEffect(() => {
    dispatch(httpCategoriesAsync());
    dispatch(httpSubcategoriesAsync());
    dispatch(httpAttributesAsync());
  }, []);

  return (
    <ContainerStyled>
      <HeaderContainerStyled>
        <h2>Categorizaciones</h2>
      </HeaderContainerStyled>

      {/* Selector de tabla */}
      <TableSelector active={active} setActive={setActive} />
      {returnTable()}
    </ContainerStyled>
  );
};

export default Definitions;
