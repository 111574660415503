import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
// Styles
import { ContainerStyled } from "../../../../styles/styled";
import { HeaderContainerStyled } from "../../../../styles/CategorizationStyled";
// Services
import useHttpClientGroups from "../../../../../../services/AppConfiguration/useHttpClientGroups";
import useHttpGetProcessParametrization from "../../../../../../services/Parameterization/useHttpGetProcessParameterization";
// Components
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Spinner from "../../../../../../components/spinner/Spinner";
import BankTransfersTimeNotifications from "./components/BankTransfersTimeNotifications";

const TimeNotifications = () => {
  const dispatch = useAppDispatch();
  const [parent] = useAutoAnimate();

  const { httpClientGroupsAsync } = useHttpClientGroups();
  const { httpGetProcessParametrization } = useHttpGetProcessParametrization();

  const reduxTimeNotifications = useAppSelector(
    (state) => state.parametrization.data
  );

  const isLoadingProcessParametrization = useAppSelector(
    (state) => state.isLoadingParametrization.loadingGetParametrization
  );

  const [dataTimeNotifications, setDataTimeNotifications] = useState([]);

  useEffect(() => {
    dispatch(httpClientGroupsAsync());
    dispatch(httpGetProcessParametrization());
  }, []);

  useEffect(() => {
    setDataTimeNotifications(reduxTimeNotifications);
  }, [reduxTimeNotifications]);

  return (
    <ContainerStyled ref={parent}>
      <HeaderContainerStyled>
        <h2>Tiempo de notificación y desbloqueo automático</h2>
      </HeaderContainerStyled>
      {/* Card transferencias */}
      {isLoadingProcessParametrization ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <BankTransfersTimeNotifications
          dataTimeNotifications={dataTimeNotifications}
          setDataTimeNotifications={setDataTimeNotifications}
        />
      )}
    </ContainerStyled>
  );
};

export default TimeNotifications;
