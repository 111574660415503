/* eslint-disable indent */
import env from "../../environment";
import { Routes } from "../routes";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { setLoadingObtenerSocioDeNegocioPorEmail } from "../../features/loading/buyForm/loadingsReducer";
import { setSociosVinculadosAEmail } from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpObtenerSocioDeNegocioPorEmail() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpObtenerSocioDeNegocioPorEmailAsync =
    (email: string): AppThunk =>
    async (dispatch: any) => {
      dispatch(setLoadingObtenerSocioDeNegocioPorEmail(true));
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.getSociosDeNegocioByEmail}?email=${email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          // Guardar datos en redux
          const data = await response.json();
          dispatch(setSociosVinculadosAEmail(data));
        }
        dispatch(setLoadingObtenerSocioDeNegocioPorEmail(false));
      } catch (error) {
        dispatch(setLoadingObtenerSocioDeNegocioPorEmail(false));
      }
    };
  return {
    httpObtenerSocioDeNegocioPorEmailAsync,
  };
}

export default useHttpObtenerSocioDeNegocioPorEmail;
