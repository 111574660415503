import React, { useEffect } from "react";
import { AssistantTabStyled } from "./Styled";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setViewTab,
  setViewAssistant,
} from "../../features/virtualAssistant/virtualAssistantReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const AssistantTab = () => {
  const dispatch = useAppDispatch();

  const { obtenerVirtualAssistant } = useLocalStorageHook();

  const virtualAssistantLocalStorageState = obtenerVirtualAssistant();
  const showTab = useAppSelector((state) => state.virtualAssistant.viewTab);

  /**
   * Desactiva la vista de la pestaña y activa la vista del asistente.
   */

  useEffect(() => {
    dispatch(setViewTab(virtualAssistantLocalStorageState.viewTab));
  }, []);

  const handleShowTab = () => {
    dispatch(setViewTab(false));
    dispatch(setViewAssistant(true));
  };

  return (
    <AssistantTabStyled showTab={showTab} onClick={handleShowTab}>
      ◀
    </AssistantTabStyled>
  );
};

export default AssistantTab;
