import styled from "styled-components";

export const ContainerModalAttachments = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
`;

export const ContainerAttachments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ButtonDownload = styled.div`
  background-color: ${(props) => props.theme.colors.main.primary};
  color: ${(props) => props.theme.colors.text.first};
  padding: 3px 8px;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.main.secondary};
  }
`;
