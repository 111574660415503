import React, { useState, useEffect } from "react";
// Styles
import {
  ValueStyled,
  SelectStyled,
  ButtonColorStyled,
} from "../../../styles/CategorizationStyled";
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
// Components
import Pagination from "../../Pagination";
import ModalConfirmation from "../../Modals/ModalConfirmation";
import DataTable, { TableColumn } from "react-data-table-component";
// Models
import {
  IFilter,
  IArticles,
  ICategorization,
} from "../../../models/Articles.model";
// Icons
import viewIcon from "../../../assets/icons/ver.png";
import EditIcon from "../../../assets/icons/editar.png";
import TrashIcon from "../../../assets/icons/trash.png";
// Services
import useHttpArticles from "../../../../../services/Categorization/Articles/useHttpArticles";
import httpEditSubcategory from "../../../../../services/Categorization/Articles/useHttpEditSubcategory";
import useHttpAttributesInArticle from "../../../../../services/Categorization/Articles/useHttpAttributesInArticle";

interface IArticlesTable {
  idArticle: number;
  filterCode: string;
  filterDescription: string;
  resetPaginationToggle: boolean;
  articleSelected: IArticles | undefined;
  setIdArticle: React.Dispatch<React.SetStateAction<number>>;
  setViewAttributes: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPaginationToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setArticleSelected: React.Dispatch<
    React.SetStateAction<IArticles | undefined>
  >;
}

const ArticlesTable = ({
  idArticle,
  filterCode,
  setIdArticle,
  articleSelected,
  setViewAttributes,
  filterDescription,
  setArticleSelected,
  resetPaginationToggle,
  setResetPaginationToggle,
}: IArticlesTable) => {
  const dispatch = useAppDispatch();

  // Servicios
  const { httpArticlesAsync } = useHttpArticles();
  const { httpEditSubcategoryAsync } = httpEditSubcategory();
  const { httpAttributesInArticleAsync } = useHttpAttributesInArticle();

  // Estados globales
  const { articles } = useAppSelector(
    // Lista de articulos
    (state) => state.categorization as ICategorization
  );
  const isLoadingArticles = useAppSelector(
    (state) => state.isLoadingArticles.loadingArticles
  );

  // UseStates
  const valuePerPage = 10;
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSelector, setShowSelector] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // cuando se haga clic en un articulo para ver sus atributos
  const handleShowAttributes = (row: IArticles) => {
    setArticleSelected(row);
    setIdArticle(row.id);
    dispatch(httpAttributesInArticleAsync(row.id));
    setViewAttributes(true);
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  // Setear subcategoría seleccionada a un artículo
  const handleChangeSubcategory = (
    article: number,
    subcategory: number,
    subcategoryName: string
  ) => {
    if (subcategory !== 0) {
      // Enviar el id de la subcategoria y el id del articulo
      dispatch(
        httpEditSubcategoryAsync(
          article,
          subcategory,
          setShowConfirmationModal,
          subcategoryName
        )
      );
    }
  };

  // Mostrar selector de edicion de subcategoría
  const handleShowEditSelector = (idArticle: number) => {
    setShowSelector(idArticle);
    if (idArticle === showSelector) {
      setShowSelector(0);
    }
  };

  // Mostrar modal de confirmacion para eliminar una subcategoría de un artículo
  const handleShowModal = (
    idArticleParam: number,
    idSubcategoryParam: number | null
  ) => {
    if (idSubcategoryParam === null) return; // si no hay subcategoria, no se muestra el modal
    setIdArticle(idArticleParam);
    setShowConfirmationModal(true);
  };

  // Condicional para cambiar el color de la fila seleccionada
  const conditionalRowStyles = [
    {
      when: (row: IArticles) => row.id === articleSelected?.id,
      style: {
        backgroundColor: "#c9c9c9",
      },
    },
  ];

  // Columnas de la tabla
  const columns: TableColumn<IArticles>[] = [
    {
      name: "Código",
      selector: (row: IArticles) => row.codigo,
      sortable: true,
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: "Nombre",
      selector: (row: IArticles) => row.nombre,
      sortable: true,
      width: "300px",
      conditionalCellStyles: conditionalRowStyles,
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Categoría",
      selector: (row: IArticles) => row.categoria,
      sortable: true,
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: "Subcategoría",
      conditionalCellStyles: conditionalRowStyles,
      center: true,
      width: "250px",
      style: {
        backgroundColor: "#f7f7f7",
      },
      cell: (item) => {
        if (item.subCategoria && showSelector !== item.id) {
          // si hay subcategoria y no esta activo mostrar selector, se muestra la subcategoria
          return (
            <ValueStyled>
              <div className="subcategory">{item.subCategoria}</div>
              <div className="buttons">
                <ButtonColorStyled
                  color="lime"
                  onClick={() => handleShowEditSelector(item.id)}
                >
                  <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
                </ButtonColorStyled>
                <ButtonColorStyled
                  color="orange"
                  onClick={() => handleShowModal(item.id, item.subCategoriaId)}
                >
                  <img
                    src={TrashIcon}
                    alt="Eliminar"
                    style={{ width: "15px" }}
                  />
                </ButtonColorStyled>
              </div>
            </ValueStyled>
          );
        }
        return (
          // si no hay subcategoria. se muestra un select
          <div style={{ display: "flex" }}>
            <SelectStyled
              key={item.id}
              onChange={(e) => {
                handleChangeSubcategory(
                  item.id,
                  Number(e.target.value),
                  e.target.options[e.target.selectedIndex].text
                );
              }}
            >
              <option value={0}>Seleccionar</option>
              {item.filtros.map((item: IFilter) => (
                <option key={item.id} value={item.id} label={item.nombre}>
                  {item.nombre}
                </option>
              ))}
            </SelectStyled>
          </div>
        );
      },
    },
    {
      name: "Atributos",
      button: true,
      width: "100px",
      conditionalCellStyles: conditionalRowStyles,
      cell: (item) => (
        <img
          alt="ver"
          src={viewIcon}
          style={{ width: "20px" }}
          onClick={() => {
            handleShowAttributes(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    // Actualzar articulos
    setShowSelector(0);
    dispatch(
      httpArticlesAsync(
        filterDescription,
        filterCode,
        currentPage,
        valuePerPage
      )
    );
  }, [filterDescription === "" && filterCode === "", refresh]);

  return (
    <>
      <DataTable
        responsive
        pointerOnHover
        data={articles}
        columns={columns}
        onRowClicked={(row: IArticles) => {
          handleShowAttributes(row);
        }}
        progressComponent={"Cargando..."}
        progressPending={isLoadingArticles}
        noDataComponent="No se encuentran resultados"
        paginationResetDefaultPage={resetPaginationToggle}
      />
      {!isLoadingArticles && articles.length !== 0 && (
        <Pagination
          filterCode={filterCode}
          valuePerPage={valuePerPage}
          setCurrentPage={setCurrentPage}
          filterDescription={filterDescription}
          resetPaginationToggle={resetPaginationToggle}
          setResetPaginationToggle={setResetPaginationToggle}
        />
      )}
      {/* Modales de confirmación */}
      {showConfirmationModal && (
        <ModalConfirmation
          refresh={refresh}
          idArticle={idArticle}
          setRefresh={setRefresh}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
    </>
  );
};

export default ArticlesTable;
