/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react";
import { routesApp } from "./RoutesApp";
import { Route, Routes, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
// Routes
import {
  Home,
  Login,
  Details,
  Register,
  NotFound,
  LoginSeller,
  Redirection,
  PurchaseForm,
  ResetPassword,
  SearchResults,
  PaymentMethod,
  PurchaseError,
  PurchaseThanks,
  PendingPayment,
  ForgotPassword,
  PurchaseRegret,
  ShippingProcess,
  BuyConfirmation,
  EmailConfirmation,
  SparePartsWithVin,
  AccessoriesWithVin,
  SearchResultsWithVIN,
  Cart,
  Transfer,
} from "./pages";
// Rutas de configuracion de cuenta
import {
  ProfilePage,
  HistoryPage,
  BillingPage,
  ArticlesPage,
  ProductsPage,
  ManagementPage,
  DefinitionsPage,
  PaymentGateways,
  NotificationsPage,
  ActivePurchasesPage,
  PurchaseReturnsPage,
  ShoppingHistoryPage,
  CollectionReportPage,
  TimeNotificationsPage,
  ManagerPage,
  ColorsSystem,
} from "./pages/settings/pages";

// Utils
import RequireAuth from "./utils/Required/RequireAuth";
// Components
import NotShow from "./utils/NotShow";
import Header from "./components/header/Header";
import Spinner from "./components/spinner/Spinner";
import AssistantTab from "./components/virtualAssistant/AssistantTab";
import VirtualAssistant from "./components/virtualAssistant/VirtualAssistant";
import useHttpLoadAppConfiguration from "./services/AppConfiguration/useHttpLoadAppConfiguration";
import { UserRol } from "./types/enum/RolesUsuario";

const LoadRoutes = () => {
  const { aliasEmpresa } = useParams();
  const { httpLoadAppConfigurationAsync } = useHttpLoadAppConfiguration();
  const empresaId = useAppSelector(
    (state) => state.appConfigurationState.empresaId
  );
  const loadingInitialConfiguration = useAppSelector(
    (state) => state.isLoading.loadingInitialConfiguration
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(httpLoadAppConfigurationAsync(aliasEmpresa as string));
  }, [aliasEmpresa]);
  return (
    <>
      {empresaId > 0 ? (
        <>
          <Header />
          <Routes>
            {/* Rutas privadas - Configuracion */}
            <Route
              path={routesApp.profile}
              element={
                <RequireAuth>
                  <ProfilePage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.management}
              element={
                <RequireAuth>
                  <ManagementPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.activePurchases}
              element={
                <RequireAuth>
                  <ActivePurchasesPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.billing}
              element={
                <RequireAuth>
                  <BillingPage />
                </RequireAuth>
              }
            />
            {/* solo Administrador */}
            <Route
              path={routesApp.manager}
              element={
                <RequireAuth authorizedRoles={[UserRol.Administrator]}>
                  <ManagerPage />
                </RequireAuth>
              }
            />
            {/* Solo compradores */}
            <Route
              path={routesApp.shoppingHistory}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <ShoppingHistoryPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.transfer}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <Transfer />
                </RequireAuth>
              }
            />
            {/* Solo vendedores */}
            <Route
              path={routesApp.salesHistory}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <HistoryPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.collectionReport}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <CollectionReportPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.articles}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <ArticlesPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.definitions}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <DefinitionsPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.notifications}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <NotificationsPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.timeNotifications}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <TimeNotificationsPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.paymentGateways}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <PaymentGateways />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.products}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <ProductsPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.purchaseReturns}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <PurchaseReturnsPage />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.colorsSystem}
              element={
                <RequireAuth authorizedRoles={[UserRol.Seller]}>
                  <ColorsSystem />
                </RequireAuth>
              }
            />
            {/* Rutas privadas */}
            <Route
              path={routesApp.paymentMethod}
              element={
                <RequireAuth>
                  <PaymentMethod />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.purchaseForm}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <PurchaseForm />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.buyConfirmation}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <BuyConfirmation />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.purchaseThanks}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <PurchaseThanks />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.purchaseError}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <PurchaseError />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.pendingPayment}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <PendingPayment />
                </RequireAuth>
              }
            />
            <Route
              path={routesApp.shippingProcess}
              element={
                <RequireAuth authorizedRoles={[UserRol.Buyer]}>
                  <ShippingProcess />
                </RequireAuth>
              }
            />
            {/* Solo se muestra si no estas logueado */}
            <Route
              path={routesApp.login}
              element={
                <NotShow>
                  <Login />
                </NotShow>
              }
            />
            <Route
              path={routesApp.loginSeller}
              element={
                <NotShow>
                  <LoginSeller />
                </NotShow>
              }
            />
            <Route path={routesApp.loginManager} element={<Login />} />
            <Route
              path={routesApp.register}
              element={
                <NotShow>
                  <Register />
                </NotShow>
              }
            />
            {/* Rutas publicas */}
            <Route path="/" element={<Home />} />
            <Route path={routesApp.cart} element={<Cart />} />
            <Route path={routesApp.details} element={<Details />} />
            <Route path={routesApp.results} element={<SearchResults />} />
            <Route path={routesApp.redirection} element={<Redirection />} />
            <Route path={routesApp.resetPasword} element={<ResetPassword />} />
            <Route
              path={routesApp.purchaseRegret}
              element={<PurchaseRegret />}
            />
            <Route
              path={routesApp.spareParts}
              element={<SparePartsWithVin />}
            />
            <Route
              path={routesApp.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={routesApp.accessories}
              element={<AccessoriesWithVin />}
            />
            <Route
              path={routesApp.resultsVin}
              element={<SearchResultsWithVIN />}
            />
            <Route
              path={routesApp.emailConfirmation}
              element={<EmailConfirmation />}
            />
          </Routes>
          <VirtualAssistant />
          <AssistantTab />
        </>
      ) : (
        <>
          {loadingInitialConfiguration ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};
export default LoadRoutes;
