/* eslint-disable indent */
import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { setLoadingRegisterAcceptPayment } from "../../features/loading/historySale/loadingsReducer";
import {
  setCollectionReportAccept,
  updateOrderNoteNumber,
} from "../../features/salesHistory/salesHistoryReducer";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpRegisterAcceptPayment = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpRegisterAcceptPaymentAsync =
    (ordenCompraId: number): AppThunk =>
    async (dispatch: any) => {
      // Token
      const token = obtenerToken();
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingRegisterAcceptPayment(true));
      try {
        const response = await fetch(
          `${env.operaciones}${Routes.registerAcceptPayment}?ordenCompraId=${ordenCompraId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          const data = await response.json();
          const nuevoNumeroNota = Number(data.numeroNotaPedidoVenta);
          viewNotification("Pago aceptado", "success");
          dispatch(setCollectionReportAccept(ordenCompraId));
          dispatch(updateOrderNoteNumber({ ordenCompraId, nuevoNumeroNota }));
        } else {
          viewNotification("Error, intente nuevamente", "danger");
        }
        dispatch(setLoadingRegisterAcceptPayment(false));
      } catch (error) {
        viewNotification("Error, intente nuevamente", "danger");
        dispatch(setLoadingRegisterAcceptPayment(false));
      }
    };
  return { httpRegisterAcceptPaymentAsync };
};

export default useHttpRegisterAcceptPayment;
