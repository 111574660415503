// Models
import { IAttributes } from "../models/Definitions.model";
// Redux
import { useAppSelector } from "../../../app/hooks";
// Styles
import { SelectStyled } from "../styles/CategorizationStyled";

interface IAttributeSelectorProps {
  setAttributeSelectedId: (id: string) => void;
}

const AttributeSelector = ({
  setAttributeSelectedId,
}: IAttributeSelectorProps) => {
  // Estados globales
  const attributes = useAppSelector(
    // Lista de categorías disponibles
    (state) => state.attributesDefinitions as IAttributes[]
  );

  const handleChangeAttribute = (e: any) => {
    setAttributeSelectedId(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <label htmlFor="categories">Atributo:</label>
      <SelectStyled name="categories" id="" onChange={handleChangeAttribute}>
        <option value="">Seleccionar atributo</option>
        {attributes.map((attribute: IAttributes) => (
          <option key={attribute.id} value={attribute.id}>
            {attribute.descripcion}
          </option>
        ))}
      </SelectStyled>
    </div>
  );
};

export default AttributeSelector;
