import React, { useEffect, useState, Dispatch, KeyboardEvent } from "react";
// Components
import DataTable, { TableColumn } from "react-data-table-component";
import ModalConfirmationCategory from "../../Modals/ModalConfirmationCategory";
// Models
import { ISubcategories } from "../../../models/Definitions.model";

// Styles
import {
  InputStyled,
  TableStyled,
  InputEditStyled,
  ButtonAddStyled,
  ButtonColorStyled,
  ContainerInputStyled,
  ButtonSaveColorStyled,
} from "../../../styles/CategorizationStyled";
// Assets
import TrashIcon from "../../../assets/icons/trash.png";
import EditIcon from "../../../assets/icons/editar.png";
// Redux
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
// Services
import useHttpSubcategoriesByCategory from "../../../../../services/Categorization/Definitions/Subcategories/useHttpSubcategoriesByCategory";
import useHttpAddOrUpdateSubcategories from "../../../../../services/Categorization/Definitions/Subcategories/useHttpAddOrUpdateSubcategories";
import useHttpValidateDeleteSubcategory from "../../../../../services/Categorization/Definitions/Subcategories/useHttpValidateDeleteSubcategory";
// Components
import CategorySelector from "../../CategorySelector";

interface IProps {
  refreshSubCategories: boolean;
  setRefreshSubCategories: Dispatch<React.SetStateAction<boolean>>;
}

const RelatedSubcategoriesTable = ({
  refreshSubCategories,
  setRefreshSubCategories,
}: IProps) => {
  const dispatch = useAppDispatch();

  // Servicios
  const { httpAddOrUpdateSubcategoriesAsync } =
    useHttpAddOrUpdateSubcategories();
  const { httpValidateDeleteSubcategoryAsync } =
    useHttpValidateDeleteSubcategory();
  const { httpSubcategoriesByCategoryAsync } = useHttpSubcategoriesByCategory();

  // Estados globales
  const isLoadingPossibleSubcategories = useAppSelector(
    (state) => state.isLoadingSubcategories.loadingSubcategories as boolean
  );
  const subcategoriesByCategory = useAppSelector(
    // Subcategorías relacionadas a la categoría
    (state) => state.subcategoriesByCategory as ISubcategories[]
  );
  const isLoadingSubcategories = useAppSelector(
    (state) => state.isLoading.loadingFiltersSubcategories as boolean
  );

  // UseStates
  const [showModalDeleteSubcategory, setShowModalDeleteSubcategory] =
    useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [categorySelectedId, setCategorySelectedId] = useState("");
  const [editSubcategory, setEditSubcategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [idSubcategory, setIdSubcategory] = useState(0);
  const [positionY, setPositionY] = useState(0);

  // Columnas de la tabla
  const columns: TableColumn<ISubcategories>[] = [
    {
      name: "Subcategorías",
      style: { fontWeight: "bold" },
      selector: (row: any) => {
        if (showSaveButton && idSubcategory === row.id) {
          return (
            <InputEditStyled
              type="text"
              value={editSubcategory}
              onChange={(e) => setEditSubcategory(e.target.value)}
              onKeyDown={(e) => handleEnterEdit(e, row.id)}
            />
          );
        }
        return row.descripcion;
      },
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row: ISubcategories) => {
        if (showSaveButton && idSubcategory === row.id) {
          return (
            <div style={{ display: "flex", gap: "5px" }}>
              <ButtonSaveColorStyled
                color="lime"
                onClick={() => handleEditSubcategory(row.id)}
              >
                <p style={{ color: "black", fontWeight: "bold" }}>Guardar</p>
              </ButtonSaveColorStyled>
              <ButtonSaveColorStyled
                color="red"
                onClick={() => handleEditSubcategory(0)}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>Cancelar</p>
              </ButtonSaveColorStyled>
            </div>
          );
        }
        return (
          <ButtonColorStyled
            color="lime"
            onClick={() => handleShowInputEdit(row)}
          >
            <img src={EditIcon} alt="Editar" style={{ width: "15px" }} />
          </ButtonColorStyled>
        );
      },
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row: ISubcategories) => (
        <ButtonColorStyled
          color="orange"
          onClick={() => handleDeleteSubcategory(row.id)}
        >
          <img src={TrashIcon} alt="Eliminar" style={{ width: "15px" }} />
        </ButtonColorStyled>
      ),
    },
  ];

  // Agregar subcategoría
  const handleAddSubcategory = () => {
    if (newSubCategory === "") return;
    dispatch(
      httpAddOrUpdateSubcategoriesAsync(
        newSubCategory,
        Number(categorySelectedId),
        refreshSubCategories,
        setRefreshSubCategories,
        setShowSaveButton
      )
    );
    setNewSubCategory("");
  };

  // Mostrar input text para editar subcategoría
  const handleShowInputEdit = (row: ISubcategories) => {
    setIdSubcategory(row.id);
    setEditSubcategory(row.descripcion);
    // cambiar el boton editar por el boton guardar
    setShowSaveButton(true);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddSubcategory();
    }
  };

  // Editar subcategoría
  const handleEditSubcategory = (idSubcat: number) => {
    // si id es 0, cancelar operacion
    if (idSubcat === 0) {
      setShowSaveButton(false);
      return;
    }
    if (editSubcategory === "") return;

    dispatch(
      httpAddOrUpdateSubcategoriesAsync(
        editSubcategory,
        Number(categorySelectedId),
        refreshSubCategories,
        setRefreshSubCategories,
        setShowSaveButton,
        idSubcat
      )
    );
  };

  const handleEnterEdit = (e: KeyboardEvent<HTMLInputElement>, id: number) => {
    if (e.key === "Enter") {
      handleEditSubcategory(id);
    }
  };

  // Eliminar subcategoría
  const handleDeleteSubcategory = (idSubcategory: number) => {
    setIdSubcategory(idSubcategory);
    dispatch(httpValidateDeleteSubcategoryAsync(idSubcategory));
    setShowModalDeleteSubcategory(true);
  };

  // Peticion de subcategorias por categoria
  useEffect(() => {
    if (categorySelectedId !== "") {
      dispatch(httpSubcategoriesByCategoryAsync(Number(categorySelectedId)));
    }
  }, [categorySelectedId, refreshSubCategories]);

  useEffect(() => {
    setNewSubCategory("");
  }, [refreshSubCategories]);

  // Rastrear posición del scroll
  useEffect(() => {
    const handleScroll = () => {
      setPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TableStyled>
      {/* Selector de categoría */}
      <CategorySelector setCategorySelectedId={setCategorySelectedId} />
      {categorySelectedId !== "" && categorySelectedId !== null && (
        <>
          {isLoadingPossibleSubcategories ? (
            "Cargando..."
          ) : (
            /* Agregar subcategoría */
            <ContainerInputStyled>
              <InputStyled
                type="text"
                placeholder="Agregar subcategoría"
                value={newSubCategory}
                onChange={(e) => {
                  setNewSubCategory(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              />
              <ButtonAddStyled onClick={() => handleAddSubcategory()}>
                Agregar
              </ButtonAddStyled>
            </ContainerInputStyled>
          )}
          <DataTable
            responsive
            data={subcategoriesByCategory}
            columns={columns}
            progressComponent={"Cargando..."}
            progressPending={isLoadingSubcategories}
            noDataComponent="No se encuentran resultados"
          />
        </>
      )}
      {showModalDeleteSubcategory && (
        <ModalConfirmationCategory
          idSubcategory={idSubcategory}
          refreshSubCategories={refreshSubCategories}
          setRefreshSubCategories={setRefreshSubCategories}
          showModalDeleteSubcategory={showModalDeleteSubcategory}
          setShowModalDeleteSubcategory={setShowModalDeleteSubcategory}
          positionY={positionY}
        />
      )}
    </TableStyled>
  );
};

export default RelatedSubcategoriesTable;
