import styled from "styled-components";
import { Table } from "../models/TablesSelector";

export const HeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableStyled = styled.div`
  border: 2px solid ${(props) => props.theme.colors.main.primary};
  max-height: 500px;
  border-radius: 12px;
  padding: 10px;
  margin-top: 15px;
  overflow-y: scroll;
`;

export const TableStyledProducts = styled.div`
  border: 2px solid ${(props) => props.theme.colors.main.primary};
  border-radius: 12px;
  padding: 5px;
  margin-top: 15px;
  width: 100%;
  overflow: hidden;
`;

export const InputsContainerStyled = styled.div`
  display: flex;
  align-content: center;
  gap: 5px;
`;

export const ButtonStyled = styled.button`
  border: none;
  width: 100px;
  height: 42px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 12px;
  font-family: "Inter";
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.text.second};
  background-color: ${(props) => props.theme.colors.text.first};
  &:hover {
    color: ${(props) => props.theme.colors.text.first};
    background-color: ${(props) => props.theme.colors.main.primary};
  }
`;

export const InputEditStyled = styled.input`
  border-radius: 8px;
  width: 350px;
  border: none;
  padding: 5px;
  border: 2px solid ${(props) => props.theme.colors.main.primary};
`;

interface IButtonColor {
  color: string;
}

export const ButtonColorStyled = styled.button<IButtonColor>`
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.5);
  }
`;

export const ButtonSaveColorStyled = styled.button<IButtonColor>`
  border: none;
  width: 70px;
  height: 30px;
  background-color: ${(props) => props.color};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  &:hover {
    filter: brightness(0.9);
  }
`;

export const SelectStyled = styled.select`
  height: 100%;
  border: 2px solid ${(props) => props.theme.colors.main.primary};
  border-radius: 12px;
  padding: 10px;
  background: transparent;
  outline: none;
`;

export const SelectAttributeStyled = styled.select`
  height: 30px;
  border-radius: 12px;
  padding: 3px;
  background: white;
  border: 1px solid #b4b4b4;
  font-weight: bold;
  font-family: "Inter";
  outline: none;
  width: 100%;
`;

export const ValueStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .buttons {
    display: none;
  }

  &:hover {
    .subcategory {
      display: none;
    }
    .buttons {
      display: flex;
      gap: 15px;
    }
  }
`;

export const ContainerInputStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const InputStyled = styled.input`
  height: 20px;
  border: none;
  border-top: 2px solid ${(props) => props.theme.colors.text.fifth};
  border-bottom: 2px solid ${(props) => props.theme.colors.text.fifth};
  border-left: 2px solid ${(props) => props.theme.colors.text.fifth};
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  padding: 4px;
  background: transparent;
  outline: none;
  cursor: text;
`;

export const ButtonAddStyled = styled.button`
  border: none;
  width: 70px;
  height: 31.3px;
  cursor: pointer;
  font-weight: bold;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  font-family: "Inter";
  color: ${(props) => props.theme.colors.text.second};
  background-color: ${(props) => props.theme.colors.text.fifth};
  &:hover {
    color: ${(props) => props.theme.colors.text.first};
    background-color: ${(props) => props.theme.colors.main.primary};
    border: 2px solid ${(props) => props.theme.colors.text.first};
  }
`;

// Definitions

export const SelectDataTableContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

interface IButtonSelectorDataTable {
  order: Table;
  active: boolean;
}

export const ButtonSelectorDataTableStyled = styled.button<IButtonSelectorDataTable>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.main.secondary : "white"};
  color: ${(props) => (props.active ? "white" : "black")};
  padding: 10px;
  font-weight: bold;
  font-family: "Inter";
  border: 2px solid ${(props) => props.theme.colors.main.secondary};
  width: 130px;
  border-top-left-radius: ${(props) =>
    props.order === Table.left ? "12px" : 0};
  border-top-right-radius: ${(props) =>
    props.order === Table.right ? "12px" : 0};
  border-bottom-left-radius: ${(props) =>
    props.order === Table.left ? "12px" : 0};
  border-bottom-right-radius: ${(props) =>
    props.order === Table.right ? "12px" : 0};
  ${(props) =>
    props.order === Table.center || props.order === Table.right
      ? "border-left: 0"
      : ""};

  &:hover {
    background-color: ${(props) => props.theme.colors.main.secondary};
    color: white;
  }
`;

export const ExpandedColumnStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const ContainerPreviewStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
