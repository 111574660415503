import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingArticles: false,
  loadingGetValues: false,
  loadingAttribute: false,
  loadingAddAttribute: false,
  loadingEditSubcategory: false,
  loadingAttributesInArticle: false,
  loadingDeleteAttributeInArticle: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingDeleteAttributeInArticle: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingDeleteAttributeInArticle = action.payload;
    },
    setLoadingArticles: (state, action: PayloadAction<boolean>) => {
      state.loadingArticles = action.payload;
    },
    setLoadingGetValues: (state, action: PayloadAction<boolean>) => {
      state.loadingGetValues = action.payload;
    },
    setLoadingAttributes: (state, action: PayloadAction<boolean>) => {
      state.loadingAttribute = action.payload;
    },
    setLoadingAddAttribute: (state, action: PayloadAction<boolean>) => {
      state.loadingAddAttribute = action.payload;
    },
    setLoadingEditSubcategory: (state, action: PayloadAction<boolean>) => {
      state.loadingEditSubcategory = action.payload;
    },
    setLoadingAttributesInArticle: (state, action: PayloadAction<boolean>) => {
      state.loadingAttributesInArticle = action.payload;
    },
  },
});

export const {
  setLoadingArticles,
  setLoadingGetValues,
  setLoadingAttributes,
  setLoadingAddAttribute,
  setLoadingEditSubcategory,
  setLoadingAttributesInArticle,
  setLoadingDeleteAttributeInArticle,
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
