import React from "react";
import Spinner from "../../../../../../components/spinner/Spinner";

const SpinnerSale = () => (
  <div style={{ margin: "215px 0" }}>
    <Spinner></Spinner>
  </div>
);

export default SpinnerSale;
