import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loadingDataTransfer: false,
  loadingPurchaseOrder: false,
  loadingTransferEnabled: false,
  loadingPostDataTransfer: false,
};

export const loadingsSlice = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setLoadingDataTransfer: (state, action: PayloadAction<boolean>) => {
      state.loadingDataTransfer = action.payload;
    },
    setLoadingPurchaseOrder: (state, action: PayloadAction<boolean>) => {
      state.loadingPurchaseOrder = action.payload;
    },
    setLoadingTransferEnabled: (state, action: PayloadAction<boolean>) => {
      state.loadingTransferEnabled = action.payload;
    },
    setLoadingPostDataTransfer: (state, action: PayloadAction<boolean>) => {
      state.loadingPostDataTransfer = action.payload;
    }
  }
});

export const {
  setLoadingDataTransfer,
  setLoadingPurchaseOrder,
  setLoadingTransferEnabled,
  setLoadingPostDataTransfer
} = loadingsSlice.actions;

export default loadingsSlice.reducer;
