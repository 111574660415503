// Este slice corresponde a los filtros que se muestran al usuario

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Direcciones } from "../../models/Direcciones";
import { Item } from "../../models/Item";
import { OrdenDeCompra } from "../../models/OrdenDeCompra";
import { BuyProgress } from "../../types/enum/BuyProgress";
import { DeliveryMethod } from "../../types/enum/DeliveryMethod";
import { PayMethod } from "../../types/enum/PayMethod";
import { Sucursal } from "../../pages/ShippingProcess/models/Sucursal.model";

type State = {
  ordenDeCompra: OrdenDeCompra,
  sucursal: Sucursal,
}
const initialState: State = {
  ordenDeCompra: {
    id: null,
    carritoDeCompras: {
      id: "",
      items: []
    },
    estadoSolicitud: null,
    formaDePago: null,
    formaDeEntrega: null,
    direccionDeEntrega: null,
    linkCheckout: null,
    comentario: ""
  },
  sucursal: {
    calle: "",
    codigoPostal: "",
    depositos: [],
    empresa: {
      cuit: "",
      domicilioComercial: "",
      esDatoSistema: false,
      id: 0,
      logo: "",
      tamanioLogo: 0,
    },
    esDatoSistema: false,
    id: 0,
    localidad: {
      codigoPostal: "",
      esDatoSistema: false,
      id: 0,
      nombre: "",
    },
    localidadId: false,
    nombre: "",
    numero: "",
  },
};

export const buyOrderSlice = createSlice({
  name: "buyOrder",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<Item>) => {
      const index = state.ordenDeCompra.carritoDeCompras.items?.findIndex(m => m.articulo.id === action.payload.articulo.id);
      if (index === -1) {
        state.ordenDeCompra.carritoDeCompras?.items.push(action.payload);
      } else {
        state.ordenDeCompra.carritoDeCompras.items[index].cantidad += action.payload.cantidad;
      }
    },
    addCart: (state, action: PayloadAction<Item[]>) => {
      state.ordenDeCompra.carritoDeCompras.items = action.payload;
    },
    addOneProduct: (state, action: PayloadAction<Item>) => {
      const index = state.ordenDeCompra.carritoDeCompras.items?.findIndex(m => m.articulo.id === action.payload.articulo.id);
      if (index === -1) {
        state.ordenDeCompra.carritoDeCompras?.items.push(action.payload);
      } else {
        state.ordenDeCompra.carritoDeCompras.items[index].cantidad += 1;
      }
    },
    deleteProduct: (state, action: PayloadAction<Item>) => {
      const index = state.ordenDeCompra.carritoDeCompras.items?.findIndex(m => m.articulo.id === action.payload.articulo.id);
      if (index !== -1) {
        // remover uno en cantidad del producto
        state.ordenDeCompra.carritoDeCompras.items[index].cantidad -= 1;
      }
    },
    removeProductById: (state, action: PayloadAction<number>) => {
      state.ordenDeCompra.carritoDeCompras.items =
      state.ordenDeCompra.carritoDeCompras.items.filter(m => Number(m.articulo.id) !== action.payload);
    },
    setOrderState: (state, action: PayloadAction<BuyProgress>) => {
      state.ordenDeCompra.estadoSolicitud = action.payload;
    },
    setPayMethod: (state, action: PayloadAction<PayMethod>) => {
      state.ordenDeCompra.formaDePago = action.payload;
    },
    setDeliveryMethod: (state, action: PayloadAction<DeliveryMethod>) => {
      state.ordenDeCompra.formaDeEntrega = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<Direcciones | undefined>) => {
      state.ordenDeCompra.direccionDeEntrega = action.payload;
    },
    setLinkCheckout: (state, action: PayloadAction<string>) => {
      state.ordenDeCompra.linkCheckout = action.payload;
    },
    resetBasket: (state) => {
      state.ordenDeCompra = {
        id: null,
        carritoDeCompras: {
          id: "",
          items: []
        },
        estadoSolicitud: null,
        formaDePago: null,
        formaDeEntrega: null,
        direccionDeEntrega: null
      };
    },
    setComents: (state, action: PayloadAction<string>) => {
      state.ordenDeCompra.comentario = action.payload;
    },
    addSucursal: (state, action: PayloadAction<Sucursal>) => {
      state.sucursal = action.payload;
    },
  },
});

export const {
  addCart,
  addProduct,
  setComents,
  addSucursal,
  resetBasket,
  setPayMethod,
  addOneProduct,
  setOrderState,
  deleteProduct,
  setLinkCheckout,
  removeProductById,
  setDeliveryMethod,
  setShippingAddress,
} = buyOrderSlice.actions;

export default buyOrderSlice.reducer;
