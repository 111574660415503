import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import { addProvincias } from "../../features/partnerBusinessForm/partnerBusinessFormReducer";
import { IProvincias } from "../../models/BuyFormModels/Provincias";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";
import { viewNotification } from "../../utils/ViewNotification";

function useHttpObtenerProvinciasPorPais() {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpObtenerProvinciasPorPaisAsync =
    (): AppThunk => async (dispatch: any) => {
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.ObtenerProvincias}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${obtenerToken()?.replace(/['"]+/g, "")}`,
              empresaId: empresaId.toString(),
            },
          }
        );
        validateUnauthorized(response, empresaId);
        const data: IProvincias[] = await response.json();
        if (response.status === 200) {
          dispatch(addProvincias(data));
        }
      } catch (error) {
        viewNotification("Error al obtener las provincias", "danger");
      }
    };
  return {
    httpObtenerProvinciasPorPaisAsync,
  };
}

export default useHttpObtenerProvinciasPorPais;
