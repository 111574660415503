/* eslint-disable indent */
import { Routes } from "../routes";
import { AppThunk, RootState } from "../../app/store";
import env from "../../environment";
import { SocioNegocioFormDataDto } from "../../DTOs/SocioNegocioFormData";
import { useAppSelector } from "../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { routesApp } from "../../RoutesApp";
import { viewNotification } from "../../utils/ViewNotification";
import { setLoadingCrearSocio } from "../../features/loading/buyForm/loadingsReducer";
import validateUnauthorized from "../../utils/validateUnauthorized";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

function useHttpCrearSocioNegocio() {
  const navigate = useNavigate();
  const { aliasEmpresa } = useParams();
  const { obtenerToken } = useLocalStorageHook();
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const httpCrearSocioNegocioAsync =
    (
      formSocioDeNegocio: SocioNegocioFormDataDto,
      redirectToShippingPage: boolean
    ): AppThunk =>
    async (dispatch: any) => {
      const token = obtenerToken();
      // quitar comillas del token
      const tokenAccess = token?.replace(/['"]+/g, "");
      dispatch(setLoadingCrearSocio(true));
      try {
        const response = await fetch(
          `${env.seguridad}${Routes.crearSocioDeNegocio}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAccess}`,
              empresaId: empresaId.toString(),
            },
            body: JSON.stringify(formSocioDeNegocio),
          }
        );
        validateUnauthorized(response, empresaId);
        if (response.status === 200) {
          dispatch(setLoadingCrearSocio(false));
          if (!redirectToShippingPage) {
            viewNotification("Datos actualizados", "success");
          } else {
            navigate(`/${aliasEmpresa}${routesApp.shippingProcess}`);
          }
        } else {
          dispatch(setLoadingCrearSocio(false));
          viewNotification("Datos incorrectos", "danger");
        }
      } catch (error) {
        dispatch(setLoadingCrearSocio(false));
        viewNotification("Error al crear el socio de negocio", "danger");
      }
    };
  return {
    httpCrearSocioNegocioAsync,
  };
}

export default useHttpCrearSocioNegocio;
