import { Routes } from "../routes";
import env from "../../environment";
import { useAppSelector } from "../../app/hooks";
import { AppThunk, RootState } from "../../app/store";
import validateUnauthorized from "../../utils/validateUnauthorized";
import { viewNotification } from "../../utils/ViewNotification";
import { IErrorPaymentGateway } from "../../DTOs/Errors/ErrorPaymentGatewayDto";
import useLocalStorageHook from "../../utils/useLocalStorageHook";

const useHttpGetUrlToAutorizeOperations = () => {
  const empresaId = useAppSelector(
    (state: RootState) => state.appConfigurationState.empresaId
  );
  const { obtenerToken } = useLocalStorageHook();

  const httpGetUrlToAutorizeOperationsAsync = (): AppThunk => async () => {
    // Token
    const token = obtenerToken();
    const tokenAccess = token?.replace(/['"]+/g, "");
    try {
      const response = await fetch(
        `${env.operaciones}${Routes.getUrlAuthorizeOperations}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenAccess}`,
            empresaId: empresaId.toString(),
          },
        }
      );
      validateUnauthorized(response, empresaId);
      const data = await response.json();
      if (data.StatusCode === "ERROR") {
        const dataError: IErrorPaymentGateway = data;
        viewNotification(dataError.EntityErrors.Id[0], "danger");
      }
      if (response.status === 200) {
        window.open(data.url);
      }
    } catch (error) {
      viewNotification("Error, intente nuevamente", "warning");
    }
  };
  return { httpGetUrlToAutorizeOperationsAsync };
};

export default useHttpGetUrlToAutorizeOperations;
